import { Component, OnInit,Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LevelService } from 'src/app/services/level.service';

@Component({
  selector: 'app-wg-wp-groups',
  templateUrl: './wg-wp-groups.component.html',
  styleUrls: ['./wg-wp-groups.component.scss']
})
export class WgWpGroupsComponent implements OnInit {

  loading: boolean = true;
  interests: any = [];

  @Input() id: string;
  @Input() role: number;
  @Input() isProject: boolean;

  constructor(
    private _levelService: LevelService,
    private _snackBar: MatSnackBar,
  ) { }

  async ngOnInit(): Promise<void> {

    try {
      const result: any = await this._levelService.getLevelChildren(
        this.id,  
        null
      ).toPromise();

      if (result.success) {
        this.interests = result.data;
      }
      else {
        this._snackBar.open('Error: ' + result.message, 'OK', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    } catch (error: any) {
      let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        this._snackBar.open('Error: ' + errorMessage, 'OK', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
    } finally {
      this.loading = false;
    }

  }

}
