<div class="container-fluid h-100">
    <div class="row h-100">
        <div class="col">

            <ng-container *ngIf="loading">

                <div class="text-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

            </ng-container>
            <ng-container *ngIf="!loading">


                <div class="h-100">

                    <mat-toolbar class="mb-4 flex-wrap" style="height: auto; min-height: 64px;">

                        <div class="d-flex align-items-center justify-content-center justify-content-md-start col-12 col-md-6">

                            <h1 class="mb-0">
                                Upcoming events
                            </h1>

                        </div>

                        <button mat-button color="primary" class="mx-auto me-md-0 ms-md-auto" routerLink="../">Calendar</button>

                    </mat-toolbar>
                  
                    <ng-container *ngIf="events.length == 0; else eventView">
                        {{'No events.' | translate}}
                    </ng-container>
            
                    <ng-template #eventView>

                        <div class="row">
                            <div class="col">


                                    <mat-list role="list">
                                        <mat-list-item role="listitem" *ngFor="let ev of events">

                                            <div mat-list-icon class="pointer text-primary" [routerLink]="ev.link">
                                                <mat-icon>today</mat-icon>
                                            </div>
                                            
                                            
                                            <div mat-line class="pointer" [routerLink]="ev.link">{{ ev.title }}
                                            </div>
                                            <div class="mat-caption text-muted" style="font-size: .8rem" mat-line>
                                                {{ ev.start_date | date }} - {{ ev.end_date | date }}</div>
            
                                            
                                        </mat-list-item>
                                    </mat-list>

                                </div>

                           
                        </div>

                       
            
                     
                    </ng-template>


                </div>


            </ng-container>

        </div>
    </div>
</div>