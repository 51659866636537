<div class="container mt-4 mt-lg-8">
    <div class="row">
        <div class="col">

            <ng-container *ngIf="loading">

                <div class="text-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

            </ng-container>
            <ng-container *ngIf="!loading">

                <div class="d-flex mb-lg-4 text-center text-md-start">

                    <div class="text-primary me-4 d-none d-md-block">
                        <mat-icon>grid_view</mat-icon>
                    </div>

                    <div class="display-5">Your collaboration platforms<div class="text-muted h4 mt-2 mt-md-0">Here is the list of
                            all the platforms where you participate or your are the owner.</div>
                    </div>

                </div>



                <div class="mb-4" *ngIf="user && user.credits && user.credits > 0">

                    <app-create-platform-user [credits]="user.credits"></app-create-platform-user>

                </div>

                <mat-toolbar class="workspace-toolbar mb-md-3">

                    <div class="d-flex flex-wrap align-items-center w-100">

                        <div class="flex-fill filter-form d-none d-md-block">
                            <mat-form-field class="mt-3">
                                <input matInput placeholder="Filter" [ngModel]="searchString"
                                    (ngModelChange)="changed($event)">
                                <mat-icon matSuffix class="me-2 pointer">search</mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="menu mx-auto mx-md-0">
                            <button mat-raised-button [color]="(view == 'grid') ? 'primary' : ''"
                                (click)="changeView('grid')">
                                <mat-icon>grid_view</mat-icon>
                            </button>
                            <button mat-raised-button [color]="(view == 'list') ? 'primary' : ''" class="ms-2"
                                (click)="changeView('list')">
                                <mat-icon>view_list</mat-icon>
                            </button>
                        </div>

                    </div>

                </mat-toolbar>

                <ng-container *ngIf="levels?.length == 0">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="mb-0">
                                <ng-container *ngIf="!searchActivate; else activated">
                                    {{"You don't collaborate in any platform" | translate}}
                                </ng-container>
                                <ng-template #activated>
                                    {{"No results found" | translate}}
                                </ng-template>
                            
                            </h4>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="levels?.length > 0">




                    <ng-container *ngIf="view == 'grid'; else list">
                        <app-workspace-grid-view [levels]="levels"></app-workspace-grid-view>
                    </ng-container>
                    <ng-template #list>
                        <app-workspace-list-view [levels]="levels"></app-workspace-list-view>
                    </ng-template>



                </ng-container>

            </ng-container>

        </div>
    </div>
</div>