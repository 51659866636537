<mat-toolbar class="d-flex align-items-center my-4">

    <h1 class="mat-h1 mb-0">Meeting dates</h1>


    <ng-container *ngIf="role == 0 || role == 1">
        <ng-container *ngIf="create; else showResults">

            <button mat-raised-button (click)="cancel()" class="ms-auto">
                <div class="d-flex align-items-center">
                    <span class="material-icons">
                        close
                    </span> {{'Cancel' | translate}}
                </div>
            </button>

        </ng-container>
        <ng-template #showResults>
            <button mat-raised-button (click)="addEvent()" class="ms-auto">
                <div class="d-flex align-items-center">
                    <span class="material-icons">
                        add
                    </span> {{'Add meeting' | translate}}
                </div>
            </button>
        </ng-template>

    </ng-container>

</mat-toolbar>


<div class="my-4">
    <ng-container *ngIf="create; else showResults">

        <div class="mx-2">

            <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

                <mat-card>

                    <h2>{{(edit) ? 'Edit meeting' : 'Add meeting' | translate}}</h2>

                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>
                            {{'Meeting title' | translate}} *
                        </mat-label>
                        <input matInput formControlName="title">
                    </mat-form-field>

                    <div class="mb-3">
                        <mat-checkbox formControlName="all_day" (change)="allDayClick(f.all_day.value)">{{'All day' |
                            translate}}
                        </mat-checkbox>
                    </div>

                    <div>

                        <div class="d-flex">

                            <mat-form-field appearance="fill" class="me-4">
                                <mat-label>{{'Start date' | translate}} *</mat-label>
                                <input matInput [matDatepicker]="picker" formControlName="start_date">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>



                            <div class="d-flex" *ngIf="!f.all_day.value">

                                <mat-form-field appearance="outline" class="me-2">
                                    <mat-label>{{'Hour' | translate}}</mat-label>
                                    <mat-select formControlName="start_hour">
                                        <mat-option *ngFor="let hour of hours" [value]="hour">
                                            <ng-container *ngIf="(hour < 10)">0{{ hour }}</ng-container>
                                            <ng-container *ngIf="(hour > 9)">{{ hour }}</ng-container>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{'Minutes' | translate}}</mat-label>
                                    <mat-select formControlName="start_minutes">
                                        <mat-option *ngFor="let minute of minutes" [value]="minute">
                                            <ng-container *ngIf="(minute < 10)">0{{ minute }}</ng-container>
                                            <ng-container *ngIf="(minute > 9)">{{ minute }}</ng-container>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>



                        </div>

                        <div class="d-flex">

                            <mat-form-field appearance="fill" class="me-4">
                                <mat-label>{{'End date' | translate}}</mat-label>
                                <input matInput [matDatepicker]="picker1" [min]="f.start_date.value"
                                    formControlName="end_date">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>

                            <div *ngIf="!f.all_day.value">


                                <mat-form-field appearance="outline" class="me-2">
                                    <mat-label>{{'Hour' | translate}}</mat-label>
                                    <mat-select formControlName="end_hour">
                                        <mat-option *ngFor="let hour of hours" [value]="hour">
                                            <ng-container *ngIf="(hour < 10)">0{{ hour }}</ng-container>
                                            <ng-container *ngIf="(hour > 9)">{{ hour }}</ng-container>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{'Minutes' | translate}}</mat-label>
                                    <mat-select formControlName="end_minutes">
                                        <mat-option *ngFor="let minute of minutes" [value]="minute">
                                            <ng-container *ngIf="(minute < 10)">0{{ minute }}</ng-container>
                                            <ng-container *ngIf="(minute > 9)">{{ minute }}</ng-container>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </div>

                    </div>

                    <div class="col-12 event-editor">
                        <div class="mat-caption mb-2">{{'Description' | translate}} ({{ 'optional' | translate }})</div>

                        <div class="editor-size">
                            <app-my-editor [levelid]="id" [data]="text" (output)="onCkEditorValue($event)">
                            </app-my-editor>
                        </div>
                    </div>

                    <div class="mt-3">
                        <button mat-raised-button color="primary" class="me-2" type="submit">
                            <ng-container *ngIf="!data?.current; else updateButton">
                                <span class="material-icons">
                                    save
                                </span> {{'Save' | translate}}
                            </ng-container>

                            <ng-template #updateButton>
                                {{'Update' | translate}}
                            </ng-template>
                        </button>
                        <button mat-raised-button (click)="cancel()">{{'Cancel' | translate}}</button>
                    </div>

                </mat-card>

            </form>

        </div>

    </ng-container>

    <ng-template #showResults>

        <div class="d-none d-sm-block">
            <button mat-button [color]="(subpage) ? 'primary' : ''" (click)="navigatePage('/')">{{'Actual meetings' |
                translate}}</button>

            <button mat-button [color]="(subpage != 'past') ? 'primary' : ''" (click)="navigatePage('past')">{{'Past
                meetings' | translate}}</button>

            <button mat-button [color]="(subpage != 'calendar') ? 'primary' : ''"
                (click)="navigatePage('calendar')">{{'Calendar' | translate}}</button>
        </div>

        <div class="d-sm-none">

            <mat-tab-group [selectedIndex]="mobileTabMenus.indexOf(subpage)"
                animationDuration="0ms" dynamicHeight>
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{'Actual meetings' | translate}}
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{'Past meetings' | translate}}
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{'Calendar' | translate}}
                    </ng-template>
                </mat-tab>
            </mat-tab-group>

        </div>

        <ng-container *ngIf="resultsLength == 0 && subpage != 'calendar'">

            <ng-container [ngSwitch]="subpage">
                <ng-container *ngSwitchCase="'404'">
                    <div class="m-2">                        
                        {{ 'Page not found' | translate }} ...
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <div class="m-2">
                        {{'There are no upcoming meeting dates' | translate}}
                    </div>
                </ng-container>

            </ng-container>
        </ng-container>

        <ng-container *ngIf="resultsLength > 0 || subpage == 'calendar'">

            <ng-container [ngSwitch]="subpage">
              
                <ng-container *ngSwitchCase="'calendar'">
                    <div class="my-2">
                        <mwl-calendar-month-view [viewDate]="viewDate" [events]="events2" [refresh]="refresh"
                            [activeDayIsOpen]="activeDayIsOpen"></mwl-calendar-month-view>
                    </div>
                </ng-container> 

                <ng-container *ngSwitchDefault>
                    <table mat-table [dataSource]="meetingData" class="mat-elevation-z2 w-100 mt-3 example-table"
                    multiTemplateDataRows>
    
    
    
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> {{'Title' | translate}} </th>
                        <td mat-cell *matCellDef="let element">
    
                            <div class="d-flex align-items-center w-100">
    
    
                                <button class="me-2" style="margin-left: -5px" mat-icon-button color="primary" (click)="expandedElement = expandedElement === element ? null : element">
                                    <ng-container *ngIf="expandedElement !== element">
                                        <mat-icon>chevron_right</mat-icon>
                                    </ng-container>
                                    <ng-container *ngIf="expandedElement === element">
                                        <mat-icon>expand_more</mat-icon>
                                    </ng-container>                            
                                </button>
    
                                <div class="flex-grow-1 d-flex align-items-center" style="cursor: pointer;" (click)="expandedElement = expandedElement === element ? null : element">
                                    <div class="text-success">
                                        <mat-icon>lock_clock</mat-icon>
                                    </div>
    
                                    <div class="ms-2">
                                        {{ element.title | truncate }}
                                    </div>
                                </div>
    
    
                            </div>
    
                            
    
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef> {{'Date' | translate}} </th>
                        <td mat-cell *matCellDef="let element">
    
    
                            <ng-container *ngIf="element.all_day">
    
                                <div class="d-flex">
    
                                    <div>{{ element.start_date | date: "longDate" }}</div>
                                    <div *ngIf="element.end_date">
                                        &nbsp;- {{ element.end_date | date: "longDate" }}
                                    </div>
    
                                </div>
    
                            </ng-container>
    
                            <ng-container *ngIf="!element.all_day">
    
                                <div class="d-flex flex-column">
    
                                    <div>{{ element.start_date | date: "longDate" }} - <span class="text-muted"
                                            style="font-size: .9em">{{ element.start_date | date: "shortTime" }}</span></div>
                                    <div *ngIf="element.end_date">
                                        {{ element.end_date | date: "longDate" }} - <span class="text-muted"
                                            style="font-size: .9em">{{ element.end_date | date: "shortTime" }}</span>&nbsp;
                                    </div>
    
                                </div>
    
                            </ng-container>
    
    
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="created_by">
                        <th mat-header-cell *matHeaderCellDef> {{ 'Author' | translate }} </th>
                        <td mat-cell *matCellDef="let element">
    
                            <div class="d-flex align-items-center">
                                <app-user-avatar width="25" fontSize="12px" [info]="element.user_id">
                                </app-user-avatar>
    
                                <!--
                                <div class="ms-1 mat-caption d-flex">
                                    <div class="me-4">
                                        {{ element.user_id.full_name | truncate : 45 }}
                                    </div>
                                </div>-->
                            </div>
    
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> {{'Action' | translate}} </th>
                        <td mat-cell *matCellDef="let element">
    
                            <ng-container *ngIf="role == 0 || role == 1">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="editMeeting(element)">
                                        <mat-icon>create</mat-icon>
                                        <span>{{'Edit meeting' | translate}}</span>
                                    </button>
                                    <button mat-menu-item (click)="deleteMeeting(element._id)">
                                        <mat-icon>delete</mat-icon>
                                        <span>{{'Delete meeting' | translate}}</span>
                                    </button>
                                </mat-menu>
                            </ng-container>
    
    
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                            <div class="example-element-detail"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
    
                                <div class="example-element-description">
    
                                    <h2 class="mt-4">{{ element.title }}</h2>
    
                                    <div class="d-flex align-items-center text-muted my-2 mb-4">
                                        <app-user-avatar width="25" fontSize="12px" [info]="element.user_id">
                                        </app-user-avatar>
    
                                        <div class="ms-1 mat-caption d-flex">
                                            <div class="me-4">
                                                {{ element.user_id.full_name | truncate : 45 }}
                                            </div>
                                            <div matTooltip="{{ element.date_created | date: 'medium' }}">
                                                {{ element.date_created | myDate }}
                                            </div>
    
                                        </div>
                                    </div>
    
                                    <div class="pb-4">
                                        <!--<label class="mat-header-cell">{{'Description' | translate}}</label>-->
                                        <div class="font-italic" *ngIf="element?.description">
                                            <div class="ck-content" [innerHTML]="element.description | sanitizeHtml"></div>
                                        </div>
                                        <div class="font-italic" *ngIf="!element?.description">/</div>
                                    </div>
    
                                   
    
    
                                </div>
    
    
    
                            </div>
                        </td>
                    </ng-container>
    
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
                        [class.example-expanded-row]="expandedElement === element"
                        >
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
    
                </ng-container>



            </ng-container>

          

        </ng-container>

    </ng-template>
</div>