<div class="d-flex align-items-center mb-2">
    <h2 class="mb-0" mat-dialog-title>
        Change profile picture
    </h2>
    <div class="ms-auto">
        <button mat-button color="secondary" [mat-dialog-close]="false">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>


<mat-dialog-content class="pb-2">
    <div class="d-flex align-items-center">

        <div style="width: 200px; margin-top: -30px;" class="d-flex justify-content-center">

            <div class="position-relative">                
                <ng-container *ngIf="data.user.avatar">
                    <div class="admin-edit-button-above-image">
                        <button mat-mini-fab color="accent" class="ms-auto" (click)="removeImage()">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="croppedImage">
                    <img [src]="croppedImage" class="user-avatar" style="width: 80px!important; height: 80px!important;" />
                </ng-container>
                <ng-container *ngIf="!croppedImage">
                    <app-user-avatar width="80" fontSize="16px" [info]="data.user"></app-user-avatar>
                </ng-container>
            </div>

        </div>
        <div class="flex-grow-1">

            <ng-container *ngIf="!imageChangedEvent">
                <app-upload-file (data)="uploadedImage($event)" dragDrop="true" [restrictFileType]="['image/jpeg', 'image/png', 'image/gif']" avatar="true"></app-upload-file>
            </ng-container>

            <ng-container *ngIf="imageChangedEvent">
                <div class="box">
                    <image-cropper [imageURL]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio" cropperMinWidth="130" cropperMinHeight="130" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>  
            </ng-container>

        </div>

    </div>
</mat-dialog-content>

<mat-dialog-actions>    

    <ng-container *ngIf="imageChangedEvent">
        <div class="d-flex justify-content-center mt-2">
            <button mat-raised-button color="primary" (click)="saveCropped()">Crop &amp; Save</button>
        </div>
    </ng-container>

    <ng-container *ngIf="!imageChangedEvent">
        <button mat-raised-button [mat-dialog-close]="false">Close</button>
    </ng-container>

</mat-dialog-actions>