<div class="my-2 h-100">

    <mat-sidenav-container class="h-100">

        <mat-sidenav-content id="mainSidebar" cdkScrollable>

            <div class="mx-2 mx-lg-5">


                <div class="row" *ngIf="_user && _user.credits && _user.credits > 0">
                    <div class="col">
                        <div class="mb-4">

                            <app-create-platform-user [credits]="_user.credits">
                            </app-create-platform-user>

                        </div>

                    </div>
                </div>

                <div class="mat-display-1 heading-stripe">{{"What's new" | translate}}</div>


                <div class="row">
                    <div class="col">

                        <ng-container *ngIf="_activities.length == 0">
                            <h4>
                                {{'No activities.' | translate}}
                            </h4>
                        </ng-container>

                        <ng-container *ngIf="_activities.length > 0">


                            <ng-container *ngFor="let a of _activities">

                                <app-activity-block [type]="a.activity_type" [level]="a.level_type"
                                    [data]="a">
                                </app-activity-block>

                            </ng-container>


                        </ng-container>



                    </div>
                </div>
            </div>

        </mat-sidenav-content>


        <mat-sidenav #drawer class="sidenav"
            [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
            [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="((isHandset$ | async) === false)"
            position="end" class="col-4" style="background: none;">

            <div class="mx-3">

                <div class="my-2">
                    <h3 class="mat-h3 heading-stripe fw-bold">{{'My tasks' | translate}}</h3>

                    <ng-container *ngIf="_tasks.length == 0">
                        <div class="p-2">
                            {{'No upcoming tasks.' | translate}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="_tasks.length > 0">

                        <mat-list role="list">
                            <mat-list-item role="listitem" *ngFor="let t of _tasks">

                                <div class="pointer" routerLink="{{t.link}}">
                                    <div mat-line matTooltip="{{ t.name }}">{{ t.name | truncate : 25 }}
                                    </div>
                                    <div class="mat-caption text-muted text-uppercase"
                                        style="font-size: .7em;" mat-line>{{'Due' | translate}}: {{
                                        t.due | date }}</div>
                                </div>

                                <div class="ms-auto">
                                    <mat-select style="width: 55px;" [(ngModel)]="t.userStatus"
                                        (selectionChange)="changeStatus(t)">
                                        <mat-select-trigger>
                                            <div class="d-flex align-items-center">
                                                <ng-container *ngIf="t.userStatus == 0">
                                                    <mat-icon class="text-danger">clear</mat-icon>
                                                </ng-container>
                                                <ng-container *ngIf="t.userStatus == 50">
                                                    <mat-icon class="text-warning">build</mat-icon>
                                                </ng-container>
                                                <ng-container *ngIf="t.userStatus == 100">
                                                    <mat-icon class="text-success">done</mat-icon>
                                                </ng-container>
                                            </div>
                                        </mat-select-trigger>
                                        <mat-option value="0">
                                            <mat-icon class="text-danger">clear</mat-icon>
                                            {{'Not started' | translate}}
                                        </mat-option>
                                        <mat-option value="50">
                                            <mat-icon class="text-warning">build</mat-icon>
                                            {{'In progress' | translate}}
                                        </mat-option>
                                        <mat-option value="100">
                                            <mat-icon class="text-success">done</mat-icon>
                                            {{'Finished' | translate}}
                                        </mat-option>
                                    </mat-select>
                                </div>

                            </mat-list-item>
                        </mat-list>


                    </ng-container>

                </div>


                <div class="my-4 mt-5">
                    <h3 class="mat-h3 heading-stripe fw-bold">{{'Events this month' | translate}}</h3>

                    <ng-container *ngIf="_events.length == 0">
                        <div class="p-2">
                            {{'No events.' | translate}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="_events.length > 0">

                        <mat-list role="list">
                            <mat-list-item role="listitem" *ngFor="let e of _events">
                                <button mat-button color="primary" [routerLink]="e.link"
                                    class="text-uppercase">{{'View event' | translate}}</button>
                                <div mat-line matTooltip="{{ e.title }}">{{ e.title | truncate : 25 }}
                                </div>
                                <div class="mat-caption text-muted" style="font-size: .8rem" mat-line>{{
                                    e.start_date | date }}</div>
                            </mat-list-item>
                        </mat-list>

                    </ng-container>
                </div>

            </div>

        </mat-sidenav>



    </mat-sidenav-container>

</div>