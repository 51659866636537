<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

    <div class="d-flex flex-wrap align-items-center mb-2">
        <h2 mat-dialog-title class="d-flex align-items-center mb-0">
            <ng-container *ngIf="!data.edit">
                <mat-icon>add</mat-icon> 
                {{'Add' | translate}}
            </ng-container>
            <ng-container *ngIf="data.edit">
                <mat-icon>create</mat-icon> 
                {{'Edit' | translate}}
            </ng-container>        
        </h2>

        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content>

        <mat-form-field appearance="fill" class="w-100">
            <mat-label>
                {{'Milestone name' | translate}}
            </mat-label>
            <input matInput formControlName="name">
        </mat-form-field>

       
        <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{'Choose a due date' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="due" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <div class="editor-size">

            <div class="mat-caption mb-2">{{'Description' | translate}} ({{ 'optional' | translate }})</div>

            <app-my-editor [levelid]="data.level_id" [data]="text" (output)="onCkEditorValue($event)"></app-my-editor>                            
        </div>


    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button color="primary" class="mr-2" type="submit">
            <ng-container *ngIf="!data.edit">
                <span class="material-icons">
                    add
                </span> {{'Add' | translate}}
            </ng-container>

            <ng-container *ngIf="data.edit">
                {{'Update' | translate}}
            </ng-container>
        </button>
        <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
    </mat-dialog-actions>


</form>