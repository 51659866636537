import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IncomingMessage } from 'src/app/incoming-message';
import { ChatService } from 'src/app/services/chat.service';
import { SocketService } from 'src/app/services/socket.service';
import { UsersService } from 'src/app/services/users.service';

import { ChatInfo } from './chat-info';
import { ServerReply } from './server-reply';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit {

  @Input() index: number;  
  @Input() chatId: string;
  @Output() closeBox = new EventEmitter<number>();

  loading: boolean = true;
  submitted: boolean = false;
  mainForm!: UntypedFormGroup;
  chatInfo: ChatInfo;

  constructor(
    private _fb: UntypedFormBuilder, 
    private _chatService: ChatService,
    private _socketService: SocketService,
    private _userService: UsersService
  ) { }

  ngOnInit(): void {    
    //this.getChatInfo(this.chatId);   
    
    this._chatService.getChatMessages(this.chatId).subscribe((serverReply: ServerReply|undefined) => {

      if(serverReply.success) {

        this.loading = false;

        this.chatInfo = {
          messages: serverReply.data.messages,
          users: serverReply.data.users
        }
        
        this.createForm();

      }
      else {
        this.closeBox.emit(this.index);
      }

    }, (err: HttpErrorResponse) => {

      if(err.status != 200) 
        this.closeBox.emit(this.index);

    });


    this._socketService.on('incoming-message', (data: IncomingMessage) => {

        if(this.chatId == data.chat_id) {

          if(!data.group_message) {
            this.chatInfo.messages.unshift({
              user_id: data.user._id,
              date_created: data.date_created,
              text: data.message,
              him: true
            })
          }
          else {
            console.log('Group message')
          }
        }
    });
  }

  createForm() {
    this.mainForm = this._fb.group({
      message: ['', [Validators.required]],
      chatId: [this.chatId, Validators.required]      
    });
  }
/*
  async getChatInfo(chatId: string): Promise<any> {

    try {
    
      let serverReply:ServerReply|any = await this._chatService.getChatMessages(chatId).toPromise();

      console.log(2)

      this.loading = false;

      if(serverReply.success) {

        this.chatInfo = {
          messages: serverReply.data.messages,
          users: serverReply.data.users
        }
        
        this.createForm();
      }
      else {
        // Experiment 
        this.closeBox.emit(this.index);
      }
    } catch(error) {
      // Experiment 
      this.closeBox.emit(this.index);
    }

    console.log(4)
    
  }*/

  closeChatBox() {

    this._userService.saveUserSettings('chat_id', this.chatId).subscribe((result: ServerReply) => {
      this.closeBox.emit(this.index);
    }, (err: HttpErrorResponse) => {
      if(err.status != 200) {
        console.log('Not saved')
      }
    })
    
  }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this._chatService.sendChat(this.chatInfo.users, this.mainForm.value.message).subscribe((result: ServerReply) => {

        if (result.success) {
          this.chatInfo.messages.unshift({
            text: this.mainForm.value.message,
            date_created: result.data.date_created
          })

          this._socketService.emit('send-message', {
            chat_id: result.data.chat_id,
            user_id: result.data.user_id,
            users: this.chatInfo.users, 
            message: this.mainForm.value.message,
            date_created: result.data.date_created
          })

          this.mainForm.patchValue({
            message: ''
          })
        }

      }, (err) => {

        if (err.status != 200) {

        }
        
      });

     

    }

  }

}
