import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from '../services/layout.service';
import { LevelService } from '../services/level.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {
  
  loading: boolean = true;
  levelId: string;
  levelInfo: any;

  error: boolean = false;
  errorMsg: string;

  constructor(
    private _route: ActivatedRoute,
    private _layoutService: LayoutService,
    private _levelService: LevelService
  ) { }

  ngOnInit(): void {

    this.levelId = this._route.snapshot.paramMap.get('id');

    if(this._route.snapshot.data.hideHeader) {

      // Set timeout because NG0100 error
      setTimeout(()=> {
        this._layoutService.hideToolbar();
        this._layoutService.hideFooter();
      }, 0)
      
    }    
    
    
    this._levelService.getLevelPublic(this.levelId).subscribe((result: any) => {

      this.loading = false;

      if (result.success) {
        this.levelInfo = result.data;
      }
      else {
        this.error = true;
        this.errorMsg = result.message;
      }

    }, err => {

      if(err.status != 200) {

        this.loading = false;

        this.error = true;
        this.errorMsg = err;

      }

    });


  }


  openLink(link: string) {
    window.open(window.location.protocol + '//' + window.location.host + '/' + link, "_blank");
  }

}
