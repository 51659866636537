import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-news-dialog',
  templateUrl: './news-dialog.component.html',
  styleUrls: ['./news-dialog.component.scss']
})
export class NewsDialogComponent implements OnInit {
 
  text: string = '';
  mainForm: UntypedFormGroup;
  submitted: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<NewsDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _newsService: NewsService
  ) { }

  ngOnInit(): void {
    this.createForm(this.data.current);

    this.mainForm.patchValue({
      level_id: this.data.level_id
    });
  }

  createForm(data?: any) {
    this.mainForm = this._fb.group({
      level_id: ['', Validators.required],
      name: [(data && data.name) ? data.name : '', [Validators.required]],
      description: [(data && data.description) ? data.description : '', Validators.required]     
    });

    this.text = (data && data.description) ? data.description : '';
  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      let action = (this.data.current) ?  
        this._newsService.editNews(
          this.data.current._id,
          this.mainForm.value.level_id,
          this.mainForm.value.name,
          this.mainForm.value.description
        ) :
        this._newsService.addNews(
          this.mainForm.value.level_id,
          this.mainForm.value.name,
          this.mainForm.value.description
        );

        action.subscribe((result: any) => {
  
        if (result.success) {
          this._dialogRef.close(result.data);
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

  onCkEditorValue(text: string) {
    this.mainForm.patchValue({
      description: text
    });
  }

}
