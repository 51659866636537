<ng-container *ngIf="!loading">

    <ng-container *ngIf="error">
        {{ errorMsg }}
    </ng-container>

    <ng-container *ngIf="!error">

        <mat-card class="m-2 pointer" (click)="openLink(levelInfo.link)">

            

                <div class="mat-caption text-muted text-uppercase" style="font-size: .6rem;">Collaboration platform</div>

                <app-level-background [data]="levelInfo.background_image_url" rootClass="export-background-height"></app-level-background>   

                

                <div class="mx-2" id="platform-avatar" style="min-width: 80px; width: 80px; height: 80px; margin-top: -50px;">
                    <app-level-avatar [data]="levelInfo.avatar_url" rootClass="export-level-avatar"></app-level-avatar>
                </div>

                <div class="mt-1">
                    <div class="ms-2">
                        <div class="mb-1 fw-bold">
                            {{ levelInfo.name }}
                        </div>
                        <mat-card-subtitle class="mb-0" style="font-size: .9em;">{{ levelInfo.description | mystriphtml | truncate:150 }}</mat-card-subtitle>

                    </div>

                </div>


                

                <div class="mt-2 w-100">
                    <button mat-raised-button class="w-100" color="primary">Join Us</button>
                </div>

                

                
          

        </mat-card>

        
    </ng-container>

</ng-container>