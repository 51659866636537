<div class="d-flex align-items-center mb-2">
    <h2 class="mb-0" mat-dialog-title>
        Change profile background picture
    </h2>
    <div class="ms-auto">
        <button mat-button color="secondary" [mat-dialog-close]="false">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>


<mat-dialog-content class="pb-2">
    
    <div class="box p-2 mt-3">            
        <div style="position: relative;" *ngIf="!croppedImage">
            <app-level-background [data]="data.user.background_image_url"></app-level-background>
            
            <div class="admin-edit-button-above-image" *ngIf="data.user.background_image_url">
                <button mat-mini-fab color="accent" class="ms-auto" (click)="removeImage()">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>

        </div>
        <div *ngIf="croppedImage" class="mb-2">
            <div [ngStyle]="{ 'background-image': 'url(' + croppedImage + ')' }" class="edit-header-image">                    
            </div>
        </div>
        
        <ng-container *ngIf="!imageChangedEvent">
            <app-upload-file (data)="uploadedImage($event)" dragDrop="true" [restrictFileType]="['image/jpeg', 'image/png', 'image/gif']" background="true"></app-upload-file>
        </ng-container>
        
        <ng-container *ngIf="imageChangedEvent">
            <div class="box">
                <image-cropper [imageURL]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio" cropperMinWidth="130" cropperMinHeight="130" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed()"></image-cropper>
            </div>                        
        </ng-container>
        
    </div>

</mat-dialog-content>

<mat-dialog-actions>    

    <ng-container *ngIf="imageChangedEvent">
        <div class="d-flex justify-content-center mt-2">
            <button mat-raised-button color="primary" (click)="saveCropped()">Crop &amp; Save</button>
        </div>
    </ng-container>

    <ng-container *ngIf="!imageChangedEvent">
        <button mat-raised-button [mat-dialog-close]="false">Close</button>
    </ng-container>

</mat-dialog-actions>