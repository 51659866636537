<div class="chat-box">

    <mat-toolbar class="d-flex align-items-center">
        Community

        <div class="ms-auto">
            <button class="small-btn" mat-button (click)="closeSideNavButton()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <mat-list role="list" *ngIf="usersList">

        <div mat-subheader>People ({{ usersList.length }})</div>
        <ng-container *ngFor="let user of usersList">
            <mat-list-item role="listitem" matRipple (click)="startNewChat(user._id)">
                <div mat-card-avatar class="me-3">
                    <app-user-avatar width="40" fontSize="12px" [info]="user"></app-user-avatar>
                </div>
                <div mat-line>{{ user.full_name }}</div>
                <div class="status-dot me-2" [ngClass]="{ 'online': user?.online }"></div><!-- .online -->
            </mat-list-item>
        </ng-container>
    
    </mat-list>

</div>