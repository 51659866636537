import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-workspace-list-view',
  templateUrl: './workspace-list-view.component.html',
  styleUrls: ['./workspace-list-view.component.scss']
})
export class WorkspaceListViewComponent implements OnInit {

  _levels: any = [];

  @Input() set levels(levels: any) { this._levels = levels }

  constructor() { }

  ngOnInit(): void {
  }

}
