import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { FileService } from 'src/app/services/file.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {
  
  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );    

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  addAttachment: boolean = false;

  text: string = '';
  attachments: any[] = [];

  @Input() user: any;      // user
  @Input() set saved(tmp: number) {

    if(this.mainForm) {

      this.mainForm.patchValue({
        message: ''
      })

      this.text = '';

    }
  }
  @Input() hideIcon: string;
  @Input() levelId: string;
  @Input() hideAttachment: boolean;

  @Output() save = new EventEmitter<any>(); 

  constructor(
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _fileService: FileService,
    private _breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.mainForm = this._fb.group({
      message: ['', Validators.required]
    });
  }

  uploadedAttachment(attachment: any): void {
    this.attachments.push({
      _id: attachment._id,
      original_name: attachment.original_name
    })
  }

  removeAttachment(fileId: string): void {

    this._fileService.deleteFile(this.levelId, fileId).subscribe((result: any) => {        
  
      if (result.success) {

        let index = -1;

        for(let i = 0; i < this.attachments.length; i++) {

          if(this.attachments[i]._id == fileId) {
            index = i;
          }

        }

        if(index != -1) {
          this.attachments.splice(index, 1);
        }     
       
      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });

   

  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this.save.emit({
        message: this.mainForm.value.message,
        attachments: this.attachments
      });

      this.attachments = [];

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

  onCkEditorValue(text: string) {

    this.text = text;

    this.mainForm.patchValue({
      message: text
    });
  }

}
