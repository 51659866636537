import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PagesService } from 'src/app/services/pages.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
//import { PagesDialogComponent } from './pages-dialog/pages-dialog.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  _id: string;

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  
  loading: boolean = true;
  create: boolean = false;

  pages: any = [];
  content: any;
  blocks: any[] = [];
  currentPage: any;
  data: string = '';

  @Input() set id(id: string) {

    this.create = false;
    this._id = id;

    this.getPages().then((pages: any) => {
      this.loading = false;
      this.pages = pages;

      if(this.pages.length > 0) {
        this.currentPage = this.pages[0];
        this.pages[0].active = true;
        this.content = this.pages[0];
      }
      else {
        this.currentPage = null;
        this.content = null;
      }

    });
  }
  @Input() role: number;    // user role

  //addPagesDialogRef: MatDialogRef<PagesDialogComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _pagesService: PagesService,
    private _fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.getPages().then((pages: any) => {
      this.loading = false;
      this.pages = pages;

      if(this.pages.length > 0) {
        this.currentPage = this.pages[0];
        this.pages[0].active = true;
        this.content = this.pages[0];
      }

    });
  }

  createForm(data?: any) {
    this.mainForm = this._fb.group({
      //title: [(data && data.title) ? data.title : '', [Validators.required]],
      content: [(data && data.content) ? data.content : ''], //, Validators.required],
      level_id: [this._id, Validators.required]
    });

    this.data = (data && data.content) ? data.content : '';
  }

  getPages() {

    return new Promise((resolve, reject) => {

      this._pagesService.getPages(this._id).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });

  }

  edit(page: any) {
    this.createForm(page);
    this.create = true;
  }

  cancel() {
    this.create = false;
  }

  delete() {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this page?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._pagesService.deletePage(this.currentPage._id, this._id).subscribe((result: any) => {

          if (result.success) {
            
            let index = 0;

            for(let i = 0; i < this.pages.length; i++) {
              if(this.pages[i]._id == this.currentPage._id) {
                index = i;
              }
            } 

            this.pages.splice(index, 1);

            if(this.pages.length > 0) {
              this.currentPage = this.pages[0];
              this.pages[0].active = true;
              this.content = this.pages[0];
            }
            else {
              this.currentPage = null;
              this.content = null;
            }
            
          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {  
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });     

      }
    });

  }

  onCkEditorValue(text: string) {
    this.mainForm.patchValue({
      content: text
    });
  }

  selectMenu(page: any) {

    for(let p of this.pages) {
      p.active = false;
    }

    this.currentPage = page;
    page.active = true;
    this.content = page;    

  }


  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") { 

      let action = (this.content) ?  
        this._pagesService.editPage(
          this.content._id,
          this.mainForm.value.level_id,
          this.mainForm.value.title,
          this.mainForm.value.content
        ) :
        this._pagesService.addPage(
          this.mainForm.value.level_id,
          this.mainForm.value.title,
          this.mainForm.value.content
        );

      action.subscribe((result: any) => {
  
        if (result.success) {

          this.create = false;

          if(!this.content) {
            this.content = {
              content: ''
            }
          }

          this.content = result.data; //this.mainForm.value.content;
          this.currentPage = result.data;

          this._snackBar.open('Saved', '', {
            duration: 2000            
          });
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });


    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

}
