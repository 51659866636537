<div class="upload-form">

    <ng-container *ngIf="dragDrop">
        <div class="mb-4">
            <div class="drop-container d-flex align-items-center justify-content-center" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)"
                [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">

                <div class="text-center">
                    <div class="mat-caption">Drag &amp; Drop</div>
                </div>

            </div>
        </div>
    </ng-container>

    <input #uploader type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput">

    <mat-progress-bar class="aligner-item mt-2" [color]="color" [mode]="mode" [value]="progressbarValue">
    </mat-progress-bar>
</div>