<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

    <div class="d-flex align-items-center mb-2">
        <h2 mat-dialog-title class="d-flex align-items-center mb-0">
            <span class="material-icons">
                add
            </span> 
            Add news
        </h2>

        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content>

      
        <mat-form-field appearance="fill" class="w-100">
            <mat-label>
                Name
            </mat-label>
            <input matInput formControlName="name">
        </mat-form-field>

        <div class="editor-size">
            <app-my-editor [levelid]="data.level_id" [data]="text" (output)="onCkEditorValue($event)"></app-my-editor>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button color="primary" class="mr-2" type="submit">
            
            <ng-container *ngIf="!data.current">
                <span class="material-icons">
                    add
                </span> Add
            </ng-container>

            <ng-container *ngIf="data.current">
                Update
            </ng-container>

        </button>
        <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
    </mat-dialog-actions>


</form>