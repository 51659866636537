import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-level-avatar',
  templateUrl: './level-avatar.component.html',
  styleUrls: ['./level-avatar.component.scss']
})
export class LevelAvatarComponent implements OnInit {

  image: any;

  @Input() set data(data: any) {
    this.image = data;

    if(this.image) {   

      if(!this.image.includes(this._config.getApiUrl())) {
        this.image = this._config.getApiUrl() + this.image;
      }
    }
  }
  @Input() rootClass: string;

  constructor(
    private _config: ConfigService,
  ) { }

  ngOnInit(): void {

    if(this.image && this.image) {   

      if(!this.image.includes(this._config.getApiUrl())) {
        this.image = this._config.getApiUrl() + this.image;
      }
    }
  }

}
