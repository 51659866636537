<ng-container *ngIf="!loading">

    <div class="h-100 d-flex flex-column">

        <mat-toolbar class="d-flex align-items-center my-4" *ngIf="role == 0 || role == 1">

            <h1 class="mat-h1 mb-0">Wiki</h1>

            <!-- mobile-menu -->

            <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" class="ms-auto d-md-none">
                <span class="material-icons">
                    more_vert
                </span>
            </button>
            <mat-menu #menu="matMenu">

                <button mat-menu-item routerLink="/{{ link }}/wiki/create">
                    <mat-icon>add</mat-icon>
                    {{'Add wiki' | translate}}
                </button>

                <button mat-menu-item (click)="browseWiki()">
                    <mat-icon>view_carousel</mat-icon>
                    {{'Import' | translate}}
                </button>

            </mat-menu>


            <!-- desktop-menu -->

            <div class="ms-auto d-none d-md-inline">
                <button mat-raised-button routerLink="/{{ link }}/wiki/create">
                    <div class="d-flex align-items-center">
                        <mat-icon>add</mat-icon>
                        {{'Add wiki' | translate}}
                    </div>
                </button>
                <button mat-raised-button (click)="browseWiki()" class="ms-2">
                    <div class="d-flex align-items-center">
                        <mat-icon>view_carousel</mat-icon>
                        <div class="ms-1">
                            {{'Import' | translate}}
                        </div>
                    </div>
                </button>
            </div>


        </mat-toolbar>

        <div class="flex-fill" style="min-height: 300px;">


            <!--
            <ng-container *ngIf="pages.length == 0; else showWiki">

                <mat-card>

                    {{'No wikis' | translate}}

                </mat-card>



            </ng-container>-->

            <!--<ng-template #showWiki>-->


                <mat-drawer-container class="h-100">
                    <mat-drawer #drawer class="wiki-sidenav" mode="over">

                        <mat-list role="list" class="wiki-menu">

                            <div class="d-block d-md-none w-100 mb-2">
                                <div class="w-100 d-flex justify-content-start">
                                    <button mat-button (click)="drawer.toggle()" class="px-2" style="padding: 0; min-width: auto;">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </div>

                            <mat-list-item role="listitem" *ngFor="let p of pages" class="pointer" [ngClass]="{ 'active' : p.active  }" (click)="showLink(p._id)"> <!--routerLink="/{{ link }}/wiki/{{ p._id }}">-->


                                <div mat-line>{{ p.title | truncate : 60 }}</div>
                                <div mat-line  *ngIf="p.shared" class="text-gray-600">Shared</div>


                            </mat-list-item>
                        </mat-list>

                    </mat-drawer>

                    <div class="wiki-sidenav-content">


                        <ng-container *ngIf="pages.length > 0">

                            <button type="button" mat-button (click)="drawer.toggle()">
                                <mat-icon>menu_open</mat-icon>
                                Show wiki pages
                            </button>

                        </ng-container>



                        <ng-container *ngIf="create">

                            <div class="col">

                                <mat-card class="mat-elevation-z0" [ngClass]="{ 'mx-2' : large }">

                                    <div class="my-2 editor-height-300px">
                                        <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate>

                                            <mat-form-field appearance="outline" class="w-100 mb-4">
                                                <mat-label>
                                                    {{'Page title' | translate}}
                                                </mat-label>
                                                <input matInput formControlName="title" maxlength="150">
                                                <mat-hint align="end">{{f.title.value.length}} / 150</mat-hint>
                                            </mat-form-field>

                                            <app-my-editor [levelid]="id" [data]="text" (output)="onCkEditorValue($event)">
                                            </app-my-editor>

                                            <ng-container *ngIf="attachments.length > 0">
                                                <mat-list role="list">
                                                <ng-container *ngFor="let a of attachments">
                                                    <mat-list-item role="listitem">

                                                        <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                                                        <div mat-line class="d-flex align-items-center">                            
                                                            <div style="max-width: 250px;" class="text-truncate">
                                                                {{a.original_name}}
                                                            </div>
                                                        
                                                            <div class="ms-auto">
                                                                <mat-icon class="pointer" (click)="removeAttachment(a._id)">delete</mat-icon>
                                                            </div>
                                                        </div>

                                                    </mat-list-item>
                                                </ng-container>      
                                                </mat-list>         
                                            </ng-container>

                                            <div class="my-5">
                                                <app-upload-file (data)="uploadedAttachment($event)" [levelId]="id" attachment="true"></app-upload-file>
                                            </div>

                                            <div class="my-3">
                                                <button mat-raised-button color="primary" type="submit">{{'Save' |
                                                    translate}}</button>
                                                <button mat-raised-button color="secondary" type="button" class="ms-2"
                                                    (click)="cancel()">{{'Cancel' | translate}}</button>
                                            </div>

                                        </form>
                                    </div>

                                </mat-card>

                            </div>

                        </ng-container>

                        <ng-container *ngIf="!create && pages.length != 0">

                            <div class="col">

                                <mat-card class="mat-elevation-z0 mx-2 h-100">

                                    <ng-container *ngIf="!content">

                                        {{ 'Not found' | translate }}

                                    </ng-container>

                                    <ng-container *ngIf="content">

                                        <div class="d-flex flex-wrap">

                                            <div class="flex-grow-1">

                                                <h2 class="mat-h2 mb-0">{{ content?.title }}</h2>

                                            </div>

                                            <div class="d-flex" *ngIf="role == 0 || role == 1">
                                                <div class="ms-md-auto mb-4">


                                                    <button mat-button [matMenuTriggerFor]="menu">
                                                        <span class="material-icons">
                                                            more_vert
                                                        </span>
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item (click)="edit(content)">
                                                            <mat-icon>create</mat-icon>
                                                            <span>{{'Edit' | translate}}</span>
                                                        </button>
                                                        <button mat-menu-item (click)="remove()" *ngIf="content?.shared">
                                                            <mat-icon>remove</mat-icon>
                                                            <span>{{'Remove' | translate}}</span>
                                                        </button>
                                                        <button mat-menu-item (click)="delete()" *ngIf="!content?.shared">
                                                            <mat-icon>delete</mat-icon>
                                                            <span>{{'Delete' | translate}}</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="ck-content" [innerHTML]="content?.content | sanitizeHtml"></div>


                                        <ng-container *ngIf="content?.attachments.length > 0">

                                            <mat-list role="list" class="mt-0 mb-5">  
                                                <ng-container *ngFor="let a of content?.attachments">
                                                    <mat-list-item role="listitem">                                                
                                                        
                                                        <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                                                        <div mat-line class="d-flex align-items-center">                            
                                                            <div style="max-width: 250px;" class="text-truncate">
                                                                {{a.original_name}} 
                                                            </div>                                                
                                                            <div class="ms-auto">
                                                                <button mat-button class="small-btn ms-auto" (click)="viewFile(a._id)" matTooltip="View"><mat-icon>visibility</mat-icon></button>
                                                                <button mat-button class="small-btn me-1" (click)="prepareDownload(a._id)" matTooltip="Download"><mat-icon>get_app</mat-icon></button>
                                                            </div>
                                                        </div>
        
                                                    </mat-list-item>
                                                </ng-container>
                                            </mat-list>
            
                                        </ng-container>


                                        <div class="d-flex align-items-center mt-3">
                                            <app-user-avatar width="25" fontSize="12px" [info]="content?.user_id">
                                            </app-user-avatar>

                                            <div class="ms-2 me-4">
                                                {{ content?.user_id.full_name | truncate : 45 }}
                                            </div>

                                            <div class="me-4 text-muted mat-caption"
                                                matTooltip="{{ content?.date_created | date: 'medium' }}">
                                                {{ content?.date_created | myDate }}
                                            </div>


                                        </div>

                                        <div class="text-muted mat-caption d-block d-lg-flex mt-2">


                                            <div *ngIf="content?.shared" class="text-italic">
                                                {{'Shared from' | translate}}: <a
                                                    routerLink="{{ content.level_id[0].link }}/wiki">{{
                                                    content.level_id[0].name }}</a>
                                            </div>
                                        </div>


                                        
                                        <div class="d-flex mt-4">                                            
                                            <ng-container *ngIf="this.pages[currentId + 1]">
                                                <button routerLink="/{{ link }}/wiki/{{ this.pages[currentId + 1]._id }}"
                                                    class="ms-auto" mat-raised-button>


                                                    {{ this.pages[currentId + 1].title | truncate }}

                                                    <mat-icon>chevron_right</mat-icon>

                                                </button>
                                            </ng-container>
                                        </div>

                                    </ng-container>

                                </mat-card>

                            </div>
                        </ng-container>

                        <ng-container *ngIf="!create && pages.length == 0">

                            <mat-card>

                                {{'No wikis' | translate}}
            
                            </mat-card>

                        </ng-container>


                    </div>
                </mat-drawer-container>


        </div>

    </div>

</ng-container>