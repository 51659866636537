import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NewsService } from 'src/app/services/news.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { ImportWneDialogComponent } from '../../import-wne-dialog/import-wne-dialog.component';
import { NewsDialogComponent } from './news-dialog/news-dialog.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  //subscription: Subscription;
  loading: boolean = true;

  news: any = [];
  relatedNews: any = [];
  newsInfo: any;
  _newsId: string;
  url: string;

  @Input() id: string;      // level id
  @Input() role: number;    // user role
  @Input() set newsId(id: string) { 

    this.loading = true;

    this._newsId = id;
    this.getAllNews();
    this.url = this._router.url;
  }

  addNewsDialogRef: MatDialogRef<NewsDialogComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  browseNewsRef: MatDialogRef<ImportWneDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _newsService: NewsService,
    private _router: Router
  ) { 

  }

  private getAllNews() {

    if(!this._newsId) {
      this.getNews().then((events: any) => {
        this.loading = false;
        this.news = events;
      });
    }
    else {
      this.getSpecificNews().then((data: any) => {

        this.loading = false;
        this.newsInfo = data;

        // get related news

        this._newsService.getRelatedNews(this.id, this._newsId).subscribe((result: any) => {

          if (result.success) {

            this.relatedNews = result.data;

          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {

            this.loading = false;

            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });

      });
    }

  }

  ngOnInit(): void {

    this.url = this._router.url;

    this.getAllNews();      

  }

  getNews() {
    return new Promise((resolve, reject) => {

      this._newsService.getNews(this.id).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });
  }

  browseNews() {
    this.browseNewsRef = this._dialog.open(
      ImportWneDialogComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id,
          type: 'news'
        },
        disableClose: false
      }
    );

    this.browseNewsRef.afterClosed().subscribe(result => {
      if (result) {   

        let news = result;

        for (let i = 0; i < news.length; i++) {
          this.news.unshift(news[i]);                  
        }

      }
    });
  }

  addNews() {
    this.addNewsDialogRef = this._dialog.open(
      NewsDialogComponent,
      {
       minWidth: '450px',        
        data: {
          level_id: this.id
        },
        disableClose: true
      }
    );

    this.addNewsDialogRef.afterClosed().subscribe(result => {
      if (result) {        

        this.news.unshift(result);     

      }
    });
  }

  getSpecificNews() {
    return new Promise((resolve, reject) => {

      this._newsService.getSpecificNews(this.id, this._newsId).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve(null);

        }

      }, err => {

        if (err.status != 200) {

          resolve(null);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });
  }

  edit() {
    this.addNewsDialogRef = this._dialog.open(
      NewsDialogComponent,
      {
        width: '60vw',
        data: {          
          level_id: this.id,
          current: this.newsInfo
        },
        disableClose: true
      }
    );

    this.addNewsDialogRef.afterClosed().subscribe(result => {
      if (result) {        

        this.newsInfo = result;

      }
    });
  }

  delete() {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this news?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._newsService.deleteNews(this.newsInfo._id, this.id).subscribe((result: any) => {

          if (result.success) {

            this.backButton();
            
          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {  
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        }); 

      }
    });

  }


  remove() {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to remove this news?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._newsService.removeNews(this.newsInfo._id, this.id).subscribe((result: any) => {

          if (result.success) {

            this.backButton();
            
          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {  
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        }); 

      }
    });

  }

  backButton() {

    let currentUrl = this._router.url;
    let tmpArray = currentUrl.split('/');
    tmpArray.pop();
        
    this._router.navigate([tmpArray.join('/')]);

  }

  navigateNews(id: string) {

    let currentUrl = this._router.url;
    let tmpArray = currentUrl.split('/');
    tmpArray.pop();

    this._router.navigate([tmpArray.join('/') + '/' + id]);

  }
  
}
