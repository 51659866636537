<ng-container *ngIf="loading">

    <div class="text-center">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

</ng-container>
<ng-container *ngIf="!loading">

    <div class="d-flex align-items-center mb-2">
        <h2 mat-dialog-title class="d-flex align-items-center mb-0">           
            {{ 'Import ' + type | translate}}
        </h2>

        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content>

        <ng-container *ngIf="levels.length > 0">
            <mat-form-field class="w-100">
                <mat-label>{{'Select level' | translate}}</mat-label>
                <mat-select [(ngModel)]="currentLevel" [ngModelOptions]="{standalone: true}" (selectionChange)="changeLevel(currentLevel)">                    
                    <mat-option *ngFor="let l of levels" [value]="l._id">
                        <ng-container *ngIf="l._id == 'current'">
                            --
                        </ng-container>

                        {{ l.name | translate }}
                    </mat-option>                    
                </mat-select>
            </mat-form-field>
        </ng-container>

        
        <ng-container *ngIf="results.length == 0">
            <div class="my-2">
                <i>{{'Nothing to import' | translate}} ...</i>
            </div>
        </ng-container>
       

        <ng-container *ngIf="results.length > 0">
           
            <div class="my-2">

                <ng-container *ngIf="type == 'wiki'">

                    <mat-accordion>                        
                        <mat-expansion-panel *ngFor="let l of results" [hideToggle]="(l.wiki?.length == 0)" [disabled]="(l.wiki?.length == 0)" [ngClass]="{ 'mb-4' : l.level?.platform }">
                            <mat-expansion-panel-header>
                                <mat-panel-title>                                   

                                    <div>
                                        {{ l.level?.name | truncate : 50 }} ({{ l.wiki?.length }})
                                        <div *ngIf="l.level?.platform" class="text-muted" style="font-size: .7rem;">Collaboration platform</div>
                                    </div>

                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <ng-container *ngIf="l.wiki?.length > 0">
                                <ul class="list-group">
                                    <li class="list-group-item" *ngFor="let w of l.wiki">

                                        <mat-checkbox [(ngModel)]="w.selected" (click)="select(w._id)">                
                                            {{ w.title }}                    
                                        </mat-checkbox>
                                        
                                    </li>
                                </ul>
                            </ng-container>
                        </mat-expansion-panel>
                    </mat-accordion>


                </ng-container>

                <ng-container *ngIf="type == 'news'">
                    
                    
                    <!--
                    <ng-container *ngIf="showRoot">
                        
                        <ul class="list-group">
                            <li class="list-group-item" *ngFor="let n of results">

                                <mat-checkbox [(ngModel)]="n.selected" (click)="select(n._id)">                
                                    {{ n.name }}                    
                                </mat-checkbox>
                                
                            </li>
                        </ul>
                        
                    </ng-container>

                    <ng-container *ngIf="!showRoot">
                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let l of results" [hideToggle]="(l.news?.length == 0)" [disabled]="(l.news?.length == 0)">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ l.level?.name }} ({{ l.news?.length }})
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <ng-container *ngIf="l.news?.length > 0">
                                    <ul class="list-group">
                                        <li class="list-group-item" *ngFor="let n of l.news">

                                            <mat-checkbox [(ngModel)]="n.selected" (click)="select(n._id)">                
                                                {{ n.name }}                    
                                            </mat-checkbox>
                                            
                                        </li>
                                    </ul>
                                </ng-container>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-container>-->

                    <mat-accordion>                        
                        <mat-expansion-panel *ngFor="let l of results" [hideToggle]="(l.news?.length == 0)" [disabled]="(l.news?.length == 0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <ng-container *ngIf="l.level?.platform">
                                        <mat-icon style="width: 20px;
                                        height: 18px;
                                        font-size: 1.1rem;
                                        margin-top: 2px;">star</mat-icon>
                                    </ng-container>

                                    {{ l.level?.name | truncate : 50 }} ({{ l.news?.length }})
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <ng-container *ngIf="l.news?.length > 0">
                                <ul class="list-group">
                                    <li class="list-group-item" *ngFor="let n of l.news">

                                        <mat-checkbox [(ngModel)]="n.selected" (click)="select(n._id)">                
                                            {{ n.name }}                    
                                        </mat-checkbox>
                                        
                                    </li>
                                </ul>
                            </ng-container>
                        </mat-expansion-panel>
                    </mat-accordion>

                </ng-container>

                <ng-container *ngIf="type == 'events'">

                    <!--
                    <ng-container *ngIf="showRoot">
                        
                        <ul class="list-group">
                            <li class="list-group-item" *ngFor="let e of results">

                                <mat-checkbox [(ngModel)]="e.selected" (click)="select(e._id)">                
                                    {{ e.title }}                    
                                </mat-checkbox>
                                
                            </li>
                        </ul>
                        
                    </ng-container>

                    <ng-container *ngIf="!showRoot">

                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let l of results" [hideToggle]="(l.events?.length == 0)" [disabled]="(l.events?.length == 0)">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ l.level?.name }} ({{ l.events?.length }})
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <ng-container *ngIf="l.events?.length > 0">
                                    <ul class="list-group">
                                        <li class="list-group-item" *ngFor="let e of l.events">

                                            <mat-checkbox [(ngModel)]="e.selected" (click)="select(e._id)">                
                                                {{ e.title }}                    
                                            </mat-checkbox>
                                            
                                        </li>
                                    </ul>
                                </ng-container>
                            </mat-expansion-panel>
                        </mat-accordion>

                    </ng-container>-->

                    <mat-accordion>                        
                        <mat-expansion-panel *ngFor="let l of results" [hideToggle]="(l.events?.length == 0)" [disabled]="(l.events?.length == 0)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <ng-container *ngIf="l.level?.platform">
                                        <mat-icon style="width: 20px;
                                        height: 18px;
                                        font-size: 1.1rem;
                                        margin-top: 2px;">star</mat-icon>
                                    </ng-container>

                                    {{ l.level?.name | truncate : 50 }} ({{ l.events?.length }})
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <ng-container *ngIf="l.events?.length > 0">
                                <ul class="list-group">
                                    <li class="list-group-item" *ngFor="let e of l.events">

                                        <mat-checkbox [(ngModel)]="e.selected" (click)="select(e._id)">                
                                            {{ e.title }}                    
                                        </mat-checkbox>
                                        
                                    </li>
                                </ul>
                            </ng-container>
                        </mat-expansion-panel>
                    </mat-accordion>

                </ng-container>

            </div>

        </ng-container>
    </mat-dialog-content>


    <mat-dialog-actions>        
        <button mat-raised-button color="primary" (click)="addSelected(selected)" [disabled]="selected.length == 0">{{'Select' | translate}}</button>
        <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
    </mat-dialog-actions>

</ng-container>