<ng-container *ngIf="!showSuccessPage; else showSuccessPageTemplate">
    <div class="landing-container flex bg-gray">
        <div class="flex flex-1" style="max-width: 1400px;">
            <div class="left-side flex-1 flex align-items-center" style="background: #fff;">

                <div class="max-w-400 mx-auto flex flex-col justify-content-center" style="margin-top: -100px">

                    <div class="flex justify-content-center mb-4">
                        <img src="assets/kmfest24/kmfest.png" />
                        <img src="assets/kmfest24/kmfest_24.png" />
                    </div>


                    <div class="relative" style="position: relative;">
                        <img src="assets/kmfest24/sign.png" style="position: absolute; left: -20px;" />
                        <h1 class="mt-5" style="font-weight: 800;">
                            Challenges, Pitfalls, and Good
                            Practices of Digitalization
                            Projects and Programs
                        </h1>
                        <img src="assets/kmfest24/sign.png" style="position: absolute; margin-top: -20px; right: 0;" />
                    </div>
                </div>

            </div>
            <div class="right-side flex-1 flex align-items-center">
                <div class="max-w-400 mx-auto flex flex-col justify-content-center" style="margin-left: -200px;">

                    <div class="mobile-heading">
                        <div class="flex justify-content-center mb-4" style="background: #fff;">
                            <img src="assets/kmfest24/kmfest.png" />
                            <img src="assets/kmfest24/kmfest_24.png" />
                        </div>
                    </div>

                    <h2 style="padding-top: 40px; font-weight: 600; font-size: 1.6rem;">Welcome to the final two KM
                        FEST‘24 Workshops (14th and 15th October 2024) registration site!</h2>

                    <p>Please follow this registration procedure.</p>

                    <p class="italic" style="font-size: 0.9em;">Registration is free!</p>

                    <app-event-countdown [deadline]="registrationDeadline"
                        (deadlineReached)="deadlineReached($event)"></app-event-countdown>

                    <ng-container *ngIf="!isDeadlineReached; else showSectionDeadlineReach">

                        <form [disabled]="isDeadlineReached" [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate
                            class="my-4">


                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>
                                    {{ 'E-mail' | translate }}
                                </mat-label>
                                <input matInput formControlName="email">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{'First name' | translate}}</mat-label>
                                <input matInput formControlName="firstName">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{'Last name' | translate}}</mat-label>
                                <input matInput formControlName="lastName">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>
                                    {{ 'Organization' | translate }}
                                </mat-label>
                                <input matInput formControlName="organization">
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>
                                    {{ 'Country' | translate }}
                                </mat-label>
                                <mat-select formControlName="country">
                                    <mat-option *ngFor="let country of countries" [value]="country.code">
                                        {{ country.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>





                            <div *ngIf="submitted && f.email.errors" class="error">Please enter required information.
                            </div>


                            <h2 class="mt-4 mb-0">
                                I'm interested to participate in:
                            </h2>



                            <div style="margin: 2rem 1rem;">
                                <label class="checkbox">
                                    in both workshops
                                    <input name="bothEvents" formControlName="bothEvents" type="checkbox"
                                        (change)="changeSelection($event)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                            <div style="margin: 2rem 1rem;">
                                <label class="checkbox">
                                    in workshop #1 (14th October 2024)
                                    <input name="firstEvent" formControlName="firstEvent" type="checkbox"
                                        (change)="changeSelection($event)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                            <div style="margin: 2rem 1rem;">
                                <label class="checkbox"> in workshop #2 (15th October 2024)
                                    <input name="secondEvent" formControlName="secondEvent" type="checkbox"
                                        (change)="changeSelection($event)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                            <ng-container *ngIf="submitted 
                                && !f.bothEvents.value 
                                && !f.firstEvent.value 
                                && !f.secondEvent.value">
                                <div class="error">Please select at least one workshop to participate.</div>
                            </ng-container>


                            <button type="submit" class="submit-button">

                                <div>Sign
                                    Me
                                    Up</div>


                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                </svg>


                            </button>

                        </form>

                    </ng-container>
                    <ng-template #showSectionDeadlineReach>
                        <div class="alert alert-danger text-center"
                            style="border-top-left-radius: 0; border-top-right-radius: 0;">

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" style="width: 24px; color: #a3383f">
                                <path stroke-linecap=" round" stroke-linejoin="round"
                                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                            </svg>


                            The deadline for registration has been reached.
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #showSuccessPageTemplate>
    <div class="landing-container flex justify-content-center align-items-center">

        <div class="padding max-w-400 mx-auto">

            <img src="assets/congrats.svg" class="mb-4" />


            <h1 class="text-center" style="font-weight: 600; font-size: 2rem;">Thank you for your interest!</h1>
            <div class="text-center" style="font-size: 1.2rem; line-height: 1.6rem;">Thank you for your interest in KM
                FEST'24. You will receive an invitation in the near future.</div>

        </div>

    </div>
</ng-template>