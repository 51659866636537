import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import { EventsService } from '../services/events.service';
import { EventViewCalendarDialogComponent } from './event-view-calendar-dialog/event-view-calendar-dialog.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  loading: boolean = true;

  events: any[] = [];

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  events2: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = false;

  viewEventDialogRef: MatDialogRef<EventViewCalendarDialogComponent>;

  constructor(
    private _eventService: EventsService,
    private _titleService: Title,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _router: Router
  ) { }

  ngOnInit(): void {

    this._titleService.setTitle('Home | ExArca');

    this._eventService.getUserEvents().subscribe((result: any) => {

      if (result.success) {

        this.loading = false;

        this.events = result.data;

        if (this.events.length > 0) {

          for (let e of this.events) {

            this.events2.push({
              start: new Date(e.start_date),
              title: e.title,
              id: e._id
            });

          }

        }

      }
      else {
        this.loading = false;
      }

    }, err => {

      if (err.status != 200) {

        this.loading = false;

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });


  }

  handleEvent(event: CalendarEvent): void {

    let eventDetails;

    for (let e of this.events) {

      if (e._id == event.id) {

        eventDetails = e;

      }

    }


    if (eventDetails) {
      this.viewEventDialogRef = this._dialog.open(
        EventViewCalendarDialogComponent,
        {
          data: eventDetails
        }
      );

      this.viewEventDialogRef.afterClosed().subscribe(result => {
        if (result) {
          // view          
          this._router.navigate([eventDetails.link]);   
        }
      });

    }

  }

}
