<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

    <div class="d-flex align-items-center mb-2">
        <h2 mat-dialog-title class="d-flex align-items-center mb-0">
            <span class="material-icons">
                add
            </span> {{'Add' | translate}}
            <div class="ms-1">
                <span [ngClass]="{ 'color-interest' : data.type != 'wg', 'color-wg' : data.type == 'wg' }">
                    <ng-container *ngIf="data.project">
                        <ng-container *ngIf="data.type != 'wg'">{{'Project' | translate}}</ng-container>
                        <ng-container *ngIf="data.type == 'wg'">{{'Work Package' | translate}}</ng-container>
                    </ng-container>
                    <ng-container *ngIf="!data.project">
                        <ng-container *ngIf="data.type != 'wg'">{{'Interest' | translate}}</ng-container>
                        <ng-container *ngIf="data.type == 'wg'">{{'Work Group' | translate}}</ng-container>
                    </ng-container>
                </span>
            </div>
        </h2>
        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content>

        <mat-form-field appearance="fill" class="w-100">
            <mat-label>
                <ng-container *ngIf="data.type == 'wg'">
                    <ng-container *ngIf="!data.project">{{'Work Group' | translate}}</ng-container>
                    <ng-container *ngIf="data.project">{{'Work Package' | translate}}</ng-container>
                    - {{'name' | translate}}
                </ng-container>
                <ng-container *ngIf="data.type != 'wg'">
                    <ng-container *ngIf="!data.project">{{'Interest' | translate}}</ng-container>
                    <ng-container *ngIf="data.project">{{'Project' | translate}}</ng-container>
                    - {{'name' | translate}}
                </ng-container>
            </mat-label>
            <input matInput formControlName="name">
        </mat-form-field>

        <div class="my-2">
            <mat-checkbox class="example-margin" [(ngModel)]="sameCoordinator" [ngModelOptions]="{standalone: true}"
                (change)="changeSameCoordinator(sameCoordinator)">I'll be the coordinator</mat-checkbox>
        </div>

        <div [hidden]="sameCoordinator">

            <mat-form-field class="w-100 mt-2">

                <mat-chip-list #chipList aria-label="User selection" multiple="false" [formControl]="emailCtrl">
                    <mat-chip *ngFor="let user of users" [selectable]="selectable" [removable]="removable"
                        (removed)="remove(user)">
                        {{user.full_name}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>

                    <input placeholder="Find existing member or add e-mail" #userInput [formControl]="userCtrl"
                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">

                </mat-chip-list>

                <mat-error *ngIf="emailCtrl.invalid">{{'Please enter valid e-mail' | translate}}</mat-error>
                <!--
                    <button mat-icon-button matSuffix type="button" (click)="browseUsers()">
                        <mat-icon style="font-size: 1.5rem">search</mat-icon>
                    </button>
                -->
                <mat-hint align="start"><strong>{{'Add new e-mail by pressing comma or press enter' |
                        translate}}.</strong></mat-hint>

                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                        <div class="d-flex align-items-center">
                            <app-user-avatar width="25" fontSize="10px" [info]="user"></app-user-avatar>
                            <div class="ms-2">
                                {{user.full_name}}
                            </div>
                        </div>
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

        </div>


    </mat-dialog-content>

    <mat-dialog-actions>
        <ng-container *ngIf="!loading">
            <button mat-raised-button color="primary" class="mr-2" type="submit">
                <span class="material-icons">
                    add
                </span> {{'Add' | translate}}</button>
            <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
        </ng-container>
        <ng-container *ngIf="loading">
            <mat-spinner diameter="20"></mat-spinner>
        </ng-container>
    </mat-dialog-actions>


</form>