import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { UsersService } from 'src/app/services/users.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { AdminEditUserComponent } from './admin-edit-user/admin-edit-user.component';
import { AdminJoinUsersComponent } from './admin-join-users/admin-join-users.component';

@Component({
  selector: 'app-admin-show-users',
  templateUrl: './admin-show-users.component.html',
  styleUrls: ['./admin-show-users.component.scss']
})
export class AdminShowUsersComponent implements OnInit {

  loading: boolean = true;
  displayedColumns: string[] = ['activated', 'validated', 'full_name', 'credits', 'role', 'email', 'stop_email', 'experiment', 'date_created', 'action']; //['options', 'original_name', 'action'];
  usersData: MatTableDataSource<any>;
  resultsLength = 0;
  users: any[] = [];

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  editUserDialogRef: MatDialogRef<AdminEditUserComponent>;
  joinUserDialogRef: MatDialogRef<AdminJoinUsersComponent>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.getUsers().then((users: any) => {
      this.loading = false;
      this.usersData = new MatTableDataSource(users); 

      this.resultsLength =  users.length;
      this.users = users;
    });
  }

  getUsers() {

    return new Promise((resolve, reject) => {

      this._usersService.adminGetUsers().subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });

  }

  editUser(user: any) {

    this.editUserDialogRef = this._dialog.open(
      AdminEditUserComponent,
      {
       minWidth: '450px',
        data: {
          user: user
        },
        disableClose: true
      }
    );

    this.editUserDialogRef.afterClosed().subscribe(result => {
      if (result) {

        for(let u of this.users) {
          if(u._id == user._id) {
            u.full_name = result.full_name;
            u.email = result.email;
            u.role = result.role;
            u.stop_email = result.stop_email;
          }
        }   

      }
    });

  }

  toggleExperiment(id: string, status: boolean) {

    console.log(status)

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'Change status of experiment mode?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._usersService.adminToggleExperiment(id, status).subscribe((result: any) => {


          if(result.success) {
    
            
            let dataLength = this.usersData.filteredData.length;
    
            for (let i = 0; i < dataLength; i++) {
    
              if (this.usersData.filteredData[i]._id == id) {
    
                this.usersData.filteredData[i].experiment_test = status;

              }
    
            }
    
            // update table
            this.usersData._updateChangeSubscription();
          }
    
        }, err => {
    
          if (err.status != 200) {
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });

      }
    });

  }

  changeActivatedStatus(id: string, status: boolean) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to change the status of this user?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._usersService.adminChangeActivatedStatus(id, status).subscribe((result: any) => {


          if(result.success) {
    
            
            let dataLength = this.usersData.filteredData.length;
    
            for (let i = 0; i < dataLength; i++) {
    
              if (this.usersData.filteredData[i]._id == id) {
    
                this.usersData.filteredData[i].activated = status;

              }
    
            }
    
            // update table
            this.usersData._updateChangeSubscription();
          }
    
        }, err => {
    
          if (err.status != 200) {
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });

      }
    });

  }

  changeValidatedStatus(id: string, status: boolean) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to validate email of this user?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._usersService.adminChangedValidatedStatus(id, status).subscribe((result: any) => {


          if(result.success) {
    
            
            let dataLength = this.usersData.filteredData.length;
    
            for (let i = 0; i < dataLength; i++) {
    
              if (this.usersData.filteredData[i]._id == id) {
    
                this.usersData.filteredData[i].validated = status;

              }
    
            }
    
            // update table
            this.usersData._updateChangeSubscription();
          }
    
        }, err => {
    
          if (err.status != 200) {
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });

      }
    });

  }

  sendValidationEmail(id: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to send validation email to this user?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._usersService.adminSendValidationEmail(id).subscribe((result: any) => {


          if(result.success) {  
            
            this._snackBar.open('E-mail sent', '', {
              duration: 2000              
            });
    
          }
    
        }, err => {
    
          if (err.status != 200) {
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });

      }
    });

  }

  deleteUser(id: string) {
    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this user?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._usersService.adminDeleteUser(id).subscribe((result: any) => {


          if(result.success) {
    
            
            let dataLength = this.usersData.filteredData.length;
    
            for (let i = 0; i < dataLength; i++) {
    
              if (this.usersData.filteredData[i]._id == id) {
    
                this.usersData.filteredData.splice(i, 1);
                dataLength--;
                this.resultsLength--;
    
              }
    
            }
    
            // update table
            this.usersData._updateChangeSubscription();
          }
    
        }, err => {
    
          if (err.status != 200) {
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });

      }
    });
  }

  removeCredit(userId: string) {
    this._usersService.adminChangeCredit(userId, 'remove').subscribe((result: any) => {

      if (result.success) {

        for(let user of this.users) {
          if(user._id == userId) {
            user.credits = result.data;
          }
        }       
        
        this.usersData = new MatTableDataSource(this.users); 
        
      }
      else {

        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {
        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });
  }

  addCredit(userId: string) {

    this._usersService.adminChangeCredit(userId, 'add').subscribe((result: any) => {

      if (result.success) {

        for(let user of this.users) {
          if(user._id == userId) {
            user.credits = result.data;
          }
        }       
        
        this.usersData = new MatTableDataSource(this.users); 
        
      }
      else {

        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {
        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });

  }

  joinDialog() {
    this.joinUserDialogRef = this._dialog.open(
      AdminJoinUsersComponent,
      {
       minWidth: '450px',
        data: {
          users: this.users
        },
        disableClose: true
      }
    );

    this.joinUserDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getUsers().then((users: any) => {
          this.usersData = new MatTableDataSource(users); 
    
          this.resultsLength =  users.length;
          this.users = users;
        });
      }
    });
  }
  
}
