import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventsService } from 'src/app/services/events.service';
import { NewsService } from 'src/app/services/news.service';
import { UsersService } from 'src/app/services/users.service';
import { WikiService } from 'src/app/services/wiki.service';

/**
 * Import Wiki News Events dialog
 */
@Component({
  selector: 'app-import-wne-dialog',
  templateUrl: './import-wne-dialog.component.html',
  styleUrls: ['./import-wne-dialog.component.scss']
})
export class ImportWneDialogComponent implements OnInit {

  loading: boolean = true;

  currentLevel: string;
  levels: any = [];
  showRoot: boolean;

  results: number = 0;
  selected: any = [];
  type: string;      // wiki | news | events

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _wikiService: WikiService,
    private _newsService: NewsService,
    private _eventsService: EventsService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<ImportWneDialogComponent>,
    private _userService: UsersService
  ) { }

  ngOnInit(): void {

    this.type = this.data.type;

    this._userService.getAdminLevelsAtTheSameLevel(this.data.level_id).subscribe((levels: any) => {

      if (levels.success) {
        this.levels = [...levels.data];

        let found = false;

        for (let i = 0; i < this.levels.length; i++) {
          if (this.levels[i]._id == this.data.level_id) {
            found = true;
          }
        }

        if (found) {
          this.currentLevel = this.data.level_id;
        }
        else {
          this.levels.push({
            _id: 'current',
            name: 'Current level'
          })

          this.currentLevel = 'current';
        }
      }

      this.getData(this.data.level_id).then((data: any) => {

        this.loading = false;
        this.results = data;

      });

    }, err => {
      if (err.status != 200) {
        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });

  }

  select(wikiId: string) {

    let exist = -1;

    for (let i = 0; i < this.selected.length; i++) {

      if (this.selected[i]._id == wikiId) {
        exist = i;
      }

    }

    if (exist == -1) {
      this.selected.push(wikiId);
    }
    else {
      this.selected.splice(exist, 1);
    }
  }

  addSelected(selectedList: any) {

    if (selectedList.length > 0) {

      let action = this._wikiService.importWikis(this.data.level_id, this.selected);

      if (this.type == 'news') {
        action = this._newsService.importNews(this.data.level_id, this.selected);
      } else if (this.type == 'events') {
        action = this._eventsService.importEvents(this.data.level_id, this.selected);
      }

      action.subscribe((result: any) => {

        if (result.success) {

          this._dialogRef.close(result.data);

        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });
    }

  }

  getData(levelId: string) {
    return new Promise((resolve, reject) => {

      let action = this._wikiService.getAvailableWikis(levelId, this.data.level_id, (this.data.level_id != levelId));

      if (this.type == 'news') {
        action = this._newsService.getAvailableNews(levelId, this.data.level_id, (this.data.level_id != levelId));
      } else if (this.type == 'events') {
        action = this._eventsService.getAvailableEvents(levelId, this.data.level_id, (this.data.level_id != levelId));
      }

      action.subscribe((result: any) => {
        if (result.success) {

          resolve(result.data);

        } else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });

    });
  }

  changeLevel(levelId: string) {

    if (levelId == 'current') {
      this.getData(this.data.level_id).then((data: any) => {

        this.showRoot = false;
        this.results = data;

      });
    }
    else {

      this.getData(levelId).then((data: any) => {

        if (this.data.level_id != levelId) {
          this.showRoot = true;
        }
        else {
          this.showRoot = false;
        }

        this.results = data;

      });

    }
  }

}
