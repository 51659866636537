
<div class="d-flex align-items-center mb-2">
    <h2 mat-dialog-title class="mb-0">Change {{ data.type }}</h2>
    <div class="ms-auto">
        <button mat-button color="secondary" [mat-dialog-close]="false">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>


<mat-dialog-content class="mb-2">

    <ng-container *ngIf="data.type == 'avatar'">
        <div class="box p-2 py-4">
            <div class="d-flex">
                <div style="width: 180px; position: relative;">
                    <ng-container *ngIf="data.levelInfo.avatar_url">
                        <div class="admin-edit-button-above-image" *ngIf="!imageChangedEvent">
                            <button mat-mini-fab color="accent" class="ms-auto" (click)="removeImage()">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="croppedImage">
                        <img [src]="croppedImage" class="w-100" />
                    </ng-container>
                    <ng-container *ngIf="!croppedImage">
                        <app-level-avatar [data]="data.levelInfo.avatar_url" rootClass="w-100"></app-level-avatar>
                    </ng-container>
                </div>
                <div class="ms-5 w-100">
                    <ng-container *ngIf="!imageChangedEvent">
                        <app-upload-file (data)="uploadedImage($event)" [levelId]="data.levelInfo._id" dragDrop="true" [restrictFileType]="['image/jpeg', 'image/png', 'image/gif']" [public]="true" [type]="data.type"></app-upload-file>
                    </ng-container>

                    <ng-container *ngIf="imageChangedEvent">
                        <div class="box">
                            <image-cropper [imageURL]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio" cropperMinWidth="130" cropperMinHeight="130" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                                (loadImageFailed)="loadImageFailed()"></image-cropper>
                        </div>                        
                    </ng-container>


                </div>

            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="data.type == 'background'">

        <div class="box p-2 mt-3">            
            <div style="position: relative;" *ngIf="!croppedImage">
                <app-level-background [data]="data.levelInfo.background_image_url"></app-level-background>
                
                <div class="admin-edit-button-above-image" *ngIf="data.levelInfo.background_image_url">
                    <button mat-mini-fab color="accent" class="ms-auto" (click)="removeImage()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>

            </div>
            <div *ngIf="croppedImage" class="mb-2">
                <div [ngStyle]="{ 'background-image': 'url(' + croppedImage + ')' }" class="edit-header-image">                    
                </div>
            </div>
            
            <ng-container *ngIf="!imageChangedEvent">
                <app-upload-file (data)="uploadedImage($event)" [levelId]="data.levelInfo._id" dragDrop="true" [restrictFileType]="['image/jpeg', 'image/png', 'image/gif']" [public]="true" [type]="data.type"></app-upload-file>
            </ng-container>
            
            <ng-container *ngIf="imageChangedEvent">
                <div class="box">
                    <image-cropper [imageURL]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio" cropperMinWidth="130" cropperMinHeight="130" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>                        
            </ng-container>
            
        </div>

    </ng-container>

</mat-dialog-content>
<mat-dialog-actions>

    <ng-container *ngIf="imageChangedEvent">
        <div class="d-flex justify-content-center mt-2">
            <button mat-raised-button color="primary" (click)="saveCropped()">Crop &amp; Save</button>
        </div>
    </ng-container>

    <ng-container *ngIf="!imageChangedEvent">
        <button mat-raised-button color="secondary" [mat-dialog-close]="false">Close</button>
    </ng-container>

</mat-dialog-actions>