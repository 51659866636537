<ng-container *ngIf="showSidebar && showHeader; else hideHeader">

    <mat-sidenav-container class="sidenav-container h-100">
        <mat-sidenav #drawer position="start" class="sidenav" fixedInViewport
            [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
            [opened]="((isHandset$ | async) === false)" (openedChange)="mainMenuSideNavClosed($event)">

            <div class="d-none d-xl-block">
                <div class="my-2 mb-4 d-flex justify-content-center">
                    <a routerLink="/">
                        <img src="assets/main-logo.png" />
                    </a>
                </div>
            </div>

            <mat-nav-list (click)="toggle(drawer)">
                <a mat-list-item routerLink="home" routerLinkActive="active">
                    <mat-icon>home</mat-icon>
                    <div class="ms-3 menu-label">{{'Home' | translate}}</div>
                </a>
                <a mat-list-item routerLink="workspace" routerLinkActive="active">
                    <mat-icon>grid_view</mat-icon>
                    <div class="ms-3 menu-label">{{'My Platforms' | translate}}</div>
                </a>
                <a mat-list-item routerLink="calendar" routerLinkActive="active">
                    <mat-icon>today</mat-icon>
                    <div class="ms-3 menu-label">{{'Calendar' | translate}}</div>
                </a>
                <a mat-list-item routerLink="messages" routerLinkActive="active">
                    <mat-icon [matBadge]="(messageNumber) ? messageNumber : null"
                        matBadgeColor="accent">chat_bubble</mat-icon>
                    <div class="ms-3 menu-label">{{'Messages' | translate}}</div>
                </a>
                <a mat-list-item routerLink="/notifications" class="d-block d-xl-none" routerLinkActive="active">
                    <mat-icon>notifications_none</mat-icon>
                    <div class="ms-3 menu-label">{{'Notifications' | translate}}</div>
                </a>
                <ng-container *ngxPermissionsOnly="['ADMIN', 'SUPPORT']">

                    <a mat-list-item routerLink="/kmfest24/results" routerLinkActive="active">
                        <mat-icon>notifications_none</mat-icon>
                        <div class="ms-3 menu-label">KMFest 2024</div>
                    </a>

                </ng-container>
            </mat-nav-list>

            <ng-container *ngxPermissionsOnly="['ADMIN']">

                <div class="mt-4">
                    <mat-toolbar>&nbsp;</mat-toolbar>
                    <mat-nav-list>
                        <!--<a mat-list-item routerLink="/admin/add-platform" routerLinkActive="active">
                            <mat-icon>add</mat-icon>
                            <div class="ms-3 menu-label">Add platform</div>
                        </a>-->
                        <a mat-list-item routerLink="/admin/add-user" routerLinkActive="active">
                            <mat-icon>add</mat-icon>
                            <div class="ms-3 menu-label">Add user</div>
                        </a>
                        <a mat-list-item routerLink="/admin/view-user" routerLinkActive="active">
                            <mat-icon>supervisor_account</mat-icon>
                            <div class="ms-3 menu-label">View users</div>
                        </a>
                        <a mat-list-item routerLink="/admin/emails" routerLinkActive="active">
                            <mat-icon>email</mat-icon>
                            <div class="ms-3 menu-label">E-mails</div>
                        </a>
                        <a mat-list-item routerLink="/admin/logs" routerLinkActive="active">
                            <mat-icon>article</mat-icon>
                            <div class="ms-3 menu-label">Logs</div>
                        </a>
                        <a mat-list-item routerLink="/admin/status" routerLinkActive="active">
                            <mat-icon>bolt</mat-icon>
                            <div class="ms-3 menu-label">System status</div>
                        </a>
                        <a mat-list-item routerLink="/ui" routerLinkActive="active">
                            <mat-icon>build</mat-icon>
                            <div class="ms-3 menu-label">UI</div>
                        </a>
                        <a mat-list-item routerLink="/admin/database" routerLinkActive="active">
                            <mat-icon>cloud_upload</mat-icon>
                            <div class="ms-3 menu-label">Database</div>
                        </a>
                    </mat-nav-list>
                </div>
            </ng-container>

        </mat-sidenav>

        <mat-sidenav #chatBox position="end" class="chatnav" [opened]="((isNotLarger$ | async) === false)"
            [attr.role]="(isNotLarger$ | async) ? 'dialog' : 'navigation'"
            [mode]="(isNotLarger$ | async) ? 'over' : 'side'">
            <app-chat-sidebar (newChat)="newChat($event)"
                (closeSideNav)="closeChatBoxDrawer($event)"></app-chat-sidebar>
        </mat-sidenav>

        <mat-sidenav-content class="h-100 d-flex flex-column" id="mainSidebar" cdkScrollable>

            <app-header [show]="showHeader" [scrollStatus]="scrollTop" [drawerStatus]="drawerStatus"
                [communityDrawer]="chatBoxDrawerStatus" (mainMenuDrawerStatus)="statusFromHeaderMainMenuDrawer($event)"
                (communityDrawerStatus)="statusFromHeaderCommunityDrawer($event)"></app-header>

            <div class="flex-fill">
                <router-outlet></router-outlet>
            </div>

            <app-footer class="mt-auto" [hidden]="hideFooter"></app-footer>

            <ng-container *ngIf="chatBoxes?.length > 0">
                <ng-container *ngFor="let c of chatBoxes; let index = index;">
                    <app-chat-box [index]="index" [chatId]="c" (closeBox)="closeChatBox($event)"></app-chat-box>
                </ng-container>
            </ng-container>

        </mat-sidenav-content>


    </mat-sidenav-container>





</ng-container>
<ng-template #hideHeader>

    <div class="h-100 d-flex flex-column">

        <app-header [show]="showHeader" [scrollStatus]="scrollTop" [drawerStatus]="drawerStatus"
            (sideStatus)="statusFromHeader($event)"></app-header>

        <div class="flex-fill">
            <router-outlet></router-outlet>
        </div>

        <app-footer class="mt-auto" [hidden]="hideFooter"></app-footer>

    </div>

</ng-template>