//import * as InlineEditor from '../../lib/ckeditor5/build/ckeditor';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-request-membership-dialog',
  templateUrl: './add-request-membership-dialog.component.html',
  styleUrls: ['./add-request-membership-dialog.component.scss']
})
export class AddRequestMembershipDialogComponent implements OnInit {

  //public Editor = InlineEditor;
  mainForm: UntypedFormGroup;

  config: any = {}; 
  text: string = '';
  _toolbar = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AddRequestMembershipDialogComponent>,
    private _fb: UntypedFormBuilder 
  ) { }

  ngOnInit(): void {

    this.config = {
      toolbar: this._toolbar, 
    }


    this.createForm();
  }

  createForm() {
    this.mainForm = this._fb.group({
      message: ['']      
    });
  }

  onSubmit(): void  {
    this._dialogRef.close({
      message: this.mainForm.value.message
    });
  }
  
  onCkEditorValue(text: string) {
    this.mainForm.patchValue({
      message: text
    });
  }

  /*
  public onChange( { editor }: ChangeEvent ) {
    console.log(editor.getData())

    this.mainForm.patchValue({
      message: editor.getData()
    });
  }*/

}
