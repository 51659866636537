import { Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LevelService } from 'src/app/services/level.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SlugifyService } from 'src/app/services/slugify.service';

@Component({
  selector: 'app-create-platform-dialog',
  templateUrl: './create-platform-dialog.component.html',
  styleUrls: ['./create-platform-dialog.component.scss']
})
export class CreatePlatformDialogComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  mainForm: UntypedFormGroup;
  submitted: boolean = false;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<CreatePlatformDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _levelService: LevelService,
    private _ngZone: NgZone,
    private _slugifyService: SlugifyService
  ) { }

  ngOnInit(): void {
    this.createForm();

    this.subscription = this.mainForm.get('name').valueChanges
        .pipe(debounceTime(800),
          distinctUntilChanged()
        )          
        .subscribe(modelValue => {

          if(!this.mainForm.get('slug').value) {
            this.mainForm.patchValue({
              'slug': this._slugifyService.convertToSlug(this.mainForm.get('name').value)
          });            
          
        }         
    });
  }

  createForm() {
    this.mainForm = this._fb.group({
      name: ['', [Validators.required]],
      slug: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

     
        this._levelService.createPlatformUser(        
          this.mainForm.value.name,
          this.mainForm.value.slug,
          this.mainForm.value.description
        ).subscribe((result: any) => {
  
        if (result.success) {
          this._dialogRef.close(result.data);
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
