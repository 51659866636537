<ng-container *ngIf="!loading">
    <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

        <div class="d-flex align-items-center mb-2">
            <h2 mat-dialog-title class="d-flex align-items-center mb-0">
                <span class="material-icons">
                    add
                </span> {{'Edit level' | translate}}
            </h2>
            <div class="ms-auto">
                <button mat-button color="secondary" [mat-dialog-close]="false">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>
        </div>

        <mat-dialog-content>



            <mat-form-field appearance="fill" class="w-100 mt-2">
                <mat-label>
                    {{'Change name' | translate}}
                </mat-label>
                <input matInput formControlName="name">
            </mat-form-field>


            <ng-container *ngIf="levelType == 'cplatform'">

                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>
                        {{'URL' | translate}}
                    </mat-label>
                    <input matInput formControlName="slug">
                </mat-form-field>

                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>
                        {{"Platform's short description" | translate}}
                    </mat-label>
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5" formControlName="description" [maxLength]="150"></textarea>
                    <mat-hint align="end">{{'Available characters' | translate}} {{f.description.value?.length ||
                        0}}/150</mat-hint>
                </mat-form-field>

                <div class="d-flex align-items-center border my-4 p-2 px-3"
                    style="justify-content: space-between; background-color: #f5f4f4;">

                    <div>
                        <h3 class="mat-h3 mb-0">Publish platform</h3>
                        <div class="mat-caption text-muted">Change the visibility of the platform</div>
                    </div>


                    <div style="width: 100px;" class="d-flex justify-content-center">
                        <mat-slide-toggle color="primary" class="text-muted mat-caption" formControlName="published"
                            (change)="changePublishSetting($event)"></mat-slide-toggle>
                    </div>

                </div>

            </ng-container>


        </mat-dialog-content>

        <mat-dialog-actions>
            <button mat-raised-button color="primary" class="mr-2" type="submit">{{'Update' | translate}}</button>
            <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
        </mat-dialog-actions>


    </form>
</ng-container>