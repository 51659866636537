import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LevelService } from 'src/app/services/level.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-edit-level',
  templateUrl: './edit-level.component.html',
  styleUrls: ['./edit-level.component.scss'],
})
export class EditLevelComponent implements OnInit {
  loading: boolean = true;

  mainForm: UntypedFormGroup;
  submitted: boolean = false;

  levelType: string;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<EditLevelComponent>,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _levelService: LevelService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._levelService.getLevel(this.data.level_id).subscribe(
      (result: any) => {
        if (result.success) {
          this.levelType = result.data.type;

          if (this.levelType == 'cplatform') {
            this.mainForm = this._fb.group({
              name: [result.data.name, [Validators.required]],
              slug: [result.data.slug, [Validators.required]],
              description: [result.data.description, [Validators.required]],
              published: [result.data.published, [Validators.required]],
            });
          } else {
            this.mainForm = this._fb.group({
              name: [result.data.name, [Validators.required]],
            });
          }
        } else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar'],
          });
        }

        this.loading = false;
      },
      (err) => {
        this.loading = false;

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar'],
          });
        }
      }
    );
  }

  get f() {
    return this.mainForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.mainForm.status != 'INVALID') {
      this._levelService
        .updateLevelName(this.data.level_id, this.mainForm.value.name)
        .subscribe(
          (result: any) => {
            if (result) {
              if (result.success) {
                if (this.levelType != 'cplatform') {
                  this._snackBar.open('Saved', '', {
                    duration: 2000,
                  });

                  this._dialogRef.close(result.data);
                } else {
                  this._levelService
                    .updateLevel(
                      this.data.level_id,
                      this.mainForm.value.slug,
                      this.mainForm.value.description,
                      this.mainForm.value.published
                    )
                    .subscribe(
                      (result: any) => {
                        if (result.success) {
                          this._snackBar.open('Saved', '', {
                            duration: 2000,
                          });

                          this._dialogRef.close(result.data);
                        } else {
                          this._snackBar.open('Error: ' + result.message, '', {
                            duration: 2000,
                            panelClass: ['error-snackbar'],
                          });
                        }
                      },
                      (err) => {
                        if (err.status != 200) {
                          // snackbar
                          this._snackBar.open('Error', '', {
                            duration: 2000,
                            panelClass: ['error-snackbar'],
                          });
                        }
                      }
                    );
                }
              } else {
                this._snackBar.open('Error: ' + result.message, '', {
                  duration: 2000,
                  panelClass: ['error-snackbar'],
                });
              }
            }
          },
          (err) => {
            if (err.status != 200) {
              // snackbar
              this._snackBar.open('Error', '', {
                duration: 2000,
                panelClass: ['error-snackbar'],
              });
            }
          }
        );
    } else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar'],
      });
    }
  }

  async changePublishSetting(event: MatSlideToggleChange) {
    this.confirmDialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        title: 'Are you sure?',
        text: event.checked
          ? 'Your platform will become public.'
          : 'Your platform will become private.',
        leftButton: 'Cancel',
        rightButton: 'Confirm',
      },
      disableClose: true,
    });

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        // we reset the value
        this.mainForm.patchValue({
          published: !event.checked,
        });
      }
    });
  }
}
