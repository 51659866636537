import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UsersService } from 'src/app/services/users.service';
import { FileService } from 'src/app/services/file.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-change-avatar-dialog',
  templateUrl: './change-avatar-dialog.component.html',
  styleUrls: ['./change-avatar-dialog.component.scss']
})
export class ChangeAvatarDialogComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  aspectRatio: Number = 1 / 1;

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ChangeAvatarDialogComponent>,
    private _dialog: MatDialog,
    private _usersService: UsersService,
    private _snackBar: MatSnackBar,
    private _fileService: FileService
  ) { }

  uploadedImage(image: any): void {
    this.imageChangedEvent = image.url;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: HTMLImageElement) {
      // show cropper
      console.log('Image loaded')
  }
  cropperReady() {
      // cropper ready
      console.log('Cropper ready')
  }
  loadImageFailed() {
      // show message
      this._snackBar.open('Error loading image', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
  }

  removeImage() {
    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'You will remove this image',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._fileService.deleteAvatar().subscribe((result: any) => {

          if(result.success) {
            window.location.reload();
          }
          else {

            this._snackBar.open(result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
    
          }
    
        }, err => {
    
          if (err.status != 200) {
    
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
    
          }
        });
      }
    });
  }

  ngOnInit(): void {
    console.log(this.data)
  }

  saveCropped() {
    this._fileService.saveCroppedImageUser(this.data.user._id, this.croppedImage).subscribe((result: any) => {

      if(result.success) {
        window.location.reload();
        this._dialogRef.close(result.data);
      }
      else {

        this._dialogRef.close(false);

        this._snackBar.open(result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }

    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });
  }

}
