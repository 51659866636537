import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { HomeComponent } from './home/home.component';
import { InstallComponent } from './install/install.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AdminComponent } from './page/admin/admin.component';
import { InterestLevelComponent } from './page/interest-level/interest-level.component';
import { PlatformLevelComponent } from './page/platform-level/platform-level.component';
import { AuthguardService } from './services/authguard.service';
import { UiStyleComponent } from './ux/ui-style/ui-style.component';
import { WorkspaceComponent } from './workspace/workspace.component';
import { ExploreComponent } from './explore/explore.component';
import { MessagesComponent } from './messages/messages.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ForgotComponent } from './forgot/forgot.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { ProfileComponent } from './page/profile/profile.component';
import { RegisterComponent } from './register/register.component';
import { ValidateComponent } from './validate/validate.component';
import { ExportComponent } from './export/export.component';
import { PrivacyPolicyComponent } from './public/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './public/terms-of-service/terms-of-service.component';
import { ResolverComponent } from './resolver/resolver.component';
import { CalendarComponent } from './calendar/calendar.component';
import { UpcomingEventViewComponent } from './calendar/upcoming-event-view/upcoming-event-view.component';
import { InvitationPageComponent } from './invitation-page/invitation-page.component';
import { InvitationPageComponent2 } from './invitation-page-2/invitation-page.component';
import { CreatePlatformComponent } from './create-platform/create-platform.component';
import { Kmfest24Component } from './landing/kmfest24/kmfest24.component';
import { KmfestResultsComponent } from './landing/kmfest24/kmfest-results/kmfest-results.component';

const routes: Routes = [
  { path: 'logout', component: LogoutComponent, data: { hideHeader: true } },
  { path: 'login', component: LoginComponent, data: { hideHeader: true } },
  { path: 'register', component: RegisterComponent, data: { hideHeader: true } },
  { path: 'register/:id', component: RegisterComponent, data: { hideHeader: true } },
  { path: 'validate/:id', component: ValidateComponent, data: { hideHeader: true } },
  { path: 'forgot', component: ForgotComponent, data: { hideHeader: true } },
  { path: 'forgot/:id', component: ForgotComponent, data: { hideHeader: true } },
  { path: 'unsubscribe/:id/:email', component: UnsubscribeComponent, data: { hideHeader: true } },
  { path: 'install', component: InstallComponent, data: { hideHeader: true } },
  { path: 'export/:id', component: ExportComponent, data: { hideHeader: true } },
  { path: 'join/kmfest', component: InvitationPageComponent, resolve: [AuthguardService], data: { hideHeader: true } },
  { path: 'join/cr-group', component: InvitationPageComponent2, resolve: [AuthguardService], data: { hideHeader: true } },
  { path: 'create-platform', component: CreatePlatformComponent, resolve: [AuthguardService], canActivate: [AuthguardService], data: { hideHeader: true } },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, resolve: [AuthguardService], data: { hideHeader: true } },
  { path: 'terms-of-service', component: TermsOfServiceComponent, resolve: [AuthguardService], data: { hideHeader: true } },
  { path: 'i/kmfest24', component: Kmfest24Component, data: { hideHeader: true } },


  /**
   * Interes & wg
   */
  {
    path: 'p/:platformId/i', component: InterestLevelComponent, resolve: [AuthguardService],
    children: [
      { path: ':interestId/w/:id', component: InterestLevelComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
      { path: ':interestId/w/:id/:page', component: InterestLevelComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
      { path: ':interestId/w/:id/:page/:subpage', component: InterestLevelComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
      { path: ':id', component: InterestLevelComponent, resolve: [AuthguardService] },
      { path: ':id/:page', component: InterestLevelComponent, resolve: [AuthguardService] },
      { path: ':id/:page/:subpage', component: InterestLevelComponent, resolve: [AuthguardService], canActivate: [AuthguardService] }
    ]
  },

  /**
   * Project & wp
   */
  {
    path: 'p/:platformId/p', component: InterestLevelComponent, resolve: [AuthguardService], data: { project: true },
    children: [
      { path: ':interestId/w/:id', component: InterestLevelComponent, resolve: [AuthguardService], canActivate: [AuthguardService], data: { project: true } },
      { path: ':interestId/w/:id/:page', component: InterestLevelComponent, resolve: [AuthguardService], canActivate: [AuthguardService], data: { project: true } },
      { path: ':interestId/w/:id/:page/:subpage', component: InterestLevelComponent, resolve: [AuthguardService], canActivate: [AuthguardService], data: { project: true } },
      { path: ':id', component: InterestLevelComponent, resolve: [AuthguardService], data: { project: true } },
      { path: ':id/:page', component: InterestLevelComponent, resolve: [AuthguardService], data: { project: true } },
      { path: ':id/:page/:subpage', component: InterestLevelComponent, resolve: [AuthguardService], canActivate: [AuthguardService], data: { project: true } }
    ]
  },

  /**
   * Platforma
   */
  {
    path: 'p/:id', component: PlatformLevelComponent, resolve: [AuthguardService],
    children: [
      { path: ':page', component: PlatformLevelComponent, resolve: [AuthguardService] },
      { path: ':page/:subpage', component: PlatformLevelComponent, resolve: [AuthguardService] },
    ]
  },
  { path: 'explore', component: ExploreComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
  { path: 'notifications', component: NotificationsComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
  {
    path: 'messages', component: MessagesComponent, resolve: [AuthguardService], canActivate: [AuthguardService],
    children: [
      { path: ':chatId', component: MessagesComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
    ],
  },
  { path: 'workspace', component: WorkspaceComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
  { path: 'home', component: HomeComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
  { path: 'calendar', component: CalendarComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
  { path: 'calendar/upcoming', component: UpcomingEventViewComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
  { path: 'profile', component: ProfileComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
  { path: 'profile/:id', component: ProfileComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
  { path: 'account-settings', component: AccountSettingsComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
  {
    path: 'admin/:page', component: AdminComponent, resolve: [AuthguardService], canActivate: [AuthguardService, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN'],
        redirectTo: '/home'
      }
    }
  },
  {
    path: 'kmfest24/results', component: KmfestResultsComponent, resolve: [AuthguardService], canActivate: [AuthguardService, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN', 'SUPPORT'],
        redirectTo: '/home'
      }
    }
  },
  {
    path: 'ui', component: UiStyleComponent, resolve: [AuthguardService], canActivate: [AuthguardService, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['ADMIN'],
        redirectTo: '/home'
      }
    }
  },  // briši  
  { path: '**', component: ResolverComponent, resolve: [AuthguardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { enableTracing: false }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
