import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { SocketService } from 'src/app/services/socket.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-chat-sidebar',
  templateUrl: './chat-sidebar.component.html',
  styleUrls: ['./chat-sidebar.component.scss']
})
export class ChatSidebarComponent implements OnInit {

  usersList!: any[];
  @Output() newChat = new EventEmitter<string>();
  @Output() closeSideNav = new EventEmitter<boolean>();

  constructor(
    private _chatService: ChatService,
    private _usersService: UsersService,
    private _socketService: SocketService
  ) { }

  ngOnInit():void {

    this.getConnectedUsers();


    this._socketService.on('client-connected', (userId) => {

      if(this.usersList && this.usersList.length > 0) {

        this.usersList.map((user) =>{

          if(user._id == userId) {
            user.online = true; 
          }

        });

      }

    });

    this._socketService.on('client-disconected', (userId) => {

      if(this.usersList && this.usersList.length > 0) {

        this.usersList.map((user) =>{

          if(user._id == userId) {
            user.online = false; 
          }

        });
        
      }

    });

  }

  async getConnectedUsers() {

    let result: any = await this._usersService.getConnectedUsers(true).toPromise();
    
    if(result?.success) {
      this.usersList = [...result.data];
    }

  }

  closeSideNavButton() {
    this.closeSideNav.emit(true);
  }

  async startNewChat(userId: string) {

    let result:any = await this._chatService.getChatIdByUserId(userId).toPromise();

    if(result.success) {
      this.newChat.emit(result.data);
    }
  }

}
