<ng-container *ngIf="!loading">

    <ng-container *ngIf="error; else noError">

        <div class="p-3 mb-2 bg-danger text-white">
            {{ errorMessage }}
        </div>

    </ng-container>
    <ng-template #noError>


        <ng-container *ngIf="editing; else noEditing">
            <app-proposal-form [id]="levelId" [proposal]="proposal" (exit)="closeForm($event)"></app-proposal-form>
        </ng-container>

        <ng-template #noEditing>


            <div class="border">
                <mat-toolbar>
                    <button mat-raised-button (click)="backButton()">
                        <mat-icon>chevron_left</mat-icon>
                        {{'Back' | translate}}
                    </button>

                    <div class="ms-auto">

                        <button mat-button [matMenuTriggerFor]="menu">
                            <span class="material-icons">
                                more_vert
                            </span>
                        </button>
                        <mat-menu #menu="matMenu">

                            <ng-container *ngIf="isWg">
                                <button mat-menu-item (click)="editProposal(true)" *ngIf="proposal.can_edit">
                                    <mat-icon>edit</mat-icon>
                                    {{'Edit' | translate}}
                                </button>

                                <button mat-menu-item (click)="deleteProposal()" *ngIf="proposal.can_delete">
                                    <mat-icon>delete</mat-icon>
                                    {{'Delete' | translate}}
                                </button>
                            </ng-container>

                            <button mat-menu-item (click)="generateProsposalPDF()">
                                <mat-icon>get_app</mat-icon>
                                {{'Export as PDF' | translate }}
                            </button>

                        </mat-menu>

                        <!--
                        <ng-container *ngIf="proposal.can_delete">
                            <button type="button" mat-raised-button color="warn" class="me-2" (click)="deleteProposal()">
                                <mat-icon>
                                    delete
                                </mat-icon>
                            </button>
                        </ng-container>

                        <ng-container *ngIf="proposal.can_edit">
                            <button type="button" mat-raised-button class="me-3" (click)="editProposal(true)">
                                <mat-icon>
                                    edit
                                </mat-icon>
                            </button>
                        </ng-container>
                        
                        <button type="button" mat-raised-button color="primary" (click)="generateProsposalPDF()">
                            <mat-icon>get_app</mat-icon>
                            {{'Export as PDF' | translate }}
                        </button>-->

                    </div>
                </mat-toolbar>

                <div style="height: 600px; overflow-y: auto;">

                    <div class="mx-4">

                        <div class="my-5 proposalView">


                            <section>
                                <h3>1. {{'Project Title' | translate}}:</h3>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="width: 60%;">{{'Title' | translate}}</th>
                                            <th>{{'Nickname' | translate}}</th>
                                            <th>TRL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ proposal.title }}</td>
                                            <td>{{ proposal.nickname }}</td>
                                            <td>{{ proposal.trl }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>

                            <section>
                                <h3>2. {{'Project Type' | translate}} ({{'underline' | translate}}):</h3>

                                <div class="mx-4">
                                    <table class="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td
                                                    [ngClass]="{ 'text-decoration-underline fw-bold' : proposal.technology }">
                                                    a. {{'Technology' | translate}}</td>
                                                <td
                                                    [ngClass]="{ 'text-decoration-underline fw-bold' : proposal.organization }">
                                                    b. {{'Organization' | translate}}</td>
                                            </tr>
                                            <tr>
                                                <td
                                                    [ngClass]="{ 'text-decoration-underline fw-bold' : proposal.competencies }">
                                                    c. {{'Competencies' | translate}}</td>
                                                <td
                                                    [ngClass]="{ 'text-decoration-underline fw-bold' : proposal.corporate_culture }">
                                                    d. {{'Corporate Culture' | translate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>

                            <section>
                                <h3>3. {{'Owner(s) of the Project Idea' | translate}}:</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>{{ proposal.owner }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>

                            <section>
                                <h3>4. {{'Proposing Organization' | translate}}:</h3>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>{{ proposal.proposing_organization }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>

                            <section>
                                <h3>5. {{'Subject of Business Secret' | translate}}:</h3>

                                <div class="mx-4">
                                    <table class="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td class="w-50 text-center"
                                                    [ngClass]="{ 'text-decoration-underline fw-bold' : proposal.subject_business_secret }">
                                                    Yes</td>
                                                <td class="w-50 text-center"
                                                    [ngClass]="{ 'text-decoration-underline fw-bold' : !proposal.subject_business_secret }">
                                                    No</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>

                            <section>
                                <h3>6. {{'Areas of Required Competencies / Expertise of Potential Project Partners' |
                                    translate}}:</h3>

                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="ck-content"
                                                    [innerHTML]="proposal.areas_of_required_competencies | sanitizeHtml">
                                                </div>
                                                <!--{{ proposal.areas_of_required_competencies }}</td>-->
                                        </tr>
                                    </tbody>
                                </table>
                            </section>

                            <section>
                                <h3>7. {{'Short Description of the Proposed Project Business Case' | translate}}:</h3>

                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="ck-content"
                                                    [innerHTML]="proposal.short_description | sanitizeHtml"></div>
                                                <!--{{ proposal.short_description }}-->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>

                            <section>
                                <h3>8. {{'Draft Project Scope & Expected Deliverables' | translate}}:</h3>

                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="ck-content"
                                                    [innerHTML]="proposal.draft_project_scope | sanitizeHtml"></div>
                                                <!--{{ proposal.draft_project_scope }}-->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>

                            <section>
                                <h3>9. {{'Conceptual Estimations: Time & Costs & Funding' | translate}}:</h3>

                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>{{'Project Duration' | translate}}</th>
                                            <th>{{'Draft Cost Estimation (EUR)' | translate}}</th>
                                            <th>{{'Expected Funding Sources' | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ proposal.draft_project_duration }}</td>
                                            <td>{{ proposal.draft_cost }}</td>
                                            <td>{{ proposal.expected_sources }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>

                            <section>
                                <h3>10. {{'Expected Benefits & KPI - Key Perfomance Indicators' | translate}}:</h3>

                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>{{'Expected Project Benefits' | translate}}</th>
                                            <th>{{'KPI - Key Perfomance Indicators' | translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="ck-content"
                                                    [innerHTML]="proposal.expected_benefits | sanitizeHtml"></div>
                                                <!--{{ proposal.expected_benefits }}-->
                                            </td>
                                            <td>
                                                <div class="ck-content" [innerHTML]="proposal.kpi | sanitizeHtml"></div>
                                                <!--{{ proposal.kpi }}-->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>

                            <section>
                                <h3>11. {{'Main Project Assumptions' | translate}}:</h3>

                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="ck-content"
                                                    [innerHTML]="proposal.assumptions_and_risks | sanitizeHtml"></div>
                                                <!--{{ proposal.assumptions_and_risks }}-->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>

                            <section>
                                <h3>12. {{'Main Project Risks / Mitigation Plan' | translate}}:</h3>

                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="ck-content"
                                                    [innerHTML]="proposal.main_project_risks | sanitizeHtml"></div>
                                                <!--{{ proposal.main_project_risks }}-->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>

                            <section>
                                <h3>13. {{'Contact Person' | translate}}:</h3>

                                <div class="mx-4">
                                    <table class="table table-borderless" style="width: auto;">
                                        <tbody>
                                            <tr>
                                                <td>a. {{'Name, Surname /Organization' | translate}}:</td>
                                                <td class="fw-bold">{{ (proposal.name_contact) ? proposal.name_contact :
                                                    '/'
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>b. {{'E-mail' | translate}}:</td>
                                                <td class="fw-bold">{{ (proposal.email) ? proposal.email : '/' }}</td>
                                            </tr>
                                            <tr>
                                                <td>c. {{'Mobile' | translate}}:</td>
                                                <td class="fw-bold">{{ (proposal.mobile) ? proposal.mobile : '/' }}</td>
                                            </tr>
                                            <tr>
                                                <td>d. {{'Others' | translate}}:</td>
                                                <td class="fw-bold">{{ (proposal.others) ? proposal.others : '/' }}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </section>

                        </div>

                    </div>

                </div>
            </div>

        </ng-template>

    </ng-template>

</ng-container>