import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpOptionsService } from '../services/http-options.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(
    private _http: HttpClient,
    private _httpOptionsService: HttpOptionsService,
    private _config: ConfigService
  ) { }

  getSystemVersion() {
    return this._http.get(this._config.getApiUrl() + '/api/system/version/', this._httpOptionsService.getHeader());
  }

  adminGetSystemStatus() {
    return this._http.get(this._config.getApiUrl() + '/api/system/status', this._httpOptionsService.getHeader());
  }

  adminGetBackups() {
    return this._http.get(this._config.getApiUrl() + '/api/system/backups', this._httpOptionsService.getHeader());
  }

  adminCreateNewBackup() {
    return this._http.put(this._config.getApiUrl() + '/api/system/backup', {}, this._httpOptionsService.getHeader());
  }
 
  adminDownloadBackup(filename: string) {
    return this._http.get(this._config.getApiUrl() + '/api/system/download/backup/' + filename, this._httpOptionsService.getHeader());
  }

}
