import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  addBlog(
    levelId: string, 
    name: string, 
    description: string, 
    ) {

    return this._http.post(this._config.getApiUrl() + '/api/blog', {    
      level_id: levelId,
      name: name,
      description: description
    }, this._httpOptionsService.getHeader())

  }

  editBlog(blogId: string, levelId: string, name: string, description: string) {

    return this._http.put(this._config.getApiUrl() + '/api/blog/' + blogId, {    
      level_id: levelId,
      name: name,
      description: description
    }, this._httpOptionsService.getHeader())

  }

  deleteComment(blogId: string, commentId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/blog/comment/' + blogId + '/' + commentId, this._httpOptionsService.getHeader())
  }

  deleteBlog(blogId: string, levelId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/blog/' + levelId + '/' + blogId, this._httpOptionsService.getHeader())
  }

  getBlogs(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/blog/' + levelId, this._httpOptionsService.getHeader())
  }

  getSpecificBlog(levelId: string, blogId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/blog/' + levelId + '/' + blogId, this._httpOptionsService.getHeader())
  }

  addComment(levelId: string, articleId: string, message: string) {

    return this._http.post(this._config.getApiUrl() + '/api/blog/comment', {    
      level_id: levelId,
      blog_id: articleId,
      message: message
    }, this._httpOptionsService.getHeader())
  }

}
