import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-embed-to-website-dialog',
  templateUrl: './embed-to-website-dialog.component.html',
  styleUrls: ['./embed-to-website-dialog.component.scss']
})
export class EmbedToWebsiteDialogComponent implements OnInit {

  url: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<EmbedToWebsiteDialogComponent>,
    private _config: ConfigService
  ) { }

  ngOnInit(): void {

    this.url = window.location.protocol + '//' + window.location.host;
    
  }

}
