import { Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SearchService } from 'src/app/services/search.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {


  results: any[] = [];
  resultsLength: number = 0;
  loading: boolean = false;

  searchString: string;
  modelChanged: Subject<string> = new Subject<string>();

  constructor(
    private _searchService: SearchService,
    private _router: Router
  ) { 

    this.modelChanged.pipe(
      debounceTime(300), 
      distinctUntilChanged())
      .subscribe(model => {

        this.loading = true;

        this.searchString = model;

        this.search(this.searchString);

      });

  }

  ngOnInit(): void {
  }

  search(searchString: string) {

    this._searchService.search(searchString).subscribe((result: any) => {

      if(result.success) {    
        
        this.loading = false;
        this.results = result.data;
        this.resultsLength = result.data.results;
        
      }
      else {
        this.results = [];
        this.resultsLength = 0;
      }

    }, err => {

      if (err.status != 200) {

        this.results = [];
        this.resultsLength = 0;
        this.loading = false;

      }
      
    });
  }
  
  changed(text: string) {
    this.modelChanged.next(text);
  }

  showLevel(link: string) {
    this.resultsLength = 0;
    this.searchString = '';
    this._router.navigate([link]);
  }

  showUser(id: string) {
    this.resultsLength = 0;
    this.searchString = '';
    this._router.navigate(['/profile/' + id]);
  }

  ngOnDestroy() {
    this.modelChanged.unsubscribe();
  }

}
