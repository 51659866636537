import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ServerReply } from '../component/chat-box/server-reply';
import { LevelService } from '../services/level.service';
import { UserinfoService } from '../services/userinfo.service';
import { UsersService } from '../services/users.service';
import { ConfirmDialogComponent } from '../utils/confirm-dialog/confirm-dialog.component';

interface Level {
  _id: string,
  name: string,
  description: string,
  owner: boolean,
  link: string
  avatar: {
    url: string;
  },
  background_image: {
    url: string;
  }
}

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss']
})
export class WorkspaceComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  loading: boolean = true;
  levels: Level[];
  view: string;
  user: any;
  searchActivate: boolean = false;

  searchString: string;
  modelChanged: Subject<string> = new Subject<string>();

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _levelService: LevelService,
    private _userinfoService: UserinfoService,
    private _snackBar: MatSnackBar,
    private _titleService: Title,
    private _userService: UsersService,
  ) {
    this.subscription = this._userinfoService.loggedIn$.subscribe((value) => {
      if (value) {
        this.user = this._userinfoService.getLocalInfo();
      }
    });

    this.modelChanged.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(search => {

        this.search(search);

      });
  }

  ngOnInit(): void {

    let userInfo: any = this._userinfoService.getLocalInfo();
    if(userInfo && userInfo.user_settings) {
      this.view = (userInfo.user_settings['workspace_view']) ? userInfo.user_settings['workspace_view'] : 'grid';
    }
    else {
      this.view = 'grid';
    }

    this._titleService.setTitle('My Platforms | ExArca');

    this._levelService.getLevelsSpecifics('cplatform').subscribe((result: Level[]) => {

      this.levels = [...result];
      this.loading = false;

    }, err => {
      this.loading = false;
      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });
  }

  // gridview or listview
  changeView(type: string) {
    this._userService.saveUserSettings('workspace_view', type).subscribe((result: ServerReply) => {
      this.view = type;
    }, (err: HttpErrorResponse) => {
      if(err.status != 200) {
        console.log('Not saved')
      }
    });
  }

  changed(text: string) {
    this.modelChanged.next(text);
  }

  search(searchString: string) {

    let query = this._levelService.searchLevelSpecifics('cplatform', searchString);
    this.searchActivate = true;

    if(searchString == '') {
      query = this._levelService.getLevelsSpecifics('cplatform');
      this.searchActivate = false;
    }    

    query.subscribe((result: any) => {

      if(result.success) {    
        
        this.levels = result.data;
        
      }
      else {
        this.levels = result.data;
      }

    }, err => {

      if (err.status != 200) {

        this.levels = [];
        this.loading = false;

      }
      
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.modelChanged.unsubscribe();
  }

}
