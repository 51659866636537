<ng-container [ngSwitch]="currentPage">

    <ng-container *ngSwitchCase="'add-proposal'">
        <app-proposal-form [id]="id"></app-proposal-form>
    </ng-container>

    <ng-container *ngSwitchCase="'show-proposal'">
        <app-specific-proposal [id]="proposalId" [levelId]="id" [isWorkGroup]="isWorkGroup"></app-specific-proposal>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <app-show-proposals [id]="id" [role]="role" [url]="url" [isWorkGroup]="isWorkGroup"></app-show-proposals>
    </ng-container>

</ng-container>