import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  newMemberEmiter$:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  getFollowers(platformId: string) {
    
  }

  getMembers(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/level/members/' + levelId, this._httpOptionsService.getHeader())
  }

  getPlatformMembers(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/level/platform/members/' + levelId, this._httpOptionsService.getHeader())
  }

  deleteMember(levelId: string, membershipId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/level/remove/member/' + levelId + '/' + membershipId, this._httpOptionsService.getHeader())
  }

  getUserMemberships(userId?: any) {
    
    return this._http.get(this._config.getApiUrl() + '/api/level/membership' + ((userId) ? ('/' + userId) : ''), this._httpOptionsService.getHeader())
  }

  updateLevelNotifications(levelId: string) {
    return this._http.put(this._config.getApiUrl() + '/api/level/change/notifications/' + levelId, {}, this._httpOptionsService.getHeader())
  }
}
