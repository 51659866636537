
    <div *ngIf="!loading" class="mat-elevation-z2 d-flex flex-column" 
    [ngStyle]="{ 'right': ((280 * index)) + 'px' }"
    style="margin-right: 280px; background: red; width: 260px; height: 350px; position: fixed; bottom: 0; z-index: 999; background: #fff; border: 1px solid #ccc;">
        <mat-toolbar>

            <ng-container *ngIf="chatInfo.users.length == 1; else multipleUsers">
                <div class="d-flex align-items-center">
                    <app-user-avatar width="30" fontSize="15px" [info]="chatInfo.users[0]"></app-user-avatar>
                    <div class="ms-2" style="font-size: 1rem;">{{ chatInfo.users[0].full_name }}</div>
                </div>
            </ng-container>
            <ng-template #multipleUsers>
                <ng-container *ngFor="let user of chatInfo.users">
                    <app-user-avatar width="30" fontSize="15px" [info]="user"></app-user-avatar>
                </ng-container>
            </ng-template>

            <button mat-button class="ms-auto small-btn" (click)="closeChatBox()">                
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
        <div class="d-flex align-items-end flex-column p-2" style="height: 250px;">
            
            <div class="w-100 h-100" style="display:flex; flex-direction:column-reverse; overflow: auto;">
                <ng-container *ngFor="let m of chatInfo?.messages">

                    <ng-container *ngIf="m.him; else me">
                        <div class="d-flex w-100">

                            <div class="my-2 bubble him">
                                {{ m.text }}
                                <div class="text-gray-600" style="font-size: .8rem;">{{ m.date_created | myDate }}</div>
                            </div>               
            
                        </div>
                    </ng-container>

                    <ng-template #me>
                        <div class="d-flex w-100">
                            <div class="my-2 bubble me  ms-auto">
                                {{ m.text }}
                                <div class="text-gray-600" style="font-size: .8rem;">{{ m.date_created | myDate }}</div>
                            </div>
                        </div>
                    </ng-template>

                </ng-container>
            </div>
  
        </div>

        <div class="mt-auto" style="border: 1px solid #eee">
            <div class="d-flex my-2 mx-2">
                <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate>
                    <input  type="text" formControlName="message" placeholder="Your message" style="border: 0;" />
                    <button mat-raised-button color="primary" class="ms-auto" type="submit">
                        <mat-icon>send</mat-icon>
                    </button>
                </form>
            </div>
        </div>
    </div>