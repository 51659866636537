import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CdkScrollable, ScrollDispatcher, ScrollingModule } from '@angular/cdk/scrolling';
import { Component, OnDestroy, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LayoutService } from './services/layout.service';
import { UserinfoService } from './services/userinfo.service';
import { debounceTime } from 'rxjs/operators';

import { Sizes } from './sizes';
import { SocketService } from './services/socket.service';
import { IncomingMessage } from './incoming-message';
import { UsersService } from './services/users.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerReply } from './component/chat-box/server-reply';
import { ChatService } from './services/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  userInfo: any;

  // chat box
  chatBoxes!: any[];

  // scroll from top - inside container
  scrollTop: Number = 0;

  isHandset$: Observable<boolean> = this._breakpointObserver.observe('(max-width: 1000px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isNotLarger$: Observable<boolean> = this._breakpointObserver.observe('(max-width: 1200px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


  subscription1: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  subscription4: Subscription;
  scrollingSubscription: Subscription;
  private chatMessagesSubs: Subscription;

  showHeader: boolean;
  hideFooter: boolean;
  showSidebar: boolean;
  innerWidth: any;
  messageNumber: number = 0;

  drawerStatus: boolean;
  @ViewChild('drawer') public drawer: MatDrawer;

  chatBoxDrawerStatus: boolean = true;
  @ViewChild('chatBox') public chatBoxDrawer: MatDrawer;


  constructor(
    private _router: Router,
    private _layoutService: LayoutService,
    private _breakpointObserver: BreakpointObserver,
    private _userinfoService: UserinfoService,
    private _userService: UsersService,
    private _socketService: SocketService,
    private scroll: ScrollDispatcher,
    private _chatService: ChatService,
  ) {

    /*
    this.scrollingSubscription = this.scroll
      .scrolled()
      //.pipe(debounceTime(100))
      .subscribe((data: CdkScrollable) => {
        this.scrollTop = data?.getElementRef().nativeElement.scrollTop;
      });*/

    this.subscription1 = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.showHeader = true;
        this.hideFooter = false;

        this.scrollTop = 0;
      }
    });

    this.subscription2 = this._layoutService.toolbarStatus$.subscribe((toolbarStatus: boolean) => {
      this.showHeader = toolbarStatus;
    });

    this.subscription3 = this._userinfoService.loggedIn$.subscribe((loggedIn: boolean) => {
      this.showSidebar = loggedIn;

      /**
       * We get user settings, because everytime we refresh the page, we want to show the same chat
       * boxex, which were open, before refresh
       */
      if (loggedIn) {

        this.userInfo = this._userinfoService.getLocalInfo();

        this.messageNumber = (this.userInfo?.notifications?.messages) ? this.userInfo?.notifications?.messages : 0;

        if (this.userInfo && this.userInfo.user_settings) {

          let chat_boxes = [];

          if (chat_boxes = this.userInfo.user_settings.chat_boxes) {

            this.chatBoxes = [...chat_boxes]

          }

        }

      }

    });

    this.subscription4 = this._layoutService.footerStatus$.subscribe((footerStatus: boolean) => {
      this.hideFooter = footerStatus;
    });


    this.chatMessagesSubs = this._chatService.messagesNumber$.subscribe((number) => {
      if (number != null) {
        this.messageNumber = number;
      }
    });

  }


  ngOnInit() {
    //#region window size
    this.innerWidth = window.innerWidth;
    this.calculateSize(this.innerWidth);
    //#endregion

    this._socketService.on('incoming-message', (data: IncomingMessage) => {


      let found = false;

      if (this.chatBoxes && this.chatBoxes.length > 0) {

        this.chatBoxes.map((chatId, index) => {
          if (chatId == data.chat_id) {
            found = true;
          }

        });

      }

      if (!found) {

        if (!this.chatBoxes) {
          this.chatBoxes = [];
        }

        this.chatBoxes.push(data.chat_id);
      }

    });

  }

  //#region resize

  /** window resize */
  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.innerWidth = window.innerWidth;
    this.calculateSize(this.innerWidth);

  }

  calculateSize(width: number) {

    if (width < 576) {
      this._layoutService.setSize(Sizes.xs);
    }
    else if (width >= 576 && width < 768) {
      this._layoutService.setSize(Sizes.sm);
    }
    else if (width >= 768 && width < 992) {
      this._layoutService.setSize(Sizes.md);
    }
    else if (width >= 992 && width < 1200) {
      this._layoutService.setSize(Sizes.lg);
    }
    else if (width >= 1200 && width < 1400) {
      this._layoutService.setSize(Sizes.xl);
    }
    else if (width >= 1400) {
      this._layoutService.setSize(Sizes.xxl);
    }

  }

  /** 
   * Function from header 
   * to trigger sidebar 
   */
  statusFromHeaderMainMenuDrawer(status: boolean) {
    this.drawer.toggle()
  }

  statusFromHeaderCommunityDrawer(status: boolean) {
    this.chatBoxDrawerStatus = status;
    this._layoutService.showChatSidebar$.next(status);
    this.chatBoxDrawer.toggle();
  }

  //#endregion

  toggle(nav: MatSidenav) {
    const isSmallScreen = this._breakpointObserver.isMatched(
      "(max-width: 599px)"
    );
    if (isSmallScreen) {
      nav.toggle();
    }
  }

  // main menu sidenav
  mainMenuSideNavClosed(status: any) {
    this.drawerStatus = status;
  }

  // remote function to close the chatbox drawer
  closeChatBoxDrawer(event: any) {
    this.chatBoxDrawerStatus = false;
    this._layoutService.showChatSidebar$.next(false);
    this.chatBoxDrawer.toggle();
  }


  newChat(chatId: string) {

    this._userService.saveUserSettings('chat_id', chatId).subscribe((result: ServerReply) => {

      let found = false;

      if (this.chatBoxes && this.chatBoxes.length > 0) {

        this.chatBoxes.map((chatId2, index) => {
          if (chatId2 == chatId) {
            found = true;
          }

        });

      }

      if (!found) {

        if (!this.chatBoxes) {
          this.chatBoxes = [];
        }

        this.chatBoxes.push(chatId);

      }

    }, (err: HttpErrorResponse) => {

      if (err.status != 200) {
        console.log('Not saved')
      }

    })

  }

  closeChatBox(index: number) {
    this.chatBoxes.splice(index, 1);

    if (this.chatBoxes.length == 0) {
      this.chatBoxes = null;
    }
  }

  ngAfterViewInit() { }

  ngOnDestroy() {

    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }

    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }

    if (this.subscription4) {
      this.subscription4.unsubscribe();
    }

    if (this.scrollingSubscription) {
      this.scrollingSubscription.unsubscribe();
    }

    if (this.chatMessagesSubs) {
      this.chatMessagesSubs.unsubscribe();
    }

  }


}
