import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { FileService } from 'src/app/services/file.service';
import { TopicService } from 'src/app/services/topic.service';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { ViewFilesDialogComponent } from '../repository/view-files-dialog/view-files-dialog.component';
import { DiscussionDialogComponent } from './discussion-dialog/discussion-dialog.component';
import { ShowLikesDialogComponent } from './show-likes-dialog/show-likes-dialog.component';


@Component({
  selector: 'app-discussions',
  templateUrl: './discussions.component.html',
  styleUrls: ['./discussions.component.scss']
})
export class DiscussionsComponent implements OnInit {

  loading: boolean = true;
  saved: number;      // for editor helper

  user: any;

  
  topics: any = [];

  // Discussions
  topicData: any;
  discussions: any = [];

  url: string;
  _topic: string;

  replyMapping: any = {
    '=0': 'reply',
    '=1': 'reply',
    '=2': 'reply_2',
    'other': 'replies'
  }

  // hide and show comments
  commentsShowNumber: number = 3;
  commentsLeft: number = 0;
  shownComments: any = [];

  @Input() id: string;      // level id
  @Input() role: number;    // user role
  @Input() set topic(topicId: string) {   // topic id
    this.url = this._router.url;
    this._topic = topicId;

    this.loading = true;

    this.showDiscussions();
  }

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  addDiscussionDialogRef: MatDialogRef<DiscussionDialogComponent>;
  showLikesDialogRef: MatDialogRef<ShowLikesDialogComponent>;
  viewFilesDialogRef: MatDialogRef<ViewFilesDialogComponent>;

  constructor(
    private _userinfoService: UserinfoService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _topicService: TopicService,
    private _fileService: FileService,
    private _config: ConfigService,
    private _location: Location,
    private _router: Router
  ) { }

  ngOnInit(): void {
    
    this.user = this._userinfoService.getLocalInfo();
    
  }

  showDiscussions() {
    
    if(!this._topic) {

      this.getTopics().then((topics: any) => {
        this.loading = false;
        this.topics = topics;
      });

    }
    else {    

      this.getTopic().then((data: any) => {
        this.loading = false;

        if(data && data.length > 0) {
          
          // top comment
          this.topicData = data[0];
          data.shift();

          this.discussions = [...data];

          /** 
           * We check how many comments do we have. 
           * If we have more then 4 comments we include the load more comments button 
           */

          
          if(this.discussions.length > this.commentsShowNumber) {
            this.commentsLeft = (this.discussions.length) - this.commentsShowNumber;

            this.shownComments = this.discussions.splice(this.commentsLeft, this.discussions.length)

            console.log(this.shownComments)
          }
          else {
            this.shownComments = [...this.discussions];  
          }


          //this.shownComments = [...this.discussions];  
          
        }         
      });

    }

  }

  addDiscussion() {

    this.addDiscussionDialogRef = this._dialog.open(
      DiscussionDialogComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id
        },
        disableClose: true
      }
    );

    this.addDiscussionDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.topics.unshift(result);
      }
    });
  }

  getTopics() {

    return new Promise((resolve, reject) => {

      this._topicService.getTopics(this.id).subscribe((result: any) => {

        if (result.success) {         

          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });

  }

  getTopic() {
    return new Promise((resolve, reject) => {

      this._topicService.getTopic(this.id, this._topic).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });
  }

  viewFile(id: string) {

    this.viewFilesDialogRef = this._dialog.open(
      ViewFilesDialogComponent,
      {        
        data: {
          level_id: this.id,
          file_id: id
        },
        panelClass: 'preview-file-dialog',
        disableClose: false
      }
    );

  }


  prepareDownload(id: string) {

    this._fileService.downloadFile(this.id, id).subscribe((result: any) => {

      if(result && result.success) {
        window.open(this._config.getApiUrl() + "/download/" + result.data, "_blank");
      }
      else {
        this._snackBar.open(result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });      
      }

    }, err => {
      if (err.status != 200) {
        this._snackBar.open('Error on the server', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });

  }

  onCommentSave(commentInfo: any) {
    this._topicService.addComment(this.id, this._topic, commentInfo.message, commentInfo.attachments).subscribe((result: any) => {

      if (result.success) {
        this.saved = Date.now();
        this.shownComments.push(result.data);

      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {
        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });
  
  }

  private addLikes(data: any, user: any) {

    let exist = true;

    if(!data.likes) {
      data.likes = [];
      exist = false;
      
    }

    if(exist) {
      let number = -1;

      for(let i = 0; i < data.likes.length; i++) {
        if(data.likes[i]._id == user._id) {
          number = i;
          exist = true;
        }
      }

      if(number > -1) {
        data.liked = false;
        data.likes_number--;

        data.likes.splice(number, 1);
      }
      else {
        exist = false;
      }
    }

    if(!exist) {
      data.liked = true;
      data.likes_number++;

      data.likes.push(user);
    }

    

  }

  like(conversationId: string) {
    this._topicService.addLike(this.id, conversationId).subscribe((result: any) => {

      if (result.success) {
        
        let found = false;
        for(let d of this.shownComments) {
          if(d._id == conversationId) {
            //d.liked = true;
            //d.likes_number++;
            
            this.addLikes(d, result.data);

            found = true;
          }
        }

        if(!found) {
          //this.topicData.liked = true;
          //this.topicData.likes_number++;

          this.addLikes(this.topicData, result.data);
        }

      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {
        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });
  }

  showLikes(likes: any) {
    this.showLikesDialogRef = this._dialog.open(
      ShowLikesDialogComponent,
      {
       minWidth: '450px',
        data: {
          likes: likes
        },
        disableClose: false
      }
    );

  }

  editComment(conversation: any) {
    this.addDiscussionDialogRef = this._dialog.open(
      DiscussionDialogComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id,
          edit: true,
          conversation: conversation
        },
        disableClose: true
      }
    );

    this.addDiscussionDialogRef.afterClosed().subscribe(result => {
      if (result) {

        for(let d of this.shownComments) {
          if(d._id == result._id) {
            d.message = result.message;
          }
         
          // replies
          for(let r of d.replies) {

            if(r._id == result._id) {
              r.message = result.message;
            }

          }
        }

        
      }
    });
  }

  editTopic() {
    this.addDiscussionDialogRef = this._dialog.open(
      DiscussionDialogComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id,
          edit: true,
          topic: this.topicData
        },
        disableClose: true
      }
    );

    this.addDiscussionDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.topicData = result;

      }
    });
  }

  deleteComment(commentId: string, adminDelete?: boolean) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this comment?',
          leftButton: 'Cancel',
          rightButton: 'Delete'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._topicService.deleteComment(this.id, commentId).subscribe((result: any) => {

          if (result.success) {

            let discLength = this.shownComments.length;

            if(discLength > 0) {              
            
              for (let index = 0; index < discLength; index++) {
                
                let repLength = (this.shownComments[index].replies) ? this.shownComments[index].replies.length : 0;
                
                if(this.shownComments[index]._id == commentId) {

                  if(adminDelete) {
                    this.shownComments.splice(index, 1);
                    index--;
                    discLength--;
                  }
                  else {
                    this.shownComments[index].deleted = true;
                  }

                }
                
                // replies
                if(repLength > 0) {
                  for(let index2 = 0; index2 < repLength; index2++) {

                    if(this.shownComments[index].replies[index2]._id == commentId) {

                      if(adminDelete) {
                        this.shownComments[index].replies.splice(index2, 1);
                        index2--;
                        repLength--;
                      }
                      else {
                        this.shownComments[index].replies[index2].deleted = true;
                      }                      

                    }

                  }
                }
                
              }

            }

          }
          else {  
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {  
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });

      }
    });

  }

  deleteTopic(topicId: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this topic with all comments and attachments?',
          leftButton: 'Cancel',
          rightButton: 'Delete'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._topicService.deleteTopic(this.id, topicId).subscribe((result: any) => {

          if (result.success) {

            /** we prepare the route */
            let currentUrl = this._router.url;
            let tmpArray = currentUrl.split('/');
            tmpArray.pop();


            /** we redirect */           
            this._router.navigate([tmpArray.join('/')]);

          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {  
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });

      }
    });

  }

  /** activate reply */
  reply(comment: any) {
    comment.reply = true;
  }

  /** add reply */
  addReply(comment: any, commentInfo: any) {
    this._topicService.addReply(comment._id, this.id, this._topic, commentInfo.message, commentInfo.attachments).subscribe((result: any) => {

      if (result.success) {

        this.saved = Date.now();

        if(this.topicData._id == comment._id) {
          this.topicData.replies.push(result.data);

          this.topicData.reply = false;

          return true;
        }

        for(let d of this.shownComments) {

          if(d._id == comment._id) {

            if(!d.replies) 
              d.replies = [];

            
            d.replies.push(result.data);

            d.reply = false;

          }

        }        

      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {
        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });
  }

  backButton() {

    let currentUrl = this._router.url;
    let tmpArray = currentUrl.split('/');
    tmpArray.pop();
        
    this._router.navigate([tmpArray.join('/')]);

  }

  loadComments() {

    for(let comment of this.shownComments) {
      comment.active = false;
    }


    this.commentsLeft = (this.discussions.length) - this.commentsShowNumber;

    if(this.commentsLeft < 0) {
      this.commentsLeft = 0;
    }
    
    let leftField = this.discussions.splice(this.commentsLeft, this.discussions.length);

    /**
     * We need to reverse array
     */
    leftField = leftField.reverse();

    for(let i = 0; i < leftField.length; i++) {

      leftField[i].active = true;

      this.shownComments.unshift(leftField[i]);
    }

  }
}
