<div class="d-flex align-items-center mb-2">
    <h2 class="mb-0" mat-dialog-title>
        Request for membership
    </h2>
    <div class="ms-auto">
        <button mat-button color="secondary" [mat-dialog-close]="false">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content class="pb-2">

    <div class="d-flex align-items-center">

        <app-user-avatar width="40" fontSize="14px" [info]="data.sender_id">
        </app-user-avatar>

        <div class="ms-2 mat-caption">{{ data.sender_id?.full_name }}</div>   

    </div>

    <div class="mt-2">
        
        {{ data.sender_id?.full_name }}          
        
        {{'requested membership for' | translate}}
        <span class="pointer" [routerLink]="data.link"
            [ngClass]="{ 'color-interest' : (data.level_type == 'interest'), 'color-wg' : (data.level_type == 'wg'), 'color-link' : (data.level_type == 'cplatform') }">{{
            data.options?.level_name }}</span>.
       
    </div>

    

    <div class="text-italic mt-2 border p-2" style="font-size: .9rem;" *ngIf="data.options?.message"> 
        {{ data.options?.message | mystriphtml }}
    </div>

</mat-dialog-content>

<mat-dialog-actions>        

    <button mat-raised-button [mat-dialog-close]="false">Close</button>

</mat-dialog-actions>