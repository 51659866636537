import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-repo-add-file',
  templateUrl: './repo-add-file.component.html',
  styleUrls: ['./repo-add-file.component.scss']
})
export class RepoAddFileComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<RepoAddFileComponent>,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  newFile(file) {

    this._dialogRef.close(file);

  }

}
