<div class="legend mb-4">
    <div class="d-flex align-items-center" [ngClass]="_levelId.type">

        <div class="indicator"></div>

        <div class="ms-2 pointer" [routerLink]="_levelId.link">
            {{ _levelId.name | truncate : 60 }}
        </div>

    </div>
</div>