import * as CKEDITOR from '../../lib/ckeditor5/build/ckeditor';

import { ConfigService } from '../../services/config.service';

import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-my-editor',
  templateUrl: './my-editor.component.html',
  styleUrls: ['./my-editor.component.scss']
})
export class MyEditorComponent implements OnInit {

  ignoreNewValueListener: boolean = false;
  editorObject: any;

  config: any = {}; 
  text: string = '';
  _toolbar = [ 'heading', '|', 'alignment', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'todoList', 'fontColor', 'highlight', '|', 'insertTable', 'htmlEmbed', 'mediaEmbed', 'imageUpload', '|', 'undo', 'redo'];

  @Input() set toolbar(toolbar: any) {
    this._toolbar = toolbar;
  }
  @Input() levelid;
  @Input() set data(data: string) {
    this.text = data;

    if(this.editorObject && data == '') {
      this.ignoreNewValueListener = true;
      this.editorObject.setData( data );      
    }
  }

  @Output() output = new EventEmitter<any>();

  @ViewChild('editorContainer') editorContainer: ElementRef;
  @ViewChild('editorToolbarContainer') editorToolbarContainer: ElementRef;
  
  constructor(
    private _config: ConfigService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {

    //console.log(CKEDITOR);  
    
    this.config = {
      viewportTopOffset: 50,
      toolbar: this._toolbar, 
      image: {
          toolbar: [
              'imageTextAlternative',
              'imageStyle:alignLeft', 
              'imageStyle:full', 
              'imageStyle:alignRight',
              'linkImage'
          ],
          styles: [
            // This option is equal to a situation where no style is applied.
            'full',
  
            // This represents an image aligned to the left.
            'alignLeft',
  
            // This represents an image aligned to the right.
            'alignRight'
        ]
      },
      table: {
          contentToolbar: [
              'tableColumn',
              'tableRow',
              'mergeTableCells',
              'tableCellProperties',
              'tableProperties'
          ]
      },
      simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: this._config.getApiUrl() + '/api/file/editor/' + this.levelid,
      
        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,
  
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: { 'Authorization': this.cookieService.get('jwtEx') },
      }
    }

    

    /*
    CKEDITOR.ClassicEditor
    .create( document.querySelector( '#classic-editor' ), this.config )
    .then( editor => {
      console.log( editor );

      editor.model.document.on( 'change:data', () => {
          console.log( 'The data has changed!' );
      });

    })
    .catch( err => {
        console.error( err.stack );
    });*/

  }

  ngAfterViewInit() {    
    let _this = this;

    CKEDITOR.DecoupledEditor
    .create( this.editorContainer.nativeElement, this.config )
    .then( editor => {

        this.editorObject = editor;

        const toolbarContainer = this.editorToolbarContainer.nativeElement;

        toolbarContainer.appendChild( editor.ui.view.toolbar.element );


        editor.model.document.on( 'change:data', () => {
          
          /** we do this because if reset the form, cursor is not moving */
          if(!_this.ignoreNewValueListener) {
            _this.output.emit(editor.getData());  
          }
          else {
            // we reset back
            _this.ignoreNewValueListener = false;
          }

        });
    } )
    .catch( error => {
        console.error( error );
    } );
  }

}
