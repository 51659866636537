<ng-container *ngIf="user && user.full_name">

    <ng-container *ngIf="user.avatar_url; else noImage">

        <ng-container *ngIf="!disableLink; else noLink1">
            <img (click)="showUserDialog(user)" [src]="user.avatar_url" [ngClass]="rootClass" class="pointer user-avatar" title="{{ user.full_name }}" [ngStyle]="{ width: customWidth, height: customWidth }">
        </ng-container>
        <ng-template #noLink1>
            <img [src]="user.avatar_url" [ngClass]="rootClass" class="user-avatar" title="{{ user.full_name }}" [ngStyle]="{ width: customWidth, height: customWidth }">
        </ng-template>

    </ng-container>

    <ng-template #noImage>

        <ng-container *ngIf="!disableLink; else noLink2">
            <div (click)="showUserDialog(user)" class="pointer user-avatar d-flex align-items-center justify-content-center" [ngClass]="rootClass" matTooltip="{{ user.full_name }}" [ngStyle]="{background: user.color, color: user.text_color, width: customWidth, height: customWidth, fontSize: fontSize }" title="{{ user.full_name }}">{{ user.initials }}</div> 
        </ng-container>
        <ng-template #noLink2>
            <div class="user-avatar d-flex align-items-center justify-content-center" [ngClass]="rootClass" matTooltip="{{ user.full_name }}" [ngStyle]="{background: user.color, color: user.text_color, width: customWidth, height: customWidth, fontSize: fontSize }" title="{{ user.full_name }}">{{ user.initials }}</div> 
        </ng-template>        
    </ng-template>

   
</ng-container>
<ng-container *ngIf="!user">
    /
</ng-container>
