<div *ngIf="!loading" class="container my-2">
    <div class="row">
        <div class="col col-lg-6 align-self-center">

            <h2 class="mat-h2 heading-stripe">Notifications</h2>

            <ng-container *ngIf="notifications.length == 0">
                You don't have any notification
            </ng-container>

            <ng-container *ngIf="notifications.length > 0">

                <div class="mat-elevation-z2 bg-white">

                    <mat-list role="list">

                        <ng-container *ngFor="let n of notifications">
                            <mat-list-item role="listitem" class="py-2 my-2" style="height: auto; min-height: 50px;">
                                <ng-container *ngIf="n.activity_type == 'add-member'">
                                    <div class="d-flex align-items-center w-100">

                                        <div class="me-2" *ngIf="!n.viewed" style="background: blue; width: 5px; height: 5px; border-radius: 50%;"></div>

                                        <app-user-avatar width="35" fontSize="12px" [info]="n.sender_id"></app-user-avatar>

                                        <div class="ms-3">                                            
                                            <div style="font-size: .8rem">
                                                <span class="fw-bold">{{ n.sender_id?.full_name }}</span>
                                                added you as the member of
                                                <span class="fw-bold" [ngClass]="{ 'color-interest' : (n.level_type == 'interest'), 'color-wg' : (n.level_type == 'wg') }">{{ n.options?.level_name }}</span>.

                                                <div class="mt-1 text-muted" style="font-size: .9em;">{{ n.date_created | myDate }}</div>
                                            </div>                                                        
                                        </div>

                                        <button mat-raised-button color="secondary" class="ms-auto" routerLink="{{ n.link }}">View</button>
                                    </div>                                                                                               
                                </ng-container>
                                <ng-container *ngIf="n.activity_type == 'level-admin'">
                                    <div class="d-flex align-items-center w-100">

                                        <div class="me-2" *ngIf="!n.viewed" style="background: blue; width: 5px; height: 5px; border-radius: 50%;"></div>

                                        <app-user-avatar width="35" fontSize="12px" [info]="n.sender_id"></app-user-avatar>
                                        <div class="ms-3">                                            
                                            <div style="font-size: .8rem">
                                                <span class="fw-bold">{{ n.sender_id?.full_name }}</span>
                                                added you as the coordinator of the
                                                <span class="fw-bold" [ngClass]="{ 'color-interest' : (n.level_type == 'interest'), 'color-wg' : (n.level_type == 'wg') }">{{ n.options?.level_name }}</span>.

                                                <div class="mt-1 text-muted" style="font-size: .9em;">{{ n.date_created | myDate }}</div>
                                            </div>                                                        
                                        </div>

                                        <button mat-raised-button color="secondary" class="ms-auto" routerLink="{{ n.link }}">View</button>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="n.activity_type == 'request-membership'">

                                    <ng-container *ngIf="n.accepted">
                                        <div class="d-flex align-items-center my-3">
                                            <div class="text-success me-3">
                                                <mat-icon>done</mat-icon>
                                            </div>
                                            Request succesfully accepted.
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="n.rejected">
                                        <div class="d-flex align-items-center my-3">
                                            <div class="text-success me-3">
                                                <mat-icon>clear</mat-icon>
                                            </div>
                                            Request succesfully rejected.
                                        </div>
                                    </ng-container>
                                    
                                    <ng-container *ngIf="!n.accepted && !n.rejected">

                                        <div class="my-2">
                                            <div class="d-flex align-items-center" [routerLink]="n.link">
                                                <app-user-avatar width="35" fontSize="12px" [info]="n.sender_id">
                                                </app-user-avatar>
                                                <div class="ms-3">
                                                    <div class="mat-caption text-italic">{{ n.sender_id?.full_name
                                                        }}</div>
                                                    <div style="font-size: .8rem">
                                                        {{'requested membership for' | translate}}
                                                        <span
                                                        class="fw-bold" [ngClass]="{ 'color-interest' : (n.level_type == 'interest'), 'color-wg' : (n.level_type == 'wg'), 'color-link' : (n.level_type == 'cplatform') }">{{
                                                            n.options?.level_name | truncate : 50 }}</span>.

                                                        <div class="mt-2 text-italic text-muted" *ngIf="n.options?.message">
                                                            {{ n.options?.message | mystriphtml }}
                                                        </div>
                                                    </div>                                                            
                                                </div>
                                            </div>

                                            <div class="mt-3">
                                                <button mat-stroked-button (click)="requestRespond(n._id, true)">Accept</button>
                                                <button mat-button (click)="requestRespond(n._id, false)">Reject</button>                                               
                                            </div>
                                        </div>

                                    </ng-container>
                                   

                                </ng-container>
                                <ng-container *ngIf="n.activity_type == 'membership-request-granted'">
                                    <div class="d-flex align-items-center pointer" [routerLink]="n.link">
                                        <app-user-avatar width="35" fontSize="12px" [info]="n.sender_id">
                                        </app-user-avatar>
                                        <div class="ms-3">
                                            <div class="mat-caption text-italic">{{ n.sender_id?.full_name
                                                }}</div>
                                            <div style="font-size: .8rem">
                                                {{'granted your membership to' | translate}}
                                                <span class="fw-bold"
                                                    [ngClass]="{ 'color-interest' : (n.level_type == 'interest'), 'color-wg' : (n.level_type == 'wg'), 'color-link' : (n.level_type == 'cplatform') }">{{
                                                    n.options?.level_name | truncate : 50 }}</span>.
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-container>

                    </mat-list>

                </div>

            </ng-container>


        </div>
    </div>
</div>
