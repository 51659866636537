<div class="display-5">
    Database management
    <div class="text-muted h4">Create backups</div>
</div>

<ng-container *ngIf="!loading">

    <ng-container *ngIf="!errorMessage; else errorMessageTemplate">

        <div class="my-4 d-flex align-items-center">
            <button mat-raised-button color="primary" (click)="createBackup()">
                <mat-icon>download</mat-icon>
                Create database backup
            </button>

            <div class="ms-4 text-danger" *ngIf="createBackupErrorMessage">
                {{ createBackupErrorMessage }}
            </div>
        </div>

        <ng-container *ngIf="backupScriptOutput">

            <div class="p-3 mb-2 bg-primary text-white">
                {{ backupScriptOutput }}
            </div>

        </ng-container>


        <h2 class="mb-1">Import script</h2>

        <div class="p-2 border mb-4">
            
            <code>
                mongorestore --archive=EXARCA-2020-12-08-2022.gz --gzip <br><br>

                mongorestore --nsFrom "exarca.*" --nsTo "exarca-live.*" --archive=EXARCAIO-2021-03-17-1258.gz --gzip
            </code>

        </div>


        <h2>Backups</h2>

        <ng-container *ngIf="backups.length == 0; else showBackups">
            There are no backups available ...
        </ng-container>
        <ng-template #showBackups>

            <mat-list role="list">
                <ng-container *ngFor="let b of backups">
                    <mat-list-item role="listitem">

                        <div mat-list-icon class="pointer text-primary" (click)="downloadBackupFile(b.name)">
                            <mat-icon>download</mat-icon>
                        </div>

                        <div mat-line>{{ b.name }}</div>
                        <div class="mat-caption text-muted text-uppercase" style="font-size: .7rem" mat-line>{{ b.time | date }}</div>

                    </mat-list-item>


                </ng-container>
            </mat-list>

        </ng-template>


    </ng-container>
    <ng-template #errorMessageTemplate>

        <div class="p-3 bg-warning">
            {{ errorMessage }}
        </div>

    </ng-template>


</ng-container>