import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { LevelService } from 'src/app/services/level.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-change-coordinator',
  templateUrl: './change-coordinator.component.html',
  styleUrls: ['./change-coordinator.component.scss']
})
export class ChangeCoordinatorComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emailCtrl = new UntypedFormControl();
  userCtrl = new UntypedFormControl();
  filteredUsers: Observable<string[]>;
  users: any[] = [];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ChangeCoordinatorComponent>,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _usersService: UsersService,
    private _levelService: LevelService
  ) { 

    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      switchMap(value => {
        if (value !== '') {
          return this._filter(value);
        } else {
          return of(null);
        }
      })
    );

  }

  createForm() {
    this.mainForm = this._fb.group({  
      user_id: ['', Validators.required],    
      message: ['']
    });
  }

  ngOnInit(): void {
    this.createForm();
  }

  getUser(search: string) {
    return new Promise((resolve, reject) => {

      this._usersService.searchConnectedUsers(search).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });
  }

  onSubmit() {

    if(this.users.length == 0) {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });

      return false;
    }
    else {
      this.mainForm.patchValue({
        user_id: (this.users[0]._id) ? this.users[0]._id : this.users[0].full_name
      });
    }
    
    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this.loading = true;

      this._levelService.changeCoordinator(this.data.level_id, this.mainForm.value.user_id, this.mainForm.value.message)
      .subscribe((result: any) => {

        if (result.success) {

          this._snackBar.open('Coordinator change', '', {
            duration: 2000
          });

          this._dialogRef.close(result.data);
        }
        else {
          this._snackBar.open('Error' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

      

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

  add(event: MatChipInputEvent): void {

    let emailRegex = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;

    if((event.value.match(emailRegex))) {

      this.emailCtrl.setErrors(null);
      
      const input = event.input;
      const value = event.value;

      // Add our user
      if ((value || '').trim()) {

        /*
        if(!this.userExist(value.trim())) { 
          this.users.push({ full_name: value.trim(), isEmail: true });
        }
        else {
          this._snackBar.open('User is already added', '', {
            horizontalPosition: 'left',
            duration: 2000
          });
        }*/

        if(this.users.length > 0) {
          this.users.pop();
        }

        this.users.push({ full_name: value.trim(), isEmail: true });

      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.userCtrl.setValue(null);
    }
    else {      
      if(event.value != '') { 
        // if email is incorrect
        this.emailCtrl.setErrors({'incorrect': true});
        this.emailCtrl.markAsTouched();
      }
    }

    
  }

  remove(user: any): void {
    const index = this.users.indexOf(user);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
   
    if(this.users.length > 0) {
      this.users.pop();
    }

    this.users.push(event.option.value);
   
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);
    this.emailCtrl.setErrors(null);
  }

  private _filter(value: any): Observable<any[]> {

    console.log(value);

    // we remove error from input on text change
    this.emailCtrl.setErrors(null);

    let filterValue = ' ';
    if(value && !value._id) {
      filterValue = (value) ? value.toLowerCase() : value;
    }  
    else {
      return of(null);
    } 

    return this._usersService.searchConnectedUsers(filterValue).pipe(
      // map the item property of the github results as our return object
      map((data:any) => data.data),
      // catch errors
      catchError(_ => {
        return of(null);
      })
    );
    
  }

  userExist(input: any): boolean {

    for(let user of this.users) {

      if(input._id) {
        if(user._id == input._id) {
          return true;
        }
      }
      else {
        if(user.full_name == input) {
          return true;
        }
      }

    }

    return false;    

  }

}
