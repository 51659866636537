import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SystemService } from 'src/app/services/system.service';

@Component({
  selector: 'app-admin-system-status',
  templateUrl: './admin-system-status.component.html',
  styleUrls: ['./admin-system-status.component.scss']
})
export class AdminSystemStatusComponent implements OnInit {

  loading: boolean = true;
  systemStatus: any[] = [];

  constructor(
    private _systemService: SystemService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    

    this._systemService.adminGetSystemStatus().subscribe((result: any) => {

      this.loading = false;

      if(result.success) {

        this.systemStatus = [...result.data];

      }
      

    }, err => {

      if (err.status != 200) {
        this.loading = false;

        this._snackBar.open('Error: ' + err.statusText, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }

    });

  }

}
