<div class="d-flex align-items-center mb-2">
    <h2 mat-dialog-title class="d-flex align-items-center mb-0">
        {{'Level sort' | translate}}
    </h2>
    <div class="ms-auto">
        <button mat-button color="secondary" [mat-dialog-close]="false">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content class="my-2">

    <ul class="list-group" *ngIf="levels.length > 0" cdkDropList (cdkDropListDropped)="drop($event)" cdkDropListOrientation="vertical">
        <li class="list-group-item" *ngFor="let l of levels; let i = index;" cdkDrag>

            <div class="d-flex align-items-center">
                <div class="pointer mt-2" cdkDragHandle>
                    <mat-icon>unfold_more</mat-icon>
                </div>
                <div class="ms-2">{{ l.name }}</div>

                <div class="ms-auto">
                    <input type="text" class="form-control" style="width: 40px;" [(ngModel)]="l.group_sort" [ngModelOptions]="{standalone: true}" (change)="sortNumber(i, l.group_sort)" />
                </div>
            </div>

        </li>
    </ul>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" class="mr-2" (click)="saveSortOrder()">
        <span class="material-icons">
            add
        </span> {{'Save' | translate}}</button>
</mat-dialog-actions>