<ng-container *ngIf="loading">

    <div class="d-flex h-100 align-items-center justify-content-center">
        <mat-spinner></mat-spinner>
    </div>

</ng-container>

<div [hidden]="loading">

    <form [formGroup]="mainForm" class="mt-4" (ngSubmit)="onSearch()" novalidate>
        <div class="d-flex">
            <div>

                <mat-form-field appearance="fill">
                    <mat-label>Start date</mat-label>
                    <input matInput [matDatepicker]="myDatepicker1" formControlName="start_date">
                    <mat-datepicker-toggle matSuffix [for]="myDatepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker1></mat-datepicker>
                </mat-form-field>

                <div class="d-flex align-items-center">

                    <div class="me-1">

                        <mat-form-field appearance="outline" class="select-field">
                            <mat-select formControlName="start_hours">
                                <mat-option *ngFor="let hour of hours" value="{{hour}}" [value]="hour">
                                    <div *ngIf="(hour < 10)">0{{ hour }}</div>
                                    <div *ngIf="(hour > 9)">{{ hour }}</div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!--<select matInput formControlName="start_hours">
                            <option *ngFor="let hour of hours" value="{{hour}}">
                                <div *ngIf="(hour < 10)">0{{ hour }}</div>
                                <div *ngIf="(hour > 9)">{{ hour }}</div>
                            </option>
                        </select>-->
                    </div>
                    <div class="me-1">

                        <!--
                        <select matInput formControlName="start_minutes">
                            <option *ngFor="let minute of minutes" value="{{minute}}">
                                <div *ngIf="(minute < 10)">0{{ minute }}</div>
                                <div *ngIf="(minute > 9)">{{ minute }}</div>
                            </option>
                        </select>-->

                        <mat-form-field appearance="outline" class="select-field">
                            <mat-select formControlName="start_minutes">
                                <mat-option *ngFor="let minute of minutes" [value]="minute">
                                    <div *ngIf="(minute < 10)">0{{ minute }}</div>
                                    <div *ngIf="(minute > 9)">{{ minute }}</div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

            </div>
            <div class="ms-3">

                <mat-form-field appearance="fill">
                    <mat-label>End date</mat-label>
                    <input matInput [matDatepicker]="myDatepicker2" formControlName="end_date" [min]="f.start_date.value">
                    <mat-datepicker-toggle matSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker2></mat-datepicker>
                </mat-form-field>

                <div class="d-flex align-items-center">



                    <div class="me-2">

                        <!--
                        <select formControlName="end_hours">
                            <option *ngFor="let hour of hours" value="{{hour}}">
                                <div *ngIf="(hour < 10)">0{{ hour }}</div>
                                <div *ngIf="(hour > 9)">{{ hour }}</div>
                            </option>
                        </select>
                        -->
                        <mat-form-field appearance="outline" class="select-field">
                            <mat-select formControlName="end_hours">
                                <mat-option *ngFor="let hour of hours" value="{{hour}}" [value]="hour">
                                    <div *ngIf="(hour < 10)">0{{ hour }}</div>
                                    <div *ngIf="(hour > 9)">{{ hour }}</div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="me-1">
                        <!--
                        <select formControlName="end_minutes">
                            <option *ngFor="let minute of minutes" value="{{minute}}">
                                <div *ngIf="(minute < 10)">0{{ minute }}</div>
                                <div *ngIf="(minute > 9)">{{ minute }}</div>
                            </option>
                        </select>
                        -->
                        <mat-form-field appearance="outline" class="select-field">
                            <mat-select formControlName="end_minutes">
                                <mat-option *ngFor="let minute of minutes" [value]="minute">
                                    <div *ngIf="(minute < 10)">0{{ minute }}</div>
                                    <div *ngIf="(minute > 9)">{{ minute }}</div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="ms-4">
                <button mat-raised-button color="primary" type="submit">Filter</button>

                <button type="button" mat-raised-button class="ms-2" (click)="reset()">Reset</button>
            </div>
        </div>
    </form>

    <div *ngIf="resultsLength == 0" class="mt-3">
        No logs.
    </div>

    <div class="my-4">

        <!--
        <div [hidden]="resultsLength == 0">
            <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons (page)="onPaginateChange($event)"></mat-paginator>
        </div>
        -->

        <div class="example-table-container" *ngIf="resultsLength > 0">

            <table mat-table style="width: 100%;" [dataSource]="logs" multiTemplateDataRows class="mat-elevation-z2">


                <!-- Timestamp Column -->
                <ng-container matColumnDef="timestamp">
                    <th mat-header-cell *matHeaderCellDef>Timestamp</th>
                    <td mat-cell *matCellDef="let row">{{row.timestamp | date:'medium'}}</td>
                </ng-container>

                <!-- Message Column -->
                <ng-container matColumnDef="message">
                    <th mat-header-cell *matHeaderCellDef>Message</th>
                    <td mat-cell *matCellDef="let row" class="text-wrap">{{row.message}}</td>
                </ng-container>

                <!-- Level Column -->
                <ng-container matColumnDef="level">
                    <th mat-header-cell *matHeaderCellDef>Level</th>
                    <td mat-cell *matCellDef="let row">
                        <div class="badge" [ngClass]="{ 'bg-warning': row.level == 'warn', 'bg-danger': row.level == 'error', 'bg-info': row.level != 'warn' && row.level != 'error' }">
                            {{row.level}}</div>
                    </td>
                </ng-container>

                <!-- Context Column -->
                <ng-container matColumnDef="context">
                    <th mat-header-cell *matHeaderCellDef>Context</th>
                    <td mat-cell *matCellDef="let row" class="text-wrap text-italic">{{(row.context) ? row.context : '/'}}</td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                            <div class="example-element-description">
                                <label class="mat-header-cell">Additional details</label>
                                <div class="font-italic" *ngIf="element?.details">{{element.details}}</div>
                                <div class="font-italic" *ngIf="!element?.details">/</div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
        </div>



        <div [hidden]="resultsLength == 0">
            <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons (page)="onPaginateChange($event)"></mat-paginator>
        </div>


    </div>

</div>