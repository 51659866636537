import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-upcoming-event-view',
  templateUrl: './upcoming-event-view.component.html',
  styleUrls: ['./upcoming-event-view.component.scss']
})
export class UpcomingEventViewComponent implements OnInit {
  
  loading: boolean = true;

  events: any[] = [];

  constructor(
    private _snackBar: MatSnackBar,
    private _eventService: EventsService
  ) { }

  ngOnInit(): void {

    this._eventService.getUserEvents().subscribe((result: any) => {

      if (result.success) {

        this.loading = false;

        this.events = [...result.data];

      }
      else {
        this.loading = false;
      }

    }, err => {

      if (err.status != 200) {

        this.loading = false;

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });


  }

}
