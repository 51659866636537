<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">


    <div class="h3 my-2 mb-0">Add user</div>
    <div style="width: 50px; border-bottom: 4px solid blue;"></div>

    <div class="mt-4">

        <div>

            <mat-form-field appearance="outline">
                <mat-label>{{'E-mail' | translate}}</mat-label>
                <input matInput formControlName="email">
            </mat-form-field>

        </div>


        <div>

            <mat-form-field appearance="outline">
                <mat-label>{{'First name' | translate}}</mat-label>
                <input matInput formControlName="firstName">
            </mat-form-field>

            <mat-form-field appearance="outline" class="ms-2">
                <mat-label>{{'Last name' | translate}}</mat-label>
                <input matInput formControlName="lastName">
            </mat-form-field>

        </div>

        <div>

            <mat-form-field appearance="outline">
                <mat-label>{{'Password' | translate}} (min. length 8 characters)</mat-label>
                <input matInput formControlName="password">
            </mat-form-field>

        </div>

        <div>
            <mat-form-field appearance="outline">
                <mat-label>Select role</mat-label>
                <mat-select formControlName="role">
                    <mat-option value="0">Admin</mat-option>
                    <mat-option value="1">Support</mat-option>
                    <mat-option value="2">Customer</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
       


        <button mat-raised-button color="primary" type="submit">Add user</button>
    </div>





</form>