<div class="card border-0 rounded mt-4 my-tasks gx-5">
    <div class="card-body">

        <div class="mb-2">
            <h2 class="mb-0 fw-bold">{{'Events this month' | translate}}</h2>
            <div class="text-gray-400 fw-bold fs-6" class="text-gray-500">Events happening this month</div>
        </div>

        <ng-container *ngIf="_events.length == 0; else events">
            {{'No events.' | translate}}
        </ng-container>

        <ng-template #events>
            <mat-list role="list">
                <mat-list-item role="listitem" *ngFor="let ev of _events">
                    <button mat-button color="primary" [routerLink]="ev.link" class="text-uppercase">{{'View event'
                        | translate}}</button>
                    <div mat-line matTooltip="{{ ev.title }}">{{ ev.title | truncate : 25 }}
                    </div>
                    <div class="mat-caption text-muted" style="font-size: .8rem" mat-line>
                        {{ ev.start_date | date }}</div>
                </mat-list-item>
            </mat-list>

         
        </ng-template>

    </div>
</div>