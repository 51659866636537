import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { UserinfoService } from '../services/userinfo.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: any[] = [];
  loading: boolean = true;

  constructor(
    private _userinfoService: UserinfoService,
    private _snackBar: MatSnackBar,
    private _titleService: Title
  ) { }

  ngOnInit(): void {

    this._titleService.setTitle('Notifications | ExArca');

    this._userinfoService.getAllNotifications().subscribe((result: any) => {

      if(result.success) {        
        this.notifications = result.data;
      }

      this.loading = false;

    }, err => {

      this.loading = false;

      if (err.status != 200) {

        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });

  }

    /**
   * Response to the membership request
   * @param reqId 
   * @param respond 
   */
     requestRespond(reqId: string, respond: boolean) { 
   
      this._userinfoService.respondToMembershipRequest(reqId, respond).subscribe((result: any) => {
  
        if(result.success) {                
  
          for(let n of this.notifications) {
  
            if(n._id == reqId) {
  
              if(respond == true) {
                n['accepted'] = true;
              }
              else {
                n['rejected'] = true;
              }
            }
  
          }       
  
        }
        else {
  
          this._snackBar.open('Error', result.message, {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
  
        }
  
      }, err => {      
  
        if (err.status != 200) {
  
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
  
        }
      });
      
    }

}
