import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-tasks-view',
  templateUrl: './my-tasks-view.component.html',
  styleUrls: ['./my-tasks-view.component.scss']
})
export class MyTasksViewComponent implements OnInit {

  _tasks: any;

  @Input() set tasks(tasks: any) { this._tasks = tasks }

  constructor() { }

  ngOnInit(): void {
  }

}
