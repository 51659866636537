<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">
    <div class="d-flex align-items-center mb-2">
        <h2 mat-dialog-title class="mb-0">Social links</h2>

        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>

    </div>
    <mat-dialog-content class="mb-2">

        <div class="social-links-form">            

            <mat-form-field class="w-100">
                <mat-label>
                    Facebook
                </mat-label>
                <input matInput formControlName="facebook">                
                <i matPrefix class="fab fa-facebook" style="margin-right: 1rem;"></i>
                <mat-error *ngIf="f.facebook.errors">
                    Please enter a valid website address
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>
                    Twitter
                </mat-label>
                <input matInput formControlName="twitter">
                <i matPrefix class="fab fa-twitter" style="margin-right: 1rem;"></i>
                <mat-error *ngIf="f.twitter.errors">
                    Please enter a valid website address
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>
                    LinkedIn
                </mat-label>
                <input matInput formControlName="linkedin">
                <i matPrefix class="fab fa-linkedin" style="margin-right: 1rem;"></i>
                <mat-error *ngIf="f.linkedin.errors">
                    Please enter a valid website address
                </mat-error>
            </mat-form-field>

        </div>

    </mat-dialog-content>
    <mat-dialog-actions>
            
        <button mat-raised-button color="primary" class="mr-2" type="submit">Save</button>
        <button mat-raised-button [mat-dialog-close]="false">Cancel</button>

    </mat-dialog-actions>
</form>