import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  users: [];

  constructor(
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.createForm();

    let getUsers = this.getUsers();
    getUsers.then((users: any) => {
      this.users = users;
    });

  }

  createForm() {
    this.mainForm = this._fb.group({     
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      role: ['2', Validators.required]
    });
  }

  getUsers() {

    return new Promise((resolve, reject) => {

      this._usersService.adminGetUsers().subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });

  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this._usersService.adminAddUser(
        this.mainForm.value.email,
        this.mainForm.value.firstName,
        this.mainForm.value.lastName,
        this.mainForm.value.password,
        this.mainForm.value.role
      ).subscribe((result: any) => {

        if (result.success) {
          
          this._snackBar.open('Saved', '', {
            duration: 2000,
          });

        }
        else {
          this._snackBar.open('Error. Please try again.', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

}
