import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { LayoutService } from 'src/app/services/layout.service';

import countries from 'src/app/utils/countries';

interface Country {
  name: string;
  code: string;
}

// Function to create a date in CET
const createDateInCET = (year, month, day, hour = 0, minute = 0) => {
  const dateUTC = new Date(Date.UTC(year, month - 1, day, hour, minute));
  const offset = -60; // CET is UTC+1, so offset is -60 minutes
  const offsetMilliseconds = offset * 60000;

  // Apply the offset
  const dateInCET = new Date(dateUTC.getTime() + offsetMilliseconds);

  return dateInCET;
};

// Example: Creating a date in CET
const DEADLINE = createDateInCET(2024, 10, 13, 14).toISOString();

@Component({
  selector: 'app-kmfest24',
  templateUrl: './kmfest24.component.html',
  styleUrls: ['./kmfest24.component.scss']
})
export class Kmfest24Component implements OnInit, OnDestroy {

  countries: Country[] = countries;

  registrationDeadline: string = DEADLINE;
  isDeadlineReached: boolean = false;

  submitted: boolean = false;
  mainForm: UntypedFormGroup;
  showSuccessPage: boolean = false;

  routerChange$: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _layoutService: LayoutService,
    private _fb: UntypedFormBuilder,
    private _http: HttpClient,
    private _config: ConfigService,
    private _snackBar: MatSnackBar,
  ) { }

  get f() { return this.mainForm.controls; }

  ngOnInit(): void {

    console.log('Deadline:', this.registrationDeadline);

    // get county based from IP
    this._http.get('https://ipapi.co/json/').subscribe((data: any) => {
      this.mainForm.get('ip').setValue(data.ip || '');
      this.mainForm.get('country').setValue(data.country_code || '');
    }, (error) => {
      console.error(error);
    });

    this.routerChange$ = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        this._layoutService.hideFooter();
        this._layoutService.hideToolbar();

        if (this._route.snapshot.queryParams['success']) {
          this.showSuccessPage = true;
        }
        else {
          this.showSuccessPage = false;
          this.submitted = false;

          // remove form values
          this.mainForm.setValue({
            email: '',
            firstName: '',
            lastName: '',
            organization: '',
            country: '',
            ip: '',
            bothEvents: true,
            firstEvent: false,
            secondEvent: false,
            eventName: 'KMFest_14th_15th_October',
          });

          this._http.get('https://ipapi.co/json/').subscribe((data: any) => {
            this.mainForm.get('ip').setValue(data.ip || '');
            this.mainForm.get('country').setValue(data.country_code || '');
          }, (error) => {
            console.error(error);
          });

          if (this._route.snapshot.queryParams['email']) {
            this.mainForm.get('email').setValue(this._route.snapshot.queryParams['email']);
          }

        }
      }
    });

    this.mainForm = this._fb.group({
      email: [this._route.snapshot.queryParams['email'] || '', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      organization: ['', [Validators.required]],
      country: ['', [Validators.required]],
      ip: [''],
      bothEvents: [true], // Variable for joining both events
      firstEvent: [false], // Variable for joining the first event
      secondEvent: [false], // Variable for joining the second event
      eventName: ['KMFest_14th_15th_October'],
    });

    if (this._route.snapshot.data.hideHeader) {
      setTimeout(() => {
        this._layoutService.hideToolbar();
        this._layoutService.hideFooter();
      }, 0)
    }
  }

  async onSubmit() {

    if (this.isDeadlineReached) {
      alert('Deadline reached');
      return;
    }

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      // at least one event should be selected
      if (!this.mainForm.value.bothEvents && !this.mainForm.value.firstEvent && !this.mainForm.value.secondEvent) {
        this._snackBar.open('Please select at least one event', 'OK', {
          duration: 3000,
          verticalPosition: 'top',
        });
        return;
      }

      try {
        await this._http.post(this._config.getApiUrl() + '/api/kmfest/subscribe', {
          email: this.mainForm.value.email,
          firstName: this.mainForm.value.firstName,
          lastName: this.mainForm.value.lastName,
          organization: this.mainForm.value.organization,
          country: this.mainForm.value.country,
          ip: this.mainForm.value.ip,
          bothEvents: this.mainForm.value.bothEvents,
          firstEvent: this.mainForm.value.firstEvent,
          secondEvent: this.mainForm.value.secondEvent,
          eventName: this.mainForm.value.eventName,
        }).toPromise();

        // redirect to ?success=1
        this._router.navigate([], {
          relativeTo: this._route,
          queryParams: {
            success: 1
          }
        });

        this.showSuccessPage = true;
      } catch (error) {

        if (error instanceof HttpErrorResponse) {
          this._snackBar.open(error.error || error.message, 'OK', {
            duration: 3000,
            verticalPosition: 'top',
          });
        }
      }


    }
  }

  deadlineReached(e: Event) {
    this.isDeadlineReached = true;
  }

  changeSelection(e: Event) {
    const target = e.target as HTMLInputElement;

    if (target.name === 'bothEvents') {
      // When 'bothEvents' is checked, deselect 'firstEvent' and 'secondEvent'
      if (target.checked) {
        this.mainForm.get('firstEvent')?.setValue(false);
        this.mainForm.get('secondEvent')?.setValue(false);
      }
    } else {
      // If 'firstEvent' or 'secondEvent' is manually checked
      if (this.mainForm.value.firstEvent && this.mainForm.value.secondEvent) {
        // If both are selected, set 'bothEvents' to true
        this.mainForm.get('bothEvents')?.setValue(true);
      } else {
        // If either 'firstEvent' or 'secondEvent' is unchecked, set 'bothEvents' to false
        this.mainForm.get('bothEvents')?.setValue(false);
      }
    }
  }

  ngOnDestroy() {
    this.routerChange$.unsubscribe();
  }

}
