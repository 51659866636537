<div class="d-flex align-items-center mb-2">
    <h2 mat-dialog-title class="d-flex align-items-center mb-0">
        <span class="material-icons">
            ios_share
        </span>
        {{'Embed to a website' | translate}}
    </h2>

    <div class="ms-auto">
        <button mat-button color="secondary" [mat-dialog-close]="false">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content>
    <div class="mat-caption text-muted mb-1">You can copy the code below, and then paste it to your website (HTML)</div>

    <textarea class="w-100" style="height: 110px;" disabled><iframe src="{{url}}/export/{{ data._id }}" width="400" height="300" style="border: 0;"></iframe></textarea>
    
</mat-dialog-content>

<mat-dialog-actions>    
    <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
</mat-dialog-actions>