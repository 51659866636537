// https://github.com/rodgc/ngx-socket-io/blob/master/src/socket-io.service.ts
// https://socket.io/docs/v3/handling-cors/

import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as io from 'socket.io-client';

import config from '../config';
import { UserinfoService } from './userinfo.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnDestroy {

  private subscription: Subscription;
  ioSocket: any;
  serverStatus: boolean = false;

  constructor(
    private _userinfoService: UserinfoService
  ) {
    
    const ioFunc = (io as any).default ? (io as any).default : io;
    this.ioSocket = ioFunc(config.api_url, {
      withCredentials: false
    });


    // If user logges while is already running
    this.subscription = this._userinfoService.loggedIn$.subscribe((logged: boolean) => {
        if(logged) {
          if(this.serverStatus) {
            this.introduceYourself();
          }
        }
    });

    // when connects with socket
    this.ioSocket.on('connect', () => {
      this.serverStatus = true;

      this.introduceYourself();
    });

    // disconnects
    this.ioSocket.on('disconnect', () => {
      this.serverStatus = false;
    });

  }

  // user introduces itself to the server
  private introduceYourself() {
    let userInfo = this._userinfoService.getLocalInfo();

    if(userInfo?._id) {
      this.ioSocket.emit('introduce', userInfo['_id'])
    }
  }

  on(eventName: string, callback: Function) {
    this.ioSocket.on(eventName, callback);
  }

  emit(eventName: string, ...args: any[]) {
    return this.ioSocket.emit.apply(this.ioSocket, arguments);
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
