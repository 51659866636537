import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class TopicService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) {     
  }

  addTopic(levelId: string, topicName: string, message: string, attachments?:any) {
    return this._http.post(this._config.getApiUrl() + '/api/topic', {    
      level_id: levelId,
      topic_name: topicName,
      message: message,
      attachments: attachments
    }, this._httpOptionsService.getHeader())
  }

  getTopics(levelId: string, order = 'asc') {
    return this._http.get(this._config.getApiUrl() + '/api/topic/' + levelId, this._httpOptionsService.getHeader())
  }

  getTopic(levelId: string, topicId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/topic/' + levelId + '/' + topicId, this._httpOptionsService.getHeader())
  }

  addComment(levelId: string, topicId: string, message: string, attachments?:any) {
    return this._http.post(this._config.getApiUrl() + '/api/topic/commment', {    
      level_id: levelId,
      topic_id: topicId,
      message: message,
      attachments: attachments
    }, this._httpOptionsService.getHeader())
  }

  addReply(commentId:string, levelId: string, topicId: string, message: string, attachments?:any) {
    return this._http.post(this._config.getApiUrl() + '/api/topic/commment/reply', {    
      comment_id: commentId,
      level_id: levelId,
      topic_id: topicId,
      message: message,
      attachments: attachments
    }, this._httpOptionsService.getHeader())
  }

  editTopic(levelId: string, topicId: string, commentId: string, topicName: string, message: string, attachments?:any) {
    return this._http.put(this._config.getApiUrl() + '/api/topic/' + topicId, {    
      level_id: levelId,
      comment_id: commentId,
      topic_name: topicName,
      message: message,
      attachments: attachments
    }, this._httpOptionsService.getHeader())
  }

  deleteTopic(levelId: string, topicId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/topic/' + levelId + '/' + topicId, this._httpOptionsService.getHeader())
  }

  editComment(levelId: string, commentId: string, message: string, attachments?:any) {
    console.log('Edit comment')
    return this._http.put(this._config.getApiUrl() + '/api/topic/comment/' + commentId, {    
      level_id: levelId,
      comment_id: commentId,
      message: message,
      attachments: attachments
    }, this._httpOptionsService.getHeader())
  }

  deleteComment(levelId: string, commentId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/topic/comment/' + levelId + '/' + commentId, this._httpOptionsService.getHeader())
  }

  addLike(levelId: string, commentId: string) {
    return this._http.post(this._config.getApiUrl() + '/api/topic/like', {
      level_id: levelId,
      comment_id: commentId
    }, this._httpOptionsService.getHeader())
  }

}
