<main style="max-height: 100vh"><!-- large documents scroll fix -->

    <ng-container *ngIf="loading">

        <div class="d-flex h-100 align-items-center justify-content-center">
            <mat-spinner></mat-spinner>
        </div>

    </ng-container>

    <ng-container *ngIf="!loading">


        <ng-container *ngIf="error">

            <div class="alert alert-danger mt-5">
                {{ errorMsg }}
            </div>

        </ng-container>

        <ng-container *ngIf="!error">

            <ng-container *ngIf="fileInfo.image">

                <div class="mt-5 d-flex flex-column">
                    <div class="text-center">
                        <img [src]="fileInfo.url" />                   

                        <div class="mt-2">
                            {{ fileInfo.original_name }}
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="fileInfo.video">

                <div class="mt-5 d-flex flex-column">
                    <div class="text-center">

                        <video controls autoplay>                            
                            <source [src]="fileInfo.url">
                            Your browser does not support the video tag.
                        </video>


                        <div class="mt-2">
                            {{ fileInfo.original_name }}
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="fileInfo.office || fileInfo.pdf">

                <pdf-viewer 
                    [src]="fileInfo.url" 
                    [show-borders]="true" 
                    [render-text]="true" 
                    [original-size]="false"
                    (on-progress)="onProgress($event)" style="display: block;"></pdf-viewer>

            </ng-container>


            <ng-container *ngIf="fileInfo.html || fileInfo.txt">

                <div class="d-flex flex-column">
                    <div>

                        <div class="bg-primary px-4 py-2 text-white text-center">
                            {{ fileInfo.original_name }}
                        </div>
                        
                        <ng-container *ngIf="fileInfo.html">
                            <div class="p-4 bg-white" style="max-height: 450px; overflow-y: auto;">                            
                                <div [innerHTML]="fileInfo.content | sanitizeHtml"></div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="fileInfo.txt">
                            <div class="p-4 bg-white" style="max-height: 450px; overflow-y: auto;">
                                {{ fileInfo.content }}
                            </div>
                        </ng-container>

                        
                    </div>
                </div>
                

            </ng-container>

        </ng-container>

        

    </ng-container>    

</main>
