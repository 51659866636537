<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="d-flex flex-column mb-2">

        <div mat-card-avatar class="d-flex align-items-center w-100 mb-2" *ngIf="!hideIcon">
            <app-user-avatar width="40" fontSize="12px" [info]="user"></app-user-avatar>


            <div class="fw-boldest text-gray-800 ms-2">
                {{ user.full_name }} 
            </div>
        </div>

        <div class="w-100 w-lg-auto flex-lg-grow-1">     
            <app-my-editor [levelid]="levelId" [data]="text" (output)="onCkEditorValue($event)"></app-my-editor>

            <ng-container *ngIf="attachments.length > 0">
                <mat-list role="list">
                 <ng-container *ngFor="let a of attachments">
                    <mat-list-item role="listitem">

                        <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                        <div mat-line class="d-flex align-items-center">                            
                            <div style="max-width: 250px;" class="text-truncate">
                                {{a.original_name}}
                            </div>
                        
                            <div class="ms-auto">
                                <mat-icon class="pointer" (click)="removeAttachment(a._id)">delete</mat-icon>
                            </div>
                        </div>

                    </mat-list-item>
                 </ng-container>      
                </mat-list>         
            </ng-container>

               
            <div class="mb-2">
                <div class="d-flex align-items-center mt-3 mb-2">

                    <ng-container *ngIf="!hideAttachment">
                        <button type="button" mat-button (click)="addAttachment = !addAttachment">
                            {{'Add attachment' | translate}} <mat-icon>{{ !addAttachment ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}}</mat-icon> 
                        </button>
                    </ng-container>
                    
                    <div class="ms-auto">       
                        <ng-container *ngIf="!hideIcon">
                            <button mat-raised-button color="primary" class="ms-auto" type="submit">
                                <mat-icon>send</mat-icon>
                            </button>          
                        </ng-container> 
                        <ng-container *ngIf="hideIcon">
                            <button mat-raised-button class="ms-auto" type="submit">
                                <mat-icon>send</mat-icon>
                            </button>          
                        </ng-container> 
                        
                    </div>
                </div>
                <ng-container *ngIf="addAttachment">
                    <div class="border p-4">
                        <app-upload-file (data)="uploadedAttachment($event)" [levelId]="levelId" attachment="true"></app-upload-file>
                    </div>
                </ng-container>
            </div>

            

        </div>

    </div>

    


</form>
