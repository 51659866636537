import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-browse-users-dialog',
  templateUrl: './browse-users-dialog.component.html',
  styleUrls: ['./browse-users-dialog.component.scss']
})
export class BrowseUsersDialogComponent implements OnInit {

  loading: boolean = true;
  users: any[] = [];
  selectionIndex: number = 0;

  constructor(
    private _usersService: UsersService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<BrowseUsersDialogComponent>
  ) { }

  ngOnInit(): void {

    this._usersService.getConnectedUsers().subscribe((result: any) => {


      if (result.success) {
        this.users = result.data;
      }
      else {

        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    }, () => {

      // complete

      this.loading = false;


    });

  }

  selectionCount(value: boolean) {

    if(value) {
      this.selectionIndex++;
    }
    else {
      this.selectionIndex--;
    }

  }

  save() {

    let selected = [];

    for(let user of this.users) {

      if(user.selected) {
        selected.push(user);
      }

    }

    this._dialogRef.close(selected);

  }

}
