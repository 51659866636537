<ng-container *ngIf="!loading">
    <h1>System status</h1>

    <ng-container *ngIf="systemStatus.length > 0; else noDataToShow">
        <table class="table">
            <thead>
                <tr>
                    <td style="width: 15%">Status</td>
                    <td>Job</td>                    
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let s of systemStatus">
                    <td>
                        <ng-container *ngIf="s.status; else notWorking">
                            <mat-icon class="text-success">done</mat-icon>
                        </ng-container>
                        <ng-template #notWorking>
                            <mat-icon class="text-danger">close</mat-icon>
                        </ng-template>
                    </td>
                    <td>
                        {{ s.job }}
                    </td>                    
                </tr>
            </tbody>            
        </table>
    </ng-container>
    <ng-template #noDataToShow>
        No jobs are active
    </ng-template>
</ng-container>