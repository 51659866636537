export default [{
    type: 'interest',
    menu: [{
        name: 'Intro',
        link: '',
        icon: 'home'
    }, {
        name: 'Blog',
        link: 'blog',
        icon: 'rss_feed'
    }, {
        name: 'Members',
        link: 'members',
        private: true,
        icon: 'supervisor_account'
    }, {
        name: 'Wiki',
        link: 'wiki',
        private: true,
        icon: 'assignment'
    }, {
        name: 'Discussions',
        link: 'discussions',
        private: true,
        icon: 'chat_bubble_outline'
    }, {
        name: 'Events',
        link: 'events',
        private: true,
        icon: 'event'
    }, {
        name: 'Milestones',
        link: 'milestones',
        private: true,
        icon: 'track_changes'
    }, {
        name: 'Files',
        link: 'repository',
        private: true,
        icon: 'article'
    }, {
        name: 'Project Proposals',
        link: 'proposal',
        private: true,
        icon: 'note_add'
    }]
}, {
    type: 'wg',
    menu: [{
        name: 'Intro',
        link: '',
        icon: 'home'
    }, {
        name: 'Members',
        link: 'members',
        private: true,
        icon: 'supervisor_account'
    }, {
        name: 'Wiki',
        link: 'wiki',
        private: true,
        icon: 'assignment'
    }, {
        name: 'Discussions',
        link: 'discussions',
        private: true,
        icon: 'chat_bubble_outline'
    }, {
        name: 'Tasks',
        link: 'tasks',
        private: true,
        icon: 'leaderboard'
    }, {
        name: 'Meeting dates',
        link: 'meetings',
        private: true,
        icon: 'lock_clock'
    }, {
        name: 'Files',
        link: 'repository',
        private: true,
        icon: 'article'
    }, {
        name: 'Project Proposals',
        link: 'proposal',
        private: true,
        icon: 'note_add'
    }]
}]