import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }


  unsubscribe(sentEmailId: string, email: string) {

    return this._http.put(this._config.getApiUrl() + '/api/user/unsubscribe/' + sentEmailId, {    
      email: email
    }, this._httpOptionsService.getHeader())

  }


}
