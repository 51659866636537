import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InquiryService } from '../services/inquiry.service';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-create-platform',
  templateUrl: './create-platform.component.html',
  styleUrls: ['./create-platform.component.scss']
})
export class CreatePlatformComponent implements OnInit, OnDestroy {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  showError: boolean = false;
  showErroMessage: String = '';
  
  successPage: boolean = false;

  navigationSub: Subscription;

  constructor(
    private _fb: UntypedFormBuilder, 
    private _router: Router,
    private _layoutService: LayoutService,
    private _inquiryService: InquiryService,
  ) { 

    this.navigationSub = this._router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {

        this._layoutService.hideToolbar();

      }
    });

  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.mainForm = this._fb.group({
      comment: [''],
    });
  }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {


      this._inquiryService.sendInquiry(this.mainForm.value.comment).subscribe((result) => {

        this.successPage = true;

      }, (err: HttpErrorResponse) => {

        if(err.status != 200) {

          this.showError = true;
          this.showErroMessage = err.statusText;

        }

      })  


    }

  }

  ngOnDestroy(): void {
    this.navigationSub.unsubscribe();
  }

}
