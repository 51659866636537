<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">
    <div class="d-flex align-items-center mb-2">
        <h2 class="mb-0" mat-dialog-title>
            {{'Send message' | translate}}
        </h2>
        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content>

        <!--<mat-form-field appearance="fill" class="w-100">
            <mat-label>
                Select user
            </mat-label>            
            <input matInput [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option.full_name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>-->

        <mat-form-field  class="w-100">          

            <mat-label>{{'Choose user' | translate}}</mat-label>
            <mat-chip-list #chipList aria-label="Fruit selection" [formControl]="emailCtrl">
                <mat-chip
                *ngFor="let user of users"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(user)">
                {{user.full_name}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input               
                #userInput
                [formControl]="userCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList">
                
            </mat-chip-list>
            <button mat-icon-button matSuffix type="button" (click)="browseUsers()">
                <mat-icon style="font-size: 1.5rem">search</mat-icon>
            </button>
            
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                  <div class="d-flex align-items-center">
                    <app-user-avatar width="25" fontSize="10px" [info]="user"></app-user-avatar>  
                    <div class="ms-2">
                        {{user.full_name}}
                    </div>
                    </div>
                </mat-option>
              </mat-autocomplete>
            
        </mat-form-field>

        <mat-form-field class="w-100 mt-2">
            <mat-label>{{'Your message' | translate}}</mat-label>
            <textarea  matInput formControlName="message"></textarea>
        </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button color="primary" class="mr-2" type="submit">
            <span class="material-icons">
                send
            </span>
            {{'Send' | translate}}</button>
        <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
    </mat-dialog-actions>

</form>