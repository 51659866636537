<div class="card border-0 rounded mt-2 my-tasks gx-5">
    <div class="card-body">

        <div class="mb-4">
            <h2 class="mb-0 fw-bold">{{'My tasks' | translate}}</h2>
            <div class="text-gray-400 fw-bold fs-6" class="text-gray-500">Your active tasks</div>
        </div>

        <ng-container *ngIf="_tasks.length == 0; else tasks">
            {{'No upcoming tasks.' | translate}}
        </ng-container>

        <ng-template #tasks>

            <table class="table mb-0">

                <tbody>
                    <ng-container *ngFor="let t of _tasks">
                        <tr>

                            <td>
                                <div class="d-flex align-items-center justify-content-center"
                                    style="border-radius: 50%; background: #dee2e6; width: 40px; height: 40px;">
                                    <mat-icon>leaderboard</mat-icon>
                                </div>
                            </td>

                            <td>
                                <span class="fw-bold">{{ t.name | truncate : 25 }}</span>
                                <div class="text-gray-500">{{'Due' | translate}}: {{
                                    t.due | date }}</div>


                                <div class="mt-2">
                                    <div class="fw-bold" class="text-gray-500">
                                        {{ t.status }}%              
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" [ngStyle]="{ width: t.status + '%'}" aria-valuenow="{{ t.status }}" aria-valuemin="0"
                                            aria-valuemax="100"></div>
                                    </div>
                                </div>

                            </td>
                            <td>

                                <button mat-button [routerLink]="t.link">View</button>

                            </td>

                        </tr>
                    </ng-container>
                    
                    <!--
                    <tr>

                        <td>
                            <div class="d-flex align-items-center justify-content-center"
                                style="border-radius: 50%; background: #dee2e6; width: 40px; height: 40px;">
                                <mat-icon>leaderboard</mat-icon>
                            </div>
                        </td>

                        <td>
                            <span class="fw-bold">Razvojna strategija INTESO Group in ponudba produktov</span>
                            <div class="text-gray-500">Due: Oct 7, 2020</div>


                            <div class="mt-2">
                                <div class="fw-bold" class="text-gray-500">
                                    25%
                                </div>

                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                                        aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </td>
                        <td>

                            <button mat-button>View</button>

                        </td>

                    </tr>
                    -->

                </tbody>

            </table>

        </ng-template>

    </div>
</div>