import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';

import { FileService } from 'src/app/services/file.service';
import { LayoutService } from 'src/app/services/layout.service';
import { Sizes } from 'src/app/sizes';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { RepoAddFileComponent } from './repo-add-file/repo-add-file.component';
import { RepoAddFolderComponent } from './repo-add-folder/repo-add-folder.component';
import { RepoRenameFolderComponent } from './repo-rename-folder/repo-rename-folder.component';
import { ViewFilesDialogComponent } from './view-files-dialog/view-files-dialog.component';

@Component({
  selector: 'app-repository',
  templateUrl: './repository.component.html',
  styleUrls: ['./repository.component.scss']
})
export class RepositoryComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  subscription2: Subscription;

  loading: boolean = true;
  displayedColumns: string[] = []; //['original_name', 'author', 'filesize', 'date_created', 'sort', 'action']; //['options', 'original_name', 'action'];
  pagesData: MatTableDataSource<any>;
  resultsLength = 0;

  folderPath: string;
  folderId: string;

  @Input() id: string;
  @Input() role: number;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  addFileDialogRef: MatDialogRef<RepoAddFileComponent>;
  addFolderDialogRef: MatDialogRef<RepoAddFolderComponent>;
  renameFolderDialogRef: MatDialogRef<RepoRenameFolderComponent>;
  viewFilesDialogRef: MatDialogRef<ViewFilesDialogComponent>;

  constructor(
    private _fileService: FileService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute,
    private _config: ConfigService,
    private _layoutService: LayoutService
  ) {

    this.subscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.folderId = this._route.snapshot.queryParams.folderId;

        if (!this.loading) {

          this.getFiles().then((result: any) => {

            this.folderPath = result.folder;

            if (!this.pagesData) {
              this.pagesData = new MatTableDataSource(result.data);
              //this.pagesData.paginator = this.paginator;     
              //this.pagesData.sort = this.sort;
            }
            else {
              this.pagesData.data = [];
              this.pagesData.data = result.data;
            }

            this.resultsLength = result.data.length

          });

        }
      }
    });

    this.subscription2 = this._layoutService.currentSize$.subscribe((size) => {

      if (size < Sizes.md) {
        this.displayedColumns = ['original_name', 'action'];
      }
      else if (size == Sizes.md) {
        this.displayedColumns = ['original_name', 'author', 'action'];
      }
      else {
        this.displayedColumns = ['original_name', 'author', 'filesize', 'date_created', 'sort', 'action'];
      }

    });

  }

  ngOnInit(): void {
    this.folderId = this._route.snapshot.queryParams.folderId;

    this.getFiles().then((result: any) => {
      this.loading = false;

      this.folderPath = result.folder;

      if (!this.pagesData) {
        this.pagesData = new MatTableDataSource(result.data);
        //this.pagesData.paginator = this.paginator;     
        //this.pagesData.sort = this.sort;
      }
      else {
        this.pagesData.data = [];
        this.pagesData.data = result.data.data;
      }

      this.resultsLength = result.data.length

    });

  }

  private getFiles() {

    return new Promise((resolve, reject) => {

      this._fileService.getFiles(this.id, (this.folderId) ? this.folderId : null).subscribe((result: any) => {

        if (result.success) {

          resolve(result);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });

        }
      });

    });

  }


  deleteFile(fileId: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this file/folder?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );


    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._fileService.deleteFile(this.id, fileId).subscribe((result: any) => {


          if (result.success) {
            let dataLength = this.pagesData.filteredData.length;

            for (let i = 0; i < dataLength; i++) {

              if (this.pagesData.filteredData[i]._id == fileId) {

                this.pagesData.filteredData.splice(i, 1);
                dataLength--;
                this.resultsLength--;

              }

            }

            // update table
            this.pagesData._updateChangeSubscription();
          }

        }, err => {

          if (err.status != 200) {
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });


      }
    });

  }



  /*
  newFile(file) {
    this.pagesData.filteredData.push(file);
    this.resultsLength++;
    this.pagesData._updateChangeSubscription();
  }*/

  addFile() {
    this.addFileDialogRef = this._dialog.open(
      RepoAddFileComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id,
          folder_id: this.folderId
        },
        disableClose: false
      }
    );

    this.addFileDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.pagesData.filteredData.push(result);
        this.resultsLength++;
        this.pagesData._updateChangeSubscription();
      }
    });
  }

  addFolder() {
    this.addFolderDialogRef = this._dialog.open(
      RepoAddFolderComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id,
          folder_id: this.folderId
        },
        disableClose: false
      }
    );

    this.addFolderDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.pagesData.filteredData.push(result);
        this.resultsLength++;
        this.pagesData._updateChangeSubscription();
      }
    });
  }

  renameFolder(folderId: string, folderName: string) {
    this.renameFolderDialogRef = this._dialog.open(
      RepoRenameFolderComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id,
          folder_id: folderId,
          folder_name: folderName
        },
        disableClose: false
      }
    );

    this.renameFolderDialogRef.afterClosed().subscribe(result => {
      if (result) {
        let dataLength = this.pagesData.filteredData.length;

        for (let i = 0; i < dataLength; i++) {

          if (this.pagesData.filteredData[i]._id == folderId) {

            this.pagesData.filteredData[i].original_name = result;

          }

        }

        // update table
        this.pagesData._updateChangeSubscription();
      }
    });
  }

  visitFolder(folderId: string) {

    const urlTree = this._router.createUrlTree([], {
      queryParams: { 'folderId': folderId },
      queryParamsHandling: "merge",
      preserveFragment: true
    });

    this._router.navigateByUrl(urlTree);

  }

  prepareDownload(id: string) {

    let newWindow = window.open();

    this._fileService.downloadFile(this.id, id).subscribe((result: any) => {

      if (result && result.success) {
        //window.open(this._config.getApiUrl() + "/download/" + result.data, "_blank");  // !!!!
        newWindow.location.href = this._config.getApiUrl() + "/download/" + result.data;
      }
      else {
        this._snackBar.open(result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {
      if (err.status != 200) {
        this._snackBar.open('Error on the server', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });

  }

  viewFile(id: string) {

    this.viewFilesDialogRef = this._dialog.open(
      ViewFilesDialogComponent,
      {
        data: {
          level_id: this.id,
          file_id: id
        },
        panelClass: 'preview-file-dialog',
        disableClose: false
      }
    );

  }


  moveUp(fileId: string) {

    this._fileService.moveUp(fileId, this.folderId).subscribe((result: any) => {

      if (result.success) {
        let dataLength = this.pagesData.filteredData.length;

        for (let i = 0; i < dataLength; i++) {

          if (this.pagesData.filteredData[i]._id == fileId) {

            let fileInfo = this.pagesData.filteredData[i];
            this.pagesData.filteredData.splice(i, 1);

            this.pagesData.filteredData.splice(i - 1, 0, fileInfo);

            break;

          }

        }

        // update table
        this.pagesData._updateChangeSubscription();
      }


    }, err => {

      if (err.status != 200) {
        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });

  }

  moveDown(fileId: string) {

    this._fileService.moveDown(fileId, this.folderId).subscribe((result: any) => {

      if (result.success) {
        let dataLength = this.pagesData.filteredData.length;

        for (let i = 0; i < dataLength; i++) {

          if (this.pagesData.filteredData[i]._id == fileId) {

            let fileInfo = this.pagesData.filteredData[i];
            this.pagesData.filteredData.splice(i, 1);

            this.pagesData.filteredData.splice(i + 1, 0, fileInfo);

            break;

          }

        }

        // update table
        this.pagesData._updateChangeSubscription();
      }


    }, err => {

      if (err.status != 200) {
        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });

  }

  ngOnDestroy() {

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }

  }

}
