import { Injectable } from '@angular/core';

import { DropdownQuestion } from './question-dropdown';
import { QuestionBase } from './question-base';
import { TextboxQuestion } from './question-textbox';
import { TextareaQuestion } from './question-textarea';
import { of } from 'rxjs';

@Injectable()
export class QuestionService {

  // TODO: get from a remote source of question metadata
  getQuestions() {

    const questions: QuestionBase<string>[] = [
      
      new TextboxQuestion({
        headline: '1. Project Title',
        key: 'title',        
        label: 'Title',
        value: '',
        class: 'w-50 pe-2',
        required: true,
        order: 1
      }),

      new TextboxQuestion({
        key: 'nickName',
        label: 'Nick Name',
        value: '',
        class: 'w-50',
        required: true,
        order: 2
      }),

      new DropdownQuestion({
        key: 'trl',
        label: 'TRL - Technology readiness level',
        options: [
          {key: '1', value: 'TRL 1 – Basic principles observed'},
          {key: '2', value: 'TRL 2 – Technology concept formulated'},
          {key: '3', value: 'TRL 3 – Experimental proof of concept'},
          {key: '4', value: 'TRL 4 – Technology validated in lab'},
          {key: '5', value: 'TRL 5 – Technology validated in relevant environment (industrially relevant environment in the case of key enabling technologies)'},
          {key: '6', value: 'TRL 6 – Technology demonstrated in relevant environment (industrially relevant environment in the case of key enabling technologies)'},
          {key: '7', value: 'TRL 7 – System prototype demonstration in operational environment'},
          {key: '8', value: 'TRL 8 – System complete and qualified'},
          {key: '9', value: 'TRL 9 – Actual system proven in operational environment (competitive manufacturing in the case of key enabling technologies; or in space)'}
        ],
        required: true,
        order: 3
      }),


      new DropdownQuestion({
        headline: '2. Project Type',
        //space: true,
        key: 'projectType',
        label: 'Select type',
        options: [
          {key: 'technology',  value: 'Technology'},
          {key: 'organization',  value: 'Organization'},
          {key: 'competencies',   value: 'Competencies'},
          {key: 'corporate_culture', value: 'Corporate Culture'}
        ],
        required: true,
        multiple: true,
        order: 4
      }),

      new TextboxQuestion({
        headline: '3. Owner(s) of the Project Idea',
        key: 'owner',
        label: 'Name, Surname/ Organization',
        value: '',
        required: true,
        order: 5
      }),

      new TextboxQuestion({
        headline: '4. Proposing Organization',
        key: 'proposingOrganization',
        label: 'Organization Name',
        value: '',
        required: true,
        order: 6
      }),

      new DropdownQuestion({
        headline: '5. Subject of Business Secret',
        key: 'subjectBusinessSecret',
        label: 'Business Secret',
        options: [
          {key: 'true',  value: 'Yes'},
          {key: 'false',  value: 'No'}          
        ],
        required: true,
        order: 7
      }),

      new TextareaQuestion({
        headline: '6. Areas of Required Competencies / Expertise of Potential Project Partners',
        key: 'areasOfRequiredCompetencies',
        label: 'Competencies / Expertise',
        value: '',
        required: true,
        order: 8
      }),

      new TextareaQuestion({
        headline: '7. Short Description of the Proposed Project Business Case',
        key: 'shortDescription',
        label: 'Short description',
        value: '',
        required: true,
        order: 9
      }),

      new TextareaQuestion({
        headline: '8. Draft Project Scope & Expected Deliverables',
        key: 'draftProjectScope',
        label: 'Scope & Deliverables',
        value: '',
        required: true,
        order: 10
      }),

      new TextboxQuestion({
        headline: '9. Conceptual Estimations: Time & Costs & Funding',
        key: 'draftCost',
        label: 'Draft Cost Estimation (EUR)',
        value: '',
        required: true,
        order: 11
      }),

      new TextareaQuestion({
        key: 'expectedSources',
        label: 'Expected Funding Sources',
        value: '',
        required: true,
        order: 12
      }),

      new TextareaQuestion({
        headline: '10. Expected Benefits & KPI - Key Perfomance Indicators',
        key: 'expectedBenefits',
        label: 'Expected Project Benefits',
        value: '',
        required: true,
        order: 13
      }),

      new TextareaQuestion({
        key: 'kpi',
        label: 'KPI - Key Perfomance Indicators',
        value: '',
        required: true,
        order: 14
      }),

      new TextareaQuestion({
        headline: '11. Main Project Assumptions and Risks',
        key: 'assumptionsAndRisks',
        label: 'Assumptions and Risks',
        value: '',
        required: true,
        order: 15
      }),

      new TextboxQuestion({
        headline: '12. Contact Person',
        key: 'nameContact',
        label: 'Name, Surname /Organization',
        value: '',
        required: true,
        order: 16
      }),

      new TextboxQuestion({
        key: 'email',
        label: 'E-mail',
        value: '',
        class: 'w-50 pe-2',
        required: true,
        order: 17
      }),

      new TextboxQuestion({
        key: 'mobile',
        label: 'Mobile',
        value: '',
        class: 'w-50',
        required: true,
        order: 18
      }),

      new TextareaQuestion({
        key: 'others',
        label: 'Others',
        value: '',
        order: 19
      })

    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}