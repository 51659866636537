import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { Injectable, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { PlatformLevelComponent } from './page/platform-level/platform-level.component';
import { HomeComponent } from './home/home.component';
import { InterestLevelComponent } from './page/interest-level/interest-level.component';
import { PlatformHeaderComponent } from './component/platform-header/platform-header.component';
import { WorkspaceComponent } from './workspace/workspace.component';
import { LoginComponent } from './login/login.component';
import { ShowLoginComponent } from './login/show-login/show-login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDividerModule } from '@angular/material/divider';
import { NgxUploaderModule } from 'ngx-uploader';
import { LogoutComponent } from './logout/logout.component';
import { InstallComponent } from './install/install.component';
import { AdminComponent } from './page/admin/admin.component';
import { AddUserComponent } from './page/admin/add-user/add-user.component';
import { AddLevelDialogComponent } from './utils/add-level-dialog/add-level-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { MatListModule } from '@angular/material/list';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxFilesizeModule } from 'ngx-filesize';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ConfirmDialogComponent } from './utils/confirm-dialog/confirm-dialog.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { UiStyleComponent } from './ux/ui-style/ui-style.component';
import { ActivityBlockComponent } from './component/activity-block/activity-block.component';
import { ActivityIconComponent } from './component/activity-icon/activity-icon.component';

import { WikiComponent } from './component/content-blocks/wiki/wiki.component';
import { DiscussionsComponent } from './component/content-blocks/discussions/discussions.component';
import { EventsComponent } from './component/content-blocks/events/events.component';
import { MilestonesComponent } from './component/content-blocks/milestones/milestones.component';
import { RepositoryComponent } from './component/content-blocks/repository/repository.component';
import { MembersComponent } from './component/content-blocks/members/members.component';
import { UploadFileComponent } from './component/upload-file/upload-file.component';
import { UserAvatarComponent } from './component/user-avatar/user-avatar.component';
import { DiscussionDialogComponent } from './component/content-blocks/discussions/discussion-dialog/discussion-dialog.component';
import { AddCommentComponent } from './component/add-comment/add-comment.component';
import { EventsDialogComponent } from './component/content-blocks/events/events-dialog/events-dialog.component';
import { NewsComponent } from './component/content-blocks/news/news.component';
import { NewsDialogComponent } from './component/content-blocks/news/news-dialog/news-dialog.component';
import { PagesComponent } from './component/content-blocks/pages/pages.component';
import { LevelAdminMenuComponent } from './component/level-admin-menu/level-admin-menu.component';
import { EditLevelComponent } from './component/level-admin-menu/edit-level/edit-level.component';
import { AddLevelMemberComponent } from './component/level-admin-menu/add-level-member/add-level-member.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AdminShowUsersComponent } from './page/admin/admin-show-users/admin-show-users.component';
import { ExploreComponent } from './explore/explore.component';
import { MessagesComponent } from './messages/messages.component';
import { TasksComponent } from './component/content-blocks/tasks/tasks.component';
import { WbsComponent } from './component/wbs/wbs.component';
import { WbsDialogComponent } from './component/wbs/wbs-dialog/wbs-dialog.component';
import { UploadImageDialogComponent } from './component/upload-image-dialog/upload-image-dialog.component';
import { ChangeImageButtonComponent } from './component/change-image-button/change-image-button.component';
import { LevelAvatarComponent } from './component/level-avatar/level-avatar.component';
import { MyDatePipe } from './pipes/my-date.pipe';
import { MilestonesDialogComponent } from './component/content-blocks/milestones/milestones-dialog/milestones-dialog.component';
import { RepoAddFileComponent } from './component/content-blocks/repository/repo-add-file/repo-add-file.component';
import { RepoAddFolderComponent } from './component/content-blocks/repository/repo-add-folder/repo-add-folder.component';
import { ShowLikesDialogComponent } from './component/content-blocks/discussions/show-likes-dialog/show-likes-dialog.component';
import { TasksDialogComponent } from './component/content-blocks/tasks/tasks-dialog/tasks-dialog.component';
import { CreatePlatformUserComponent } from './component/create-platform-user/create-platform-user.component';
import { CreatePlatformDialogComponent } from './component/create-platform-user/create-platform-dialog/create-platform-dialog.component';
import { SuccessCreatingPlatformComponent } from './component/create-platform-user/success-creating-platform/success-creating-platform.component';
import { AdminEditUserComponent } from './page/admin/admin-show-users/admin-edit-user/admin-edit-user.component';
import { LevelBackgroundComponent } from './component/level-background/level-background.component';
import { ChangeAvatarDialogComponent } from './account-settings/change-avatar-dialog/change-avatar-dialog.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ConversationViewComponent } from './messages/conversation-view/conversation-view.component';
import { UserInfoDialogComponent } from './component/user-avatar/user-info-dialog/user-info-dialog.component';
import { NewMessageDialogComponent } from './messages/new-message-dialog/new-message-dialog.component';
import { BrowseUsersDialogComponent } from './component/browse-users-dialog/browse-users-dialog.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { MyEditorComponent } from './component/my-editor/my-editor.component';
import { ImportWneDialogComponent } from './component/import-wne-dialog/import-wne-dialog.component';
import { ForgotComponent } from './forgot/forgot.component';
import { AdminJoinUsersComponent } from './page/admin/admin-show-users/admin-join-users/admin-join-users.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { ViewFilesDialogComponent } from './component/content-blocks/repository/view-files-dialog/view-files-dialog.component';
import { MeetingDatesComponent } from './component/content-blocks/meeting-dates/meeting-dates.component';
import { BlogComponent } from './component/content-blocks/blog/blog.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ProfileComponent } from './page/profile/profile.component';
import { InlineEditorComponent } from './component/inline-editor/inline-editor.component';
import { SocialDialogComponent } from './page/profile/social-dialog/social-dialog.component';
import { ChangeBackgroundDialogComponent } from './account-settings/change-background-dialog/change-background-dialog.component';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { RouteReuseService } from './services/route-reuse.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SortLevelDialogComponent } from './utils/sort-level-dialog/sort-level-dialog.component';
import { ProposalsComponent } from './component/content-blocks/proposals/proposals.component';
import { RegisterComponent } from './register/register.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { CoolSocialLoginButtonsModule } from '@angular-cool/social-login-buttons';
import { ValidateComponent } from './validate/validate.component';
import { ResizableModule } from 'angular-resizable-element';
import { CustomReuseStrategy } from './reuse-strategy';
import { DynamicFormQuestionComponent } from './component/content-blocks/proposals/dynamic-form-question/dynamic-form-question.component';
import { DynamicFormComponent } from './component/content-blocks/proposals/dynamic-form/dynamic-form.component';
import { ChatBoxComponent } from './component/chat-box/chat-box.component';
import { MystriphtmlPipe } from './pipes/mystriphtml.pipe';
import { ShowInfoRequestMembershipDialogComponent } from './utils/show-info-request-membership-dialog/show-info-request-membership-dialog.component';
import { AddRequestMembershipDialogComponent } from './utils/add-request-membership-dialog/add-request-membership-dialog.component';
import { EmbedToWebsiteDialogComponent } from './utils/embed-to-website-dialog/embed-to-website-dialog.component';
import { ExportComponent } from './export/export.component';
import { AdminViewEmailsComponent } from './page/admin/admin-view-emails/admin-view-emails.component';
import { SearchBarComponent } from './layout/header/search-bar/search-bar.component';
import { PrivacyPolicyComponent } from './public/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './public/terms-of-service/terms-of-service.component';
import { ViewEmailInfoDialogComponent } from './page/admin/admin-view-emails/view-email-info-dialog/view-email-info-dialog.component';
import { AdminViewLogsComponent } from './page/admin/admin-view-logs/admin-view-logs.component';
import { ProjectProposalComponent } from './component/content-blocks/project-proposal/project-proposal.component';
import { ProposalFormComponent } from './component/content-blocks/project-proposal/proposal-form/proposal-form.component';
import { ShowProposalsComponent } from './component/content-blocks/project-proposal/show-proposals/show-proposals.component';
import { SpecificProposalComponent } from './component/content-blocks/project-proposal/specific-proposal/specific-proposal.component';
import { AdminSystemStatusComponent } from './page/admin/admin-system-status/admin-system-status.component';
import { ResolverComponent } from './resolver/resolver.component';
import { ChangeCoordinatorComponent } from './component/level-admin-menu/change-coordinator/change-coordinator.component';
import { ChangeRoleComponent } from './component/content-blocks/members/change-role/change-role.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ActivityAComponent } from './home/activity-a/activity-a.component';
import { ActivityBComponent } from './home/activity-b/activity-b.component';
import { ActivityLevelInfoComponent } from './home/activity-level-info/activity-level-info.component';
import { MyTasksViewComponent } from './home/my-tasks-view/my-tasks-view.component';
import { MyEventViewComponent } from './home/my-event-view/my-event-view.component';
import { EventViewCalendarDialogComponent } from './calendar/event-view-calendar-dialog/event-view-calendar-dialog.component';
import { WorkspaceGridViewComponent } from './workspace/workspace-grid-view/workspace-grid-view.component';
import { WorkspaceListViewComponent } from './workspace/workspace-list-view/workspace-list-view.component';
import { UpcomingEventViewComponent } from './calendar/upcoming-event-view/upcoming-event-view.component';
import { AdminDatabaseComponent } from './page/admin/admin-database/admin-database.component';
import { CommentsComponent } from './component/content-blocks/comments/comments.component';
import { RepoRenameFolderComponent } from './component/content-blocks/repository/repo-rename-folder/repo-rename-folder.component';
import { ChatSidebarComponent } from './component/chat-sidebar/chat-sidebar.component';
import { DashboardComponent } from './experiment/dashboard/dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { TableComponent } from './experiment/table/table.component';
import { TreeComponent } from './experiment/tree/tree.component';
import { InvitationPageComponent } from './invitation-page/invitation-page.component';
import { StartCollaboratingComponent } from './home/start-collaborating/start-collaborating.component';
import { CreatePlatformComponent } from './create-platform/create-platform.component';
import { InvitationPageComponent2 } from './invitation-page-2/invitation-page.component';
import { DiscussionSortComponent } from './component/content-blocks/discussion-sort/discussion-sort.component';
import { WgWpGroupsComponent } from './component/content-blocks/wg-wp-groups/wg-wp-groups.component';
import { Kmfest24Component } from './landing/kmfest24/kmfest24.component';
import { KmfestResultsComponent } from './landing/kmfest24/kmfest-results/kmfest-results.component';
import { EventCountdownComponent } from './landing/kmfest24/event-countdown/event-countdown.component';



// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// custom configuration Hammerjs
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // I will only use the swap gesture so 
    // I will deactivate the others to avoid overlaps
    'pinch': { enable: false },
    'rotate': { enable: false }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PlatformLevelComponent,
    HomeComponent,
    InterestLevelComponent,
    PlatformHeaderComponent,
    WorkspaceComponent,
    LoginComponent,
    ShowLoginComponent,
    LogoutComponent,
    InstallComponent,
    AdminComponent,
    AddUserComponent,
    AddLevelDialogComponent,
    ConfirmDialogComponent,
    SanitizeHtmlPipe,
    UiStyleComponent,
    ActivityBlockComponent,
    ActivityIconComponent,
    WikiComponent,
    DiscussionsComponent,
    EventsComponent,
    MilestonesComponent,
    RepositoryComponent,
    MembersComponent,
    UploadFileComponent,
    UserAvatarComponent,
    DiscussionDialogComponent,
    AddCommentComponent,
    EventsDialogComponent,
    NewsComponent,
    NewsDialogComponent,
    PagesComponent,
    LevelAdminMenuComponent,
    EditLevelComponent,
    AddLevelMemberComponent,
    AccountSettingsComponent,
    AdminShowUsersComponent,
    ExploreComponent,
    MessagesComponent,
    TasksComponent,
    WbsComponent,
    WbsDialogComponent,
    UploadImageDialogComponent,
    ChangeImageButtonComponent,
    LevelAvatarComponent,
    MyDatePipe,
    MilestonesDialogComponent,
    RepoAddFileComponent,
    RepoAddFolderComponent,
    ShowLikesDialogComponent,
    TasksDialogComponent,
    CreatePlatformUserComponent,
    CreatePlatformDialogComponent,
    SuccessCreatingPlatformComponent,
    AdminEditUserComponent,
    LevelBackgroundComponent,
    ChangeAvatarDialogComponent,
    TruncatePipe,
    ConversationViewComponent,
    UserInfoDialogComponent,
    NewMessageDialogComponent,
    BrowseUsersDialogComponent,
    NotificationsComponent,
    MyEditorComponent,
    ImportWneDialogComponent,
    ForgotComponent,
    AdminJoinUsersComponent,
    UnsubscribeComponent,
    ViewFilesDialogComponent,
    MeetingDatesComponent,
    BlogComponent,
    ProfileComponent,
    InlineEditorComponent,
    SocialDialogComponent,
    ChangeBackgroundDialogComponent,
    SortLevelDialogComponent,
    ProposalsComponent,
    RegisterComponent,
    ValidateComponent,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    ChatBoxComponent,
    MystriphtmlPipe,
    ShowInfoRequestMembershipDialogComponent,
    AddRequestMembershipDialogComponent,
    EmbedToWebsiteDialogComponent,
    ExportComponent,
    AdminViewEmailsComponent,
    SearchBarComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    ViewEmailInfoDialogComponent,
    AdminViewLogsComponent,
    ProposalFormComponent,
    ProjectProposalComponent,
    ShowProposalsComponent,
    SpecificProposalComponent,
    AdminSystemStatusComponent,
    ResolverComponent,
    ChangeCoordinatorComponent,
    ChangeRoleComponent,
    CalendarComponent,
    ActivityAComponent,
    ActivityBComponent,
    ActivityLevelInfoComponent,
    MyTasksViewComponent,
    MyEventViewComponent,
    EventViewCalendarDialogComponent,
    WorkspaceGridViewComponent,
    WorkspaceListViewComponent,
    UpcomingEventViewComponent,
    AdminDatabaseComponent,
    CommentsComponent,
    RepoRenameFolderComponent,
    ChatSidebarComponent,
    DashboardComponent,
    TableComponent,
    TreeComponent,
    InvitationPageComponent,
    InvitationPageComponent2,
    StartCollaboratingComponent,
    CreatePlatformComponent,
    DiscussionSortComponent,
    WgWpGroupsComponent,
    Kmfest24Component,
    KmfestResultsComponent,
    EventCountdownComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    NgxPermissionsModule.forRoot(),
    MatDialogModule,
    CKEditorModule,
    MatButtonModule,
    MatTableModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatCardModule,
    MatTreeModule,
    MatProgressBarModule,
    NgxUploaderModule,
    MatSelectModule,
    MatDividerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatAutocompleteModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    ImageCropperModule,
    MatTooltipModule,
    ShareButtonsModule,
    ShareIconsModule,
    MatSlideToggleModule,
    MatListModule,
    ColorPickerModule,
    MatBadgeModule,
    MatChipsModule,
    MatExpansionModule,
    MatSidenavModule,
    MatRadioModule,
    DragDropModule,
    PdfViewerModule,
    NgxFilesizeModule,
    LayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    SocialLoginModule,
    CoolSocialLoginButtonsModule,
    ResizableModule,
    ScrollingModule,
    MatPaginatorModule,
    HammerModule,
    MatRippleModule,
    MatGridListModule,
    MatSortModule,
  ],
  providers: [
    DatePipe,
    CookieService,
    //{ provide: LOCALE_ID, useValue: 'fr' },
    /*{
      provide: RouteReuseStrategy,
      useClass: RouteReuseService
    }*/
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1069159168643-9gprm16gaam6llsaqdrv75hqni8qjvhh.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('310310663832294')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    }
    //{provide: RouteReuseStrategy, useClass: CustomReuseStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
