<div *ngIf="platformInfo">

    <ng-container *ngIf="(isHandset$ | async); else biggerScreen">

        <div class="position-relative">
            <app-level-background [data]="platformInfo.background_image_url"></app-level-background>
            <div class="mobile-avatar">
                <app-level-avatar [data]="platformInfo.avatar_url"></app-level-avatar>
            </div>
        </div>
        <mat-card>
            <div class="mb-3 text-center">
                <div class="text-gray-500">
                    Collaboration platform
                </div>
                <h2>{{ platformInfo.name | truncate : 120 }}</h2>

            </div>
        </mat-card>

    </ng-container>

    <ng-template #biggerScreen>
        <section id="platform-header" class="position-relative">
            <ng-container *ngxPermissionsOnly="['PLATFORM_ADMIN']">
                <app-change-image-button [levelInfo]="platformInfo" type="background"
                    (change)="backgroundChange($event)"></app-change-image-button>
            </ng-container>
            <app-level-background [data]="platformInfo.background_image_url"></app-level-background>
            <div class="d-flex align-items-center">
                <div id="platform-avatar" class="ms-3 position-relative">
                    <ng-container *ngxPermissionsOnly="['PLATFORM_ADMIN']">
                        <app-change-image-button [levelInfo]="platformInfo" type="avatar"
                            (change)="avatarChange($event)"></app-change-image-button>
                    </ng-container>
                    <a routerLink="/{{url}}/">
                        <!-- <img src="/assets/logo.png"> -->
                        <app-level-avatar [data]="platformInfo.avatar_url"></app-level-avatar>
                    </a>
                </div>
                <div class="ms-3 mt-2 pr-4">
                    <ng-container *ngxPermissionsOnly="['PLATFORM_ADMIN']">
                        <div style="display: flex;">
                            <ng-container *ngIf="platformInfo.published; else notPublished">
                                <div class="fw-bold rounded"
                                    style="font-size: .7rem; padding-left: 5px; padding-right: 5px; color: #166534; background-color: #e0fcef;">
                                    Published
                                </div>
                            </ng-container>
                            <ng-template #notPublished>
                                <div class="fw-bold rounded"
                                    style="font-size: .7rem; padding-left: 5px; padding-right: 5px; color: #991b1b; background-color: #ffe6e5;">
                                    Private
                                </div>
                            </ng-template>
                        </div>
                    </ng-container>
                    <h1 class="platform-title" style="max-width: 600px;">{{ platformInfo.name | truncate : 120 }}</h1>
                </div>
                <div class="ms-auto">
                    <ng-container *ngIf="!hideRequestToJoin">
                        <ng-container *ngIf="!platformInfo?.membership_request">
                            <button (click)="requestToJoin()" mat-raised-button class="me-3"
                                *ngIf="platformInfo.role == undefined"
                                matTooltip="Add request to become a member of this platform">
                                <mat-icon>person_add</mat-icon> Add request</button>
                        </ng-container>
                        <ng-container *ngIf="platformInfo?.membership_request">
                            <button mat-flat-button class="me-3" *ngIf="platformInfo.role == undefined">
                                <mat-icon>check</mat-icon> Request sent</button>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!hideChangeNotificationsButton && showNotificationsButton">
                        <ng-container *ngIf="notifications === true; else notificationsOff">
                            <button mat-mini-fab class="me-3" color="secondary" matTooltip="Turn off notifications"
                                (click)="turnOnOffNotifications(notifications)">
                                <mat-icon>notifications</mat-icon>
                            </button>
                        </ng-container>
                        <ng-template #notificationsOff>
                            <button mat-mini-fab class="me-3" color="warn" matTooltip="Turn on notifications"
                                (click)="turnOnOffNotifications(notifications)">
                                <mat-icon>notifications_off</mat-icon>
                            </button>
                        </ng-template>
                    </ng-container>

                    <button mat-mini-fab class="me-3" color="secondary" matTooltip="Embed to a website"
                        (click)="embed()">
                        <mat-icon>
                            ios_share
                        </mat-icon>
                    </button>

                    <button mat-mini-fab color="primary" class="me-3" [matMenuTriggerFor]="sharemenu"
                        matTooltip="Share to social media">
                        <mat-icon>share</mat-icon>
                    </button>
                    <mat-menu #sharemenu="matMenu" (click)="$event.stopPropagation()">
                        <share-button button="facebook"></share-button>
                        <share-button button="twitter"></share-button>
                        <share-button button="linkedin"></share-button>
                        <!--<share-button button="vk"></share-button>
                        <share-button button="email"></share-button>
                        <share-button button="copy"></share-button>
                        <share-button button="print"></share-button>-->
                    </mat-menu>
                    <ng-container *ngxPermissionsOnly="['PLATFORM_ADMIN']">
                        <app-level-admin-menu mode="platform" [id]="platformInfo._id"></app-level-admin-menu>
                    </ng-container>
                </div>
            </div>
        </section>
    </ng-template>

    <!--<nav mat-tab-nav-bar class="platform-menu my-2 mb-4 mt-3 platform-menu-tabs mat-elevation-z2 py-2 px-2 bg-white" style="border-bottom: 0px!important;" (swipeleft)="swipe($event.type)" (swiperight)="swipe($event.type)">-->
    <nav mat-tab-nav-bar class="platform-menu mat-elevation-z2 bg-white px-xl-1"
        [backgroundColor]="(isHandset$ | async) ? 'primary' : ''" (swipeleft)="onSwipe($event.type)"
        (swiperight)="onSwipe($event.type)">

        <ng-container *ngFor="let m of platformMenu">
            <a mat-tab-link routerLink="/{{url}}{{ m.link }}" routerLinkActive #rla1="routerLinkActive"
                [routerLinkActiveOptions]="{ exact: (m.link != '/') ? false : true }" [active]="rla1.isActive">
                <mat-icon>{{ m.icon }}</mat-icon>
                <span class="ms-2">{{m.name | translate}}</span>
            </a>
        </ng-container>

    </nav>

</div>