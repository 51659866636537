<ng-container *ngIf="loading">

    <div class="text-center">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

</ng-container>
<ng-container *ngIf="!loading">


    <ng-container *ngIf="!levelInfo">

        <div style="padding-top: 4rem;">
            <div class="d-flex justify-content-center">
                <div class="align-items-center text-center" style="width: 500px;">

                    <mat-icon class="text-primary" style="font-size: 4rem;">error</mat-icon>

                    <div class="mat-display-1 mt-2 mb-4">Platform not found</div>
                    <div class="text-muted" style="font-size: 1.2rem;">The platform you are trying to
                        access does not
                        exist or may have
                        been moved.
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="levelInfo">

        <div class="container-xl h-100">
            <div class="row justify-content-center h-100">
                <div class="col">



                    <div class="bg-main-content h-100 d-flex flex-column">

                        <app-platform-header [platformId]="levelInfo"></app-platform-header>

                        <div class="flex-fill">

                            <ng-container *ngIf="!page">
                                <app-pages [id]="levelInfo._id" [role]="levelInfo.role"></app-pages>
                            </ng-container>
                            <ng-container *ngIf="page == 'news'">
                                <app-news [id]="levelInfo._id" [role]="levelInfo.role" [newsId]="subpage"></app-news>
                            </ng-container>
                            <ng-container *ngIf="page == 'events'">
                                <app-events [id]="levelInfo._id" [role]="levelInfo.role"
                                    [pageId]="subpage"></app-events>
                            </ng-container>
                            <ng-container *ngIf="page == 'wiki'">
                                <app-wiki [id]="levelInfo._id" [role]="levelInfo.role" [link]="levelInfo.link"
                                    [wikiId]="subpage" large="true" platform="true"></app-wiki>
                            </ng-container>
                            <ng-container *ngIf="page == 'community'">
                                <app-members [id]="levelInfo._id" [role]="levelInfo.role"
                                    [type]="levelInfo.type"></app-members>
                            </ng-container>
                            <ng-container *ngIf="page == 'blog'">
                                <app-blog [id]="levelInfo._id" [role]="levelInfo.role" [blogId]="subpage"></app-blog>
                            </ng-container>

                        </div>
                        <!-- .mx-3 -->

                    </div>

                </div>
            </div>
        </div>

        <app-wbs [id]="levelInfo._id" [currentId]="levelInfo._id"></app-wbs>


    </ng-container>


</ng-container>