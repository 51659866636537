import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ChatService } from 'src/app/services/chat.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-user-info-dialog',
  templateUrl: './user-info-dialog.component.html',
  styleUrls: ['./user-info-dialog.component.scss']
})
export class UserInfoDialogComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  sendMessage: Boolean = false;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<UserInfoDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _ngZone: NgZone,
    private _snackBar: MatSnackBar,
    private _chatService: ChatService,
    private _socketService: SocketService,
    private _router: Router
  ) { }

  showMessageForm() {
    this.sendMessage = !this.sendMessage;
  }

  ngOnInit(): void {
    this.mainForm = this._fb.group({
      message: ['', [Validators.required]],
      user_id: [this.data.user._id, Validators.required]      
    });
  }

  viewProfile(userId: string) {
    this._dialogRef.close();

    this._router.navigate(['profile/' + userId]);
  }

  onSubmit() {
    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this._chatService.sendChat(
        this.mainForm.value.user_id, 
        this.mainForm.value.message
      ).subscribe((result: any) => {

        if(result.success) {

          this._socketService.emit('send-message', {
            chat_id: result.data.chat_id,
            users: this.mainForm.value.user_id, 
            message: this.mainForm.value.message
          })

          this._dialogRef.close();
          
          this._snackBar.open('Message sent', 'View Inbox', {
            duration: 5000
          });
          
        }
        else {    
  
          this._snackBar.open(result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
  
        }
  
      }, err => {
  
        if (err.status != 200) {
  
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
  
        }
      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

}
