<div class="h-100">
    <div class="w-100 h-100">

        <ng-container *ngIf="loading">
            <div class="d-flex h-100 align-items-center justify-content-center">
                <mat-spinner></mat-spinner>
            </div>
        </ng-container>

        <ng-container *ngIf="!loading">
            <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">
                <div class="container h-100">
                    <div class="row h-100">

                        <div class="col-12 d-flex justify-content-center align-items-center">



                            <div style="width: 500px;">

                                <ng-container *ngIf="!id">

                                    <mat-card *ngIf="!done">

                                        <h1 class="mat-h1">{{ 'Reset your password' | translate }}</h1>

                                        <mat-card-content>


                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>
                                                    {{ 'E-mail' | translate }}
                                                </mat-label>
                                                <input matInput formControlName="email">
                                            </mat-form-field>

                                            <ng-container *ngIf="showError">
                                                <div class="mat-caption mb-4">
                                                    <div class="alert alert-danger" role="alert">
                                                        {{ showErrorMessage | translate }}
                                                    </div>
                                                </div>
                                            </ng-container>


                                            <mat-dialog-actions>
                                                <div class="d-flex align-items-center">
                                                    <button mat-raised-button color="primary" class="mr-2"
                                                        type="submit">
                                                        {{ 'Reset' | translate }}
                                                    </button>
                                                </div>
                                            </mat-dialog-actions>


                                        </mat-card-content>

                                    </mat-card>

                                    <mat-card *ngIf="done">

                                        <mat-card-content>

                                            <div class="d-flex align-items-center">



                                                <div>
                                                    <h2 class="mat-h2 mb-2">{{'Please check your inbox' | translate}}</h2>

                                                    {{'Reset link was sent to your e-mail.' | translate}}
                                                </div>

                                                <div class="ms-auto">
                                                    <mat-icon>email</mat-icon>
                                                </div>

                                            </div>


                                        </mat-card-content>

                                    </mat-card>

                                </ng-container>

                                <ng-container *ngIf="id">


                                    <ng-container *ngIf="!requestNotFound">


                                        <ng-container *ngIf="!done">

                                            <h1 class="mat-h1">{{'Change your password' | translate}}</h1>

                                            <mat-card>

                                                <mat-card-content>

                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>
                                                            {{'Password' | translate}}
                                                        </mat-label>
                                                        <input matInput formControlName="password" type="password">
                                                        <mat-hint>{{'At least 8 characters long' | translate}}</mat-hint>
                                                    </mat-form-field>

                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>
                                                            {{'Re-enter password' | translate}}
                                                        </mat-label>
                                                        <input matInput formControlName="password2" type="password">
                                                    </mat-form-field>

                                                    <ng-container *ngIf="showError">
                                                        <div class="mat-caption mb-4">
                                                            <div class="alert alert-danger" role="alert">
                                                                {{ showErrorMessage | translate }}
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <mat-dialog-actions>
                                                        <div class="d-flex align-items-center">
                                                            <button mat-raised-button color="primary" class="mr-2"
                                                                type="submit">
                                                                {{'Change password' | translate}}
                                                            </button>
                                                        </div>
                                                    </mat-dialog-actions>

                                                </mat-card-content>

                                            </mat-card>
                                        </ng-container>

                                        <mat-card *ngIf="done">

                                            <mat-card-content>
    
                                                <div class="d-flex align-items-center">
    
    
                                                    <div>
                                                        <h2 class="mat-h2 mb-2">{{'Password changed successfully' | translate}}</h2>

                                                        {{'Your password has been reset successfully.' | translate}} 

                                                        <div class="mt-3">
                                                            <button mat-raised-button color="primary" class="mr-2"
                                                                    type="button" routerLink="/login">
                                                                    {{'Log in' | translate}}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="ms-auto">
                                                        <mat-icon>done</mat-icon>
                                                    </div>

    
                                                </div>
    
    
                                            </mat-card-content>
    
                                        </mat-card>
    

                                    </ng-container>
                                    <ng-container *ngIf="requestNotFound">
                                        

                                        <mat-card>
                                            <mat-card-content>

                                                <div class="d-flex align-items-center">



                                                    <div>
                                                        <h2 class="mat-h2 mb-2">{{'Request not found' | translate}}</h2>

                                                        {{'Password reset request is not found. Click the button below to send another request.' | translate}}

                                                        <div class="mt-3">
                                                            <button mat-raised-button color="primary" class="mr-2"
                                                                    type="button" routerLink="/forgot">
                                                                    {{'Send another request' | translate}}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="ms-auto">
                                                        <mat-icon>error</mat-icon>
                                                    </div>

                                                </div>


                                            </mat-card-content>

                                        </mat-card>


                                    </ng-container>

                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</div>