<ng-container *ngIf="!loading">


    <mat-toolbar class="d-flex align-items-center my-4">

        <h1 class="mat-h1 mb-0">{{'Introduction' | translate}}</h1>

        <ng-container *ngIf="create; else showToolbar">

            <button mat-raised-button (click)="cancel()" class="ms-auto">
                <div class="d-flex align-items-center">
                    <span class="material-icons">
                        close
                    </span> Cancel
                </div>
            </button>

        </ng-container>
        <ng-template #showToolbar>

            <ng-container *ngIf="content && (role == 0 || role == 1)">

                <!-- mobile-menu -->

                <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" class="ms-auto d-md-none">
                    <span class="material-icons">
                        more_vert
                    </span>
                </button>
                <mat-menu #menu="matMenu">
                    
                    <button mat-menu-item (click)="edit(content)">
                        <mat-icon>create</mat-icon>
                        <span>{{'Edit page' | translate}}</span>
                    </button>

                    <button mat-menu-item (click)="delete()">
                        <mat-icon>delete</mat-icon>
                        <span>{{'Delete page' | translate}}</span>
                    </button>

                </mat-menu>
            

                <!-- desktop-menu -->

                <button mat-raised-button (click)="edit(content)" class="ms-auto d-none d-md-inline">
                    <span class="material-icons">
                        create
                    </span>
                </button>

                <button mat-raised-button (click)="delete()" class="ms-2 d-none d-md-inline">
                    <span class="material-icons">
                        delete
                    </span>
                </button>
                

            </ng-container>

        </ng-template>

    </mat-toolbar>

    <div class="my-4">

        <mat-card class="mat-elevation-z0" style="overflow-x: auto;">

            <ng-container *ngIf="create">
                <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" class="editor-height-300px" novalidate>

                    <app-my-editor [levelid]="_id" [data]="data" (output)="onCkEditorValue($event)"></app-my-editor>


                    <div class="my-3">
                        <button mat-raised-button color="primary" type="submit">
                            {{'Save' | translate}}
                        </button>
                        
                        <!--<button mat-raised-button color="secondary" type="button" class="ms-2"
                            (click)="cancel()">{{'Cancel' | translate}}</button>-->
                    </div>

                </form>
            </ng-container>
            <ng-container *ngIf="!create">

                <ng-container *ngIf="!content">
                    {{'No content available' | translate}}

                    <div *ngIf="role == 0 || role == 1">
                        <div class="ms-auto my-4">
                            <button mat-raised-button color="primary" (click)="edit(content)">
                                <span class="material-icons">
                                    add
                                </span>
                                {{'Add page' | translate}}
                            </button>
                        </div>
                    </div>
                </ng-container>


                <ng-container *ngIf="content?.content">
                    <div class="ck-content" [innerHTML]="content.content | sanitizeHtml"></div>
                </ng-container>


                <!--
                <div class="d-flex align-items-center mt-4">

                    <div class="flex-grow-1">
                        <div class="d-flex align-items-center" *ngIf="content?.user_id && content?.user_id?.full_name">

                            <app-user-avatar width="25" fontSize="12px" [info]="content?.user_id">
                            </app-user-avatar>

                            <div class="ms-2 text-muted mat-caption d-flex">
                                <div class="me-4">
                                    {{ content?.user_id.full_name | truncate : 45 }}
                                </div>
                                <div class="me-4" matTooltip="{{ content?.date_created | date: 'medium' }}">
                                    {{ content?.date_created | myDate }}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>-->


                <div class="d-flex align-items-center mt-2" *ngIf="content?.user_id && content?.user_id?.full_name">

                    <div class="me-2">
                        <app-user-avatar width="40" fontSize="12px" [info]="content?.user_id">
                        </app-user-avatar>
                    </div>

                    <div class="d-flex flex-column">

                        <div class="fw-boldest text-gray-800">
                            {{ content?.user_id.full_name | truncate : 45 }}
                        </div>
                        <div class="text-gray-500 fw-bold"
                            matTooltip="{{ content?.date_created | date: 'medium' }}">
                            {{ content?.date_created | myDate }}
                        </div>

                    </div>
                </div>

            </ng-container>

        </mat-card>

    </div>

</ng-container>