export default [{
    icons: 'far fa-file-alt',
    name: 'newWikiPage'
}, {
    icons: 'fas fa-tasks',
    name: 'newTask'
}, {
    icons: 'fas fa-stopwatch',
    name: 'newMilestone'
}, {
    icons: 'far fa-folder',
    name: 'newFolder'
}, {
    icons: 'far fa-file',
    name: 'newFile'
}, {
    icons: 'far fa-edit',
    name: 'changedTitle'
}, {
    icons: 'fas fa-plus',
    name: 'newLevel'
}, {
    icons: 'fas fa-calendar-week',
    name: 'newEvent'
}, {
    icons: 'far fa-comment',
    name: 'newComment'
}, {
    icons: 'far fa-comments',
    name: 'newTopic'
}, {
    icons: 'fas fa-newspaper',
    name: 'newNews'
}]