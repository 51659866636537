import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-social-dialog',
  templateUrl: './social-dialog.component.html',
  styleUrls: ['./social-dialog.component.scss']
})
export class SocialDialogComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<SocialDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.createForm(this.data);
  }

  createForm(data: any) {

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    if(data.social) {
      this.mainForm = this._fb.group({
        facebook: [(data.social.facebook) ? data.social.facebook : '', [Validators.pattern(reg)]],
        twitter: [(data.social.twitter) ? data.social.twitter : '', [Validators.pattern(reg)]],
        linkedin: [(data.social.linkedin) ? data.social.linkedin : '', [Validators.pattern(reg)]],
      });
    }
    else {
      this.mainForm = this._fb.group({
        facebook: ['', [Validators.pattern(reg)]],
        twitter: ['', [Validators.pattern(reg)]],
        linkedin: ['', [Validators.pattern(reg)]]
      });
    }
  }

  get f(){
    return this.mainForm.controls;
  }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this._usersService.updateSocial(
        this.mainForm.value.facebook,
        this.mainForm.value.twitter,
        this.mainForm.value.linkedin
      ).subscribe((result: any) => {

        if(result.success) {

          this._dialogRef.close({
            facebook: this.mainForm.value.facebook,
            twitter: this.mainForm.value.twitter,
            linkedin: this.mainForm.value.linkedin
          });
        
        }
        else {

          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });

        }


      }, err => {

        if (err.status != 200) {

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

}
