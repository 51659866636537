import { Component, Inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { RequestMembershipService } from 'src/app/services/request-membership.service';
import { AddRequestMembershipDialogComponent } from 'src/app/utils/add-request-membership-dialog/add-request-membership-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmbedToWebsiteDialogComponent } from 'src/app/utils/embed-to-website-dialog/embed-to-website-dialog.component';

import PlatformMenu from './platform-menu';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MembershipService } from 'src/app/services/membership.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-platform-header',
  templateUrl: './platform-header.component.html',
  styleUrls: ['./platform-header.component.scss']
})
export class PlatformHeaderComponent implements OnInit, OnDestroy {

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );  
  
  subscription: Subscription;
  subscriptionRoute: Subscription;

  platformMenu: any[] = [...PlatformMenu];

  platformInfo: any;

  smallHeader: boolean = true; //false;
  lock: boolean = false;

  // email notifications for level
  notifications!: boolean;
  showNotificationsButton!: boolean;

  url: string = 'p';
  @Input() set platformId(platformId: any) {
    if(platformId) {

      this.platformInfo = platformId;
      this.url = this.url + '/' + platformId._id;
      
      this.notifications = this.platformInfo?.notifications;
      if (this.notifications === true || this.notifications === false) {
        this.showNotificationsButton = true;
      }
    }
  }

  @Input() hideRequestToJoin: boolean;
  @Input() hideChangeNotificationsButton: boolean;

  @Input() makeSmall(small: boolean) {
    //this.smallHeader = small;
  }

  
  addRequestForMembershipRef: MatDialogRef<AddRequestMembershipDialogComponent>;
  embedDialogRef: MatDialogRef<EmbedToWebsiteDialogComponent>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _scrollDispatcher: ScrollDispatcher,
    private _zone: NgZone,
    private _requestMembershipService: RequestMembershipService,
    private _dialog: MatDialog,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _membershipService: MembershipService,
    private _snackBar: MatSnackBar,
  ) { 

    /*
    this.subscription = this._scrollDispatcher.scrolled().subscribe(x => {

      // to avoid hitting change detection for every scroll event
      this._zone.run(() => {
        
        let scrollTop = this.document.getElementById('mainSidebar').scrollTop;

        if (scrollTop > 50 || scrollTop > 50) {
          if(!this.smallHeader) {
            this.smallHeader = true;
            this.lock = true;

            // V primeru robnih pogojev, da ne utripa zaslon
            setTimeout(() => {
              this.lock = false;
            }, 500)
          }
        }
        else {
          if(this.smallHeader && !this.lock)
            this.smallHeader = false;
        }      

      });

    });*/

    this.subscriptionRoute = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {

        this.addActiveFieldAtPlaformMenu(event.url);
        
      }
    });

  }

  ngOnInit(): void {
   
    this.addActiveFieldAtPlaformMenu(this._router.url);

  }

  avatarChange(image: any): void {    
    this.platformInfo.avatar = image;    
    this.platformInfo.avatar_url = image.url;  
  }

  backgroundChange(image: any): void {    
    window.location.reload();
    //this.platformInfo.background_image = image;    
  }

  requestToJoin(): void {

    /**
     * We open the dialog for text
     */
    this.addRequestForMembershipRef = this._dialog.open(
      AddRequestMembershipDialogComponent,
      {
        width: '450px',
        data: this.platformInfo
      }
    );

    this.addRequestForMembershipRef.afterClosed().subscribe(data => {
      if (data) {
        /**
         * We send the request
         */
        this._requestMembershipService.addRequest(this.platformInfo._id, data.message).then((data: any) => {

          this.platformInfo.membership_request = true;    
    
        }).catch(err => { console.log(err) });
      }
    });

  }

  /**
   * Embed to a website dialog
   */
  embed() {
    this.embedDialogRef = this._dialog.open(
      EmbedToWebsiteDialogComponent,
      {
        width: '450px',
        data: this.platformInfo
      }
    );
  }

  /**
   * Swipe event listener for navbar
   * @param eType swipeleft | swiperight
   */
  onSwipe(eType: string){

    this.platformMenu.map((m, index) => {

      if(m.active == true) {

        if(eType === this.SWIPE_ACTION.LEFT) {
          // we go to the right        
          
          if(this.platformMenu[index + 1]) {
            this._router.navigate(['/' + this.url + '/' + this.platformMenu[index + 1].link]);
          }
          else {
            this._router.navigate(['/' + this.url + '/' + this.platformMenu[0].link]);
          }

        }
        else if(eType === this.SWIPE_ACTION.RIGHT) {
          // we go to the left         

          if(this.platformMenu[index - 1]) {
            this._router.navigate(['/' + this.url + '/' + this.platformMenu[index - 1].link]);
          }
          else {
            this._router.navigate(['/' + this.url + '/' + this.platformMenu[this.platformMenu.length - 1].link]);
          }

        }

      }

    })

  }

  /**
   * We add active field at menu - which helps us at swiping
   * @param url 
   * @returns 
   */
  addActiveFieldAtPlaformMenu(url) {

    let splitUrl = url.split('/');
    let urlLink = '/';

    this.platformMenu.map((el) => {

      if(splitUrl[3]) {
        urlLink = '/' + splitUrl[3];
      }
      
      el.active = (urlLink == el.link);
     

    });
  }

  async turnOnOffNotifications(notifications: boolean): Promise<void> {

    this.notifications = !notifications;

    try {
      const res = await <any>this._membershipService.updateLevelNotifications(this.platformInfo._id).toPromise();

      if (res.success) {
        this._snackBar.open(`Notifications are turned ` + (this.notifications ? 'on' : 'off'), 'Close', {
          duration: 2000,
        });
      }
    } catch(err) {

      let statusText = `[${err.status}] Problems with server. Please try again.`;
      if (err.error?.msg) {
        statusText = `[${err.status}] ${err.error.msg}...`;
      }

      this._snackBar.open( statusText, 'Close', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });

      this.notifications = !this.notifications;

    }

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if(this.subscriptionRoute) {
      this.subscriptionRoute.unsubscribe();
    }
  }
}
