import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserinfoService } from 'src/app/services/userinfo.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  currentLang: string;
  currentYear: number;

  constructor(
    private _translate: TranslateService,
    private _userinfoService: UserinfoService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {    


    this.subscription = this._userinfoService.loggedIn$.subscribe((value) => {
      if(value) {
        this.currentLang = this._userinfoService.getUserLang();
      }
    });

    this.currentYear = new Date().getFullYear();

  }

  changeLang(lang: string) {

    if(this._userinfoService.getLoggedIn()) {

      this._userinfoService.setDefaultLang(lang).subscribe((result: any) => {

        if(result.success) {
          this.currentLang = lang;
          this._translate.use(lang);
        }      

      }, err => {

        if (err.status != 200) {

          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });

        }

      });

    }
    else {
      this.currentLang = lang;
      this._translate.use(lang);
    }
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
