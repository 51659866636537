import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  chatOpened: boolean;
  chatOpened$ = new BehaviorSubject<boolean>(false); 

  messagesNumber$ = new BehaviorSubject<number>(0); 

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  /*
  sendChat(userId: string, message: string) {

    return this._http.post(this._config.getApiUrl() + '/api/chat', {    
      user_id: userId,
      message: message
    }, this._httpOptionsService.getHeader())

  }*/  

  sendChat(users: any[], message: string) {

    return this._http.post(this._config.getApiUrl() + '/api/chat', {    
      users: users,
      message: message
    }, this._httpOptionsService.getHeader())

  }

  chatFocusStatus(status: boolean) {
    this.chatOpened = status;
    this.chatOpened$.next(status);
  }

  getUserChats() {
    return this._http.get(this._config.getApiUrl() + '/api/chat', this._httpOptionsService.getHeader())
  }

  getChatMessages(chatId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/chat/' + chatId, this._httpOptionsService.getHeader())
  }

  // not in use
  /*
  getChatMessagesUserId(userId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/chat/messages/' + userId, this._httpOptionsService.getHeader())
  }*/

  // When we click on user, we resolve chat_id if exists
  getChatIdByUserId(userId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/chat/id/resolve/' + userId, this._httpOptionsService.getHeader())
  }

  deleteChat(chatId) {
    return this._http.delete(this._config.getApiUrl() + '/api/chat/' + chatId, this._httpOptionsService.getHeader())
  }
}
