import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import config from '../config';
import { LayoutService } from '../services/layout.service';
import { LevelService } from '../services/level.service';
import { Level } from '../interface/level';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AuthentificationService } from '../services/authentification.service';
import { User } from '../interface/user';
import { UserinfoService } from '../services/userinfo.service';
import { ApiResponse } from '../interface/api-reponse';
import { CookieService } from 'ngx-cookie-service';
import { HttpOptionsService } from '../services/http-options.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-invitation-page',
  templateUrl: './invitation-page.component.html',
  styleUrls: ['./invitation-page.component.scss']
})
export class InvitationPageComponent2 implements OnInit, OnDestroy {

  platformId: string;
  step: number = 1;

  loading: boolean = true;
  platformInfo: Level;
  userInfo: User;
  loggedIn: boolean;

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  showError: boolean = false;
  showErroMessage: String = '';
  isNewUser: boolean = true;

  navigationSub: Subscription;
  userInfoSub: Subscription;

  constructor(
    private _fb: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _levelService: LevelService,
    private _layoutService: LayoutService,
    private _titleService: Title,
    private _userinfoService: UserinfoService,
    private _cookieService: CookieService,
    private _httpOptionsService: HttpOptionsService,
  ) {

    this.navigationSub = this._router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {

        this._layoutService.hideToolbar();

        let tmpStepVal = this._route.snapshot.queryParams.step;



        if (tmpStepVal) {

          /*
          switch(parseInt(tmpStepVal)) {
            case 1:
              this.step++;
              break;

            case 2:
              this.step--;
              break;

            default:
              this.step = 1;
              break;

          } */

          this.step = parseInt(tmpStepVal);
        }
        else {
          this.step = 1;
        }

      }

    });

    this.userInfoSub = this._userinfoService.loggedIn$.subscribe((loggedIn: boolean) => {

      if (loggedIn) {
        this.userInfo = <User>this._userinfoService.getLocalInfo();
      }
      this.loggedIn = loggedIn;
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.mainForm.controls; }

  ngOnInit(): void {



    this.createForm();
    //this.userInfo = <User>this._userinfoService.getLocalInfo();

    this.platformId = '617b08092abba8691fc95b8f';

    this._titleService.setTitle('Join Our Group');

    this._levelService.getLevelPublic(this.platformId).subscribe((result: any) => {

      if (result.success) {
        this.platformInfo = <Level>result.data;
      }

      this.loading = false;

    }, (err: HttpErrorResponse) => {

      if(err.status != 200) {
        console.log('napaka ' + err)

        this.loading = false;
      }

    })



  }

  createForm(): void {
    this.mainForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      organization: [''],
      privacyPolicy: [false, [Validators.required]],
    });
  }


  onSubmit(): void {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {


      this._levelService.joinLevelAsGuest(this.platformId, <User>{
        email: this.mainForm.value.email,
        firstName: this.mainForm.value.firstName,
        lastName: this.mainForm.value.lastName,
        organization: this.mainForm.value.organization,
      }).subscribe((result: ApiResponse) => {

        if (result.success) {
          this.isNewUser = result.data.new_user;
          let token = result.data.token;

          let expiredDate = new Date();
          expiredDate.setDate( expiredDate.getDate() + 1 );   // 1 day

          this._cookieService.set('jwtEx', token, expiredDate, '/', '', true, 'Strict');

          this._httpOptionsService.refreshHeader();

          this.goToTheNextStep();
        }

      }, err => {

        this.showError = true;
        this.showErroMessage = err.statusText;

      })




    }

  }

  joinThisLevelAsMember() {
    this._levelService.joinLevelAsMember(this.platformId).subscribe((result: ApiResponse) => {

      if (result.success) {
        this.goToTheNextStep();
      }

    }, err => {

      this.showError = true;
      this.showErroMessage = err.statusText;

    })
  }

  goToTheNextStep(): void {
    this.showError = false;
    this.step++;

    let urlTree = this._router.createUrlTree([], {
      queryParams: { 'step': this.step },
      queryParamsHandling: "merge",
      preserveFragment: true
    });

    this._router.navigateByUrl(urlTree);
  }

  viewGroup() {
    this._router.navigate([this.platformInfo.link]);
  }

  private login(result: any): void {

    let expiredDate = new Date();
    expiredDate.setDate( expiredDate.getDate() + 1 );   // one day

  }


  ngOnDestroy() {

    if (this.navigationSub) {
      this.navigationSub.unsubscribe();
    }

    if (this.userInfoSub) {
      this.userInfoSub.unsubscribe();
    }

  }

}
