import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ConfigService } from 'src/app/services/config.service';
import { ProposalsService } from 'src/app/services/proposals.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
//import * as InlineEditor from '../../../../lib/ckeditor5/build/ckeditor';

@Component({
  selector: 'app-proposal-form',
  templateUrl: './proposal-form.component.html',
  styleUrls: ['./proposal-form.component.scss']
})
export class ProposalFormComponent implements OnInit {

  //public Editor = InlineEditor;
  //config: any = {};
  //toolbar = ['bold', 'alignment', 'italic', 'bulletedList', 'numberedList', 'link', 'imageUpload'];

  mainForm: UntypedFormGroup;
  submitted: boolean = false;

  trl: any = [
    { key: '1', value: 'TRL 1 – Basic principles observed' },
    { key: '2', value: 'TRL 2 – Technology concept formulated' },
    { key: '3', value: 'TRL 3 – Experimental proof of concept' },
    { key: '4', value: 'TRL 4 – Technology validated in lab' },
    { key: '5', value: 'TRL 5 – Technology validated in relevant environment (industrially relevant environment in the case of key enabling technologies)' },
    { key: '6', value: 'TRL 6 – Technology demonstrated in relevant environment (industrially relevant environment in the case of key enabling technologies)' },
    { key: '7', value: 'TRL 7 – System prototype demonstration in operational environment' },
    { key: '8', value: 'TRL 8 – System complete and qualified' },
    { key: '9', value: 'TRL 9 – Actual system proven in operational environment (competitive manufacturing in the case of key enabling technologies; or in space)' }
  ]

  projectTypes: any = [
    { key: 'technology', value: 'Technology' },
    { key: 'organization', value: 'Organization' },
    { key: 'competencies', value: 'Competencies' },
    { key: 'corporate_culture', value: 'Corporate Culture' }
  ]

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  @Input() id: string;      // level id
  @Input() proposal: any;
  @Output() exit = new EventEmitter<boolean>(false);

  constructor(
    private _fb: UntypedFormBuilder,
    private _proposalService: ProposalsService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _config: ConfigService,
    private cookieService: CookieService,
    private _dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    /*
    this.config = {
      viewportTopOffset: 50,
      toolbar: this.toolbar,
      image: {
        toolbar: [
          'imageTextAlternative',
          'imageStyle:alignLeft',
          'imageStyle:full',
          'imageStyle:alignRight',
          'linkImage'
        ],
        styles: [
          // This option is equal to a situation where no style is applied.
          'full',

          // This represents an image aligned to the left.
          'alignLeft',

          // This represents an image aligned to the right.
          'alignRight'
        ]
      },
      simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: this._config.getApiUrl() + '/api/file/editor/' + this.id,

        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,

        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: { 'Authorization': this.cookieService.get('jwtEx') },
      }
    }*/


    let testObject = {
      title: 'title',
      nickname: 'nickname',
      trl: ['1', '2'],
      project_type: ['technology', 'organization'],
      owner: 'owner',
      proposing_organization: 'proposing_organization',
      subject_business_secret: false,
      areas_of_required_competencies: 'areas_of_required_competencies',
      short_description: 'short_description',
      draft_project_scope: 'draft_project_scope',
      draft_project_duration: 'draft_project_duration',
      draft_cost: 'draft_cost',
      expected_sources: 'expected_sources',
      expected_benefits: 'expected_benefits',
      kpi: 'kpi',
      assumptions_and_risks: 'assumptions_and_risks',
      main_project_risks: 'main_project_risks',
      name_contact: 'name_contact',
      email: 'email',
      mobile: 'mobile',
      others: 'others'
    }

    if (this.proposal) {
      this.createForm(this.proposal);
    }
    else {
      this.createForm(); //testObject);
    }
  }

  createForm(data?: any) {
    this.mainForm = this._fb.group({
      title: [(data && data.title) ? data.title : '', [Validators.required]],
      nickname: [(data && data.nickname) ? data.nickname : '', []],
      trl: [(data && data.trl) ? data.trl : [''], []],
      project_type: [(data && data.project_type) ? data.project_type : '', []],
      owner: [(data && data.owner) ? data.owner : '', []],
      proposing_organization: [(data && data.proposing_organization) ? data.proposing_organization : '', []],
      subject_business_secret: [(data && data.subject_business_secret) ? data.subject_business_secret : false, []],
      areas_of_required_competencies: [(data && data.areas_of_required_competencies) ? data.areas_of_required_competencies : '', []],
      short_description: [(data && data.short_description) ? data.short_description : '', []],
      draft_project_scope: [(data && data.draft_project_scope) ? data.draft_project_scope : '', []],
      draft_project_duration: [(data && data.draft_project_duration) ? data.draft_project_duration : '', []],
      draft_cost: [(data && data.draft_cost) ? data.draft_cost : '', []],
      expected_sources: [(data && data.expected_sources) ? data.expected_sources : '', []],
      expected_benefits: [(data && data.expected_benefits) ? data.expected_benefits : '', []],
      kpi: [(data && data.kpi) ? data.kpi : '', []],
      assumptions_and_risks: [(data && data.assumptions_and_risks) ? data.assumptions_and_risks : '', []],
      main_project_risks: [(data && data.main_project_risks) ? data.main_project_risks : '', []],
      name_contact: [(data && data.name_contact) ? data.name_contact : '', []],
      email: [(data && data.email) ? data.email : ''],
      mobile: [(data && data.mobile) ? data.mobile : ''],
      others: [(data && data.others) ? data.others : '']
    });

  }

  compareWith(field1: any, field2: any): boolean {
    return field1 && field2 && field1 === field2;
  }

  get f() { return this.mainForm.controls; }


  onCkEditorValue(formControlName: string, text: string) {

    let object = {}
    object[formControlName] = text;

    this.mainForm.patchValue(object);
  }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      let promise = (!this.proposal) ?
        this._proposalService.saveProposal(
          this.id,
          this.mainForm.value.title,
          this.mainForm.value.nickname,
          this.mainForm.value.trl,
          this.mainForm.value.project_type,
          this.mainForm.value.owner,
          this.mainForm.value.proposing_organization,
          this.mainForm.value.subject_business_secret,
          this.mainForm.value.areas_of_required_competencies,
          this.mainForm.value.short_description,
          this.mainForm.value.draft_project_scope,
          this.mainForm.value.draft_project_duration,
          this.mainForm.value.draft_cost,
          this.mainForm.value.expected_sources,
          this.mainForm.value.expected_benefits,
          this.mainForm.value.kpi,
          this.mainForm.value.assumptions_and_risks,
          this.mainForm.value.main_project_risks,
          false,
          this.mainForm.value.name_contact,
          this.mainForm.value.email,
          this.mainForm.value.mobile,
          this.mainForm.value.others
        )
        :
        this._proposalService.updateProposal(
          this.proposal._id,
          this.id,
          this.mainForm.value.title,
          this.mainForm.value.nickname,
          this.mainForm.value.trl,
          this.mainForm.value.project_type,
          this.mainForm.value.owner,
          this.mainForm.value.proposing_organization,
          this.mainForm.value.subject_business_secret,
          this.mainForm.value.areas_of_required_competencies,
          this.mainForm.value.short_description,
          this.mainForm.value.draft_project_scope,
          this.mainForm.value.draft_project_duration,
          this.mainForm.value.draft_cost,
          this.mainForm.value.expected_sources,
          this.mainForm.value.expected_benefits,
          this.mainForm.value.kpi,
          this.mainForm.value.assumptions_and_risks,
          this.mainForm.value.main_project_risks,
          false,
          this.mainForm.value.name_contact,
          this.mainForm.value.email,
          this.mainForm.value.mobile,
          this.mainForm.value.others
        );

      promise.subscribe((result: any) => {

        if (result.success) {

          if (!this.proposal) {
            let currentUrl = this._router.url;
            let tmpArray = currentUrl.split('/');
            tmpArray.pop();

            this._router.navigate([tmpArray.join('/')]);
          }

          this._snackBar.open('Saved succesfully', '', {
            duration: 2000
          });
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          this._snackBar.open('Error: ' + err.statusText, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Invalid form', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }



  }

  exitForm() {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'You will loose all unsaved data',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.exit.emit(true);

      }
    });
  }

}
