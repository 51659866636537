<div style="height: 40px;"></div>

<footer class="container">
    <div class="row">
        <div class="col" style="min-height: 80px;">

            <div class="d-flex flex-wrap flex-column flex-lg-row  align-items-center justify-content-center">

                <ng-container [ngSwitch]="currentLang">

                    <ng-container *ngSwitchDefault>

                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                English (US)
                            </li>
                            <li class="list-inline-item">
                                <a [routerLink]="[]" (click)="changeLang('sl_SI')">
                                    Slovenščina
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a [routerLink]="[]" (click)="changeLang('hr')">
                                    Hrvatski
                                </a>
                            </li>
                        </ul>

                    </ng-container>

                    <ng-container *ngSwitchCase="'sl_SI'">

                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                Slovenščina
                            </li>
                            <li class="list-inline-item">
                                <a [routerLink]="[]" (click)="changeLang('en')">
                                    English (US)
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a [routerLink]="[]" (click)="changeLang('hr')">
                                    Hrvatski
                                </a>
                            </li>
                        </ul>

                    </ng-container>

                    <ng-container *ngSwitchCase="'hr'">

                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                Hrvatski
                            </li>
                            <li class="list-inline-item">
                                <a [routerLink]="[]" (click)="changeLang('sl_SI')">
                                    Slovenščina
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a [routerLink]="[]" (click)="changeLang('en')">
                                    English (US)
                                </a>
                            </li>
                        </ul>

                    </ng-container>

                </ng-container>

                <div class="ms-md-auto my-2 my-md-0">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <a routerLink="/privacy-policy">
                                Privacy Policy
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a routerLink="/terms-of-service">
                                Terms of Service
                            </a>
                        </li>
                    </ul>
                </div>

            </div>

            <mat-divider class="my-2 mb-3 d-none d-md-block"></mat-divider>

            <div class="text-center text-md-start mt-4 mt-md-0">
                ExArca &copy; {{ currentYear }}
            </div>

            
            <div #footerSpanMobile class="d-block d-md-none" style="height: 80px;"></div>

        </div>
    </div>
</footer>