<ng-container *ngIf="loading">

    <div class="text-center">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

</ng-container>

<ng-container *ngIf="!loading">
    <mat-toolbar>
        <div class="d-flex my-4 justify-content-center" *ngIf="personalProfile">
            <div>
                <button mat-raised-button color="primary" (click)="viewPublicProfile()">
                    <mat-icon>visibility</mat-icon> View public profile
                </button>

                <button mat-raised-button (click)="editProfile()" class="ms-3">
                    <ng-container *ngIf="!edit">
                        <mat-icon>edit</mat-icon> Edit profile
                    </ng-container>
                    <ng-container *ngIf="edit">
                        <mat-icon>close</mat-icon> Cancel
                    </ng-container>
                </button>
            </div>
        </div>
    </mat-toolbar>

    <div class="container h-100-profile">

        <ng-container *ngIf="!userFound">

            <div class="d-flex flex-column justify-content-center text-center w-100 h-100">

                <div class="text-center">
                    <div class="mb-3 text-muted">
                        <i class="fas fa-user-circle" style="font-size: 5rem"></i>
                    </div>
                    <div class="mat-display-1 mb-3">Member not found</div>
                    <div class="mat-body-2">Such member doesn't exists</div>
                </div>

            </div>

        </ng-container>

        <ng-container *ngIf="user">

            <div class="h-40px-xl"></div>



            <div class="row">
                <div class="col-12 col-xl-8 offset-xl-2">



                    <!--
                <section id="platform-header" class="position-relative">
                    <app-level-background [data]="user.background_image_url" rootClass="profile-image"></app-level-background>

                    <div class="admin-edit-button-above-image" *ngIf="edit">
                        <button mat-mini-fab color="accent" class="ms-auto" (click)="changeBackground()">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </section>-->

                    <section class="px-4">

                        <div class="d-flex align-items-center my-4">

                            <!--<app-user-avatar width="120" fontSize="16px" [info]="user"></app-user-avatar>-->

                            <div class="position-relative">
                                <app-user-avatar width="100" fontSize="24px" [info]="user" disableLink="true">
                                </app-user-avatar>

                                <div class="admin-edit-button-above-image" *ngIf="edit">
                                    <button mat-mini-fab color="accent" class="ms-auto" (click)="changeAvatar()">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </div>
                            </div>


                            <div class="ms-4">
                                <div class="d-flex">
                                    <ng-container *ngIf="!user.edit_name; else editName">
                                        <div class="mat-display-1 mb-0">
                                            {{ user.full_name }}
                                        </div>
                                    </ng-container>
                                    <ng-template #editName>
                                        <app-inline-editor [data]="user.full_name"
                                            (output)="updatedField('full_name', $event, 'edit_name')">
                                        </app-inline-editor>
                                    </ng-template>
                                </div>
                                <div class="d-flex">
                                    <ng-container *ngIf="!user.edit_education; else editEducation">
                                        <div class="mat-caption text-muted">
                                            {{ user.education }}
                                        </div>

                                        <button mat-mini-fab color="accent" *ngIf="edit"
                                            (click)="user.edit_education = !user.edit_education">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </ng-container>
                                    <ng-template #editEducation>
                                        <app-inline-editor [data]="user.education"
                                            (output)="updatedField('education', $event, 'edit_education')">
                                        </app-inline-editor>
                                    </ng-template>
                                </div>
                            </div>
                        </div>

                        <mat-card>




                            <mat-tab-group mat-align-tabs="start" backgroundColor="secondary">
                                <mat-tab label="Introduction">

                                    <div class="my-4">

                                        <!--<div class="mat-caption text-muted text-uppercase my-2 mt-4">introduction</div>-->

                                        <div class="position-relative">
                                            <ng-container *ngIf="!user.edit_introduction; else editIntroduction">

                                                <ng-container *ngIf="user.introduction; else noContent">
                                                    <div class="ck-content"
                                                        [innerHTML]="user.introduction | sanitizeHtml"></div>
                                                </ng-container>
                                                <ng-template #noContent>
                                                    No intro ...
                                                </ng-template>


                                                <div class="admin-edit-button-above-image" style="right: 10px;"
                                                    *ngIf="edit"
                                                    (click)="user.edit_introduction = !user.edit_introduction">
                                                    <button mat-mini-fab color="accent">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                </div>
                                            </ng-container>
                                            <ng-template #editIntroduction>
                                                <app-inline-editor [data]="user.introduction" showEditor="true"
                                                    (output)="updatedField('introduction', $event, 'edit_introduction')">
                                                </app-inline-editor>
                                            </ng-template>
                                        </div>





                                    </div>

                                </mat-tab>
                                <mat-tab label="Membership ({{memberships}})">

                                    <div class="my-4">
                                        <ng-container *ngIf="memberships == 0; else showMemberships">
                                            Not a member of any public platform
                                        </ng-container>

                                        <ng-template #showMemberships>

                                            <div class="mat-caption text-muted text-uppercase mb-2 mt-4">Memberships
                                            </div>

                                            <div class="d-flex align-items-center mx-2">

                                                <div>
                                                    <div class="d-flex text-center">
                                                        <div class="d-flex align-items-center justify-content-center">
                                                            <div style="height: 30px; width: 30px; border-radius: 20%;"
                                                                class="platform-bg text-center text-white mb-2 d-flex justify-content-center align-items-center">
                                                                <div class="fw-bold">{{ platforms.length }}</div>
                                                            </div>
                                                            <div class="ms-2">platforms</div>
                                                        </div>

                                                    </div>
                                                    <div class="d-flex text-center">
                                                        <div class="d-flex align-items-center justify-content-center">
                                                            <div style="height: 30px; width: 30px; border-radius: 20%;"
                                                                class="interest-bg text-center text-white mb-2 d-flex justify-content-center align-items-center">
                                                                <div class="fw-bold">{{ interests }}</div>
                                                            </div>
                                                            <div class="ms-2">interests / projects</div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex text-center">
                                                        <div class="d-flex align-items-center justify-content-center">
                                                            <div style="height: 30px; width: 30px; border-radius: 20%;"
                                                                class="wg-bg text-center text-white mb-2 d-flex justify-content-center align-items-center">
                                                                <div class="fw-bold">{{ wgs }}</div>
                                                            </div>
                                                            <div class="ms-2">wgs / wps</div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>


                                            <ng-container *ngIf="platforms.length > 0">

                                                <div class="mat-caption text-muted text-uppercase mt-4">
                                                    Collaborations platforms
                                                </div>

                                                <mat-list role="list" class="platform-list">
                                                    <ng-container *ngFor="let p of platforms; let last = last">
                                                        <mat-list-item role="listitem">

                                                            <div class="pointer" routerLink="{{ p.link }}">

                                                                <app-level-avatar [data]="p.avatar_url">
                                                                </app-level-avatar>

                                                                {{ p.name | truncate : 60 }}

                                                            </div>


                                                        </mat-list-item>
                                                        <ng-container *ngIf="!last">
                                                            <mat-divider></mat-divider>
                                                        </ng-container>
                                                    </ng-container>
                                                </mat-list>
                                            </ng-container>



                                        </ng-template>
                                    </div>

                                </mat-tab>
                            </mat-tab-group>

                        </mat-card>

                        <ng-container *ngIf="user.social || edit">

                            <div class="d-flex my-4">

                                <div class="social-buttons">

                                    <ng-container *ngIf="!user.social">
                                        <div class="p-2 text-gray-400 d-flex">
                                            <div class="me-4">
                                                <i class="fab fa-facebook"></i>
                                            </div>
                                            <div class="me-4">
                                                <i class="fab fa-twitter"></i>
                                            </div>
                                            <div class="me-4">
                                                <i class="fab fa-linkedin"></i>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="user.social?.facebook">
                                        <button class="small-btn" mat-button
                                            (click)="redirectSocial(user.social.facebook)"><i
                                                class="fab fa-facebook"></i></button>
                                    </ng-container>
                                    <ng-container *ngIf="user.social?.twitter">
                                        <button class="small-btn" mat-button
                                            (click)="redirectSocial(user.social.twitter)"><i
                                                class="fab fa-twitter"></i></button>
                                    </ng-container>
                                    <ng-container *ngIf="user.social?.linkedin">
                                        <button class="small-btn" mat-button
                                            (click)="redirectSocial(user.social.linkedin)"><i
                                                class="fab fa-linkedin"></i></button>
                                    </ng-container>
                                </div>


                                <button mat-mini-fab color="accent" class="ms-2" *ngIf="edit" (click)="socialDialog()">
                                    <mat-icon>edit</mat-icon>
                                </button>

                            </div>
                        </ng-container>

                    </section>

                </div>


            </div>

        </ng-container>

    </div>
</ng-container>