import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { EmailService } from 'src/app/services/email.service';
import { ViewEmailInfoDialogComponent } from './view-email-info-dialog/view-email-info-dialog.component';

@Component({
  selector: 'app-admin-view-emails',
  templateUrl: './admin-view-emails.component.html',
  styleUrls: ['./admin-view-emails.component.scss']
})
export class AdminViewEmailsComponent implements OnInit {

  loading: boolean = true;
  displayedColumns: string[] = ['sent', 'title', 'user_id', 'date_created', 'content', 'html', 'error'];
  emailData: MatTableDataSource<any>;
  resultsLength = 0;
  emails: any[] = [];

  emailInfoDialogRef: MatDialogRef<ViewEmailInfoDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _emailService: EmailService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {

    this._emailService.adminGetEmails().subscribe((result: any) => {

      this.loading = false;

      if(result.success) {

        this.resultsLength = result.data.resultsLength;
        this.emails = [...result.data];

        this.emailData = new MatTableDataSource(this.emails); 

        

      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {
        this.loading = false;

        this._snackBar.open('Error: ', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }

    });

  }

  showInfoDialog(emailDetails: any) {
    this.emailInfoDialogRef = this._dialog.open(
      ViewEmailInfoDialogComponent,
      {
        width: '30vw',
        data: emailDetails
      }
    );
  }

}
