import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AuthentificationService } from '../services/authentification.service';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  loading: boolean = true;

  socialLogin: boolean = false;
  id: any = null;
  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  showError: boolean = false;
  showErroMessage: String = '';
  requestNotFound: boolean = false;

  user: SocialUser;
  private subscription: Subscription;

  done: boolean;

  constructor(
    private _fb: UntypedFormBuilder, 
    private _authService: SocialAuthService,
    private _authenticationService: AuthentificationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _layoutService: LayoutService,
    private _cookieService: CookieService,
    private _titleService: Title
  ) { }

  ngOnInit(): void {

    if(this._route.snapshot.data.hideHeader) {

      // Set timeout because NG0100 error
      setTimeout(()=> {
        this._layoutService.hideToolbar();
      }, 0)
      
    }  

    this.id = this._route.snapshot.paramMap.get('id');

    this._titleService.setTitle('Create your account');

    if(!this.id) {
      this.loading = false;
      this.createForm();
    }
    else {
      this._authenticationService.getActivationLink(this.id).subscribe((result: any) => {  

        if(result.success) {
  
          this.loading = false;
          this.createForm(result.data);

          this.socialLogin = result.data.social_login;
  
        }
        else {
          this.loading = false;
          this.showError = true;
          this.requestNotFound = true;
          this.showErroMessage = result.message;
        }
  
        
  
      }, err => {  
  
        if(err.status != 200) {
          this.loading = false;
  
          this.showError = true;
          this.requestNotFound = true;
          this.showErroMessage = 'Request not found';
        }
  
      });
    }

    this.subscription = this._authService.authState.subscribe((user) => {
      this.user = user;
      
      if(this.user) {

        if(this.user.provider == 'GOOGLE') {

          this._authenticationService.loginGoogle(this.user.email, this.user.firstName, this.user.lastName, this.user.photoUrl).subscribe((result: any) => {  

            if(result.success) {
              this._router.navigate(['register/' + result.data]);
            }

          }, err => {  

            if(err.status != 200) {
              this.showError = true;
              this.showErroMessage = err.error.message;
            }

          });

        }
        else {
          this._authenticationService.loginFacebook(this.user.email, this.user.firstName, this.user.lastName, this.user.photoUrl).subscribe((result: any) => {  

            if(result.success) {
              this._router.navigate(['register/' + result.data]);
            }

          }, err => {  

            if(err.status != 200) {
              this.showError = true;
              this.showErroMessage = err.error.message;
            }

          });
        }

      }

    });

    

    
  }

  createForm(data?: any) {

    if(!this.id) {
      this.mainForm = this._fb.group({
        email: ['', [Validators.required, Validators.email]],
      });
    }
    else {

      if(data.social_login) {
        this.mainForm = this._fb.group({
          email: [(data.email) ? data.email : '', [Validators.required, Validators.email]],
          firstName: [(data.first_name) ? data.first_name : '', [Validators.required]],
          lastName: [(data.last_name) ? data.last_name : '', [Validators.required]],
          password: ['']
        });
      }
      else {
        this.mainForm = this._fb.group({
          email: [(data.email) ? data.email : '', [Validators.required, Validators.email]],
          firstName: [(data.first_name) ? data.first_name : '', [Validators.required]],
          lastName: [(data.last_name) ? data.last_name : '', [Validators.required]],
          password: ['', [Validators.required]]
        });
      }

      
    }
  }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      // validate email @todo
      this._authenticationService.registerWithEmail(this.mainForm.value.email, this._cookieService.get('reqMemId')).subscribe((result: any) => {  

        if (result.success) {
          this.done = true;
        }
        else {
          this.showError = true;
          this.showErroMessage = result.message;
        }

      }, err => {  

        if(err.status != 200) {
          this.showError = true;
          this.showErroMessage = 'Please try again.';
        }


      });


    }
    else {

      this.showError = true;
      this.showErroMessage = 'Please enter your e-mail';

    }

  }

  createAccount() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this._authenticationService.finishRegistration(
        this.id,
        this.mainForm.value.email,
        this.mainForm.value.firstName,
        this.mainForm.value.lastName,
        this.mainForm.value.password,
        this._cookieService.get('reqMemId')
      ).subscribe((result: any) => { 
        
        if(result.success) {

          // redirect v login
          this._router.navigate(['login']);

        }
        else {

          this.showError = true;
          this.showErroMessage = result.message;

        }

      }, err => {  

        if(err.status != 200) {
          this.showError = true;
          this.showErroMessage = 'Please try again.';
        }

      });

    }
    else {

      this.showError = true;
      this.showErroMessage = 'Please enter your data';

    }

  }

  signInWithGoogle(): void {
    this._authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this._authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
