<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

    <div class="d-flex align-items-center mb-2">
        <h2 mat-dialog-title class="d-flex align-items-center mb-0">
            <span class="material-icons">
                add
            </span> 
            {{'Add folder' | translate}}
        </h2>
        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content>

        <mat-form-field appearance="fill" class="w-100">
            <mat-label>
                {{'Folder name' | translate}}
            </mat-label>
            <input matInput formControlName="name">
        </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button color="primary" class="mr-2" type="submit">
            <span class="material-icons">
                add
            </span> {{'Add' | translate}}</button>
        <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
    </mat-dialog-actions>


</form>