import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-level-info',
  templateUrl: './activity-level-info.component.html',
  styleUrls: ['./activity-level-info.component.scss']
})
export class ActivityLevelInfoComponent implements OnInit {

  _levelId: any;

  @Input() set levelId(levelId: any) { this._levelId = levelId }

  constructor() { }

  ngOnInit(): void {
  }

}
