import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private currentSize: number;
  currentSize$ = new BehaviorSubject<number>(0);

  toolbarStatus$ = new BehaviorSubject<boolean>(true);
  footerStatus$ = new BehaviorSubject<boolean>(true);
  showChatSidebar$ = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  setSize(size: number) {
    this.currentSize = size;
    this.currentSize$.next(size);
  }

  getSize() {
    return this.currentSize;
  }

  hideToolbar() {
    this.toolbarStatus$.next(false);
  }

  showToolbar() {
    this.toolbarStatus$.next(true);
  }

  hideFooter() {
    this.footerStatus$.next(true);
  }

}
