<div class="my-2 mt-5">

    <div class="d-flex flex-wrap align-items-center mx-2">
        <div class="text-primary me-2 d-none d-md-block">
            <mat-icon>lightbulb</mat-icon>
        </div>
        <h1 class="mt-4 text-muted" style="font-weight: 100;">Start the collaboration</h1>
    </div>

    <section class="collaborating-platforms my-2">

        <div class="row">

            <ng-container *ngIf="platformsToRecommend?.length > 0">
                
                <div class="col-12 col-sm-6 col-xl-4" *ngFor="let p of platformsToRecommend">
                    <div class="space bg-white">
                    
                        <div class="d-flex flex-column align-items-center justify-content-center h-100 text-center" [title]="p.name">

                            <app-level-avatar [data]="p.avatar_url" rootClass="recommend-platform-avatar"></app-level-avatar>

                            <h3 class="fw-bold mb-1">{{ p.name | truncate }}</h3>

                            <button mat-raised-button color="primary" [routerLink]="p.link">View</button>

                        </div>
                        
                    </div>
                </div>

            </ng-container>
      

            <div class="col-12 col-sm-6 col-xl-4">

                <div class="space d-flex justify-content-center bg-primary text-white">


                    <div class="d-flex align-items-center justify-content-center create-platform h-100" routerLink="/create-platform">
                        <div class="mx-4" style="font-size: 3rem;">
                            +
                        </div>

                        <h2 class="mb-0">
                            Create Your Platform
                        </h2>
                    </div>

                </div>

            </div>

        </div>

    </section>


</div>