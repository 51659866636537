import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { map, startWith, catchError, debounceTime, switchMap } from 'rxjs/operators';

import { LevelService } from 'src/app/services/level.service';
import { UsersService } from 'src/app/services/users.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { BrowseUsersDialogComponent } from '../../browse-users-dialog/browse-users-dialog.component';
import { MembershipService } from 'src/app/services/membership.service';



@Component({
  selector: 'app-add-level-member',
  templateUrl: './add-level-member.component.html',
  styleUrls: ['./add-level-member.component.scss']
})
export class AddLevelMemberComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emailCtrl = new UntypedFormControl();
  userCtrl = new UntypedFormControl();
  filteredUsers: Observable<string[]>;
  users: any[] = [];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  loading: boolean = false;

  options: any[] = [];
  filteredOptions: Observable<any[]>;

  browseUserDialogRef: MatDialogRef<BrowseUsersDialogComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AddLevelMemberComponent>,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _levelService: LevelService,
    private _usersService: UsersService,
    private _dialog: MatDialog,
    private _membershipService: MembershipService,
  ) { 

      this.filteredUsers = this.userCtrl.valueChanges.pipe(
        startWith('a'),
        switchMap(value => {
          if (value !== '') {
            return this._filter(value);
          } else {
            return of(null);
          }
        })
      );

  }

  createForm() {
    this.mainForm = this._fb.group({      
      message: ['']
    });
  }

  ngOnInit(): void {
    this.createForm();
  }

  getUser(search: string) {
    return new Promise((resolve, reject) => {

      this._usersService.searchConnectedUsers(search).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });
  }


  onSubmit() {
    
    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      if(this.users.length == 0) {
        this._snackBar.open('Please add user', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

        return false;
      }

      this.loading = true;
      
      this._levelService.addMember(this.data.level_id, this.users, this.mainForm.value.message)
      .subscribe((result: any) => {
  
        if (result.success) {

          this._snackBar.open('Member added', '', {
            duration: 2000
          });

          // we notify <app-members> component
          this._membershipService.newMemberEmiter$.next(this.users)

          this._dialogRef.close(result.data);
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }
  }

  add(event: MatChipInputEvent): void {

    let emailRegex = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;

    if((event.value.match(emailRegex))) {

      this.emailCtrl.setErrors(null);
      
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {

        if(!this.userExist(value.trim())) { 
          this.users.push({ full_name: value.trim(), isEmail: true });
        }
        else {
          this._snackBar.open('User is already added', '', {
            horizontalPosition: 'left',
            duration: 2000
          });
        }
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.userCtrl.setValue(null);
    }
    else {      
      if(event.value != '') { 
        // if email is incorrect
        this.emailCtrl.setErrors({'incorrect': true});
        this.emailCtrl.markAsTouched();
      }
    }

    
  }

  remove(user: any): void {
    const index = this.users.indexOf(user);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
   

    //this.users.push(event.option.viewValue);
    if(!this.userExist(event.option.value)) { 
      this.users.push(event.option.value);
    }
    else {
      this._snackBar.open('User is already added', '', {
        horizontalPosition: 'left',
        duration: 2000
      });
    }
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);
    this.emailCtrl.setErrors(null);
  }

  private _filter(value: any): Observable<any[]> {

    // we remove error from input on text change
    this.emailCtrl.setErrors(null);

    let filterValue = ' ';
    if(value && !value._id) {
      filterValue = (value) ? value.toLowerCase() : value;
    }  
    else {
      return of(null);
    } 

    return this._usersService.searchConnectedUsers(filterValue).pipe(
      // map the item property of the github results as our return object
      map((data:any) => data.data),
      // catch errors
      catchError(_ => {
        return of(null);
      })
    );
    
  }

  userExist(input: any): boolean {

    for(let user of this.users) {

      if(input._id) {
        if(user._id == input._id) {
          return true;
        }
      }
      else {
        if(user.full_name == input) {
          return true;
        }
      }

    }

    return false;    

  }

  browseUsers() {

    // we remove the autocomplete
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);

    this.browseUserDialogRef = this._dialog.open(
      BrowseUsersDialogComponent,
      {
       minWidth: '450px',
        data: {         
        },
        disableClose: false
      }
    );

    this.browseUserDialogRef.afterClosed().subscribe(users => {
      if (users) {
        
        for(let user of users) {

          if(!this.userExist(user)) { 
            this.users.push(user);
          }

        }

      }
    });
  }


}
