import { Component, Input, OnInit } from '@angular/core';

import { Activity } from './activity';

@Component({
  selector: 'app-activity-block',
  templateUrl: './activity-block.component.html',
  styleUrls: ['./activity-block.component.scss']
})
export class ActivityBlockComponent implements OnInit {

  @Input() type: string;        
  @Input() level: string;       // platform | interest | wg
  @Input() data: Activity;

  constructor() { }

  ngOnInit(): void {
  }


}
