import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { HttpOptionsService } from './http-options.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UserinfoService {

  userInfo = null;
  
  loggedIn: boolean;
  loggedIn$ = new BehaviorSubject<boolean>(false); //(this.loggedIn);

  constructor(
    private _http: HttpClient,
    private _httpOptionsService: HttpOptionsService,
    private _config: ConfigService
  ) { 
  }

  setLocalInfo(userInfo) {
    this.userInfo = {};
    this.userInfo = userInfo;
  }

  getUserLang() {
    return this.userInfo['lang'] ? this.userInfo['lang'] : 'en';
  }

  getLocalInfo() {
    return this.userInfo;
  }

  getUserInfo() {
    return this._http.get(this._config.getApiUrl() + '/api/user/info', this._httpOptionsService.getHeader())
  }

  getNotifications() {
    return this._http.get(this._config.getApiUrl() + '/api/user/notifications', this._httpOptionsService.getHeader())
  }

  getAllNotifications() {
    return this._http.get(this._config.getApiUrl() + '/api/user/all/notifications', this._httpOptionsService.getHeader())
  }

  markNotificationAsRead(id: string) {
    return this._http.put(this._config.getApiUrl() + '/api/user/mark/notification/' + id, {}, this._httpOptionsService.getHeader())
  }

  saveNewUserAdmin(userInfo) {
    return this._http.post(this._config.getApiUrl() + '/api/user', userInfo, this._httpOptionsService.getHeader())
  }
  
  setLoggedIn(value: boolean) {

    if(value != this.loggedIn) {    
      this.loggedIn$.next(value);
      this.loggedIn = value;
    }

  }

  setDefaultLang(lang: string) {
    return this._http.put(this._config.getApiUrl() + '/api/user/lang', {
      lang: lang
    }, this._httpOptionsService.getHeader())
  }

  sendRequestNotification(levelId: string, message: string, loggedIn: boolean) {

    let url = '/api/user/req/notification';
    if(!loggedIn) {
      url = '/api/user/req/notification/guest';
    }

    return this._http.post(this._config.getApiUrl() + url, {
      level_id: levelId,
      message: message,
      active: loggedIn
    }, this._httpOptionsService.getHeader())
  }

  respondToMembershipRequest(notificationId: string, value: boolean) {
    
    return this._http.put(this._config.getApiUrl() + '/api/user/req/notification/response', {
      notification_id: notificationId,
      value: value
    }, this._httpOptionsService.getHeader())

  }

  finishProcessOfRequestNotification(notificationId: string) {
    return this._http.put(this._config.getApiUrl() + '/api/user/req/notification/finish/' + notificationId, {}, this._httpOptionsService.getHeader())
  }

  hideShowSidebar(status: boolean) {
    return this._http.put(this._config.getApiUrl() + '/api/user/hide/show/sidebar', {
      status: status
    }, this._httpOptionsService.getHeader())
  }

  getLoggedIn() {
    return this.loggedIn;
  } 

}
