<h2 class="text-muted">Comments ({{ _comments.length}})</h2>

<ng-container *ngIf="_comments.length > 0; else noCommentsTemplate">
    

    <ng-container *ngFor="let c of _comments">

        <div class="ms-lg-2 p-1 my-2">

            <div class="d-flex align-items-center flex-wrap my-3">
                <div class="me-2">
                    <app-user-avatar width="25" fontSize="12px" [info]="c.user_id">
                    </app-user-avatar>
                </div>

                
                <div class="me-4 fw-bold">
                    {{ c.user_id.full_name | truncate : 45 }}
                </div>
                <div class="text-muted " matTooltip="{{ c.date_created | date: 'medium' }}">
                    {{ c.date_created | myDate }}
                </div>

                
                <ng-container *ngIf="commentRemoved">
                    <ng-container *ngIf="c.isOwner">
                        <button type="button" (click)="deleteComment(c._id)" mat-raised-button class="ms-auto">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </ng-container>
                </ng-container>
               
            </div>

            <div class="ck-content" [innerHTML]="c.message | sanitizeHtml"></div>

        </div>     

    </ng-container>


</ng-container>
<ng-template #noCommentsTemplate>
    No comments.
</ng-template>

