import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthentificationService } from '../services/authentification.service';
import { HttpOptionsService } from '../services/http-options.service';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  loading: boolean = true;

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  showError: boolean = false;
  showErrorMessage: String = '';
  requestNotFound: boolean = false;

  id: any = null;
  returnUrl: string;
  page: string;

  done: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _fb: UntypedFormBuilder,
    private _httpOptionsService: HttpOptionsService,
    private _authenticationService: AuthentificationService,
    private _snackBar: MatSnackBar,
    private _titleService: Title,
    private _layoutService: LayoutService
  ) {
  }

  ngOnInit() {

    if(this._route.snapshot.data.hideHeader) {

      // Set timeout because NG0100 error
      setTimeout(()=> {
        this._layoutService.hideToolbar();
      }, 0)
      
    }  

    this._titleService.setTitle('Forgot');
    this.id = this._route.snapshot.paramMap.get('id');

    if(!this.id) {
      this.createForm()
    }
    else {

      this._authenticationService.checkForgotId(this.id).subscribe((result: any) => {

        this.createForm(this.id);

        if(!result.success) {
          this.requestNotFound = true;
        }

      }, err => {

        let errorText = (err.status == 422) ? 'Form is incorrect' : err.statusText;

        this._snackBar.open(errorText, '', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });

      });

      
    }
  }

  createForm(forgotLink?: string) {
    this.loading = false;

    if(!forgotLink) {
      this.mainForm = this._fb.group({
        email: ['', [Validators.required, Validators.email]]
      });
    }
    else {
      this.mainForm = this._fb.group({
        password: ['', [Validators.required, Validators.minLength(8)]],
        password2: ['', [Validators.required]]
      });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    this.showError = false;

    if (this.mainForm.status != "INVALID") {

      if(this.id) {
        if(this.mainForm.value.password != this.mainForm.value.password2) {
          this.showError = true;
          this.showErrorMessage = 'Passwords don\'t match';
          return false;
        }
      }      

      let action = 
        (!this.id) ?  
          this._authenticationService.forgot(this.mainForm.value.email) :
          this._authenticationService.resetPassword(this.id, this.mainForm.value.password);

      action.subscribe((result: any) => {

        if (result.success) {
          this.done = true;
        }
        else {
          this.showError = true;
          this.showErrorMessage = result.message;
        }

      }, err => {

        if (err.status != 200) {

          let errorText = (err.status == 422) ? 'Form is incorrect' : err.statusText;

          this._snackBar.open(errorText, '', {
            duration: 4000,
            panelClass: ['error-snackbar']
          });
        }
      });

    }
    else {

      this.showError = true;
      this.showErrorMessage = 'Please enter your data';

      /*
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
      });*/
    }

  }

}
