<div class="h-100">
    <div class="w-100 h-100">
            <div class="container h-100">
                <div class="row h-100">

                    <div class="col-12 d-flex justify-content-center align-items-center">
                       

                        <div style="width: 500px;">

                            <ng-container *ngIf="loading">
                                <div class="d-flex align-items-center justify-content-center">
                                    <mat-spinner></mat-spinner>
                                </div>
                            </ng-container>

                            <mat-card *ngIf="!loading">

                                <h1 class="mat-h1">{{ 'Email validation failed' | translate }}</h1>

                                <div *ngIf="showError" class="alert alert-danger">
                                    {{ showErroMessage | translate }}
                                </div>

                            </mat-card>

                        </div>

                    </div>
                </div>
            </div>
    </div>
</div>