import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

import { AuthentificationService } from '../../services/authentification.service';
import { HttpOptionsService }      from '../../services/http-options.service';
import { UserinfoService } from '../../services/userinfo.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-show-login',
  templateUrl: './show-login.component.html',
  styleUrls: ['./show-login.component.scss']
})
export class ShowLoginComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  showError: boolean = false;
  showErroMessage: String = '';

  user: SocialUser;

  returnUrl: string;
  requestMembership: string;
  page: string;

  constructor(
    private permissionsService: NgxPermissionsService,
    private _userinfoService: UserinfoService,    
    private _route: ActivatedRoute,
    private _router: Router,
    private _fb: UntypedFormBuilder, 
    private _cookieService: CookieService,
    private _httpOptionsService: HttpOptionsService,
    private _authenticationService: AuthentificationService,
    private _snackBar: MatSnackBar,
    private _titleService: Title,
    private _authService: SocialAuthService,
  ) { 
  }

  ngOnInit() {

    this.subscription = this._authService.authState.subscribe((user) => {
      this.user = user;

      if(this.user) {

        if(this.user.provider == 'GOOGLE') {

          
          this._authenticationService.loginGoogle(this.user.email, this.user.firstName, this.user.lastName, this.user.photoUrl).subscribe((result: any) => {  

            this.login(result);

          }, err => {  

            if(err.status != 200) {
              this.showError = true;
              this.showErroMessage = err.error.message;
            }

          });
          
        }
        else {

          this._authenticationService.loginFacebook(this.user.email, this.user.firstName, this.user.lastName, this.user.photoUrl).subscribe((result: any) => {  

            this.login(result);

          }, err => {  

            if(err.status != 200) {
              this.showError = true;
              this.showErroMessage = err.error.message;
            }

          });

        }

      }

    });


    this.createForm();
    
    this._titleService.setTitle('Login');
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
        
    /**
     * Request memberhip - we store ID into cookie
     */
    this.requestMembership = this._route.snapshot.queryParams['requestMembership'];

    /**
     * If we find request, then we add to cookie
     */
    if(this.requestMembership) {
      let expiredDate = new Date();
      expiredDate.setMinutes(expiredDate.getMinutes() + 10);  // 10 minutes
      this._cookieService.set('reqMemId', this.requestMembership, expiredDate, '/', '', true, 'Strict');
    }
  }

  createForm() {
    this.mainForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]      
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this.showError = false;

      this._authenticationService.login(this.mainForm.value.email, this.mainForm.value.password).subscribe((result: any) => {  

        this.login(result);
      
      }, err => {  

        if(err.error && err.error.not_validate_hint) {
          //this.another_email_validate_link = true;
        }

        if(err.status != 200) {

          this.showError = true;

          if(err.status == 422) {
            this.showErroMessage = err.error.errors[0].msg;
          }
          else if(err.status == 429) {
            this.showErroMessage = err.error;
          }
          else {
            this.showErroMessage = err.error.message;        
          }
        }
      });

    }
    else {

      this.showError = true;
      this.showErroMessage = 'Please enter your data';

    }
    
  }

  signInWithGoogle(): void {
    this._authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this._authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  private login(result: any): void {

    if(!result.token) {
      this._router.navigate(['register/' + result.data]);
    }
    else {

      let expiredDate = new Date();
      expiredDate.setDate( expiredDate.getDate() + 30 );

      // if safari browser
      if (navigator.userAgent.indexOf('Safari') != -1) {
        this._cookieService.set('jwtEx', result.token, 30, '/'); //, '', true, 'Strict');
      } else {
        this._cookieService.set('jwt', result.token, expiredDate, '/', '', true, 'Strict');
      }

      
      this._httpOptionsService.refreshHeader();

      let user = result.user;

      this._userinfoService.setLocalInfo(user);

      if (user.role == 0) {
        this.permissionsService.addPermission('ADMIN');
      }
      else if (user.role == 1) {
        this.permissionsService.addPermission('SUPPORT');
      }
      else if (user.role == 2) {
        this.permissionsService.addPermission('CUSTOMER');
      }

      if(this._cookieService.get('reqMemId')) {
        
        /**
         * We get the user request. Then we finalize it and then redirect to the right level
         */ 
        this._userinfoService.finishProcessOfRequestNotification(this._cookieService.get('reqMemId')).subscribe((result: any) => {

          if(result && result.success) {
            this._router.navigate([result.data], { queryParams: { requestMembership: this._cookieService.get('reqMemId') }});
          }
          else {
            this.defaultNavigate();
          }

        }, err => {

          if (err.status != 200) {
            this.defaultNavigate();
          }
        });

      }      
      else {

        /** We check if cookie is set */
        if(this._cookieService.get('jwtEx')) {
          this.defaultNavigate();
        }

      }     

    }

  }

  defaultNavigate() {
    if(this._cookieService.get('reqMemId')) {
      this._cookieService.delete('reqMemId');
    }

    if(this._cookieService.get('newMemberId')) {
      let link = this._cookieService.get('newMemberId');
      link.replace(/%2F/g, '/');

      this._cookieService.delete('newMemberId');
      this._router.navigate([link]);      
    }
    else {

      if(this.returnUrl && this.returnUrl != '/')
        this._router.navigate([this.returnUrl]);
      else
        this._router.navigate(['home']);

    }
    
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
