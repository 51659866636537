<div *ngIf="!loading" class="my-2">

    <ng-container *ngIf="!_topic; else showTopic">

        <mat-toolbar class="d-flex align-items-center my-4">

            <h1 class="mat-h1 mb-0">{{'Discussions' | translate}}</h1>

            <button mat-raised-button (click)="addDiscussion()" class="ms-auto">
                <span class="material-icons">
                    add
                </span> {{'Add thread' | translate}}                                                     
            </button>

        </mat-toolbar>


        <div class="my-2">
            <ng-container *ngIf="topics.length == 0">
                <div class="mx-2">
                    {{'No discussions' | translate}}
                </div>
            </ng-container>
            <ng-container *ngIf="topics.length > 0">
                
                <ng-container *ngFor="let t of topics">
                    <mat-card class="mat-elevation-z0 pointer" routerLink="{{ url }}/{{ t._id }}">
                        <mat-card-header>                       
                            <div mat-card-avatar>
                                <app-user-avatar width="40" fontSize="12px" [info]="t.user_id"></app-user-avatar>
                            </div>

                            <mat-card-title>{{ t.topic_name }}</mat-card-title>
                            <mat-card-subtitle class="d-flex flex-wrap mat-caption mt-1">
                                <div class="text-primary me-3">{{ t.user_id.full_name }}</div>
                                <div class="me-3" matTooltip="{{ t.date_created | date: 'medium' }}">{{ t.date_created | myDate: "shortTime" }}</div>
                                <div>{{ t.number_of_comments }} {{'comment(s)' | translate}} </div>
                            </mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </ng-container>

            </ng-container>
        </div>

    </ng-container>

    <ng-template #showTopic>

        <ng-container *ngIf="!topicData">
            <h4>{{'Topic not found.' | translate}}</h4>

            <button mat-raised-button type="button" (click)="backButton()">{{'Back to discussion' | translate}}</button>
        </ng-container>
        <ng-container *ngIf="topicData">


            <ng-container *ngIf="!topicData.topic_id; else topicExist">
                <h4>{{'Topic not found.' | translate}}</h4>

                <button mat-raised-button type="button" (click)="backButton()">{{'Back to discussion' | translate}}</button>
            </ng-container>

            <ng-template #topicExist>

                
                <button mat-raised-button (click)="backButton()" class="mb-3">
                    <mat-icon>chevron_left</mat-icon>
                    {{'Back' | translate}}
                </button>

                <div class="mat-elevation-z2">
                    <mat-toolbar class="py-2" style="height: auto;">
                        <div class="text-wrap flex-fill">{{ topicData.topic_id.topic_name }}</div>

                        <div class="ms-md-auto" *ngIf="topicData.can_edit_topic || topicData.can_delete_topic">

                            <button mat-button [matMenuTriggerFor]="menu">
                                <span class="material-icons">
                                    more_vert
                                </span>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="editTopic()" *ngIf="topicData.can_edit_topic">
                                    <mat-icon>create</mat-icon>
                                    <span>{{'Edit' | translate}}</span>
                                </button>                                
                                <button mat-menu-item (click)="deleteTopic(topicData.topic_id._id)" *ngIf="topicData.can_delete_topic">
                                    <mat-icon>delete</mat-icon>
                                    <span>{{'Delete' | translate}}</span>
                                </button>
                            </mat-menu>


                            <!--
                            <ng-container *ngIf="topicData.can_edit_topic">
                                <button mat-raised-button class="small-btn me-2" (click)="editTopic()">
                                    <mat-icon>create</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="topicData.can_delete_topic">
                                <button mat-raised-button class="small-btn me-2" (click)="deleteTopic(topicData.topic_id._id)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </ng-container>-->
                        </div>
                    </mat-toolbar>

                    <mat-card class="mat-elevation-z0 align-items-center">
                        <mat-card-header>                            
                            <div mat-card-avatar>
                                <app-user-avatar width="40" fontSize="12px" [info]="topicData.user_id"></app-user-avatar>
                            </div>
                            <mat-card-title>{{ topicData.user_id.full_name }}</mat-card-title>
                            <mat-card-subtitle class="d-flex" matTooltip="{{ topicData.date_created | date: 'medium' }}">
                                {{ topicData.date_created | myDate }}                            
                            </mat-card-subtitle>                        
                        </mat-card-header>
                        <mat-card-content class="my-1">
                            
                            <div class="ck-content" [innerHTML]="topicData.message | sanitizeHtml"></div>

                            <ng-container *ngIf="topicData.attachments.length > 0">

                                <div class="mt-3">
                                    <div class="mat-caption">
                                        {{'Attachment(s)' | translate}}:
                                    </div>

                                    <mat-list role="list">  
                                        <ng-container *ngFor="let a of topicData.attachments">
                                            <mat-list-item role="listitem">                                                
                                                
                                                <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                                                <div mat-line class="d-flex align-items-center">                            
                                                    <div style="max-width: 250px;" class="text-truncate">
                                                        {{a.original_name}} 
                                                    </div>                                                
                                                    <div class="ms-auto">
                                                        <button mat-button class="small-btn ms-auto" (click)="viewFile(a._id)" matTooltip="View"><mat-icon>visibility</mat-icon></button>
                                                        <button mat-button class="small-btn me-1" (click)="prepareDownload(a._id)" matTooltip="Download"><mat-icon>get_app</mat-icon></button>
                                                    </div>
                                                </div>

                                            </mat-list-item>
                                        </ng-container>
                                    </mat-list>
                                </div>

                            </ng-container>
                        </mat-card-content>
                        <mat-card-actions>
                            <div class="d-flex align-items-center">

                                
                                <button mat-button (click)="topicData.reply = true" class="text-capitalize">
                                    <mat-icon>reply</mat-icon> {{'reply' | translate}}
                                </button>
                            

                                <ng-container *ngIf="topicData.liked">
                                    <button mat-button color="primary" (click)="like(topicData._id)">
                                        <mat-icon>favorite</mat-icon> {{'Liked' | translate}}
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="!topicData.liked">
                                    <button mat-button color="primary" (click)="like(topicData._id)">
                                        <mat-icon>favorite_border</mat-icon> {{'Like' | translate}}
                                    </button>
                                </ng-container>

                                <div class="mat-caption pointer" matTooltip="{{'Show likes' | translate}}" (click)="showLikes(topicData.likes)">
                                    {{ topicData.likes_number }} {{'likes' | translate}}
                                </div>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </div>

                <!-- reply box -->
                <ng-container *ngIf="topicData.reply">
                    <div class="pt-4 px-4" style="background: #3e65f812;">

                        <div class="d-flex w-100 align-items-center mb-2">

                            <div class="d-flex align-items-center px-2">
                                <div class="me-2">
                                    <mat-icon>reply</mat-icon>
                                </div>
                                <div class="mat-cation">{{ topicData.user_id.full_name }}</div>
                            </div>

                            <div class="ms-auto">
                                <button mat-button type="button" (click)="topicData.reply = false;">
                                    <mat-icon>close</mat-icon>
                                </button>                                
                            </div>

                        </div>

                        <div class="my-3">
                            <mat-divider></mat-divider>
                        </div>

                        <app-add-comment [user]="user" [saved]="saved" [levelId]="id" hideIcon="true" (save)="addReply(topicData, $event)"></app-add-comment>

                        
                    </div>
                </ng-container>

                <!-- replies -->
                <ng-container *ngIf="topicData?.replies?.length > 0; else noreply">
                    
                    <ul class="list-unstyled mt-2 mb-5" style="margin-left: 50px; margin-bottom: 80px;">
                        <li *ngFor="let reply of topicData.replies">
                            <mat-card [attr.id]="'comment-' + reply._id" class="mb-2 d-flex align-items-center" style="background: #3e65f812">

                                <div class="me-2">
                                    <mat-icon>reply</mat-icon>
                                </div>

                                <div class="flex-grow-1">
                                    <div class="d-flex align-items-center">

                                        
                                    
                                        <div class="me-3">
                                            <app-user-avatar width="40" fontSize="12px" [info]="reply.user_id"></app-user-avatar>
                                        </div>
                                        

                                        <div class="d-flex align-items-center">
                                            <div class="mat-h3 mb-0 me-3">{{ reply.user_id.full_name }}</div>
                                            
                                            <div class="mat-caption" matTooltip="{{ reply.date_created | date: 'medium' }}">{{  reply.date_created | myDate }}</div>
                                        </div>
                                        
                                        <div class="ms-auto">
                                            <ng-container *ngIf="!reply.deleted; else replyNotDeleted1">
                                                <ng-container *ngIf="reply.can_edit_comment">
                                                    <button mat-button class="small-btn" (click)="editComment(reply)">
                                                        <mat-icon>create</mat-icon>
                                                    </button>
                                                </ng-container>
                                                <ng-container *ngIf="reply.can_delete_comment">
                                                    <button mat-button class="small-btn" (click)="deleteComment(reply._id)">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #replyNotDeleted1>
                                                <ng-container *ngIf="role == 0 || role == 1">
                                                    <button mat-button class="small-btn" (click)="reply.showComment = !reply.showComment;">
                                                        <mat-icon>visibility</mat-icon>
                                                    </button>
                                                    <button mat-button class="small-btn" (click)="deleteComment(reply._id, true)">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </ng-container>
                                            </ng-template>
                                        </div>
                                    </div>
                                   
                                    <div class="mt-3">
                                        <ng-container *ngIf="!reply.deleted || reply.showComment; else deletedReply1">
                                            <div class="ck-content" [innerHTML]="reply.message | sanitizeHtml"></div>
                                        </ng-container>
                                        <ng-template #deletedReply1>
                                            <div class="fst-italic text-gray-600">{{'Comment deleted' | translate}}</div>
                                        </ng-template>
                                    </div>
                                     

                                    <!-- attachments -->
                                    <ng-container *ngIf="!reply.deleted && reply.attachments.length > 0">

                                        <div class="mt-3">
                                            <div class="mat-caption">
                                                {{'Attachment(s)' | translate}}:
                                            </div>
        
                                            <mat-list role="list">  
                                                <ng-container *ngFor="let a of reply.attachments">
                                                    <mat-list-item role="listitem">                                                
                                                        
                                                        <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                                                        <div mat-line class="d-flex align-items-center">                            
                                                            <div style="max-width: 250px;" class="text-truncate">
                                                                {{a.original_name}} 
                                                            </div>                                                
                                                            <!--<div class="text-muted mx-2 mat-caption">(8mb)</div>-->
                                                            <div class="ms-auto">
                                                                <button mat-button class="small-btn ms-auto" (click)="viewFile(a._id)" matTooltip="View"><mat-icon>visibility</mat-icon></button>
                                                                <button mat-button class="small-btn me-1" (click)="prepareDownload(a._id)" matTooltip="Download"><mat-icon>get_app</mat-icon></button>
                                                            </div>
                                                        </div>
        
                                                    </mat-list-item>
                                                </ng-container>
                                            </mat-list>
                                        </div>
        
                                    </ng-container>

                                </div>
                            </mat-card>
                           
                        </li>
                    </ul>

                </ng-container>

                <ng-template #noreply>
                    <div style="height: 20px;"></div>
                </ng-template>

                <ng-container *ngIf="shownComments.length > 0">

                    <div class="text-center my-5" style="border-left: 1px dotted #ccc;" *ngIf="commentsLeft > 0">
                        
                        <div class="mat-caption text-muted mb-1">{{ commentsLeft }} comment(s)</div>

                        <button mat-stroked-button class="mb-2" (click)="loadComments()">
                            <mat-icon>sync</mat-icon>
                            Load more comments
                        </button>


                        <div style="height: 20px;"></div>

                    </div>

                    <ng-container *ngFor="let d of shownComments">
                        <mat-card [attr.id]="'comment-' + d._id" class="mb-2 align-items-center" [ngClass]="{ 'active-comment' : d.active }"><!-- .ms-5 -->
                            <mat-card-header>
                                
                                <div mat-card-avatar>
                                    <app-user-avatar width="40" fontSize="12px" [info]="d.user_id"></app-user-avatar>
                                </div>
                                
                                <mat-card-title>{{ d.user_id.full_name }}</mat-card-title>
                                <mat-card-subtitle class="d-flex" matTooltip="{{ d.date_created | date: 'medium' }}">
                                    {{  d.date_created | myDate }}
                                </mat-card-subtitle>
                                <div class="ms-auto">
                                    <ng-container *ngIf="!d.deleted; else notDeleted">
                                        <ng-container *ngIf="d.can_edit_comment">
                                            <button mat-button class="small-btn" (click)="editComment(d)">
                                                <mat-icon>create</mat-icon>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="d.can_delete_comment">
                                            <button mat-button class="small-btn" (click)="deleteComment(d._id)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #notDeleted>
                                        <ng-container *ngIf="role == 0 || role == 1">
                                            <button mat-button class="small-btn" (click)="d.showComment = !d.showComment;">
                                                <mat-icon>visibility</mat-icon>
                                            </button>
                                            <button mat-button class="small-btn" (click)="deleteComment(d._id, true)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </ng-container>
                                    </ng-template>
                                </div>
                            </mat-card-header>
                            <mat-card-content class="my-1">
                                <ng-container *ngIf="!d.deleted || d.showComment; else deletedComment">
                                    <div class="ck-content" [innerHTML]="d.message | sanitizeHtml"></div>
                                </ng-container>
                                <ng-template #deletedComment>
                                    <div class="fst-italic text-gray-600">{{'Comment deleted' | translate}}</div>
                                </ng-template>

                                <ng-container *ngIf="d.attachments.length > 0">

                                    <div class="mt-3">
                                        <div class="mat-caption">
                                            {{'Attachment(s)' | translate}}:
                                        </div>

                                        <mat-list role="list">  
                                            <ng-container *ngFor="let a of d.attachments">
                                                <mat-list-item role="listitem">                                                
                                                    
                                                    <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                                                    <div mat-line class="d-flex align-items-center">                            
                                                        <div style="max-width: 250px;" class="text-truncate">
                                                            {{a.original_name}} 
                                                        </div>                                                
                                                        <!--<div class="text-muted mx-2 mat-caption">(8mb)</div>-->
                                                        <div class="ms-auto">
                                                            <button mat-button class="small-btn ms-auto" (click)="viewFile(a._id)" matTooltip="View"><mat-icon>visibility</mat-icon></button>
                                                            <button mat-button class="small-btn me-1" (click)="prepareDownload(a._id)" matTooltip="Download"><mat-icon>get_app</mat-icon></button>
                                                        </div>
                                                    </div>

                                                </mat-list-item>
                                            </ng-container>
                                        </mat-list>
                                    </div>

                                </ng-container>
                            </mat-card-content>
                            <mat-card-actions> 
                                <ng-container *ngIf="!d.deleted">
                                    <div class="d-flex align-items-center">

                                        <button mat-button (click)="d.reply = true;" class="text-capitalize">
                                            <mat-icon>reply</mat-icon> {{'reply' | translate}}
                                        </button>
                                
                                        <ng-container *ngIf="d.liked">
                                            <button mat-button color="primary" (click)="like(d._id)">
                                                <mat-icon>favorite</mat-icon> {{'Liked' | translate}}
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="!d.liked">
                                            <button mat-button color="primary" (click)="like(d._id)">
                                                <mat-icon>favorite_border</mat-icon> {{'Like' | translate}}
                                            </button>
                                        </ng-container>

                                        <div class="mat-caption pointer" matTooltip="{{'Show likes' | translate}}" (click)="showLikes(d.likes)">
                                            {{ d.likes_number }} {{'likes' | translate}}
                                        </div>
                                    </div>                           
                                </ng-container>                                  
                            </mat-card-actions>
                        </mat-card>


                        <!-- reply box -->
                        <ng-container *ngIf="d.reply">

                            <div class="pt-4 px-4 ms-5" style="background: #3e65f812;">

                                <div class="d-flex w-100 align-items-center mb-2">
        
                                    <div class="d-flex align-items-center px-2">
                                        <div class="me-2">
                                            <mat-icon>reply</mat-icon>
                                        </div>
                                        <div class="mat-cation">{{ d.user_id.full_name }}</div>
                                    </div>
        
                                    <div class="ms-auto">
                                        <button mat-button type="button" (click)="d.reply = false;">
                                            <mat-icon>close</mat-icon>
                                        </button>                                
                                    </div>
        
                                </div>
        
                                <div class="my-3">
                                    <mat-divider></mat-divider>
                                </div>
        
                                <app-add-comment [user]="user" [saved]="saved" [levelId]="id" hideIcon="true" (save)="addReply(d, $event)"></app-add-comment>
        
                                
                            </div>

                        </ng-container>

                        

                        <!-- replies -->
                        <ng-container *ngIf="d?.replies?.length > 0; else noreply1">
                            
                            <ul class="list-unstyled mt-2 reply-comment">
                                <li *ngFor="let reply of d.replies">
                                    <mat-card class="mb-2 d-flex align-items-center" style="background: #3e65f812">

                                        <div class="me-3">
                                            <mat-icon>reply</mat-icon>
                                        </div>

                                        <div class="flex-grow-1">
                                            <div class="d-flex flex-wrap">

                                                
                                            
                                                <div class="me-2">
                                                    <app-user-avatar width="40" fontSize="12px" [info]="reply.user_id"></app-user-avatar>
                                                </div>
                                                

                                                <div>
                                                    <div class="mat-h3 mb-0 me-3 fw-boldest">{{ reply.user_id.full_name }}</div>
                                                    
                                                    <div class="mat-caption text-gray-600 fw-bold">{{ reply.date_created | myDate }}</div>
                                                </div>
                                                
                                                <div class="ms-auto">

                                                    <ng-container *ngIf="!reply.deleted; else replyNotDeleted2">
                                                        <button mat-button [matMenuTriggerFor]="menu">
                                                            <span class="material-icons">
                                                                more_vert
                                                            </span>
                                                        </button>
                                                        <mat-menu #menu="matMenu">
                                                            <button mat-menu-item (click)="editComment(reply)" *ngIf="reply.can_edit_comment">
                                                                <mat-icon>create</mat-icon>
                                                                <span>{{'Edit' | translate}}</span>
                                                            </button>                                
                                                            <button mat-menu-item (click)="deleteComment(reply._id)" *ngIf="reply.can_delete_comment">
                                                                <mat-icon>delete</mat-icon>
                                                                <span>{{'Delete' | translate}}</span>
                                                            </button>
                                                        </mat-menu>
                                                    </ng-container>
                                                    <ng-template #replyNotDeleted2>
                                                        <ng-container *ngIf="role == 0 || role == 1">
                                                            <button mat-button class="small-btn" (click)="reply.showComment = !reply.showComment;">
                                                                <mat-icon>visibility</mat-icon>
                                                            </button>
                                                            <button mat-button class="small-btn" (click)="deleteComment(reply._id, true)">
                                                                <mat-icon>delete</mat-icon>
                                                            </button>
                                                        </ng-container>
                                                    </ng-template>
                                                    
                                                </div>
                                            </div>
                                        
                                            <div class="mt-3">
                                                <ng-container *ngIf="!reply.deleted || reply.showComment; else deletedReply2">
                                                    <div class="ck-content" [innerHTML]="reply.message | sanitizeHtml"></div>
                                                </ng-container>
                                                <ng-template #deletedReply2>
                                                    <div class="fst-italic text-gray-600">{{'Comment deleted' | translate}}</div>
                                                </ng-template>
                                            </div>
                                            
                                            <!-- attachments -->
                                            <ng-container *ngIf="reply.attachments.length > 0">

                                                <div class="mt-3">
                                                    <div class="mat-caption">
                                                        {{'Attachment(s)' | translate}}:
                                                    </div>
                
                                                    <mat-list role="list">  
                                                        <ng-container *ngFor="let a of reply.attachments">
                                                            <mat-list-item role="listitem">                                                
                                                                
                                                                <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                                                                <div mat-line class="d-flex align-items-center">                            
                                                                    <div style="max-width: 250px;" class="text-truncate">
                                                                        {{a.original_name}} 
                                                                    </div>                                                
                                                                    <!--<div class="text-muted mx-2 mat-caption">(8mb)</div>-->
                                                                    <div class="ms-auto">
                                                                        <button mat-button class="small-btn ms-auto" (click)="viewFile(a._id)" matTooltip="View"><mat-icon>visibility</mat-icon></button>
                                                                        <button mat-button class="small-btn me-1" (click)="prepareDownload(a._id)" matTooltip="Download"><mat-icon>get_app</mat-icon></button>
                                                                    </div>
                                                                </div>
                
                                                            </mat-list-item>
                                                        </ng-container>
                                                    </mat-list>
                                                </div>
                
                                            </ng-container>

                                        </div>
                                                            
                                    </mat-card>
                                </li>
                            </ul>

                        </ng-container>

                        <ng-template #noreply1>
                            <div style="height: 20px;"></div>
                        </ng-template>
        
                        <!--
                        <div class="my-2">
                            <mat-divider></mat-divider>
                        </div>-->
                    </ng-container>
                </ng-container>


                <div class="my-4 mx-2">
                    <app-add-comment [user]="user" [saved]="saved" [levelId]="id" (save)="onCommentSave($event)"></app-add-comment>
                </div>

            </ng-template>

        </ng-container>

    </ng-template>

</div>