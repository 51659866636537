import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  addEvent(
    levelId: string, 
    title: string, 
    description: string, 
    startDate: Date, 
    //startHour: string, 
    //startMinutes: string, 
    endDate: Date, 
    //endHour: string, 
    //endMinutes: string, 
    allDay?: boolean) {

    return this._http.post(this._config.getApiUrl() + '/api/events', {    
      level_id: levelId,
      title: title,
      description: description,
      start_date: startDate,
      //start_hour: startHour,
      //start_minutes: startMinutes,
      end_date: endDate,
      //end_hour: endHour,
      //end_minutes: endMinutes,
      all_day: allDay
    }, this._httpOptionsService.getHeader())

  }

  editEvents(
    eventId: string, 
    levelId: string, 
    title: string, 
    description: string, 
    startDate: Date, 
    //startHour: string, 
    //startMinutes: string, 
    endDate: Date, 
    //endHour: string, 
    //endMinutes: string, 
    allDay?: boolean) {

    return this._http.put(this._config.getApiUrl() + '/api/events/' + eventId, {    
      level_id: levelId,
      title: title,
      description: description,
      start_date: startDate,
      //start_hour: startHour,
      //start_minutes: startMinutes,
      end_date: endDate,
      //end_hour: endHour,
      //end_minutes: endMinutes,
      all_day: allDay
    }, this._httpOptionsService.getHeader())

  }


  removeEvents(eventId: string, levelId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/events/remove/' + levelId + '/' + eventId, this._httpOptionsService.getHeader())
  }

  deleteEvents(eventId: string, levelId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/events/' + levelId + '/' + eventId, this._httpOptionsService.getHeader())
  }

  getSpecificEvent(eventId: string, levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/events/specific/' + levelId + '/' + eventId, this._httpOptionsService.getHeader())
  }

  getFutureEvents(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/events/future/' + levelId, this._httpOptionsService.getHeader())
  }

  getPastEvents(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/events/past/' + levelId, this._httpOptionsService.getHeader())
  }

  getEvents(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/events/all/' + levelId, this._httpOptionsService.getHeader())
  }

  getUserEvents() {
    return this._http.get(this._config.getApiUrl() + '/api/events/user', this._httpOptionsService.getHeader())
  }

  getUserEventsThisMonth() {
    return this._http.get(this._config.getApiUrl() + '/api/events/user/month', this._httpOptionsService.getHeader())
  }

  getAvailableEvents(levelId: string, targetLevelId: string, root?: boolean) {
    return this._http.get(this._config.getApiUrl() + '/api/events/available/' + levelId + '/' + targetLevelId + '/' + root, this._httpOptionsService.getHeader())
  }

  importEvents(levelId: string, events: any[]) {
    return this._http.post(this._config.getApiUrl() + '/api/events/import', {    
      level_id: levelId,
      events: events
    }, this._httpOptionsService.getHeader())
  }
}
