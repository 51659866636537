<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

    <div class="d-flex align-items-center mb-2">
        <h2 mat-dialog-title class="d-flex align-items-center mb-0">
            <mat-icon>create</mat-icon>
            <div class="ms-2">Edit user</div>
        </h2>

        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content class="pb-2">

        <div *ngIf="userInfo.validation_request_link">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Validation link</mat-label>
                <input matInput disabled value="{{ '/validate/' + userInfo.validation_request_link }}">
            </mat-form-field>
        </div>

        <div>

            <mat-form-field appearance="outline" class="w-100">
                <mat-label>E-mail</mat-label>
                <input matInput formControlName="email">
            </mat-form-field>

        </div>


        <div>

            <mat-form-field appearance="outline" class="w-100">
                <mat-label>First name</mat-label>
                <input matInput formControlName="firstName">
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Last name</mat-label>
                <input matInput formControlName="lastName">
            </mat-form-field>

        </div>

        <div>

            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Password (min. length 8 characters)</mat-label>
                <input matInput formControlName="password">
            </mat-form-field>

        </div>

        <div>
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Select role</mat-label>
                <mat-select formControlName="role">
                    <mat-option value="0">Admin</mat-option>
                    <mat-option value="1">Support</mat-option>
                    <mat-option value="2">Customer</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Stop sending emails</mat-label>
                <mat-select formControlName="stopEmail">
                    <mat-option value="true">Stop sending</mat-option>
                    <mat-option value="false">Send emails</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
       
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button color="primary" class="me-2" type="submit">
            Update
        </button>
        <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
    </mat-dialog-actions>

</form>
