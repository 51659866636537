import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  _comments: any[];
  _user: any;

  @Input() set comments(comments: any[]) { this._comments = [...comments]; }
  @Input() set user(user: any) { this._user = user }

  @Output() commentRemoved: EventEmitter<string> = new EventEmitter<string>();

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(private _dialog: MatDialog) { }

  ngOnInit(): void {
  }

  // we send the commentId to the parent component
  deleteComment(commentId: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Delete this comment?',
          text: 'Are you sure that you want to delete this comment?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.commentRemoved?.emit(commentId);

      }
    });

  }

}
