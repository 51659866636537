<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

    <div class="d-flex align-items-center mb-2">
        <h2 mat-dialog-title class="d-flex align-items-center">
            <span class="material-icons">
                add
            </span> 
            {{'Create your Platform' | translate}}
        </h2>

        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content class="pb-2">

        <div class="mat-caption">{{'Create your own Platform and start your virtual collaboration' | translate}}.</div>

        <div style="height: 20px;"></div>

        <div class="d-flex">
            <div>
                <img src="/assets/default_avatar.jpg" />
            </div>
            <div class="flex-grow-1">
                <div class="d-flex">

                    <div class="flex-grow-1">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>
                                {{'Platform name' | translate}}
                            </mat-label>
                            <input matInput formControlName="name">
                        </mat-form-field>
                    </div>
            
                    <div class="ms-2 flex-grow-2">
                        <mat-form-field appearance="fill" class="w-100">                
                            <mat-label>
                                {{'URL' | translate}}
                            </mat-label>
                            <input matInput formControlName="slug" placeholder="https://exarca.io/{ URL }">
                        </mat-form-field>
                    </div>
        
                </div>
              
                <mat-form-field class="w-100">
                    <mat-label>{{"Platform's short description" | translate}}</mat-label>
                    <textarea matInput cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5" formControlName="description" [maxLength]="150"></textarea>
                    <mat-hint align="end">{{'Available characters' | translate}} {{f.description.value?.length || 0}}/150</mat-hint>
                </mat-form-field>

                <div class="mt-4">
                    <button mat-raised-button color="primary" class="me-2" type="submit">
            
                        <span class="material-icons">
                            add
                        </span> {{'Create' | translate}}
                                
                    </button>
                    <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
                </div>
            </div>
        </div>

        

    </mat-dialog-content>

   


</form>