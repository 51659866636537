<div [formGroup]="form">
    <div [ngSwitch]="question.controlType" [ngClass]="{ 'my-4' : question.space }">

        <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'textbox'">
            <mat-label [attr.for]="question.key">
                {{question.label}} <span style="color: red" *ngIf="question.required">*</span>
            </mat-label>
            <input matInput [formControlName]="question.key"
                    [id]="question.key" [type]="question.type">
        </mat-form-field>
  
        <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'dropdown'">
            <mat-label [attr.for]="question.key">
                {{question.label}} <span style="color: red" *ngIf="question.required">*</span>
            </mat-label>
            <mat-select [id]="question.key" [formControlName]="question.key" [multiple]="question.multiple">
                <mat-option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'textarea'">
            <mat-label [attr.for]="question.key">
                {{question.label}} <span style="color: red" *ngIf="question.required">*</span>
            </mat-label>
            <textarea matInput [formControlName]="question.key" [id]="question.key"></textarea>

        </mat-form-field>
  
    </div>
  
    <!--
    <div class="errorMessage mat-caption" *ngIf="!isValid">{{question.label}} is required</div>
    -->
  </div>