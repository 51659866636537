<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

    <div class="d-flex align-items-center mb-2">
        <h2 mat-dialog-title class="d-flex align-items-center mb-0">
            <span class="material-icons">
                add
            </span>

            <ng-container *ngIf="!isEditMode">
                {{ 'Add event' | translate }}
            </ng-container>
            <ng-container *ngIf="isEditMode">
                {{ 'Update event' | translate }}
            </ng-container>
        </h2>

        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content>

        <div class="row">

            <div class="col-12">



                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>
                        {{'Event name' | translate}}
                    </mat-label>
                    <input matInput formControlName="title">
                </mat-form-field>

                <div class="mb-3">
                    <mat-checkbox formControlName="all_day" (change)="allDayClick(f.all_day.value)">{{'All day' | translate}}
                    </mat-checkbox>
                </div>

                <div class="d-flex flex-wrap">

                    <div class="me-4">

                        <mat-form-field appearance="fill" class="me-4">
                            <mat-label>{{'Start date' | translate}}</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="start_date">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                      

                        <div class="mb-3" *ngIf="!f.all_day.value">

                            <mat-form-field appearance="fill" class="me-2">
                                <mat-label>{{'Hour' | translate}}</mat-label>
                                <mat-select formControlName="start_hour">
                                    <mat-option *ngFor="let hour of hours" [value]="hour">
                                        <ng-container *ngIf="(hour < 10)">0{{ hour }}</ng-container>
                                        <ng-container *ngIf="(hour > 9)">{{ hour }}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{'Minutes' | translate}}</mat-label>
                                <mat-select formControlName="start_minutes">
                                    <mat-option *ngFor="let minute of minutes" [value]="minute">
                                        <ng-container *ngIf="(minute < 10)">0{{ minute }}</ng-container>
                                        <ng-container *ngIf="(minute > 9)">{{ minute }}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>



                    </div>

                    <div>

                        <mat-form-field appearance="fill" class="me-4">
                            <mat-label>{{'End date' | translate}}</mat-label>
                            <input matInput [matDatepicker]="picker1" [min]="f.start_date.value" formControlName="end_date">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>

                        <div class="mb-3" *ngIf="!f.all_day.value">


                            <mat-form-field appearance="fill" class="me-2">
                                <mat-label>{{'Hour' | translate}}</mat-label>
                                <mat-select formControlName="end_hour">
                                    <mat-option *ngFor="let hour of hours" [value]="hour">
                                        <ng-container *ngIf="(hour < 10)">0{{ hour }}</ng-container>
                                        <ng-container *ngIf="(hour > 9)">{{ hour }}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>{{'Minutes' | translate}}</mat-label>
                                <mat-select formControlName="end_minutes">
                                    <mat-option *ngFor="let minute of minutes" [value]="minute">
                                        <ng-container *ngIf="(minute < 10)">0{{ minute }}</ng-container>
                                        <ng-container *ngIf="(minute > 9)">{{ minute }}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>

                </div>

            </div>

            <div class="col-12 event-editor">
                <div class="mat-caption mb-2">{{'Event description' | translate}} ({{'optional' | translate}})</div>
                
                <div class="editor-size">                  
                    <app-my-editor [levelid]="data.level_id" [data]="text" (output)="onCkEditorValue($event)"></app-my-editor>
                </div>


                <div style="height: 20px;">&nbsp;</div>
            </div>

        </div>



    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button color="primary" class="me-2" type="submit">
            <ng-container *ngIf="!data.current">
                <span class="material-icons">
                    add
                </span> {{'Add' | translate}}
            </ng-container>

            <ng-container *ngIf="data.current">
                {{'Update' | translate}}
            </ng-container>
        </button>
        <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
    </mat-dialog-actions>


</form>