import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LevelService } from 'src/app/services/level.service';

@Component({
  selector: 'app-sort-level-dialog',
  templateUrl: './sort-level-dialog.component.html',
  styleUrls: ['./sort-level-dialog.component.scss']
})
export class SortLevelDialogComponent implements OnInit {

  loading: boolean = true;
  levels: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private _levelService: LevelService,
    private _dialogRef: MatDialogRef<SortLevelDialogComponent>,
  ) { }

  ngOnInit(): void {

    this._levelService.getLevelChildren(this.data.level.parent_id, (this.data.level.sublevel_type) ? this.data.level.sublevel_type : 'null').subscribe((result: any) => {

      if(result.success) {

        this.levels = [...result.data];

      }

    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
      
    });


  }

  drop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.levels, event.previousIndex, event.currentIndex);

    for(let i = 0; i < this.levels.length; i++) {

      this.levels[i].group_sort = i;

    }

  }

  sortNumber(index: any, sort: any) {

    if(Number.isInteger(parseInt(sort))) {
      moveItemInArray(this.levels, index, sort);
    }
  }

  saveSortOrder() {

    this._levelService.saveSortOrder(this.data.level.parent_id, this.levels, (this.data.level.sublevel_type) ? this.data.level.sublevel_type : null).subscribe((result: any) => {

      if(result.success) {

        this._dialogRef.close(this.levels);

      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
      
    });

  }

}
