<div [hidden]="!showEditor">
    
    <!--<ckeditor [config]="config" [editor]="Editor" [(ngModel)]="text" (change)="onChange($event)"></ckeditor>-->

    <div #editorContainer>
        <div [innerHTML]="text | sanitizeHtml"></div>
    </div>

    <button mat-raised-button color="primary" class="mt-2" (click)="save(text)">
        <mat-icon>done</mat-icon>
    </button>
</div>
<div [hidden]="showEditor">
    <mat-form-field>
        <input matInput [(ngModel)]="text">
        <button mat-button matSuffix class="text-success" (click)="save(text)">
            <mat-icon>done</mat-icon>
        </button>
    </mat-form-field>
</div>