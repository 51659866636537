<div *ngIf="!loading" class="my-2">

    <!--
    <ng-container *ngIf="!folderPath && resultsLength != 0">      
      <button mat-button>        
        {{'ROOT FOLDER' | translate}}
      </button>
    </ng-container>-->

    <ng-container *ngIf="folderPath">

      <!--
      <div class="mat-caption text-upper">Root /
        <ng-container *ngFor="let f of folderPath">
          {{ f.original_name }} /
        </ng-container>
      </div>-->

      <!-- second option -->

      <button mat-button (click)="visitFolder(null)">
        {{'ROOT FOLDER' | translate}}
      </button>
      
      <div *ngFor="let f of folderPath; let i = index;" [ngStyle]="{ 'margin-left': ((20 * (i + 1)) + 'px') }">
        <div class="d-flex align-items-center">
          <mat-icon>subdirectory_arrow_right</mat-icon>
          <button mat-button (click)="visitFolder(f._id)">
          {{ f.original_name }}
          </button>
        </div>
      </div>


    </ng-container>

    <div *ngIf="resultsLength == 0" class="mt-4">
  
      <div class="mat-elevation-z0 w-100 mt-3 p-2 py-3">
        {{'No files' | translate}}.
      </div>
  
    </div>
  
    <div [hidden]="resultsLength == 0">

        <table mat-table matSort [dataSource]="pagesData" class="mat-elevation-z2 w-100 mt-3">

            <ng-container matColumnDef="original_name">
                <th mat-header-cell *matHeaderCellDef> {{'Name' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex flex-wrap align-items-center">
                      <ng-container *ngIf="!element.folder">
                        <div class="text-truncate" style="max-width: 280px;" matTooltip="{{element.original_name}}">{{element.original_name}}</div>
                        
                        <div class="ms-auto d-flex d-none d-md-block">
                          <button mat-button class="small-btn" (click)="viewFile(element._id)" matTooltip="View"><mat-icon>visibility</mat-icon></button>
                          <button mat-button class="small-btn" (click)="prepareDownload(element._id)" matTooltip="Download"><mat-icon>get_app</mat-icon></button>    
                        </div>
                      </ng-container>
                      <ng-container *ngIf="element.folder">
                        <div class="d-flex align-items-center pointer w-100" (click)="visitFolder(element._id)">
                          <mat-icon>folder</mat-icon>
                          <div class="text-truncate ms-2" style="max-width: 280px;" matTooltip="{{element.original_name}}">{{element.original_name}}</div>  
                        </div>
                      </ng-container>
                    </div>                    
                </td>
              </ng-container>

            <ng-container matColumnDef="date_created">
                <th mat-header-cell *matHeaderCellDef> {{'Created' | translate}} </th>
                <td mat-cell *matCellDef="let element" matTooltip="{{ element.date_created | date: 'medium' }}">{{ element.date_created | myDate }}</td>
              </ng-container>

              <ng-container matColumnDef="filesize">
                <th mat-header-cell *matHeaderCellDef> {{'Size' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="!element.folder">{{ element.filesize | filesize }}</ng-container>
                  <ng-container *ngIf="element.folder">/</ng-container>
                </td>
              </ng-container>

            <ng-container matColumnDef="author">
                <th mat-header-cell *matHeaderCellDef> {{'Author' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                  <app-user-avatar [info]="element.user_id"></app-user-avatar>                  
                </td>
              </ng-container>

            <ng-container matColumnDef="sort">
              <th mat-header-cell *matHeaderCellDef> {{'Sort' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button type="button" (click)="moveUp(element._id)">
                  <mat-icon>keyboard_arrow_up</mat-icon>
                </button>

                <button mat-icon-button type="button" (click)="moveDown(element._id)">
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> {{'Action' | translate}}</th>
                <td mat-cell *matCellDef="let element">

                   
                    <ng-container *ngIf="!element.folder">

                      <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">         
                        
                        <!-- mobile -->
                        <button class="d-md-none" mat-menu-item (click)="viewFile(element._id)">
                          <mat-icon>visibility</mat-icon>
                          <span>{{'View' | translate}}</span>
                        </button>                  
                        <button class="d-md-none" mat-menu-item (click)="prepareDownload(element._id)">
                          <mat-icon>get_app</mat-icon>
                          <span>{{'Download' | translate}}</span>
                        </button>                  

                        <!-- desktop -->
                        <button mat-menu-item (click)="deleteFile(element._id)">
                          <mat-icon>delete</mat-icon>
                          <span>{{'Delete file' | translate}}</span>
                        </button>
                      </mat-menu>

                    </ng-container>
                    <ng-container *ngIf="element.folder">

                      <button mat-icon-button [matMenuTriggerFor]="menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">  
                          <button mat-menu-item (click)="renameFolder(element._id, element.original_name)">
                            <mat-icon>create</mat-icon>
                            <span>{{'Rename folder' | translate}}</span>
                          </button>                  
                          <button mat-menu-item (click)="deleteFile(element._id)">
                            <mat-icon>delete</mat-icon>
                            <span>{{'Delete folder' | translate}}</span>
                          </button>
                        </mat-menu>

                    </ng-container>

                      
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>       

        
    
    </div>
</div>
<ng-container *ngIf="role != undefined">


  <div class="my-2 mt-4">

    <button mat-raised-button color="secondary" [matMenuTriggerFor]="filemenu"><mat-icon>add</mat-icon> {{'Add' | translate}}</button>
    <mat-menu #filemenu="matMenu">
      <button mat-menu-item (click)="addFile()"><mat-icon>insert_drive_file</mat-icon>{{'Add file' | translate}}</button>
      <button mat-menu-item (click)="addFolder()"><mat-icon>folder</mat-icon>{{'Add folder' | translate}}</button>
  </mat-menu>

  </div>
    
  <!--<app-upload-file [levelId]="id" (data)="newFile($event)"></app-upload-file>-->
</ng-container>
