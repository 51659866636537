<div class="d-flex align-items-center mb-2">
    <h2 mat-dialog-title class="d-flex align-items-center mb-0">
        <span class="material-icons">
            favorite
        </span> 
        People who liked
    </h2>

    <div class="ms-auto">
        <button mat-button color="secondary" [mat-dialog-close]="false">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content>    

    <ng-container *ngIf="data.likes && data.likes.length; else noLikes">

        <mat-list role="list">            
            <ng-container *ngFor="let user of data.likes">
                <mat-list-item role="listitem">
                    
                    <div mat-card-avatar class="me-3">
                        <app-user-avatar width="40" fontSize="12px" [info]="user"></app-user-avatar>
                    </div>
                    <div>{{ user.full_name }}</div>

                </mat-list-item>            
            </ng-container>
        </mat-list>

    </ng-container>

    <ng-template #noLikes>
        <mat-list-item role="listitem">{{'No likes' | translate}}</mat-list-item>        
    </ng-template>

</mat-dialog-content>

<mat-dialog-actions>    
    <button mat-raised-button [mat-dialog-close]="false">{{'Close'}}</button>
</mat-dialog-actions>