<div class="container">
    <div class="row">
        <div class="col py-8">

            <div class="bg-white w-100 mat-elevation-z2 p-2" style="overflow: hidden;">

                <div class="d-flex flex-nowrap w-100" style="overflow-x: scroll;">

                    <div class="me-3" style="min-width: 100px;" *ngFor="let l of [1, 2, 3, 4, 5, 6, 7, 8, 9]">
                        <a href="#">Link item {{ l }}</a>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>