import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { QuestionBase } from '../question-base';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss']
})
export class DynamicFormQuestionComponent {

  @Input() question: QuestionBase<string>;
  @Input() form: UntypedFormGroup;
  get isValid() { return this.form.controls[this.question.key].valid; }

}
