<ng-container *ngIf="!loading">

    <mat-toolbar class="d-flex align-items-center my-4">

        <h1 class="mat-h1 mb-0">{{'Events' | translate}}</h1>

        <ng-container *ngIf="role == 0 || role == 1">

            <!-- mobile-menu -->

            <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" class="ms-auto d-md-none">
                <span class="material-icons">
                    more_vert
                </span>
            </button>
            <mat-menu #menu="matMenu">

                <button mat-menu-item (click)="addEvent()">
                    <mat-icon>add</mat-icon>
                    <span>{{'Add event' | translate}}</span>
                </button>

                <button mat-menu-item (click)="browseEvents()">
                    <mat-icon>view_carousel</mat-icon>
                    <span>{{'Import' | translate}}</span>
                </button>

            </mat-menu>

            <!-- desktop-menu -->

            <button mat-raised-button (click)="addEvent()" class="ms-auto d-none d-md-inline">
                <div class="d-flex align-items-center">
                    <mat-icon>add</mat-icon> {{'Add event' | translate}}
                </div>
            </button>

            <button mat-raised-button (click)="browseEvents()" class="ms-2 d-none d-md-inline">
                <div class="d-flex align-items-center">
                    <mat-icon>view_carousel</mat-icon>
                    <div class="ms-1">
                        {{'Import' | translate}}
                    </div>
                </div>
            </button>
        </ng-container>

    </mat-toolbar>

    <div class="my-4">

        <div class="d-none d-sm-block">
            <button mat-button [color]="(_pageId) ? 'primary' : ''" (click)="navigatePage('/')">{{'Actual events' |
                translate}}</button><!--  ({{ upcomingEventsNumber }}) -->

            <button mat-button [color]="(_pageId != 'past') ? 'primary' : ''" (click)="navigatePage('past')">{{'Past
                events' | translate}}</button><!--  ({{ pastEventsNumber }}) -->

            <button mat-button [color]="(_pageId != 'calendar') ? 'primary' : ''"
                (click)="navigatePage('calendar')">{{'Calendar' | translate}}</button>
        </div>

        <div class="d-sm-none">

            <mat-tab-group [selectedIndex]="mobileTabMenus.indexOf(_pageId)"
                animationDuration="0ms" dynamicHeight>
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{'Actual events' | translate}}
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{'Past events' | translate}}
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{'Calendar' | translate}}
                    </ng-template>
                </mat-tab>
            </mat-tab-group>

        </div>





        <ng-container [ngSwitch]="_pageId">

            <ng-container *ngSwitchCase="'404'">

                <mat-card class="mat-elevation-z0">

                    <h1>{{ 'Not found' | translate }}</h1>

                    <div class="text-gray-600">
                        {{ 'Event not found' | translate }} ...
                    </div>

                </mat-card>

            </ng-container>

            <ng-container *ngSwitchCase="'calendar'">

                <mat-card class="mat-elevation-z0">

                    <div class="d-flex align-items-center justify-content-center mb-4">


                        <button mat-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
                            <mat-icon>chevron_left</mat-icon>
                        </button>

                        <div
                            class="text-center d-flex flex-wrap align-items-center justify-content-center justify-content-md-start">
                            <button mat-button mwlCalendarToday [view]="view" [(viewDate)]="viewDate"
                                matTooltip="Today">
                                <mat-icon>center_focus_strong</mat-icon>
                            </button>

                            <h2 class="mb-0">
                                {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
                            </h2>
                        </div>

                        <button mat-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
                            <mat-icon>chevron_right</mat-icon>
                        </button>



                    </div>


                    <mwl-calendar-month-view [viewDate]="viewDate" [events]="events2" [refresh]="refresh"
                        [activeDayIsOpen]="activeDayIsOpen"></mwl-calendar-month-view>

                </mat-card>

            </ng-container>

            <ng-container *ngSwitchCase="'single'">

                <section class="col-12">

                    <mat-card class="mat-elevation-z1 my-2 mt-4 mt-md-2 rounded w-100" style="overflow-x: auto;">



                        <div class="bg-primary text-white p-2 text-center py-4">

                            <mat-icon>today</mat-icon>

                            <h2 class="my-2">
                                {{ singleEvent.title }}
                            </h2>


                            <div class="text-gray-300 mt-md-0">
                                <ng-container *ngIf="singleEvent.all_day">

                                    <div class="d-flex flex-wrap justify-content-center">
                                        <div class="text-center text-md-start">
                                            {{ singleEvent.start_date | date: "longDate" }}
                                        </div>
                                        <div class="text-center mx-2">-</div>
                                        <div class="text-center text-md-start">
                                            {{ singleEvent.end_date | date: "longDate" }}
                                        </div>
                                    </div>


                                </ng-container>
                                <ng-container *ngIf="!singleEvent.all_day">

                                    <div class="d-flex flex-wrap justify-content-center">
                                        <div class="text-center text-md-start">
                                            {{ singleEvent.start_date | date: "longDate" }} {{
                                            singleEvent.start_date | date: "shortTime" }}
                                        </div>
                                        <div class="d-none d-md-block mx-2">-</div>
                                        <div class="text-center text-md-start">
                                            {{ singleEvent.end_date | date: "longDate" }} {{
                                            singleEvent.end_date | date: "shortTime" }}
                                        </div>
                                    </div>


                                </ng-container>


                            </div>

                        </div>

                        <div class="d-flex align-items-center my-3">

                            <div class="d-flex align-items-center">

                                <div class="me-2">
                                    <app-user-avatar width="40" fontSize="12px" [info]="singleEvent.user_id">
                                    </app-user-avatar>
                                </div>

                                <div class="d-flex flex-column">

                                    <div class="fw-boldest text-gray-800">
                                        {{ singleEvent.user_id.full_name | truncate : 45 }}
                                    </div>
                                    <div class="text-gray-500 fw-bold"
                                        matTooltip="{{ singleEvent.date_created | date: 'medium' }}">
                                        {{ singleEvent.date_created | myDate }}
                                    </div>

                                </div>
                            </div>


                            <!-- mobile 
                                        <div class="d-block d-lg-none ms-auto">-->
                            <div class="ms-auto">
                                <ng-container *ngIf="role != 0 && role != 1">

                                    <button mat-button [matMenuTriggerFor]="menu">
                                        <mat-icon>
                                            shortcut
                                        </mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item
                                            (click)="generateCalendar('ical', singleEvent)">iCalendar</button>
                                        <button mat-menu-item (click)="generateCalendar('yahoo', singleEvent)">Yahoo!
                                            Calendar</button>
                                        <button mat-menu-item (click)="generateCalendar('google', singleEvent)">Google
                                            Calendar</button>
                                        <button mat-menu-item
                                            (click)="generateCalendar('microsoft', singleEvent)">Outlook
                                            Web Calendar</button>
                                    </mat-menu>

                                </ng-container>
                                <ng-container *ngIf="role == 0 || role == 1">

                                    <button mat-button [matMenuTriggerFor]="menu">
                                        <mat-icon>
                                            more_vert
                                        </mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="edit(singleEvent)">
                                            <mat-icon>create</mat-icon>
                                            Edit
                                        </button>
                                        <button mat-menu-item (click)="remove(singleEvent._id)"
                                            *ngIf="singleEvent?.shared">
                                            <mat-icon>remove</mat-icon>
                                            <span>{{'Remove' | translate}}</span>
                                        </button>
                                        <button mat-menu-item (click)="delete(singleEvent._id)"
                                            *ngIf="!singleEvent?.shared">
                                            <mat-icon>delete</mat-icon>
                                            {{'Delete' | translate}}
                                        </button>
                                        <button mat-menu-item [matMenuTriggerFor]="export">Export</button>
                                    </mat-menu>

                                    <mat-menu #export="matMenu">
                                        <button mat-menu-item
                                            (click)="generateCalendar('ical', singleEvent)">iCalendar</button>
                                        <button mat-menu-item (click)="generateCalendar('yahoo', singleEvent)">Yahoo!
                                            Calendar</button>
                                        <button mat-menu-item (click)="generateCalendar('google', singleEvent)">Google
                                            Calendar</button>
                                        <button mat-menu-item
                                            (click)="generateCalendar('microsoft', singleEvent)">Outlook
                                            Web Calendar</button>
                                    </mat-menu>

                                </ng-container>
                            </div>

                        </div>

                        <div class="my-4 my-md-2" *ngIf="singleEvent.description">


                            <div class="mt-2 ck-content" [innerHTML]="singleEvent.description | sanitizeHtml"></div>


                            <div class="my-2 text-gray-600 d-flex flex-wrap align-items-center"
                                *ngIf="singleEvent?.shared">
                                <mat-icon style="font-size: 1.3rem;">reply</mat-icon>
                                <div class="ms-1">
                                    {{'Shared from' | translate}} <a
                                        routerLink="{{ singleEvent.level_id.link }}/events">{{
                                        singleEvent.level_id.name }}</a>
                                </div>
                            </div>

                        </div>


                    </mat-card>

                </section>

            </ng-container>

            <ng-container *ngSwitchCase="'error'">

                <div class="p-3 bg-warning text-dark fw-bold">Error: {{ errorMsg }}</div>

            </ng-container>

            <ng-container *ngSwitchDefault>

                <ng-container *ngIf="events.length == 0; else eventView">
                    <mat-card class="mat-elevation-z0">
                        <div class="mx-2">
                            <ng-container *ngIf="!_pageId">
                                {{'No events' | translate}}
                            </ng-container>
                            <ng-container *ngIf="_pageId == 'past'">
                                {{'No past events' | translate}}
                            </ng-container>
                        </div>
                    </mat-card>
                </ng-container>

                <ng-template #eventView>

                    <div class="row">
                        <div class="col col-xl-8">



                            <mat-list role="list">
                                <mat-list-item role="listitem" *ngFor="let ev of events" class="pointer" (click)="viewEvent(ev.link)">

                                    <div mat-list-icon class="pointer text-primary">
                                        <mat-icon>today</mat-icon>
                                    </div>

                                    <div mat-line>{{ ev.title }}
                                    </div>
                                    <div class="mat-caption text-muted" style="font-size: .8rem" mat-line>
                                        {{ ev.start_date | date }} - {{ ev.end_date | date }}</div>


                                </mat-list-item>
                            </mat-list>

                        </div>


                    </div>




                </ng-template>



            </ng-container>



        </ng-container>





    </div>

</ng-container>