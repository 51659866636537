<ng-container *ngIf="!loading">

    <mat-toolbar class="d-flex align-items-center my-4" *ngIf="role == 0 || role == 1">

        <h1 class="mat-h1 mb-0">{{'Milestones' | translate}}</h1>

        <!-- mobile-menu -->
        <button mat-raised-button (click)="addMilestone()" class="ms-auto d-md-none">
            <mat-icon>
                add
            </mat-icon>
        </button>

        <!-- desktop-menu -->
        <button mat-raised-button (click)="addMilestone()" class="ms-auto d-none d-md-block">
            <span class="material-icons">
                add
            </span> {{'Add milestone' | translate}}
        </button>

    </mat-toolbar>

    <div class="my-4">

        <ng-container *ngIf="milestones.length == 0">
            <div class="mx-2">
                {{'No milestones' | translate}}
            </div>
        </ng-container>

        <ng-container *ngIf="milestones.length > 0">

           
                <ng-container *ngFor="let m of milestones">
                        <div class="mx-2 my-4">
                            <div class="d-flex flex-wrap align-items-center">

                                <div class="text-primary mt-2" style="font-size: 1.4rem;">
                                    <mat-icon>track_changes</mat-icon>
                                </div>
                                
                                <div class="mx-2 text-center milestone-line">{{ m.due | date: "longDate" }}</div>

                                <div class="flex-fill">
                                    <div class="d-flex flex-wrap align-items-center">
                                        <h2 class="mb-0">{{ m.name }}</h2>
                                        <div class="ms-auto" *ngIf="role == 0 || role == 1">

                                            <button mat-button [matMenuTriggerFor]="menu">                                        
                                                <mat-icon>
                                                    more_vert
                                                </mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item (click)="edit(m)">
                                                    <mat-icon>create</mat-icon>
                                                    Edit
                                                </button>
                                                <button mat-menu-item (click)="delete(m._id)">
                                                    <mat-icon>delete</mat-icon>
                                                    Delete
                                                </button>
                                            </mat-menu>

                                        </div>
                                    </div>

                                    <ng-container *ngIf="!m.show_more">
                                        <div class="mat-caption text-muted">
                                            {{ m.description | mystriphtml | truncate:250 }}
                                        </div>

                                        <button class="mt-2 text-uppercase" mat-stroked-button color="primary" (click)="showMore(m, true)">{{'Show more' | translate}}</button>
                                    </ng-container>
                                    <ng-container *ngIf="m.show_more">
                                        <div class="mt-2 ck-content" [innerHTML]="m.description | sanitizeHtml"></div>



                                        <div class="d-flex align-items-center my-2 mb-4">
                                            <app-user-avatar width="25" fontSize="12px" [info]="m.user_id">
                                            </app-user-avatar>
    
                                            <div class="ms-1 text-muted mat-caption d-flex">
                                                <div class="me-4">
                                                    {{ m.user_id.full_name | truncate : 45 }}
                                                </div>
                                                <div matTooltip="{{ m.date_created | date: 'medium' }}">
                                                    {{ m.date_created | myDate }}
                                                </div>
    
                                            </div>
                                        </div>

                                        <button class="text-uppercase" mat-stroked-button color="primary" (click)="showMore(m, false)">{{'Show less' | translate}}</button>
                                    </ng-container>

                                </div>

                              
                                
                            </div>

                        
                        </div>
                    
                    
                </ng-container>
     

          
        </ng-container>
    </div>

</ng-container>