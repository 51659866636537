<div class="row">

    <div class="col">
        <mat-list role="list" class="workspace-list">
            <ng-container *ngFor="let l of _levels">
                <mat-list-item role="listitem" class="pointer" [routerLink]="l.link">

                    

                    <div mat-list-icon>
                        <app-level-avatar [data]="l.avatar_url" rootClass="images rounded"></app-level-avatar>
                    </div>

                    <ng-container *ngIf="l.owner">
                        
                        <button mat-mini-fab color="primary" class="ms-auto" routerLink="/p/{{ l._id }}" matTooltip="You are the owner">
                            <mat-icon>grade</mat-icon>
                        </button>
                       
                    </ng-container>
                    
                    <div mat-line matTooltip="{{ l.title }}" class="pointer" [routerLink]="l.link">{{ l.name }}</div>
                    <div class="mat-caption text-muted" style="font-size: .8rem" mat-line>
                        {{ l.description | mystriphtml
                        }}</div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>
    </div>

</div>