<div class="d-flex align-items-center mb-2">
    <h2 mat-dialog-title class="d-flex align-items-center">
        <span class="material-icons">
            add
        </span> 
        {{'Add file' | translate}}
    </h2>

    <div class="ms-auto">
        <button mat-button color="secondary" [mat-dialog-close]="false">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content>

    <app-upload-file [folderId]="data.folder_id" [levelId]="data.level_id" (data)="newFile($event)" dragDrop="true"></app-upload-file>

</mat-dialog-content>

<mat-dialog-actions>       
    <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
</mat-dialog-actions>
