<ng-container *ngIf="!loading">


    <mat-toolbar class="d-flex align-items-center my-4">


        <ng-container *ngIf="!_newsId; else showBack">
            <h1 class="mat-h1">{{'News' | translate}}</h1>
        </ng-container>
        <ng-template #showBack>
            <button mat-button (click)="backButton()">
                <mat-icon>chevron_left</mat-icon>
                {{'Back' | translate}}
            </button>
        </ng-template>

        <ng-container *ngIf="role == 0 || role == 1">

            <ng-container *ngIf="!_newsId; else showNewsButton">

                <!-- mobile-menu -->

                <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" class="ms-auto d-md-none">
                    <span class="material-icons">
                        more_vert
                    </span>
                </button>
                <mat-menu #menu="matMenu">

                    <button mat-menu-item (click)="addNews()">
                        <mat-icon>add</mat-icon>
                        <span>{{'Add news' | translate}}</span>
                    </button>

                    <button mat-menu-item (click)="browseNews()">
                        <mat-icon>view_carousel</mat-icon>
                        <span>{{'Import' | translate}}</span>
                    </button>

                </mat-menu>

                <!-- desktop-menu -->

                <button mat-raised-button (click)="addNews()" class="ms-auto d-none d-md-block">
                    <div class="d-flex align-items-center">
                        <mat-icon>add</mat-icon>
                        {{'Add news' | translate}}
                    </div>
                </button>
                <button mat-raised-button (click)="browseNews()" class="ms-2 d-none d-md-block">
                    <div class="d-flex align-items-center">
                        <mat-icon>view_carousel</mat-icon>
                        <div class="ms-1">
                            {{'Import' | translate}}
                        </div>
                    </div>
                </button>

            </ng-container>
            <ng-template #showNewsButton>


                <div class="ms-auto d-block d-lg-none">
                    <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon>
                            more_vert
                        </mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="edit()">
                            <mat-icon>create</mat-icon>
                            <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="remove()" *ngIf="newsInfo?.shared">
                            <mat-icon>delete</mat-icon>
                            <span>Remove</span>
                        </button>
                        <button mat-menu-item (click)="delete()" *ngIf="!newsInfo?.shared">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </div>

                <div class="ms-auto d-none d-lg-block">

                    <button mat-raised-button (click)="edit()" class="me-2">
                        <mat-icon>create</mat-icon>
                        {{'Edit news' | translate}}
                    </button>

                    <button mat-raised-button (click)="remove()" *ngIf="newsInfo?.shared">
                        <mat-icon>remove</mat-icon>
                        {{'Remove news' | translate}}
                    </button>
                    <button mat-raised-button (click)="delete()" *ngIf="!newsInfo?.shared">
                        <mat-icon>delete</mat-icon>
                        {{'Delete news' | translate}}
                    </button>

                </div>



            </ng-template>

        </ng-container>
    </mat-toolbar>

    <div class="my-4">

        <ng-container *ngIf="!_newsId">

            <ng-container *ngIf="news.length == 0">
                <mat-card class="mat-elevation-z0">
                    {{'No news' | translate}}.
                </mat-card>
            </ng-container>

            <ng-container *ngIf="news.length > 0">

                <div class="d-flex flex-wrap">

                    <ng-container *ngFor="let n of news">

                        <div class="col-xl-6 col-12">

                            <div class="p-4 bg-white m-2">

                                <div class="d-flex align-items-center my-3 mb-4">

                                    <div class="me-2">
                                        <app-user-avatar width="40" fontSize="12px" [info]="n.user_id">
                                        </app-user-avatar>
                                    </div>

                                    <div class="d-flex flex-column">

                                        <div class="fw-boldest text-gray-800">
                                            {{ n.user_id.full_name | truncate : 45 }}
                                        </div>
                                        <div class="text-gray-500 fw-bold"
                                            matTooltip="{{ n.date_created | date: 'medium' }}">
                                            {{ n.date_created | myDate }}
                                        </div>

                                    </div>
                                </div>

                                <h2 routerLink="{{ url }}/{{ n._id }}" class="pointer mb-1">{{ n.name | truncate: 80 }}
                                </h2>


                                <div class="mb-3">
                                    {{ n.description | mystriphtml | truncate:250 }}
                                </div>

                                <button mat-button routerLink="{{ url }}/{{ n._id }}" color="primary">{{'View' |
                                    translate}}</button>

                            </div>

                            <!--
                            <mat-card class="mb-2 mat-elevation-z0">

                                <mat-card-header>
                                    <div mat-card-avatar>
                                        <app-user-avatar width="40" fontSize="12px" [info]="n.user_id">
                                        </app-user-avatar>
                                    </div>
                                    <mat-card-title></mat-card-title>
                                    <mat-card-subtitle matTooltip="{{ n.date_created | date: 'medium' }}" class="d-flex flex-wrap">
                                        <div class="me-4">{{ n.user_id.full_name }}</div> {{ n.date_created | myDate }}
                                    </mat-card-subtitle>
                                </mat-card-header>

                                <mat-card-content style="height: 80px;">

                                    {{ n.description | mystriphtml | truncate:250 }}

                                </mat-card-content>
                                <mat-card-actions>
                                    <button mat-button color="primary" routerLink="{{url}}/{{ n._id }}">READ</button>
                                </mat-card-actions>
                            </mat-card>-->
                        </div>

                    </ng-container>

                </div>

                <!-- @todo
                <div class="p-2">
                    <button mat-stroked-button class="w-100">Load more</button>
                </div>-->

            </ng-container>

        </ng-container>
        <ng-container *ngIf="_newsId">

            <div *ngIf="!newsInfo">
                <div class="mx-2">
                    {{'News is not found' | translate}}.

                    <div class="mt-4">
                        <button mat-button (click)="backButton()">
                            <mat-icon>chevron_left</mat-icon>
                            Return to news
                        </button>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="newsInfo">

                <div class="col mb-4">


                    <mat-card class="mat-elevation-z0">

                        <!--
                        <mat-card-header>
                            <div mat-card-avatar>
                                <app-user-avatar width="40" fontSize="12px" [info]="newsInfo.user_id"></app-user-avatar>
                            </div>
                            <mat-card-title>{{ newsInfo?.name | truncate : 80 }}</mat-card-title>
                            <mat-card-subtitle matTooltip="{{ newsInfo.date_created | date: 'medium' }}" class="d-flex flex-wrap">
                                <div class="me-4">{{ newsInfo?.user_id?.full_name }}</div> {{ newsInfo.date_created |
                                myDate }}
                                <div class="ms-4 text-italic" *ngIf="newsInfo?.shared">
                                    {{'Shared from' | translate}}: <a routerLink="{{ newsInfo.level_id.link }}/news">{{
                                        newsInfo?.level_id?.name | truncate }}</a>
                                </div>
                            </mat-card-subtitle>
                           
                        </mat-card-header>-->

                        <h1>{{ newsInfo.name | truncate: 80 }}</h1>

                        <div class="d-flex align-items-center mb-3">

                            <div class="me-2">
                                <app-user-avatar width="40" fontSize="12px" [info]="newsInfo.user_id">
                                </app-user-avatar>
                            </div>

                            <div class="d-flex flex-column">

                                <div class="fw-boldest text-gray-800">
                                    {{ newsInfo.user_id.full_name | truncate : 45 }}
                                </div>
                                <div class="text-gray-500 fw-bold"
                                    matTooltip="{{ newsInfo.date_created | date: 'medium' }}">
                                    {{ newsInfo.date_created | myDate }}
                                </div>

                            </div>
                        </div>

                        <div class="my-2 text-gray-600 d-flex flex-wrap align-items-center" *ngIf="newsInfo?.shared">
                            <mat-icon style="font-size: 1.3rem;">reply</mat-icon>
                            <div class="ms-1">
                                {{'Shared from' | translate}} <a routerLink="{{ newsInfo.level_id.link }}/news">{{
                                    newsInfo?.level_id?.name | truncate }}</a>
                            </div>
                        </div>



                        <mat-card-content>
                            <div class="ck-content" [innerHTML]="newsInfo.description | sanitizeHtml"></div>
                        </mat-card-content>
                    </mat-card>

                </div>

                <div class="col-12 col-lg-4" *ngIf="relatedNews.length > 0">

                    <mat-card class="mat-elevation-z0">

                        <mat-list role="list">

                            <div mat-subheader>Related news</div>
                            
                            <mat-list-item *ngFor="let r of relatedNews; let last = last;">
                                <app-user-avatar width="40" fontSize="12px" [info]="r.user_id"></app-user-avatar>
                                <div mat-line (click)="navigateNews(r._id)" class="fw-boldest text-gray-800 pointer"
                                    matTooltip="{{ r.name }}">
                                    {{ r.name | truncate : 30 }}
                                </div>
                                <div matLine class="text-gray-500 fw-bold">{{ r.date_created | myDate }}</div>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </mat-list-item>
                        </mat-list>

                    </mat-card>

                </div>

            </div>

        </ng-container>
    </div>
</ng-container>