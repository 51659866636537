import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-creating-platform',
  templateUrl: './success-creating-platform.component.html',
  styleUrls: ['./success-creating-platform.component.scss']
})
export class SuccessCreatingPlatformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
