import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-repo-add-folder',
  templateUrl: './repo-add-folder.component.html',
  styleUrls: ['./repo-add-folder.component.scss']
})
export class RepoAddFolderComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<RepoAddFolderComponent>,
    private _snackBar: MatSnackBar,
    private _fileService: FileService
  ) { }

  ngOnInit(): void {
    this.createForm();

    this.mainForm.patchValue({
      level_id: this.data.level_id,
      folder_id: this.data.folder_id,
    });
  }

  createForm() {
    this.mainForm = this._fb.group({
      name: ['', [Validators.required]],
      level_id: ['', Validators.required],
      folder_id: ['']
    });
  }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this._fileService.addFolder(
        this.mainForm.value.level_id,
        this.mainForm.value.name,
        this.mainForm.value.folder_id
      )
      .subscribe((result: any) => {
  
        if (result.success) {
          this._dialogRef.close(result.data);
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

}
