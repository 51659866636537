export class QuestionBase<T> {
    value: T;
    headline: string;
    space: boolean;
    key: string;
    label: string;
    required: boolean;
    index: string;
    order: number;
    controlType: string;
    type: string;
    class: string;
    multiple: boolean;
    options: {key: string, value: string}[];
  
    constructor(options: {
        value?: T;
        headline?: string;
        space?: boolean;
        key?: string;
        label?: string;
        required?: boolean;
        order?: number;
        controlType?: string;
        type?: string;
        class?: string;
        multiple?: boolean;
        options?: {key: string, value: string}[];
      } = {}) {
      this.value = options.value;
      this.headline = options.headline || '';
      this.space = options.space || false;
      this.key = options.key || '';
      this.label = options.label || '';
      this.required = !!options.required;
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.type = options.type || '';
      this.class = options.class || 'w-100';
      this.multiple = options.multiple || false;
      this.options = options.options || [];
    }
  }