import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class InquiryService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  sendInquiry(comment?: string) {
    return this._http.post(this._config.getApiUrl() + '/api/inquiry', {
      comment: comment
    }, this._httpOptionsService.getHeader())
  }
}
