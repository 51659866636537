import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { LevelService } from 'src/app/services/level.service';
import { AddLevelDialogComponent } from 'src/app/utils/add-level-dialog/add-level-dialog.component';
import { SortLevelDialogComponent } from 'src/app/utils/sort-level-dialog/sort-level-dialog.component';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import LevelMenu from './level-menu'
import { Level } from './level';
import { LevelNode } from './level-node';
import { NgxPermissionsService } from 'ngx-permissions';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ResizeEvent } from 'angular-resizable-element';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { Title } from '@angular/platform-browser';
import { RequestMembershipService } from 'src/app/services/request-membership.service';
import { AddRequestMembershipDialogComponent } from 'src/app/utils/add-request-membership-dialog/add-request-membership-dialog.component';
import { MatDrawer, MatDrawerContainer } from '@angular/material/sidenav';
import { MembershipService } from 'src/app/services/membership.service';

/** Flat node with expandable and level information */
interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  add_child: boolean;
  role: Number;
}

@Component({
  selector: 'app-interest-level',
  templateUrl: './interest-level.component.html',
  styleUrls: ['./interest-level.component.scss']
})
export class InterestLevelComponent implements OnInit, OnDestroy {

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  @ViewChild('drawer') myDrawer: MatDrawer;

  loading: boolean = true;
  levelFound: boolean = false;

  url: string = '';
  interests: any = [];
  level: Level;

  private subscription: Subscription;

  platformId: any = null;
  id: any = null;
  page: any = null;
  interestId: any = null;
  project: boolean = false;
  subpage: any = null;

  levelLink: string = 'i';
  link: string;

  levelInfo: any;
  platformInfo: any;
  mainMenu: any[] = [];

  drawerWidth: number = 300;

  addLevelDialogRef: MatDialogRef<AddLevelDialogComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  sortDialogRef: MatDialogRef<SortLevelDialogComponent>;
  addRequestForMembershipRef: MatDialogRef<AddRequestMembershipDialogComponent>;

  //#region tree view region

  // we add the data in list here
  private _transformer = (node: LevelNode, level: number) => {
    return {
      expandable: (node.type == 'interest'), /** !!node.wg && node.wg.length > 0, */
      _id: node._id,
      name: node.name,
      level: level,
      link: node.link,
      parent_id: node.parent_id,
      selected: node.selected,
      add_child: node.add_child,
      role: node.role,
      sublevel_type: node.sublevel_type
    };
  }

  treeControl = new FlatTreeControl<FlatNode>(node => node.level, node => node.expandable);
  treeFlattener = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.wg);
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  //#endregion


  // email notifications for level
  notifications!: boolean;
  showNotificationsButton!: boolean;

  constructor(
    private _permissionsService: NgxPermissionsService,
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _levelService: LevelService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _userinfoService: UserinfoService,
    private _titleService: Title,
    private _requestMembershipService: RequestMembershipService,
    private _breakpointObserver: BreakpointObserver,
    private _membershipService: MembershipService,
  ) {

    /** We listen for the route change */
    this.subscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {

        /**
         * We check if we are looking at the projects or interest
         * We get that info from routes
         */
        if (this._route.snapshot.data && this._route.snapshot.data.project) {
          this.levelLink = 'p';
          this.project = true;
        }
        else {
          this.levelLink = 'i';
          this.project = false;
        }

        let newPlatform = false;

        if (this.platformId && this.platformId != this._route.snapshot.paramMap.get('platformId')) {
          newPlatform = true;
        }

        this.platformId = this._route.snapshot.paramMap.get('platformId');

        /**
         * this._route.firstChild <-- so that we get the paramters from child routes
         */
        if (this._route.firstChild) {

          this.id = this._route.firstChild.snapshot.paramMap.get('id');
          this.page = this._route.firstChild.snapshot.paramMap.get('page');
          this.interestId = this._route.firstChild.snapshot.paramMap.get('interestId');
          this.subpage = this._route.firstChild.snapshot.paramMap.get('subpage');


          //#region we get url
          let currentUrl = this._router.url;
          let tmpArray = currentUrl.split('/');
          tmpArray.pop();

          this.url = tmpArray.join('/');
          //#endregion


          // we mark the right level and we show it
          if (!this.loading) {
            this.findLevel();
          }

        }

        if (newPlatform) {

          this.getData();

        }




      }

    });
  }

  getData() {
    let promise = this._userinfoService.getLoggedIn() ?
      this._levelService.getLevelHierarchy(this.platformId, (!this.project ? 'interest' : 'project')) :
      this._levelService.getLevelHierarchyPublic(this.platformId, (!this.project ? 'interest' : 'project'))

    /** We get levels for the tree view component */
    promise.subscribe((result: any) => {

      this.loading = false;

      if (result.success) {

        this.interests = (result.data.interest) ? result.data.interest : [];

        // we mark the right level
        this.findLevel();


      }


    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });

    // if we are still on the platform level, we don't show interest or wg information
    this._permissionsService.removePermission('PLATFORM_ADMIN');
    this._permissionsService.removePermission('FOLLOWER');

    let promise2 = this._userinfoService.getLoggedIn() ?
      this._levelService.getLevel(this.platformId) : this._levelService.getLevelPublic(this.platformId)

    promise2.subscribe((result: any) => {

      if (result.success) {

        this.platformInfo = result.data;

        if (this.platformInfo.role == 0) {
          this._permissionsService.addPermission('PLATFORM_ADMIN');
        }
        else if (this.platformInfo.role == 3) {
          this._permissionsService.addPermission('FOLLOWER');
        }

      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });

  }

  // tree view
  hasChild = (_: number, node: FlatNode) => node.expandable;

  ngOnInit(): void {

    // fix for the tree view
    if (this._route.snapshot?.data?.project) {
      this.levelLink = 'p';
      this.project = true;
    }
    else {
      this.levelLink = 'i';
      this.project = false;
    }

    this.platformId = this._route.snapshot.paramMap.get('platformId');

    if (this._route.firstChild) {

      this.id = this._route.firstChild.snapshot.paramMap.get('id');
      this.page = this._route.firstChild.snapshot.paramMap.get('page');
      this.interestId = this._route.firstChild.snapshot.paramMap.get('interestId');
      this.subpage = this._route.firstChild.snapshot.paramMap.get('subpage');

    }

    let promise = this._userinfoService.getLoggedIn() ?
      this._levelService.getLevelHierarchy(this.platformId, (!this.project ? 'interest' : 'project')) :
      this._levelService.getLevelHierarchyPublic(this.platformId, (!this.project ? 'interest' : 'project'))

    /** We get levels for the tree view component */
    promise.subscribe((result: any) => {

      this.loading = false;

      if (result.success) {

        console.warn('interests:', result.data.interest)

        this.interests = (result.data.interest) ? result.data.interest : [];

        // we mark the right level
        this.findLevel();

      }


    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });

    // if we are still on the platform level, we don't show interest or wg information
    if (!this.platformInfo) {

      this._permissionsService.removePermission('PLATFORM_ADMIN');
      this._permissionsService.removePermission('FOLLOWER');

      let promise = this._userinfoService.getLoggedIn() ?
        this._levelService.getLevel(this.platformId) : this._levelService.getLevelPublic(this.platformId)

      promise.subscribe((result: any) => {

        if (result.success) {

          this.platformInfo = result.data;

          if (this.platformInfo.role == 0) {
            this._permissionsService.addPermission('PLATFORM_ADMIN');
          }
          else if (this.platformInfo.role == 3) {
            this._permissionsService.addPermission('FOLLOWER');
          }

        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });

        }
      });

    }


  }


  /** We show the right level */
  findLevel() {

    this.levelFound = false;

    this._permissionsService.removePermission('LEVEL_ADMIN');
    this._permissionsService.removePermission('LEVEL_MEMBER');
    this._permissionsService.removePermission('LEVEL_VISITOR');

    let interestIndex = 0;

    /** We look at the router parameter */
    if (!this.interestId) {

      /** INTEREST */

      this.link = '/p/' + this.platformId + '/' + this.levelLink + '/' + this.id;

      for (let l of this.interests) {
        if (l._id == this.id) {
          this.level = l;

          console.log('level info (interest/wg)', this.level);
          this.notifications = this.level?.notifications;
          if (this.notifications === true || this.notifications === false) {
            this.showNotificationsButton = true;
          }

          this._titleService.setTitle(l.name);

          this.levelFound = true;

          this.mainMenu = [...LevelMenu[0].menu];

          // if project we remove project proposal menu
          if (this.project) {
            this.mainMenu = this.mainMenu.filter((item) => item.link != 'proposal');

            // if project
            // we add WP - Work Package menu
            this.mainMenu.push({
              name: 'WP - Work Packages',
              link: 'wg',
              icon: 'work_outline',
              private: true
            })
          } else {
            // if collab. interest
            // we add WG - Work Group menu
            this.mainMenu.push({
              name: 'WG - Work Groups',
              link: 'wg',
              icon: 'work_outline',
              private: true
            })
          }



          l.selected = true;
        }
        else {
          l.selected = false;
        }

        if (l.wg) {
          for (let w of l.wg) {
            w.selected = false;
          }
        }
      }
    }
    else {

      /** WG */

      this.link = '/p/' + this.platformId + '/' + this.levelLink + '/' + this.interestId + '/w/' + this.id;

      for (let l of this.interests) {
        if (l._id == this.interestId) {
          //l.selected = true;   
          this.mainMenu = [...LevelMenu[1].menu];

          if (this.project) {
            // remove project proposal menu
            this.mainMenu = this.mainMenu.filter((item) => item.link != 'proposal');
          }

          if (l.wg) {
            for (let w of l.wg) {
              if (w._id == this.id) {
                w.selected = true;
                this.level = w;
                this._titleService.setTitle(w.name);
                this.levelFound = true;

                //console.log(this.level);
              }
              else {
                w.selected = false;
              }
            }
          }

        }
      }

    }

    // we update TreeView
    this.dataSource.data = this.interests;

    // we only expand if specific level selected
    if (this.level) {

      //#region we setup the role
      if (this.level.role == 0 || this.level.role == 1) {
        this._permissionsService.addPermission('LEVEL_ADMIN');
      }
      else if (this.level.role == 2) {
        this._permissionsService.addPermission('LEVEL_MEMBER');
      }
      else {
        this._permissionsService.addPermission('LEVEL_VISITOR');
      }
      //#endregion

      /** We remove the menus */

      if (this.level.role == undefined) {
        let length = this.mainMenu.length;
        for (let i = 0; i < length; i++) {
          if (this.mainMenu[i].private) {
            this.mainMenu.splice(i, 1);
            i--;
            length--;
          }
        }
      }


      for (let i = 0; i < this.treeControl.dataNodes.length; i++) {

        if (this.treeControl.dataNodes[i]['_id'] == (this.interestId || this.id)) {
          interestIndex = i;
        }

      }

      this.treeControl.expand(this.treeControl.dataNodes[interestIndex]);
    }

    this.addActiveFieldAtMainMenu(this._router.url)

  }

  /** Dialogs for adding Interest & WG */

  addInterestDialog() {

    this.addLevelDialogRef = this._dialog.open(
      AddLevelDialogComponent,
      {
        width: '450px',
        data: {
          type: 'interest',
          project: this.project,
          parentId: this.platformId,
          platformId: this.platformId
        },
        disableClose: true
      }
    );

    this.addLevelDialogRef.afterClosed().subscribe(result => {
      if (result) {

        result['wg'] = [];
        result['role'] = 0;

        this.interests.push(result);

        this.dataSource.data = this.interests;

        let index = 0;

        for (let i = 0; i < this.treeControl.dataNodes.length; i++) {

          if (this.treeControl.dataNodes[i]['_id'] == this.id) {
            index = i;
          }

        }

        this.treeControl.expand(this.treeControl.dataNodes[index]);

      }
    });
  }

  addWgDialog(parentId: string) {

    console.log('add wg dialog', parentId);

    this.addLevelDialogRef = this._dialog.open(
      AddLevelDialogComponent,
      {
        width: '450px',
        data: {
          type: 'wg',
          project: this.project,
          parentId: parentId, //(this.interestId) ? this.interestId : this.id,
          platformId: this.platformId
        },
        disableClose: true
      }
    );

    this.addLevelDialogRef.afterClosed().subscribe(result => {
      if (result) {

        let id = parentId;

        let index = -1;
        let i = 0;

        // find the interest
        this.interests.filter((interest) => {
          if (interest._id == id) {
            index = i;
            result.role = 0;

            // we add the new WG
            interest.wg.splice(interest.wg.length - 1, 0, result);


          }
          i++;
        });

        // we update TreeView
        this.dataSource.data = this.interests;

        // we expand the interest
        if (index != -1) {
          this.treeControl.expand(this.treeControl.dataNodes[index]);
        }

      }
    });
  }

  avatarChange(url): void {
    window.location.reload();
  }

  levelDeleted(data) {
    this.level = null;

    if (!this.interestId) {
      // interest
      let index = -1;

      for (let i = 0; i < this.interests.length; i++) {
        if (this.interests[i]._id == data) {
          index = i;
        }
        else {
          this.interests[i].selected = false;
        }
      }

      if (index != -1) {
        this.interests.splice(index, 1)

        if (this.interests.length > 0) {
          this.id = this.interests[0]._id;
          this.findLevel();
        }
      }

    }
    else {
      // wg

      let interestIndex = -1;
      let index = -1;

      for (let i = 0; i < this.interests.length; i++) {
        if (this.interests[i]._id == this.interestId) {

          if (this.interests[i].wg) {

            for (let j = 0; j < this.interests[i].wg.length; j++) {
              if (this.interests[i].wg[j]._id == this.id) {
                interestIndex = i;
                index = j;
              }
              else {
                this.interests[i].wg[j].selected = false;
              }
            }
          }
        }
        else {
          this.interests[i].selected = false;
        }
      }

      if ((interestIndex != -1) && (index != -1)) {
        this.interests[interestIndex].wg.splice(index, 1);

        this.id = this.interests[interestIndex]._id;
        this.interestId = null;
        this.findLevel();

      }

    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.interests, event.previousIndex, event.currentIndex);
    this.dataSource.data = this.interests;
    console.table(event)
  }

  sortDialog(level, type: String) {
    this.sortDialogRef = this._dialog.open(
      SortLevelDialogComponent,
      {
        minWidth: '450px',
        data: {
          level: level,
          type: type
        },
        disableClose: false
      }
    );

    this.sortDialogRef.afterClosed().subscribe(result => {
      if (result) {

        window.location.reload();

      }
    });
  }

  onResizeEnd(event: ResizeEvent): void {

    this.drawerWidth = event.rectangle.width;

  }

  requestToJoin(): void {

    /**
     * We open the dialog for text
     */
    this.addRequestForMembershipRef = this._dialog.open(
      AddRequestMembershipDialogComponent,
      {
        width: '450px',
        data: this.platformInfo
      }
    );

    this.addRequestForMembershipRef.afterClosed().subscribe(data => {
      if (data) {
        /**
         * We send the request
         */
        this._requestMembershipService.addRequest(this.id, data.message).then((data: any) => {

          this.level.membership_request = true;

        }).catch(err => { console.log(err) });
      }
    });

  }

  showLink(link: string) {
    this._router.navigate([link]);

    /*
    if(
      this._breakpointObserver.isMatched(
      "(max-width: 599px)"
      )
    ) {
      this.myDrawer.toggle();
    }*/

  }

  /**
   * Swipe event listener for navbar
   * @param eType swipeleft | swiperight
   */
  onSwipe(eType: string) {

    this.mainMenu.map((m, index) => {

      if (m.active == true) {



        if (eType === this.SWIPE_ACTION.LEFT) {
          // we go to the right        

          if (this.mainMenu[index + 1]) {
            this._router.navigate(['/' + this.link + '/' + this.mainMenu[index + 1].link]);
          }
          else {
            this._router.navigate(['/' + this.link + '/' + this.mainMenu[0].link]);
          }

        }
        else if (eType === this.SWIPE_ACTION.RIGHT) {
          // we go to the left         

          if (this.mainMenu[index - 1]) {
            this._router.navigate(['/' + this.link + '/' + this.mainMenu[index - 1].link]);
          }
          else {
            this._router.navigate(['/' + this.link + '/' + this.mainMenu[this.mainMenu.length - 1].link]);
          }
        }

      }

    });
  }

  /**
   * We add active field at menu - which helps us at swiping
   * @param url 
   * @returns 
   */
  addActiveFieldAtMainMenu(url) {

    // menu index 
    let menuIndex = (!this.interestId) ? 5 : 7;

    if (this.mainMenu.length == 0) {
      return false;
    }

    let splitUrl = url.split('/');
    let urlLink = '';

    this.mainMenu.map((el) => {

      if (splitUrl[menuIndex]) {
        urlLink = splitUrl[menuIndex];
      }

      el.active = (urlLink == el.link);


    });

  }

  async turnOnOffNotifications(notifications: boolean): Promise<void> {

    this.notifications = !notifications;

    try {
      const res = await <any>this._membershipService.updateLevelNotifications(this.level._id).toPromise();

      if (res.success) {
        this._snackBar.open(`Notifications are turned ` + (this.notifications ? 'on' : 'off'), 'Close', {
          duration: 2000,
        });
      }
    } catch (err) {

      let statusText = `[${err.status}] Problems with server. Please try again.`;
      if (err.error?.msg) {
        statusText = `[${err.status}] ${err.error.msg}...`;
      }

      this._snackBar.open(statusText, 'Close', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });

      this.notifications = !this.notifications;

    }

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
