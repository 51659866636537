import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-admin-edit-user',
  templateUrl: './admin-edit-user.component.html',
  styleUrls: ['./admin-edit-user.component.scss']
})
export class AdminEditUserComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  userInfo: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AdminEditUserComponent>,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _userService: UsersService
  ) { }

  ngOnInit(): void {
    this.createForm(this.data.user);
    this.userInfo = this.data.user;
  }

  createForm(user: any) {
    this.mainForm = this._fb.group({
      email: [user.email, [Validators.required, Validators.email]],
      firstName: [user.first_name, Validators.required],
      lastName: [user.last_name, Validators.required],
      password: [''],
      role: [user.role + '', Validators.required],
      stopEmail: [user.stop_email + '', Validators.required]
    });
  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this._userService.adminEditUser(     
        this.data.user._id,
        this.mainForm.value.email,
        this.mainForm.value.firstName,
        this.mainForm.value.lastName,
        this.mainForm.value.password,
        this.mainForm.value.role,
        this.mainForm.value.stopEmail
      ).subscribe((result: any) => {

        if (result.success) {
          this._dialogRef.close(result.data);
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }
  }

}
