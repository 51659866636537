<div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="d-flex flex-wrap">
  
      <ng-container *ngFor="let question of questions">
        <ng-container *ngIf="question.headline">
            <div class="w-100">
              <h3 class="mb-2 mt-4">{{question.headline}}</h3>
            </div>
        </ng-container>

        <div class="form-row" [ngClass]="question.class">        
          <app-question [question]="question" [form]="form"></app-question>
        </div>
      </ng-container>
  
      <div class="form-row">
        <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid">Save</button>
      </div>
    </form>
  
    <div *ngIf="payLoad" class="form-row">
      <strong>Saved the following values</strong><br>{{payLoad}}
    </div>
  </div>