import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter  } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { SystemService } from 'src/app/services/system.service';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { ShowInfoRequestMembershipDialogComponent } from 'src/app/utils/show-info-request-membership-dialog/show-info-request-membership-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy { 

  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );    

  private subscription: Subscription;
  private chatSubscription: Subscription;

  visible: boolean = false;
  version: string;
  openedMainMenu: boolean;
  openedCommunityDrawer: boolean;
  startScroll: Number;

  notifications: any[] = [];
  notificationLoading: boolean = true;
  notificationError: string = '';

  messageNumber: number = 0;

  @Input() set show(show: boolean) {
    this.visible = show;
  }

  @Input() set scrollStatus(scroll: Number) {
    this.startScroll = scroll;
  }

  @Input() set drawerStatus(status: boolean) {
    this.openedMainMenu = status;
  }

  @Input() set communityDrawer(status: boolean) {
    this.openedCommunityDrawer = status;
  }

  @Output() mainMenuDrawerStatus = new EventEmitter<any>();
  @Output() communityDrawerStatus = new EventEmitter<any>();

  user: any;
  loggedIn: boolean;

  showInfoRequestDialogRef:  MatDialogRef<ShowInfoRequestMembershipDialogComponent>;

  constructor(
    private _userinfoService: UserinfoService,
    private _systemService: SystemService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _breakpointObserver: BreakpointObserver  
  ) { 
    //let firstTime = true;
    
    // If user is logged in
    this.subscription = this._userinfoService.loggedIn$.subscribe((value) => {     

      //if(!firstTime) {
        if(value) {
          this.loggedIn = true;
          this.user = this._userinfoService.getLocalInfo();

          this._systemService.getSystemVersion().subscribe((result: any) => {

            if(result.success) {
              this.version = result.data;
            }
      
          }); 
        }        
        /*
      }
      else {
        firstTime = false;
      }*/
    });

   

    //console.log($localize`:@@205:`);
  }

  ngOnInit(): void {   



  }

  showNotifications() {
    this._userinfoService.getNotifications().subscribe((result: any) => {

      if(result.success) {        
        this.notifications = result.data;

        /*
        if(this.user?.notifications) {
          this.user.notifications.notifications = this.user.notifications.notifications - result.data.length;
        }*/

      }

      this.notificationLoading = false;

    }, err => {

      this.notificationLoading = false;

      if (err.status != 200) {

        this.notificationError = 'Something went wrong, please try later'

      }
    });
  }

  markAsViewedNotification(notification: any) {

    /**
     * We don't hide "Request membership".
     * Other notifications we hide.
     */
    if(notification.activity_type != 'request-membership') {

      this._userinfoService.markNotificationAsRead(notification._id).subscribe((result: any) => {

        if(result.success) {                

          if(this.user?.notifications) {

            /**
             * We don't go to the negative
             */
            if(this.user.notifications.notifications != 0) {
              this.user.notifications.notifications = this.user.notifications.notifications - 1;
            }
          }

          this._router.navigate([notification.link]);        

        }

      }, err => {      

        if (err.status != 200) {

          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });

        }
      });

    }

  }

  openMainMenuDrawer() {
    this.mainMenuDrawerStatus.emit(this.openedMainMenu)

    this._userinfoService.hideShowSidebar(this.openedMainMenu).subscribe((result: any) => {});
  }

  openCommunityDrawer() {
    this.openedCommunityDrawer = !this.openedCommunityDrawer;
    this.communityDrawerStatus.emit(this.openedCommunityDrawer)
  }

  showReqInfo(request: any) {
    this.showInfoRequestDialogRef = this._dialog.open(
      ShowInfoRequestMembershipDialogComponent,
      {
        width: '450px',
        data: request
      }
    );
  }

  /**
   * Response to the membership request
   * @param reqId 
   * @param respond 
   */
  requestRespond(reqId: string, respond: boolean) { 
   
    this._userinfoService.respondToMembershipRequest(reqId, respond).subscribe((result: any) => {

      if(result.success) {                

        for(let n of this.notifications) {

          if(n._id == reqId) {

            if(respond == true) {
              n['accepted'] = true;
            }
            else {
              n['rejected'] = true;
            }
          }

        }       

      }
      else {

        this._snackBar.open('Error', result.message, {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }

    }, err => {      

      if (err.status != 200) {

        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });
    
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
