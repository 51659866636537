<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

    <div class="d-flex align-items-center mb-2">
        <h2 mat-dialog-title class="d-flex align-items-center mb-0">
            <mat-icon>supervisor_account</mat-icon>
            <div class="ms-2">Join user</div>
        </h2>

        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content class="pb-2">


        <div class="mat-caption mb-2">
            Secondary account will merge with the main account. If profile picture doesn't exists in the main account, the secondary will be imported. 
            The password stays the same from the main account.
        </div>


        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Main account</mat-label>
            <mat-select formControlName="userId1">
                <mat-option *ngFor="let u of users" [value]="u._id">{{ u.full_name }} | {{ u.email }}</mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Secondary account</mat-label>
            <mat-select formControlName="userId2">
                <mat-option *ngFor="let u of users" [value]="u._id">{{ u.full_name }} | {{ u.email }}</mat-option>
            </mat-select>
        </mat-form-field>

    </mat-dialog-content>


    <mat-dialog-actions>
        <button mat-raised-button color="primary" class="me-2" type="submit">
            Join
        </button>
        <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
    </mat-dialog-actions>

</form>