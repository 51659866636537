<ng-container *ngIf="loading">

    <div class="text-center">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

</ng-container>
<ng-container *ngIf="!loading">

    <div class="container-xl __container-interest-child-height h-100">
        <div class="row h-100">
            <div class="col">
                <div class="bg-main-content interest-level h-100 d-flex flex-column">

                    <app-platform-header [platformId]="platformInfo" hideRequestToJoin="true"
                        hideChangeNotificationsButton="true"></app-platform-header>

                    <mat-toolbar class="my-4">

                        <h1 class="mat-h1 mb-0">
                            <ng-container *ngIf="project; else notProject">
                                {{'Projects' | translate}}
                            </ng-container>
                            <ng-template #notProject>
                                {{'Collab. interests' | translate}}
                            </ng-template>
                        </h1>

                    </mat-toolbar>

                    <mat-sidenav-container class="mat-elevation-z0 mt-2 flex-fill" style="min-height: 300px;" autosize>
                        <mat-sidenav style="overflow-y: auto;" #drawer
                            [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                            [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="true"
                            [ngStyle]="{ 'width': drawerWidth + 'px' }" mwlResizable [enableGhostResize]="true"
                            (resizeEnd)="onResizeEnd($event)">
                            <!--[opened]="(!isHandset$ | async) ? true : ((level) ? false : true)"-->


                            <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>

                            <div class="d-flex flex-column h-100">

                                <div class="d-block d-md-none w-100">
                                    <div class="w-100 d-flex justify-content-start">
                                        <button mat-button (click)="drawer.toggle()" class="px-2"
                                            style="padding: 0; min-width: auto;">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <ng-container *ngIf="interests.length == 0">
                                    <div class="my-2 font-italic text-center mt-5">
                                        <ng-container *ngIf="!project">{{'No interests' | translate}}.</ng-container>
                                        <ng-container *ngIf="project">{{'No projects' | translate}}.</ng-container>
                                    </div>
                                </ng-container>


                                <div style="overflow-y: auto; overflow-x: auto; min-width: 200px;">
                                    <ng-container *ngIf="interests.length > 0">

                                        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl"
                                            class="interest-menu">
                                            <mat-tree-node *matTreeNodeDef="let node">

                                                <ng-container *ngIf="node.add_child">
                                                    <!-- adding WG/WP -->

                                                    <div class="pt-2 pb-4 px-2 text-center w-100">
                                                        <button mat-button class="w-100" color="primary"
                                                            (click)="addWgDialog(node.parent_id)"
                                                            style="overflow-y: hidden; font-size: 0.9em;">
                                                            <mat-icon>add</mat-icon>

                                                            <ng-container *ngIf="project; else notProject">
                                                                {{'Add WP' | translate}}
                                                            </ng-container>
                                                            <ng-template #notProject>
                                                                {{'Add WG' | translate}}
                                                            </ng-template>

                                                        </button>
                                                    </div>

                                                </ng-container>

                                                <ng-container *ngIf="!node.add_child">
                                                    <!-- WG/WP level -->

                                                    <div class="w-100 d-flex align-items-center"
                                                        [ngClass]="{ 'selected-item' : node.selected }">



                                                        <div class="wg-link d-flex align-items-center pointer"
                                                            [ngClass]="{ 'fw-bold':  node.selected }"
                                                            (click)="showLink(node.link)">

                                                            <div class="wg-indicator"></div>

                                                            <ng-container *ngIf="node.role == 0">*</ng-container>
                                                            {{ node.name | truncate : 40}}
                                                        </div>

                                                        <div *ngIf="node.role == 0" class="pointer"
                                                            style="color: #ff4081; width: 25px; margin-right: 10px; margin-top: 5px;"
                                                            (click)="sortDialog(node, 'wg')">
                                                            <mat-icon
                                                                style="width: 10px; height: 10px; font-size: 1.3rem;">
                                                                unfold_more</mat-icon>
                                                        </div>

                                                    </div>

                                                </ng-container>


                                            </mat-tree-node>
                                            <mat-tree-node *matTreeNodeDef="let node;when: hasChild">

                                                <!-- interest level -->

                                                <div class="w-100 d-flex align-items-center"
                                                    [ngClass]="{ 'selected-item' : node.selected, 'not-member' : node.role == undefined }">

                                                    <ng-container *ngIf="node.role != undefined">
                                                        <button mat-icon-button matTreeNodeToggle
                                                            [attr.aria-label]="'Toggle ' + node.name" class="ms-auto">
                                                            <mat-icon class="mat-icon-rtl-mirror">
                                                                {{treeControl.isExpanded(node) ? 'expand_more' :
                                                                'chevron_right'}}
                                                            </mat-icon>
                                                        </button>
                                                    </ng-container>

                                                    <ng-container *ngIf="node.role == undefined">
                                                        <button mat-icon-button class="ms-auto">
                                                            <mat-icon class="mat-icon-rtl-mirror">
                                                                chevron_right
                                                            </mat-icon>
                                                        </button>
                                                    </ng-container>

                                                    <div class="interest-indicator"></div>

                                                    <div class="interest-link d-flex align-items-center pointer text-break"
                                                        (click)="showLink(node.link)" title="{{ node.name }}"
                                                        [ngClass]="{ 'fw-bold':  node.selected }">

                                                        <ng-container *ngIf="node.role == 0">*</ng-container>

                                                        {{node.name | truncate : 70 }}

                                                    </div>

                                                    <div *ngIf="node.role == 0" class="pointer"
                                                        style="color: #956DF2; width: 25px; margin-right: 10px; margin-top: 5px;"
                                                        (click)="sortDialog(node, 'interest')">
                                                        <mat-icon style="width: 10px; height: 10px; font-size: 1.3rem;">
                                                            unfold_more</mat-icon>
                                                    </div>

                                                </div>

                                            </mat-tree-node>
                                        </mat-tree>

                                    </ng-container>
                                </div>

                                <div class="py-2">
                                    <div class="text-center">
                                        <ng-container *ngxPermissionsOnly="['PLATFORM_ADMIN']">
                                            <button mat-raised-button color="primary" class="my-2 mt-4 interest-button"
                                                (click)="addInterestDialog()">
                                                <span class="material-icons">
                                                    add
                                                </span> {{'Add' | translate}}
                                                <ng-container *ngIf="!project">{{'Interest' | translate}}</ng-container>
                                                <ng-container *ngIf="project">{{'Project' | translate}}</ng-container>
                                            </button>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                        </mat-sidenav>
                        <mat-sidenav-content style="background: #fff;">

                            <mat-toolbar [ngClass]="(!level) ? 'h-100 py-8' : 'h-50px mb-md-4 py-2'">


                                <button type="button" mat-raised-button (click)="drawer.toggle()" style="  
                                    font-size: .7rem;
                                ">
                                    <div class="d-flex align-items-center">
                                        <ng-container *ngIf="drawer.opened">Hide</ng-container>
                                        <ng-container *ngIf="!drawer.opened">Show</ng-container>
                                        levels
                                        <mat-icon>
                                            <ng-container *ngIf="drawer.opened">chevron_left</ng-container>
                                            <ng-container *ngIf="!drawer.opened">chevron_right</ng-container>
                                        </mat-icon>
                                    </div>
                                </button>

                            </mat-toolbar>

                            <ng-container *ngIf="!level && id">

                                <div style="height: 400px;">
                                    <h4 class="mat-h4">{{'Not found' | translate}} ...</h4>
                                </div>

                            </ng-container>

                            <ng-container *ngIf="!level && !id">

                                <!--<div style="height: 400px;">&nbsp;</div>-->
                            </ng-container>

                            <ng-container *ngIf="level">






                                <div class="px-lg-4">

                                    <mat-toolbar class="interest-header"
                                        [ngClass]="{ 'interest-header' : !interestId, 'wg-header' : interestId }">
                                        <div
                                            class="d-flex flex-column flex-md-row flex-md-wrap text-center text-md-start justify-content-center align-items-center w-100">
                                            <div class="level-avatar position-relative mx-2 me-3">
                                                <ng-container *ngxPermissionsOnly="['PLATFORM_ADMIN', 'LEVEL_ADMIN']">
                                                    <app-change-image-button [levelInfo]="level" type="avatar"
                                                        (change)="avatarChange($event)"></app-change-image-button>
                                                </ng-container>

                                                <app-level-avatar [data]="level.avatar_url"
                                                    rootClass="mat-elevation-z2 my-3 my-md-0">
                                                </app-level-avatar>

                                            </div>

                                            <!-- @todo -> glede na dimenzijo -->
                                            <div style="max-width: 600px;" class="text-wrap text-caption">
                                                {{ level.name }} <!-- | truncate : 60 -->
                                            </div>

                                            <ng-container *ngxPermissionsOnly="['LEVEL_VISITOR']">
                                                <div class="ms-auto">
                                                    <ng-container *ngIf="!level?.membership_request">
                                                        <button (click)="requestToJoin()" mat-raised-button
                                                            matTooltip="Add request to become a member of this level"
                                                            *ngIf="level.role == undefined">
                                                            <mat-icon>person_add</mat-icon> Add request
                                                        </button>
                                                    </ng-container>
                                                    <ng-container *ngIf="level?.membership_request">
                                                        <button mat-flat-button class="me-3"
                                                            *ngIf="level.role == undefined">
                                                            <mat-icon>check</mat-icon> Request sent
                                                        </button>
                                                    </ng-container>
                                                </div>
                                            </ng-container>

                                            <div class="ms-auto">

                                                <ng-container
                                                    *ngIf="!hideChangeNotificationsButton && showNotificationsButton">
                                                    <ng-container *ngIf="notifications === true; else notificationsOff">
                                                        <button mat-mini-fab class="me-3" color="secondary"
                                                            matTooltip="Turn off notifications"
                                                            (click)="turnOnOffNotifications(notifications)">
                                                            <mat-icon>notifications</mat-icon>
                                                        </button>
                                                    </ng-container>
                                                    <ng-template #notificationsOff>
                                                        <button mat-mini-fab class="me-3" color="warn"
                                                            matTooltip="Turn on notifications"
                                                            (click)="turnOnOffNotifications(notifications)">
                                                            <mat-icon>notifications_off</mat-icon>
                                                        </button>
                                                    </ng-template>
                                                </ng-container>

                                                <ng-container *ngxPermissionsOnly="['PLATFORM_ADMIN', 'LEVEL_ADMIN']">

                                                    <app-level-admin-menu [mode]="(project) ? 'project' : ''"
                                                        [wg]="interestId" [id]="level._id"
                                                        (levelDelete)="levelDeleted($event)"></app-level-admin-menu>

                                                </ng-container>

                                            </div>

                                        </div>
                                    </mat-toolbar>

                                    <ng-container *ngIf="mainMenu">

                                        <nav mat-tab-nav-bar class="my-2 mat-elevation-z2 py-2 px-md-2 bg-white"
                                            [ngClass]="{ 'interest-main-menu' : !interestId, 'wg-main-menu' : interestId }"
                                            (swipeleft)="onSwipe($event.type)" (swiperight)="onSwipe($event.type)">

                                            <ng-container *ngFor="let m of mainMenu">

                                                <a mat-tab-link routerLink="/{{link}}//{{m.link}}" routerLinkActive
                                                    #rla="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }"
                                                    [active]="rla.isActive"
                                                    [routerLinkActiveOptions]="{ exact: (m.link == '') ? true : false }"
                                                    [active]="rla.isActive">
                                                    <div>
                                                        <mat-icon *ngIf="m.icon">{{ m.icon }}</mat-icon>
                                                        <div>
                                                            {{ m.name | translate }}
                                                        </div>
                                                    </div>
                                                </a>

                                            </ng-container>

                                        </nav>

                                    </ng-container>


                                    <div class="my-4" style="min-height: 250px;">

                                        <ng-container *ngIf="!page">
                                            <app-pages [id]="level._id" [role]="level.role"></app-pages>
                                        </ng-container>

                                        <ng-container *ngIf="page == 'wiki'">
                                            <app-wiki [id]="level._id" [role]="level.role" [link]="level.link"
                                                [wikiId]="subpage" [type]="level.type"></app-wiki>
                                        </ng-container>

                                        <ng-container *ngIf="page == 'discussions'">
                                            <!--<app-discussions [id]="level._id" [role]="level.role" [topic]="subpage">
                                            </app-discussions>-->
                                            <app-discussion-sort [id]="level._id" [role]="level.role"
                                                [topic]="subpage"></app-discussion-sort>
                                        </ng-container>

                                        <ng-container *ngIf="page == 'events'">
                                            <app-events [id]="level._id" [role]="level.role" [pageId]="subpage">
                                            </app-events>
                                        </ng-container>

                                        <ng-container *ngIf="page == 'milestones'">
                                            <app-milestones [id]="level._id" [role]="level.role"></app-milestones>
                                        </ng-container>

                                        <ng-container *ngIf="page == 'tasks'">
                                            <app-tasks [id]="level._id" [role]="level.role"></app-tasks>
                                        </ng-container>

                                        <ng-container *ngIf="page == 'repository'">
                                            <app-repository [id]="level._id" [role]="level.role"></app-repository>
                                        </ng-container>

                                        <ng-container *ngIf="page == 'members'">
                                            <app-members [id]="level._id" [role]="level.role" [type]="level.type"
                                                [isProject]="project">
                                            </app-members>
                                        </ng-container>

                                        <ng-container *ngIf="page == 'meetings'">
                                            <app-meeting-dates [id]="level._id" [role]="level.role"
                                                [pageId]="subpage"></app-meeting-dates>
                                        </ng-container>

                                        <ng-container *ngIf="page == 'blog'">
                                            <app-blog [id]="level._id" [role]="level.role" [blogId]="subpage">
                                            </app-blog>
                                        </ng-container>

                                        <ng-container *ngIf="page == 'proposal'">
                                            <app-project-proposal [id]="level._id" [role]="level.role" [page]="subpage"
                                                [type]="level.type">
                                            </app-project-proposal>
                                        </ng-container>

                                        <ng-container *ngIf="page == 'wg'">
                                            <app-wg-wp-groups [id]="level._id" [role]="level.role"
                                                [isProject]="project"></app-wg-wp-groups>
                                        </ng-container>

                                    </div>

                                    <ng-container *ngIf="platformInfo">
                                        <app-wbs [id]="platformInfo._id" [currentId]="level._id" [project]="project">
                                        </app-wbs>
                                    </ng-container>

                                </div>
                            </ng-container>


                        </mat-sidenav-content>
                    </mat-sidenav-container>

                </div>
            </div>
        </div>
    </div>




</ng-container>