import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  page: string;

  constructor(
    private _route: ActivatedRoute
  ) { 

    this._route.url.subscribe((data) => {
      this.page = this._route.snapshot.paramMap.get('page');
    });

  }

  ngOnInit(): void {
    this.page = this._route.snapshot.paramMap.get('page');
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
