import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { AuthguardService } from '../services/authguard.service';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(    
    private _route: ActivatedRoute,
    private authguardService: AuthguardService,
    private _authService: SocialAuthService,
    private _layoutService: LayoutService
  ) { }

  ngOnInit() {
    if(this._route.snapshot.data.hideHeader) {

      // Set timeout because NG0100 error
      setTimeout(()=> {
        this._layoutService.hideToolbar();
      }, 0)
      
    }  
    
    this._authService.signOut().then((data) => {
      this.authguardService.logout();
    }).catch((data) => {
      this.authguardService.logout();
    });
  }

}
