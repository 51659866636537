import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserinfoService } from './userinfo.service';

@Injectable({
  providedIn: 'root'
})
export class RequestMembershipService {

  constructor(
    private _router: Router,
    private _userinfoService: UserinfoService
  ) { }

  addRequest(levelId: string, message: string) {

    return new Promise((resolve, reject) => {

      this._userinfoService.sendRequestNotification(levelId, message, this._userinfoService.getLoggedIn()).subscribe((result: any) => {              

        if(!this._userinfoService.getLoggedIn()) {
      
          // we request to login / signup
          console.log('login/signup')
  
          this._router.navigate(['/login'], { queryParams: { requestMembership: result.data } });       
  
        }

        return resolve(result.data);
  
      }, err => {
  
        if (err.status != 200) {
  
          return reject(err.statusText);

        }
      });     
      
    });

  }
}
