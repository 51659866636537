import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-activity-a',
  templateUrl: './activity-a.component.html',
  styleUrls: ['./activity-a.component.scss']
})
export class ActivityAComponent implements OnInit {

  isHandset$: Observable<boolean> = this._breakpointObserver.observe('(max-width: 800px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  _user: any;
  _activities: any = [];
  _tasks: any = [];
  _events: any = [];

  @Input() set user(user: any) { this._user = user }
  @Input() set activities(activities: any) { this._activities = [...activities] }
  @Input() set tasks(tasks: any) { this._tasks = [...tasks] }
  @Input() set events(event: any) { this._events = [...event] }

  constructor(
    private _breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
  }

}
