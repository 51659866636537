import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PDFProgressData } from 'pdfjs-dist';
import { FileService } from 'src/app/services/file.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-view-files-dialog',
  templateUrl: './view-files-dialog.component.html',
  styleUrls: ['./view-files-dialog.component.scss']
})
export class ViewFilesDialogComponent implements OnInit {

  loading: boolean = true;

  fileId: string;  
  fileInfo: any;
  error: boolean = false;
  errorMsg: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fileService: FileService,
    private _dialogRef: MatDialogRef<ViewFilesDialogComponent>,
    private _config: ConfigService
  ) { }

  ngOnInit(): void {

    this.fileId = this.data.file_id;

    this._fileService.viewFile(this.data.level_id, this.fileId).subscribe((result: any) => {

      this.loading = false;

      if(result.success) {
        this.error = false;
        this.fileInfo = result.data;
         
        /**
         * If office - we open PDF
         */
        if(this.fileInfo.office) {
          this.fileInfo.url = this._config.getApiUrl() + '/download/' + this.fileInfo._id + '.pdf'; 
        }
        else {
          this.fileInfo.url = this._config.getApiUrl() + '/download/' + this.fileInfo._id; 
        }
      }
      else {
        this.error = true;
        this.errorMsg = result.message;
      }

    }), err => {

      if (err.status != 200) {
        this.error = true;
        this.errorMsg = err;

        this.loading = false;
      }

    };

  }
  
  onProgress(progressData: PDFProgressData) {
    // do anything with progress data. For example progress indicator
    console.log(progressData)

    if(progressData.loaded == progressData.total) {

      console.log('Naloženo')

    }

  }
}
