<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

    <div class="d-flex align-items-center mb-2">
        <h2 mat-dialog-title class="d-flex align-items-center mb-0">
            <span class="material-icons">
                add
            </span> {{'Request for membership' | translate}}
        </h2>

        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content>
        <div class="mat-caption text-muted mb-1">You can send a short message, to present yourself (optional)</div>
        <!--<ckeditor [config]="config" [editor]="Editor" [(ngModel)]="text" (change)="onChange($event)"></ckeditor>-->
        <app-my-editor [toolbar]="['bold', 'alignment', 'italic']" [data]="text" (output)="onCkEditorValue($event)"></app-my-editor>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button color="primary" class="me-2" type="submit">
            {{'Send' | translate}}
        </button>
        <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
    </mat-dialog-actions>

</form>