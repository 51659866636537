import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  constructor(
    private http: HttpClient,
    private _config: ConfigService
  ) { }

  login(username: string, password: string) {

    return this.http.post(this._config.getApiUrl() + '/login', { 
      username: username, 
      password: password 
    });

  }

  loginGoogle(email: string, firstName: string, lastName: string, photoUrl: string) {

    return this.http.post(this._config.getApiUrl() + '/social/google', { 
      email: email,
      firstName: firstName,
      lastName: lastName,
      photoUrl: photoUrl
    });

  }
  
  loginFacebook(email: string, firstName: string, lastName: string, photoUrl: string) {

    return this.http.post(this._config.getApiUrl() + '/social/facebook', { 
      email: email,
      firstName: firstName,
      lastName: lastName,
      photoUrl: photoUrl
    });

  }

  forgot(email: string) {
    
    return this.http.post(this._config.getApiUrl() + '/forgot/pass', { email: email });

  }

  resetPassword(forgotPasswordId: string, password: string) {
    return this.http.post(this._config.getApiUrl() + '/forgot/update', { 
      forgotPasswordId: forgotPasswordId, 
      password: password 
    });
  }

  checkForgotId(id: string) {

    return this.http.get(this._config.getApiUrl() + '/forgot/pass/' + id);

  }

  validateEmail(link: string) {

    return this.http.get(this._config.getApiUrl() + '/email/validation/' + link);

  }

  registerWithEmail(email: string, requestForMembershipId?: string) {

    return this.http.post(this._config.getApiUrl() + '/registration/email', { 
      email: email,
      requestForMembershipId: requestForMembershipId
    });

  }

  getActivationLink(id: string) {

    return this.http.get(this._config.getApiUrl() + '/activation/link/' + id);

  }

  finishRegistration(activationLink: string, email: string, firstName: string, lastName: string, password: string, requestForMembershipId?: string) {

    return this.http.put(this._config.getApiUrl() + '/finish/registration/' + activationLink, { 
      email: email,
      firstName: firstName,
      lastName: lastName,
      password: password,
      requestForMembershipId: requestForMembershipId
    });

  }

}
