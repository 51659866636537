import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WikiService } from 'src/app/services/wiki.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ImportWneDialogComponent } from '../../import-wne-dialog/import-wne-dialog.component';
import { MatDrawer } from '@angular/material/sidenav';
import { FileService } from 'src/app/services/file.service';
import { ViewFilesDialogComponent } from '../repository/view-files-dialog/view-files-dialog.component';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-wiki',
  templateUrl: './wiki.component.html',
  styleUrls: ['./wiki.component.scss']
})
export class WikiComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  
  loading: boolean = true;
  create: boolean = false;

  pages: any = [];
  content: any;
  currentPage: any;
  wiki_id: string;
  text: string = '';
  attachments: any[] = [];

  currentId: number = 0;

  @Input() id: string;      // level id
  @Input() role: number;    // user role
  @Input() link: string; 
  @Input() set wikiId(id: string) {    
    this.create = (id == 'create') ? true: false;    
    this.selectMenu(id);    
    this.wiki_id = id;    
  }  
  @Input() large: boolean;
  @Input() platform: boolean;
  @Input() type: string;

  @ViewChild('drawer') myDrawer: MatDrawer;

  browseWikiRef: MatDialogRef<ImportWneDialogComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  viewFilesDialogRef: MatDialogRef<ViewFilesDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _wikiService: WikiService,
    private _fb: UntypedFormBuilder,
    private _router: Router,
    private _fileService: FileService,
    private _config: ConfigService,
  ) { }

  ngOnInit(): void {

    this.getPages().then((pages: any) => {
      this.loading = false;
      this.pages = pages;

      if(!this.wiki_id) {
        this.wiki_id = (this.pages[0]) ? this.pages[0]._id : null;
      }

      this.selectMenu(this.wiki_id);
      
    });

  }

  get f() { return this.mainForm.controls; }

  createForm(data?: any) {
    this.mainForm = this._fb.group({
      title: [(data && data.title) ? data.title : '', [Validators.required]],
      content: [(data && data.content) ? data.content : '', Validators.required],
      level_id: [this.id, Validators.required]
    });

    this.text = (data && data.content) ? data.content : '';

    // we add existing attachments
    if(data?.attachments) {
      this.attachments = data.attachments;
    }
  }


  //#region attachments

  uploadedAttachment(attachment: any): void {
    console.log('attachment', attachment);
    this.attachments.push({
      _id: attachment._id,
      original_name: attachment.original_name
    })
  }

  removeAttachment(fileId: string): void {

    console.log('remove attachment', fileId);

    this._fileService.deleteFile(this.id, fileId).subscribe((result: any) => {        
  
      if (result.success) {

        let index = -1;

        for(let i = 0; i < this.attachments.length; i++) {

          if(this.attachments[i]._id == fileId) {
            index = i;
          }

        }

        if(index != -1) {
          this.attachments.splice(index, 1);
        }     
       
      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });

  }

  //#endregion

  getPages() {

    return new Promise((resolve, reject) => {

      this._wikiService.getPages(this.id).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });

  }

 
  browseWiki() {

    this.browseWikiRef = this._dialog.open(
      ImportWneDialogComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id,
          type: 'wiki'
        },
        disableClose: false
      }
    );

    this.browseWikiRef.afterClosed().subscribe(result => {
      if (result) {        

        let wikis = result;

        for (let i = 0; i < wikis.length; i++) {
          this.pages.push(wikis[i]);                  
        }

      }
    });
    
  }

  

  edit(wiki: any) {    
    
    this.createForm(wiki);
    this.create = true;

  }

  delete() {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this page?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._wikiService.deletePage(this.id, this.currentPage._id).subscribe((result: any) => {

          if (result.success) {
            
            let index = 0;

            for(let i = 0; i < this.pages.length; i++) {
              if(this.pages[i]._id == this.currentPage._id) {
                index = i;
              }
            } 

            this.pages.splice(index, 1);

            if(this.pages.length > 0) {
              this.currentPage = this.pages[0];
              this.pages[0].active = true;
              this.content = this.pages[0];

              this.wiki_id = this.pages[0]._id;
              this.createForm(this.pages[0]);
            }
            else {
              this.currentPage = null;
              this.content = null;
            }
            
          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {  
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });     

      }
    });

  }

  remove() {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to remove this page?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._wikiService.removePage(this.id, this.currentPage._id).subscribe((result: any) => {

          if (result.success) {
            
            let index = 0;

            for(let i = 0; i < this.pages.length; i++) {
              if(this.pages[i]._id == this.currentPage._id) {
                index = i;
              }
            } 

            this.pages.splice(index, 1);

            if(this.pages.length > 0) {
              this.currentPage = this.pages[0];
              this.pages[0].active = true;
              this.content = this.pages[0];
            }
            else {
              this.currentPage = null;
              this.content = null;
            }
            
          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {  
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });     

      }
    });

  }

  selectMenu(id: string) {

    this.attachments = [];

    let found = false;

    for (let i = 0; i < this.pages.length; i++) {
      
        if(this.pages[i]._id == id) {
                
          this.currentPage = this.pages[i];
          this.pages[i].active = true;
          this.content = this.pages[i];    


          this.wiki_id = this.pages[i]._id;
          this.createForm(this.pages[i]);

          found = true;

          this.currentId = i;

        }
        else {
          this.pages[i].active = false;
        }

    }

    if(this.create) {

      this.createForm();

    }
    else {

      if(!found) {

        //console.log('Not found')
  
      }

    }

  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  cancel() {
    if(this.pages.length > 0) {
      this.wiki_id = (this.pages[0]) ? this.pages[0]._id : null;
    }

    this._router.navigate([this.link + '/wiki/' + ((this.wiki_id) ? this.wiki_id : '')]);
  }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {      

      let action = (this.wiki_id != 'create') ?  
        this._wikiService.editPage(
          this.wiki_id,
          this.mainForm.value.level_id,
          this.mainForm.value.title,
          this.mainForm.value.content,
          this.attachments,
        ) :
        this._wikiService.addPage(
          this.mainForm.value.level_id,
          this.mainForm.value.title,
          this.mainForm.value.content,
          this.attachments,
        );

      action.subscribe((result: any) => {
  
        if (result.success) {

          console.log('success', result);

          if(this.wiki_id != 'create') {

            for (let i = 0; i < this.pages.length; i++) {

              if(this.pages[i]._id == this.wiki_id) {
                this.pages[i] = result.data;
                this.pages[i].active = true;
              }

            }

          }
          else {
            this._router.navigate([this.link + '/wiki/' + result.data._id]);

            this.pages.push(result.data);

            this.pages.sort( function( a, b ) {
              a = a.title.toLowerCase();
              b = b.title.toLowerCase();
          
              return a < b ? -1 : a > b ? 1 : 0;
          })

          }

          this.create = false;
          this.selectMenu(result.data._id);

          this._snackBar.open('Saved', '', {
            duration: 2000
          });
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

  showLink(id: string) {

    let link = this.link + '/wiki/' + id;

    this._router.navigate([link]);      
    this.myDrawer.toggle();

  }

  onCkEditorValue(text: string) {
    this.mainForm.patchValue({
      content: text
    });
  }

  viewFile(id: string) {

    this.viewFilesDialogRef = this._dialog.open(
      ViewFilesDialogComponent,
      {        
        data: {
          level_id: this.id,
          file_id: id
        },
        panelClass: 'preview-file-dialog',
        disableClose: false
      }
    );

  }


  prepareDownload(id: string) {

    this._fileService.downloadFile(this.id, id).subscribe((result: any) => {

      if(result && result.success) {
        window.open(this._config.getApiUrl() + "/download/" + result.data, "_blank");
      }
      else {
        this._snackBar.open(result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });      
      }

    }, err => {
      if (err.status != 200) {
        this._snackBar.open('Error on the server', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });

  }
}
