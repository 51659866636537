<div class="h-100 invitation-page">

    <div class="d-flex align-items-md-center justify-content-md-center h-100">

        <ng-container *ngIf="loading; else show">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </ng-container>

        <ng-template #show>
            
            <ng-container *ngIf="step == 1; else secondStep">

                <div>

                    <div class="display-6 mb-4 mb-md-5 mt-5 mt-md-0 text-center">
                        Join Our Platform
                        <div class="text-muted" style="font-size: .6em">and stay connected</div>
                    </div>
    
                    <div class="d-flex align-items-center">
    
                        <div class="level-background-image d-none d-md-block">
                            <!--<app-level-background [data]="platformInfo?.background_image_url"></app-level-background>-->
                            <img src="assets/join-us.png" />
                        </div>
    
                        <mat-card class="join-platform-page p-md-5">
    
                            <mat-card-content>
    
    
                                <div class="d-flex align-items-center mb-4">
    
                                    <div class="me-4">
                                        <app-level-avatar [data]="platformInfo?.avatar_url" rootClass="mat-elevation-z2 platform-avatar"></app-level-avatar>
                                    </div>
    
                                    <div>
    
                                        <h1 class="fw-boldest mb-0">{{ platformInfo?.name }}</h1>
                                        <div class="text-muted fw-bold">{{ platformInfo?.description | mystriphtml | truncate:160 }}</div>
    
                                    </div>
    
                                    
    
                                </div>
    
    
                                
    
                                <ng-container *ngIf="loggedIn; else guest">

                                    <div class="mb-4">
                                        You are already logged in. Click the button below and join this platform.
                                    </div>

                                    <button type="button" mat-raised-button color="primary" (click)="joinThisPlatformAsMember()">Join this platform</button>
        
                                    <div *ngIf="showError" class="alert alert-danger text-center mt-2" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                                        {{ showErroMessage | translate }}
                                    </div>
                                   
                                </ng-container>

                                <ng-template #guest>

                                    <div class="mb-4">
                                        Finish the registration below and join the platform. Get materials from the event and stay connected with people you've met.
                                    </div>
        

                                    <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate>
    
    
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <mat-label>
                                                        {{ 'E-mail' | translate }}
                                                    </mat-label>
                                                    <input matInput formControlName="email">
                                                </mat-form-field>
                                            </div>
                                        </div>
        
                                        <div class="row">
        
                                            <div class="col">
        
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <mat-label>{{'First name' | translate}}</mat-label>
                                                    <input matInput formControlName="firstName">
                                                </mat-form-field>
        
                                            </div>
        
                                            <div class="col">
        
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <mat-label>{{'Last name' | translate}}</mat-label>
                                                    <input matInput formControlName="lastName">
                                                </mat-form-field>
        
                                            </div>
        
                                            
        
                                        </div>
        
                                        <div class="row">
                                            <div class="col">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <mat-label>
                                                        {{ 'Organization' | translate }} ({{ 'optional' | translate }})
                                                    </mat-label>
                                                    <input matInput formControlName="organization">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                      
        
                                        <div class="my-3 mat-caption">
                                            <mat-checkbox formControlName="privacyPolicy">By using our services you agree with our Privacy policy</mat-checkbox>
                                        </div>
        
                                        <button type="submit" mat-raised-button color="primary" [disabled]="!f.privacyPolicy.value">Register &amp; join</button>
        
        
                                        <div *ngIf="showError" class="alert alert-danger text-center" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                                            {{ showErroMessage | translate }}
                                        </div>
        
                                    </form>

                                </ng-template>
    
                               
    
                            </mat-card-content>
    
                        </mat-card>
    
                    </div>
    
                </div>

            </ng-container>
            <ng-template #secondStep>

                <div>

                    <div class="d-flex align-items-md-center">

                       

                        <mat-card class="join-platform-page p-md-5">
    
                            <mat-card-content class="text-center">

                               

                                <div class="display-6 mb-4 mb-md-2 mt-5 mt-md-0 text-center">
                                    Thanks!
                                </div>

                                <ng-container *ngIf="loggedIn; else guestSuccess">

                                    <div class="text-muted">
                                        You have now become a member of the platform.
                                    </div>

                                </ng-container>

                                <ng-template #guestSuccess>

                                    <ng-container *ngIf="isNewUser; else notNewUser">

                                        <div class="text-muted">
                                            You should receive an email with your password. <br><br>Meanwhile you can click on the button below and visit the platform.
                                        </div>

                                    </ng-container>
                                    <ng-template #notNewUser>
                                        <div class="text-muted">
                                            You have now become a member of the platform.
                                        </div>
                                    </ng-template>

                                </ng-template>

                                <div class="mt-4">
                                    <button mat-raised-button color="primary" (click)="viewPlatform()">View Platform</button>
                                </div>

                            </mat-card-content>

                        </mat-card>

                    </div>

                </div>

            </ng-template>

           

        </ng-template>

    </div>

</div>