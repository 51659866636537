import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { EventsService } from 'src/app/services/events.service';
import { EventsDialogComponent } from './events-dialog/events-dialog.component';
import { Subject, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { ImportWneDialogComponent } from '../../import-wne-dialog/import-wne-dialog.component';
import { CalendarOptions, GoogleCalendar, ICalendar, OutlookCalendar, YahooCalendar } from 'datebook';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {

  loading: boolean = true;
  events: any = [];
  singleEvent: any;
  errorMsg: any;

  upcomingEventsNumber: number = 0;
  pastEventsNumber: number = 0;
  mobileTabMenus: string[] = ['/', 'past', 'calendar'];

  // calendar
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  events2: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = false;

  _pageId: string;

  @Input() id: string;      // level id
  @Input() role: number;    // user role
  @Input() set pageId(id: string) {

    this._pageId = id;

    this.showEvents(this._pageId)

  }

  addEventDialogRef: MatDialogRef<EventsDialogComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  browseEventsRef: MatDialogRef<ImportWneDialogComponent>;

  constructor(
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _eventsService: EventsService,
    private _router: Router
  ) {
  }

  ngOnInit(): void {
    //this.showEvents(this._pageId)
  }

  /**
   * User friendly format
   * @param event 
   */
  /*
  prepareDates(event: any) {

    if (!event.all_day) {

      event.start_date = new Date(event.start_date);
      //event.start_date.setHours(event.start_hour);
      //event.start_date.setMinutes(event.start_minutes);

      if (event.end_date) {

        event.end_date = new Date(event.end_date);
        //event.end_date.setHours(event.end_hour);
        event.end_date.setMinutes(event.end_minutes);

      }

    }
  }*/

  showEvents(type: string) {

    if (!type) {

      this.getFutureEvents().then((events: any) => {
        this.loading = false;
        this.events = events;

        /*
        for (let event of this.events) {
          this.prepareDates(event);
        }*/

      });

    }
    else if (type == 'past') {

      this.getPastEvents().then((events: any) => {
        this.loading = false;
        this.events = events;

        for (let event of this.events) {
          //this.prepareDates(event);
        }
      });

    }
    else if (type == 'calendar') {

      this.getEvents().then((events: any) => {
        this.loading = false;
        this.events = events;

        for (let event of this.events) {
          //this.prepareDates(event);
        }

        if (this.events.length > 0) {

          while (this.events2.length > 0) {
            this.events2.pop();
          }

          for (let e of this.events) {

            this.events2.push({
              start: new Date(e.start_date),
              title: e.title
            });

          }

          this.refresh.next()

        }

      });

    }
    else {

      let checkID = new RegExp("^[0-9a-fA-F]{24}$");
      if (checkID.test(type)) {

        this._eventsService.getSpecificEvent(type, this.id).subscribe((result: any) => {

          this.loading = false;

          if (result.success) {

            this._pageId = 'single';
            this.singleEvent = result.data;            

          }
          else {
            this._pageId = '404';
          }

        }, err => {

          if (err.status != 200) {

            this._pageId = 'error';
            this.errorMsg = err.error.message;

            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });


      }
      else {
        console.log('page not found')
      }

    }

  }

  getFutureEvents() {

    return new Promise((resolve, reject) => {

      this._eventsService.getFutureEvents(this.id).subscribe((result: any) => {

        if (result.success) {
          this.upcomingEventsNumber = result.data.length

          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });

  }

  getPastEvents() {

    return new Promise((resolve, reject) => {

      this._eventsService.getPastEvents(this.id).subscribe((result: any) => {

        if (result.success) {
          
          this.pastEventsNumber = result.data.length

          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });

  }

  getEvents() {
    return new Promise((resolve, reject) => {

      this._eventsService.getEvents(this.id).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });
  }

  browseEvents() {
    this.browseEventsRef = this._dialog.open(
      ImportWneDialogComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id,
          type: 'events'
        },
        disableClose: false
      }
    );

    this.browseEventsRef.afterClosed().subscribe(result => {
      if (result) {

        this.showEvents(this._pageId)
      }
    });
  }

  addEvent() {

    this.addEventDialogRef = this._dialog.open(
      EventsDialogComponent,
      {        
        data: {
          level_id: this.id
        },
        disableClose: true,
        autoFocus: false
      }
    );

    this.addEventDialogRef.afterClosed().subscribe(result => {
      if (result) {


        if(!this._pageId) {
          this.events.push(result);

          for (let ev of this.events) {
            //this.prepareDates(ev);
          }
        }
        else {

          // we redirect
          this._router.navigate([result.link]);

        }

       

      }
    });

  }

  edit(event: any) {

    this.addEventDialogRef = this._dialog.open(
      EventsDialogComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id,
          current: event
        },
        disableClose: true
      }
    );

    this.addEventDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.singleEvent.title = result.title;


        if (!this.singleEvent.all_day) {

          this.singleEvent.start_date = new Date(result.start_date);
          //.singleEvent.start_date.setHours(result.start_hour);
          //this.singleEvent.start_date.setMinutes(result.start_minutes);

          if (this.singleEvent.end_date) {

            this.singleEvent.end_date = new Date(result.end_date);
            //this.singleEvent.end_date.setHours(result.end_hour);
            //this.singleEvent.end_date.setMinutes(result.end_minutes);

          }

        }

      }
    });
  }


  remove(id: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to remove this event?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._eventsService.removeEvents(id, this.id).subscribe((result: any) => {

          if (result.success) {

            let index = 0;

            for (let i = 0; i < this.events.length; i++) {
              if (this.events[i]._id == id) {
                index = i;
              }
            }

            let currentUrl = this._router.url;
            let tmpArray = currentUrl.split('/');
            tmpArray.pop();

            this._router.navigate([tmpArray.join('/')]);

          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }

        }, err => {

          if (err.status != 200) {
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });

      }
    });

  }

  delete(id: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this event?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._eventsService.deleteEvents(id, this.id).subscribe((result: any) => {

          if (result.success) {

            /*
            let index = 0;

            for (let i = 0; i < this.events.length; i++) {
              if (this.events[i]._id == id) {
                index = i;
              }
            }

            this.events.splice(index, 1);*/

            this.navigatePage('');

          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }

        }, err => {

          if (err.status != 200) {
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        });


      }

    });

  }

  generateCalendar(type: string, eventInfo: any) {

    let startDate = new Date(eventInfo.start_date);
    //startDate.setHours(eventInfo.start_hour);
    //startDate.setMinutes(eventInfo.start_minutes);

    let endDate = new Date(eventInfo.end_date);
    //endDate.setHours(eventInfo.end_hour);
    //endDate.setMinutes(eventInfo.end_minutes);

    let options: CalendarOptions = {
      title: eventInfo.title,
      start: startDate,
      end: endDate
    }

    switch (type) {
      case 'ical': {

        let icalendar = new ICalendar(options)
        icalendar.download();

        break;
      }
      case 'yahoo': {

        let yahooCalendar = new YahooCalendar(options);
        window.open(yahooCalendar.render(), "_blank");

        break;
      }
      case 'google': {

        let googleCalendar = new GoogleCalendar(options);
        window.open(googleCalendar.render(), "_blank");

        break;

      }
      case 'microsoft': {

        let outlookCalendar = new OutlookCalendar(options)
        window.open(outlookCalendar.render(), "_blank");

        break;

      }
      default: {
        break;
      }
    }

  }

  navigatePage(page: string) {

    let currentUrl = this._router.url;
    let tmpArray = currentUrl.split('/');

    if (tmpArray.length == 5 || tmpArray.length == 7)
      tmpArray.pop();

    this._router.navigate([tmpArray.join('/') + '/' + page]);

  }

  navigateTab($event) {
    this.navigatePage(this.mobileTabMenus[$event.index]);    
  }

  viewEvent(link: string) {
    this._router.navigate([link]);
  }

  ngOnDestroy() {

  }

}
