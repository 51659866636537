import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../interface/user';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root',
})
export class LevelService {
  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) {}

  addPlatform(name: string, slug: string, ownerId?: string) {
    return this._http.post(
      this._config.getApiUrl() + '/api/level/platform',
      {
        name: name,
        type: 'cplatform',
        slug: slug,
        owner_id: ownerId,
      },
      this._httpOptionsService.getHeader()
    );
  }

  createPlatformUser(name: string, slug: string, description: string) {
    return this._http.post(
      this._config.getApiUrl() + '/api/level/platform/user',
      {
        name: name,
        //type: 'cplatform',
        slug: slug,
        description: description,
      },
      this._httpOptionsService.getHeader()
    );
  }

  /**
   * Dodajanje nivoja s strani koordinatorja / lastnika (platforme | interesa)
   * Na nivoju platforme se dodajajo interesi
   * Na nivoju interesa se dodajajo WG
   * @param name
   * @param type
   * @param sublevelType
   * @param coordinatorId
   * @param parentId
   * @param platformId
   * @returns
   */
  addLevel(
    name: string,
    type: string,
    sublevelType: string,
    coordinatorId: string,
    parentId: string,
    platformId: string
  ) {
    return this._http.post(
      this._config.getApiUrl() + '/api/level',
      {
        name: name,
        type: type,
        sublevel_type: sublevelType,
        coordinator_id: coordinatorId,
        parent_id: parentId,
        platform_id: platformId,
      },
      this._httpOptionsService.getHeader()
    );
  }

  addMember(levelId: string, users: string[], message?: string) {
    return this._http.post(
      this._config.getApiUrl() + '/api/level/member',
      {
        level_id: levelId,
        users: users,
        message: message,
      },
      this._httpOptionsService.getHeader()
    );
  }

  getLevels() {
    return this._http.get(
      this._config.getApiUrl() + '/api/level',
      this._httpOptionsService.getHeader()
    );
  }

  // for dashboard to show only specific
  getLevelsSpecifics(type: string) {
    return this._http.get(
      this._config.getApiUrl() + '/api/level/spec/' + type,
      this._httpOptionsService.getHeader()
    );
  }

  searchLevelSpecifics(type: string, search: string) {
    return this._http.put(
      this._config.getApiUrl() + '/api/level/search/' + type + '/' + search,
      {},
      this._httpOptionsService.getHeader()
    );
  }

  // get level menu
  getLevelHierarchy(platformId: string, levelType: string) {
    return this._http.get(
      this._config.getApiUrl() +
        '/api/level/tree/' +
        platformId +
        '/' +
        levelType,
      this._httpOptionsService.getHeader()
    );
  }

  getLevelHierarchyPublic(platformId: string, levelType: string) {
    return this._http.get(
      this._config.getApiUrl() +
        '/api/level/public/tree/' +
        platformId +
        '/' +
        levelType,
      this._httpOptionsService.getHeader()
    );
  }

  getLevelChildren(levelId: string, levelType: string) {
    return this._http.get(
      this._config.getApiUrl() +
        '/api/level/children/' +
        levelId +
        '/' +
        levelType,
      this._httpOptionsService.getHeader()
    );
  }

  getLevel(levelId: string) {
    return this._http.get(
      this._config.getApiUrl() + '/api/level/' + levelId,
      this._httpOptionsService.getHeader()
    );
  }

  getLevelPublic(levelId: string) {
    return this._http.get(
      this._config.getApiUrl() + '/api/level/public/' + levelId,
      this._httpOptionsService.getHeader()
    );
  }

  updateLevel(
    levelId: string,
    slug: string,
    description: string,
    isPublished: boolean = false
  ) {
    return this._http.put(
      this._config.getApiUrl() + '/api/level/update/' + levelId,
      {
        slug: slug,
        description: description,
        published: isPublished,
      },
      this._httpOptionsService.getHeader()
    );
  }

  updateLevelName(levelId: string, name: string) {
    return this._http.put(
      this._config.getApiUrl() + '/api/level/update/' + levelId + '/name',
      {
        value: name,
      },
      this._httpOptionsService.getHeader()
    );
  }

  deleteLevel(levelId: string) {
    return this._http.delete(
      this._config.getApiUrl() + '/api/level/' + levelId,
      this._httpOptionsService.getHeader()
    );
  }

  saveSortOrder(parent_id, levels: any[], sublevel_type?: string) {
    return this._http.put(
      this._config.getApiUrl() + '/api/level/sort/' + parent_id,
      {
        levels: levels,
        sublevel_type: sublevel_type,
      },
      this._httpOptionsService.getHeader()
    );
  }

  resolvePlatform(slug: string) {
    return this._http.get(
      this._config.getApiUrl() + '/api/level/resolve/' + slug,
      this._httpOptionsService.getHeader()
    );
  }

  changeCoordinator(levelId: string, coordinatorId: string, message?: string) {
    return this._http.put(
      this._config.getApiUrl() + '/api/level/change/coordinator/' + levelId,
      {
        coordinator_id: coordinatorId,
        message: message,
      },
      this._httpOptionsService.getHeader()
    );
  }

  changeRole(memberId: string, role: number) {
    return this._http.put(
      this._config.getApiUrl() + '/api/level/change/role/' + memberId,
      {
        role: role,
      },
      this._httpOptionsService.getHeader()
    );
  }

  joinPlatformAsGuest(levelId: string, userInfo: User) {
    return this._http.post(this._config.getApiUrl() + '/api/level/join/guest', {
      level_id: levelId,
      email: userInfo.email,
      first_name: userInfo.firstName,
      last_name: userInfo.lastName,
      organization: userInfo.organization,
    });
  }

  joinPlatformAsMember(levelId: string) {
    return this._http.post(
      this._config.getApiUrl() + '/api/level/join/existing',
      {
        level_id: levelId,
      },
      this._httpOptionsService.getHeader()
    );
  }

  //#region Dodajanje za CRI grupo
  joinLevelAsGuest(levelId: string, userInfo: User) {
    return this._http.post(
      this._config.getApiUrl() + '/api/level/join/guest2',
      {
        level_id: levelId,
        email: userInfo.email,
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        organization: userInfo.organization,
      }
    );
  }

  joinLevelAsMember(levelId: string) {
    return this._http.post(
      this._config.getApiUrl() + '/api/level/join/existing2',
      {
        level_id: levelId,
      },
      this._httpOptionsService.getHeader()
    );
  }
  //#endregion

  recommendPlatforms() {
    return this._http.get(
      this._config.getApiUrl() + '/api/level/recommend',
      this._httpOptionsService.getHeader()
    );
  }
}
