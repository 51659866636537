<div *ngIf="!loading" class="my-2">
    <div *ngIf="resultsLength == 0">

        No files

    </div>

    <div [hidden]="resultsLength == 0">

        <button mat-raised-button type="button" (click)="joinDialog()">
            <mat-icon>
                supervisor_account
            </mat-icon>
            Join members
        </button>

        <table mat-table matSort [dataSource]="usersData" class="mat-elevation-z2 w-100 mt-3">

            <ng-container matColumnDef="activated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="activated"> Activated </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon class="text-success" *ngIf="element.activated">done</mat-icon>
                    <mat-icon class="text-danger" *ngIf="!element.activated">close</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="validated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="validated"> E-mail val </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon class="text-success" *ngIf="element.validated">done</mat-icon>
                    <mat-icon class="text-danger" *ngIf="!element.validated">close</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="experiment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="experiment"> Experiment </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon class="text-success" *ngIf="element.experiment_test">done</mat-icon>
                    <mat-icon class="text-danger" *ngIf="!element.experiment_test">close</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="full_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="full_name"> Full name </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.full_name | truncate:80 }}
                </td>
            </ng-container>

            <ng-container matColumnDef="credits">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="credit"> Credits </th>
                <td mat-cell *matCellDef="let element">

                    <button class="small-btn" mat-button color="warn" (click)="removeCredit(element._id)">-</button>

                    {{element.credits}}

                    <button class="small-btn" mat-button color="primary" (click)="addCredit(element._id)">+</button>
                </td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="role"> Role </th>
                <td mat-cell *matCellDef="let element">
                    {{element.role}}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="email"> Email </th>
                <td mat-cell *matCellDef="let element">
                    {{element.email}}
                </td>
            </ng-container>


            <ng-container matColumnDef="stop_email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="stop_email"> Stop email </th>
                <td mat-cell *matCellDef="let element">

                    <ng-container *ngIf="!element.stop_email">
                        <div class="d-flex align-items-center text-success">
                            <mat-icon>play_arrow</mat-icon>
                            <div class="mat-caption">Send emails</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="element.stop_email">
                        <div class="d-flex align-items-center text-danger">
                            <mat-icon>not_interested</mat-icon>
                            <div class="mat-caption">Stop sending</div>
                        </div>
                    </ng-container>

                </td>
            </ng-container>

            <ng-container matColumnDef="date_created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Created </th>
                <td mat-cell *matCellDef="let element">{{ element.date_created | date: "medium" }}</td>
            </ng-container>



            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action</th>
                <td mat-cell *matCellDef="let element">

                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editUser(element)">
                            <mat-icon>create</mat-icon>
                            <span>Edit user</span>
                        </button>
                        <button mat-menu-item (click)="toggleExperiment(element._id, !element.experiment_test)">
                            <mat-icon>star_border</mat-icon>
                            <span>Toggle experiment mode</span>
                        </button>
                        <button mat-menu-item (click)="changeActivatedStatus(element._id, !element.activated)">                            
                            <ng-container *ngIf="!element.activated">
                                <mat-icon>check</mat-icon>
                                <span>Activate user</span>
                            </ng-container>
                            <ng-container *ngIf="element.activated">
                                <mat-icon>close</mat-icon>
                                <span>De-activate user</span>
                            </ng-container>                            
                        </button>
                        <ng-container *ngIf="!element.validated">
                            <button mat-menu-item (click)="changeValidatedStatus(element._id, !element.validated)">                            
                                <mat-icon>check</mat-icon>
                                <span>Validate e-mail</span>
                            </button>
                            <button mat-menu-item (click)="sendValidationEmail(element._id)">                            
                                <mat-icon>send</mat-icon>
                                <span>Send validation e-mail</span>
                            </button>
                        </ng-container>
                        <button mat-menu-item (click)="deleteUser(element._id)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete user</span>
                        </button>
                    </mat-menu>

                    <!--

                    <button mat-raised-button  color="primary" class="small-btn me-3">
                        <span class="material-icons">
                            create
                        </span>
                    </button>
                    <button mat-raised-button class="small-btn me-3">
                        <span class="material-icons">
                            visibility
                        </span>
                    </button>
                    <button mat-raised-button color="warn" class="small-btn" (click)="deleteUser(element._id)">
                        <span class="material-icons">
                            delete
                        </span>
                    </button>-->

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>



    </div>
</div>