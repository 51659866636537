import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class MilestonesService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  addMilestone(levelId: string, name: string, due: number, description: string) {

    return this._http.post(this._config.getApiUrl() + '/api/milestones', {    
      level_id: levelId,
      name: name,
      due: due,
      description: description
    }, this._httpOptionsService.getHeader())

  }

  editMilestone(milestoneId: string, levelId: string, name: string, due: number, description: string) {

    return this._http.put(this._config.getApiUrl() + '/api/milestones/' + milestoneId, {    
      level_id: levelId,
      name: name,
      due: due,
      description: description
    }, this._httpOptionsService.getHeader())

  }

  deleteMilestone(milestoneId: string, levelId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/milestones/' + levelId + '/' + milestoneId, this._httpOptionsService.getHeader())
  }

  getMilestones(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/milestones/' + levelId, this._httpOptionsService.getHeader())
  }
}
