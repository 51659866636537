import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ConfigService } from 'src/app/services/config.service';
import { FileService } from 'src/app/services/file.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-upload-image-dialog',
  templateUrl: './upload-image-dialog.component.html',
  styleUrls: ['./upload-image-dialog.component.scss']
})
export class UploadImageDialogComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  aspectRatio: Number = 3 / 1;

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<UploadImageDialogComponent>,
    private _dialog: MatDialog,
    private _fileService: FileService,
    private _snackBar: MatSnackBar,
    private _config: ConfigService
  ) { }

  ngOnInit(): void {
    if(this.data.type == 'avatar') {
      this.aspectRatio = 1 / 1;
    }
  }

  removeImage() {
    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'You will remove this image',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._fileService.deletePublicImage(this.data.type, this.data.levelInfo._id).subscribe((result: any) => {

          if(result.success) {
            
            if(this.data.type == 'avatar') {
              this.data.levelInfo.avatar = null;
              this.data.levelInfo.avatar_url = null;
            }
            else {
              this.data.levelInfo.background_image = null;
              this.data.levelInfo.background_image_url = null;
            }
            
          }
          else {    
    
            this._snackBar.open(result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
    
          }
    
        }, err => {
    
          if (err.status != 200) {
    
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
    
          }
        });

      }

    });
  }

  uploadedImage(image: any): void {
    this.imageChangedEvent = image.url;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: HTMLImageElement) {
      // show cropper
      console.log('Image loaded')
  }
  cropperReady() {
      // cropper ready
      console.log('Cropper ready')
  }
  loadImageFailed() {
      // show message
      this._snackBar.open('Error loading image', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
  }

  saveCropped() {
    this._fileService.saveCroppedImage(this.data.levelInfo._id, this.data.type, this.croppedImage).subscribe((result: any) => {

      if(result.success) {
        this._dialogRef.close(result.data);
      }
      else {

        this._dialogRef.close(false);

        this._snackBar.open(result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }

    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });
  }

}
