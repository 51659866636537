<div class="d-flex flex-column text-center align-items-center">

    <img src="/assets/new-platform.png" class="mb-4" />

    <div class="mat-display-1 mb-1">
        {{'Congratulations' | translate}}!
    </div>
    <h2 class="mat-h2">{{"You've successfully created a new Platform" | translate}}</h2>

    <div class="my-2">
        <button mat-raised-button color="primary" [mat-dialog-close]="false">            
            {{'Start' | translate}}
        </button>
    </div>

</div>

