<div class="h-100">
    <div class="w-100 h-100">

        <ng-container *ngIf="loading">
            <div class="d-flex h-100 align-items-center justify-content-center">
                <mat-spinner></mat-spinner>
            </div>
        </ng-container>

        <ng-container *ngIf="!loading">

            <div class="container h-100">
                <div class="row h-100">
                    <div class="col-12 d-flex  flex-column justify-content-center align-items-center">

                        

                        <ng-container *ngIf="!requestNotFound">

                            <img src="assets/exarca.png" width="200" />

                            <h1 class="mat-h1 my-5">{{'Your subscription had been successfully adjusted' | translate}}</h1>

                            <div class="text-muted">
                                {{'You can always change your subscription settings at the account settings' | translate}}
                            </div>

                            <button mat-button color="primary" routerLink="/account-settings">{{'Account settings' | translate}}</button>

                        </ng-container>

                        <ng-container *ngIf="requestNotFound">

                            <h1 class="mat-h1 my-5">{{'Request not found' | translate}}</h1>

                        </ng-container>

                    </div>
                </div>
            </div>

        </ng-container>

    </div>
</div>



