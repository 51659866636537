import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OrgChart } from 'd3-org-chart';
import { LevelService } from 'src/app/services/level.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-wbs-dialog',
  templateUrl: './wbs-dialog.component.html',
  styleUrls: ['./wbs-dialog.component.scss']
})
export class WbsDialogComponent implements OnInit {
  loading: boolean = true;
  private chart: OrgChart;
  private levelsData = [];
  private currentIndex = 7;
  private availableZoom = [.1, .2, .3, .4, .5, .6, .7, .8, .9, 1];

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  @ViewChild('chartContainer') chartContainer: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<WbsDialogComponent>,
    private _levelService: LevelService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _router: Router
  ) { }

  ngOnInit(): void { }

  private truncate(str: string, n: number): string {
    return (str.length > n) ? str.substr(0, n - 1) + '&hellip;' : str;
  }

  ngAfterViewInit() {
    if (!this.chart) {
      this.chart = new OrgChart();
    }

    this._levelService.getLevelHierarchy(this.data.level_id, !this.data.project ? 'interest' : 'project').subscribe((result: any) => {
      if (result.success) {
        this.prepareNodes(result.data);
        setTimeout(() => {
          this.loading = false;
          this.updateChart();
        }, 1000);
      } else {
        this.showError(result.message);
      }
    }, err => {
      if (err.status != 200) {
        this.showError('Error');
      }
    });
  }

  private prepareNodes(data: any) {
    const rootNode = this.createNodeData(data);
    this.levelsData.push(rootNode);

    if (data.interest && data.interest.length > 0) {
      data.interest.forEach((interest: any) => {
        const interestNode = this.createNodeData(interest, data._id);
        this.levelsData.push(interestNode);

        if (interest.wg && interest.wg.length > 0) {
          interest.wg.forEach((wg: any) => {
            if (wg.name) {
              const wgNode = this.createNodeData(wg, interest._id);
              this.levelsData.push(wgNode);
            }
          });
        }
      });
    }
  }

  private createNodeData(item: any, parentNodeId: string = null): any {
    return {
      nodeId: item._id,
      parentNodeId: parentNodeId,
      itemData: item,  // Save the entire item for use in nodeContent function
      expanded: true,
      width: 250,
      height: 70,
      borderColor: this.getNodeColor(item),
      backgroundColor: this.getNodeBackgroundColor(item),
      connectorLineColor: {
        red: 220,
        green: 189,
        blue: 207,
        alpha: 1
      }
    };
  }

  private getNodeColor(item: any) {
    return this.data.current_id === item._id
      ? { red: 255, green: 255, blue: 255, alpha: 1 }
      : { red: 110, green: 194, blue: 219, alpha: 1 };
  }

  private getNodeBackgroundColor(item: any) {
    if (this.data.current_id === item._id) {
      return { red: 255, green: 255, blue: 255, alpha: 1 };
    } else if (item.parentNodeId) {
      return { red: 149, green: 109, blue: 242, alpha: 1 };
    } else {
      return { red: 110, green: 194, blue: 219, alpha: 1 };
    }
  }

  private showError(message: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['error-snackbar']
    });
  }

  updateChart(zoom?: number) {
    if (!this.levelsData.length || !this.chart) {
      return;
    }
    this.chart
      .container(this.chartContainer.nativeElement)
      .data(this.levelsData)
      .svgWidth(1000)
      .initialZoom(zoom ? zoom : this.availableZoom[this.currentIndex])
      .nodeContent((d) => this.getNodeTemplate(d))  // Use nodeContent for custom node rendering
      .onNodeClick(d => this.nodeClicked(d))
      .render();
  }

  private getNodeTemplate(d: any) {
    const item = d.data.itemData;
    const color = this.data.current_id === item._id ? '#6EC2DB' : '#956DF2';
    return `
      <div style="width: 100%; height: 100%; padding: 10px; box-sizing: border-box; border: 1px solid #ddd; border-radius: 5px; background-color: ${color}; display: flex; justify-content: center; align-items: center;">
        <div style="font-size:18px; color: #fff;" title="${item.name}">${this.truncate(item.name, 25)}</div>      
      </div>`;
  }

  nodeClicked(d: any) {
    this._levelService.getLevel(d.nodeId).subscribe((result: any) => {
      if (result.success) {
        this.confirmDialogRef = this._dialog.open(ConfirmDialogComponent, {
          width: '350px',
          data: {
            title: 'Visit level ...',
            text: 'Would you like to visit - ' + result.data.name + '?',
            leftButton: 'Cancel',
            rightButton: 'Yes'
          },
          disableClose: true
        });

        this.confirmDialogRef.afterClosed().subscribe(confirmation => {
          if (confirmation) {
            this._router.navigate([result.data.link]);
            this._dialogRef.close();
          }
        });
      }
    });
  }

  zoomIn() {
    this.currentIndex++;
    if (this.currentIndex <= 9) {
      this.updateChart(this.availableZoom[this.currentIndex]);
    } else {
      this.currentIndex--;
    }
  }

  zoomOut() {
    this.currentIndex--;
    if (this.currentIndex >= 0) {
      this.updateChart(this.availableZoom[this.currentIndex]);
    } else {
      this.currentIndex++;
    }
  }
}
