import { Component, Input, OnInit } from '@angular/core';

import Icons from './icons'

@Component({
  selector: 'app-activity-icon',
  templateUrl: './activity-icon.component.html',
  styleUrls: ['./activity-icon.component.scss']
})
export class ActivityIconComponent implements OnInit {

  selectedIcon: string;

  @Input() set type(icon: string) {
    
    if(icon) {
      
      for(let i of Icons) {
        if(i.name == icon) {
          this.selectedIcon = i.icons;
        }
      }

    }

  } 

  constructor() { 
  }

  ngOnInit(): void {
  }

}
