import { Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MeetingDatesService } from 'src/app/services/meeting-dates.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { CalendarEvent, CalendarView } from 'angular-calendar';

@Component({
  selector: 'app-meeting-dates',
  templateUrl: './meeting-dates.component.html',
  styleUrls: ['./meeting-dates.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class MeetingDatesComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;

  loading: boolean = true;
  create: boolean = false;
  edit: boolean = false;
  meetingId: string = '';

  meetings: any = [];

  displayedColumns: string[] = ['name', 'date', 'created_by', 'action'];
  meetingData: MatTableDataSource<any>;
  resultsLength = 0;

  text: string = '';

  hours: any[] = []
  minutes: any[] = []

  startHour: number = 9;
  startMinutes: number = 0; 
  endHour: number = 10;
  endMinutes: number = 0;

  subpage: string;
  mobileTabMenus: string[] = ['/', 'past', 'calendar'];

  // calendar
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  events2: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = false;

  
  @Input() id: string;      // level id
  @Input() role: number;    // user role
  @Input() set pageId(id: string) {
    this.subpage = id;
    
    // we check if page exist
    if(this.mobileTabMenus.indexOf(id) == -1 && id) {
      this.subpage = '404';
      return;
    }

    this.getMeetings(this.subpage).then((data: any) => {
      this.loading = false;

      if(!this.meetingData) {
        this.meetingData = new MatTableDataSource(data);                   
      }
      else {
        this.meetingData.data = [];
        this.meetingData.data = data;
      }

      this.meetings = data;

      // we prepare for calendar
      this.events2 = [];
      for(let m of data) {
        this.events2.push({
          start: new Date(m.start_date),
          end: new Date(m.end_date),
          title: m.title,
        });
      }

      this.resultsLength =  data.length;
    }).catch((err) => {
      this.loading = false;
    });

  }

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  
  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _meetingsService: MeetingDatesService,
    private _fb: UntypedFormBuilder,
    private _router: Router,
  ) { 

    for(let i = 0; i < 24; i++) {      
      this.hours.push( i );
    }

    for(let i = 0; i < 60; i++) {
      this.minutes.push( i );
    }

  }

  ngOnInit(): void {    

    /*
    this.getMeetings().then((data: any) => {
      this.loading = false;

      if(!this.meetingData) {
        this.meetingData = new MatTableDataSource(data);                   
      }
      else {
        this.meetingData.data = [];
        this.meetingData.data = data;
      }

      this.meetings = data;
      this.resultsLength =  data.length

    });*/

  }

  createForm(data?: any) {    

    this.meetingId = '';

    if(data) {
      this.edit = true;
      this.meetingId = data._id;
    }
    else {
      this.edit = false;
    }
 
    this.mainForm = this._fb.group({
      level_id: [this.id, Validators.required],
      title: [(data && data.title) ? data.title : '', [Validators.required]],
      description: [(data && data.description) ? data.description : ''],
      start_date: [(data && data.start_date) ? data.start_date : '', Validators.required],
      start_hour: (data && data.start_hour) ? data.start_hour : 0,
      start_minutes: (data && data.start_minutes) ? data.start_minutes : 0,
      end_date: [(data && data.end_date) ? data.end_date : ''],
      end_hour: (data && data.end_hour) ? data.end_hour : 0,
      end_minutes: (data && data.end_minutes) ? data.end_minutes : 0,
      all_day: [(data && data.all_day != undefined) ? data.all_day : true, Validators.required]      
    });

    this.text = (data && data.description) ? data.description : '';
  }

  /*
  getMeetings_old() {
    return new Promise((resolve, reject) => {

      this._meetingsService.getEvents(this.id).subscribe((result: any) => {

        if (result.success) {

          for(let r of result.data) {

            r.start_date = new Date(r.start_date);
            r.start_date.setHours(r.start_hour);
            r.start_date.setMinutes(r.start_minutes);

            if(r.end_date) {
             
              r.end_date = new Date(r.end_date);
              r.end_date.setHours(r.end_hour);
              r.end_date.setMinutes(r.end_minutes);

            }
          }

          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });
  }
  */

  getMeetings(subpage?: string) {
    return new Promise(async (resolve, reject) => {

      try {
        const result: any = await this._meetingsService.getEvents(this.id, subpage).toPromise();

        if (result.success) {

          for(let r of result.data) {

            r.start_date = new Date(r.start_date);
            r.start_date.setHours(r.start_hour);
            r.start_date.setMinutes(r.start_minutes);

            if(r.end_date) {
             
              r.end_date = new Date(r.end_date);
              r.end_date.setHours(r.end_hour);
              r.end_date.setMinutes(r.end_minutes);

            }
          }

          resolve(result.data);

        } else {
          throw new Error(result.message);
        }

      } catch (error: any) {

        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        this._snackBar.open('Error: ' + errorMessage, 'OK', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

        reject(error);
      }

    });
  }

  addEvent() {
    this.createForm();
    this.create = true;
  }

  cancel() {
    this.create = false;
  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {   

      let action = (!this.edit) ? 
      this._meetingsService.addEvent(
        this.mainForm.value.level_id,
        this.mainForm.value.title,
        this.mainForm.value.description,
        this.mainForm.value.start_date,
        this.mainForm.value.start_hour,
        this.mainForm.value.start_minutes,
        this.mainForm.value.end_date,
        this.mainForm.value.end_hour,
        this.mainForm.value.end_minutes,
        this.mainForm.value.all_day
      ) : 
      this._meetingsService.editEvents(
        this.meetingId,
        this.mainForm.value.level_id,
        this.mainForm.value.title,
        this.mainForm.value.description,
        this.mainForm.value.start_date,
        this.mainForm.value.start_hour,
        this.mainForm.value.start_minutes,
        this.mainForm.value.end_date,
        this.mainForm.value.end_hour,
        this.mainForm.value.end_minutes,
        this.mainForm.value.all_day
      )
      ;

      action.subscribe((result: any) => {
  
        if (result.success) {

          this.create = false;

          if(!this.edit) {

            result.start_date = new Date(result.start_date);
            result.start_date.setHours(result.start_hour);
            result.start_date.setMinutes(result.start_minutes);

            if(result.end_date) {
             
              result.end_date = new Date(result.end_date);
              result.end_date.setHours(result.end_hour);
              result.end_date.setMinutes(result.end_minutes);

            }

            this.meetings.push(result.data);

            this.meetingData.data = this.meetings; 
            this.resultsLength = this.meetings.length;         

          }
          else {

            for(let m of this.meetings) {

              if(m._id == this.meetingId) {

                m.title = this.mainForm.value.title;
                m.description = this.mainForm.value.description;
                m.end_date = this.mainForm.value.end_date;
                m.all_day = this.mainForm.value.all_day;

                m.start_date = new Date(this.mainForm.value.start_date);
                m.start_date.setHours(this.mainForm.value.start_hour);
                m.start_date.setMinutes(this.mainForm.value.start_minutes);
                m.start_hour = this.mainForm.value.start_hour;
                m.start_minutes = this.mainForm.value.start_minutes;

                if(m.end_date) {
                
                  m.end_date = new Date(this.mainForm.value.end_date);
                  m.end_date.setHours(this.mainForm.value.end_hour);
                  m.end_date.setMinutes(this.mainForm.value.end_minutes);
                  m.end_hour = this.mainForm.value.end_hour;
                  m.end_minutes = this.mainForm.value.end_minutes;

                }

              }
 
            }

            this.meetingData.data = this.meetings; 
          }

        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }
  }

  allDayClick(checked: boolean) {
    if(checked) {
     this.mainForm.patchValue({
       start_hour: 0,
       start_minutes: 0,
       end_hour: 0,
       end_minutes: 0
     });
    }
    else {
     this.mainForm.patchValue({
       start_hour: this.startHour,
       start_minutes: this.startMinutes,
       end_hour: this.endHour,
       end_minutes: this.endMinutes
     });
    }
   }

  onCkEditorValue(text: string) {
    this.mainForm.patchValue({
      description: text
    });
  }

  editMeeting(meeting: any) {

    this.createForm(meeting);
    this.create = true;
    
  }
  
  deleteMeeting(meetingId: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this meeting?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._meetingsService.deleteEvents(meetingId, this.id).subscribe((result: any) => {

          if (result.success) {

            let index = -1;

            for(let i = 0; i < this.meetings.length; i++) {

              if(this.meetings[i]._id == meetingId) {
                index = i;
              }

            }

            if(index != -1) {
              this.meetings.splice(index, 1);
            }            

            this.meetingData.data = this.meetings; 
            this.resultsLength = this.meetings.length;          
          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {  
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        }); 


      }
    });

  }

  navigatePage(page: string) {


    // we get current url
    let currentUrl = this._router.url;

    // we split it
    let tmpArray = currentUrl.split('/');

    let lastUrl = tmpArray[tmpArray.length - 1];

    // we remove last element if it is not meetings
    if (lastUrl != 'meetings') {      
      tmpArray.pop();
    }

    // we push new page
    tmpArray.push(page);

    // we navigate to new url
    this._router.navigate([tmpArray.join('/')]);
    

  }

  navigateTab($event) {
    this.navigatePage(this.mobileTabMenus[$event.index]);    
  }

}
