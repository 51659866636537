import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from 'src/app/services/config.service';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { UserInfoDialogComponent } from './user-info-dialog/user-info-dialog.component';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {

  user: any;
  userInfo: any;

  @Input() set info(user: any) {

    (async () => {

      this.user = user;

      if (this.user && this.user.avatar_url) {
        await this.setupAvatar(this.user);
      }

    })();


  }

  @Input() width: number;
  @Input() fontSize: number
  @Input() rootClass: string;
  @Input() disableLink: boolean;

  customWidth: string;
  textColor: String = 'black';

  viewUserInfoDialogRef: MatDialogRef<UserInfoDialogComponent>;

  constructor(
    private _userinfoService: UserinfoService,
    private _dialog: MatDialog,
    private _config: ConfigService,
    private _http: HttpClient,
    private _sanitizer: DomSanitizer,
  ) { }

  async ngOnInit(): Promise<void> {

    this.userInfo = this._userinfoService.getLocalInfo();

    if (this.user) {

      if (this.userInfo?._id) {
        if (this.user._id.toString() == this.userInfo._id.toString()) {
          this.disableLink = true;
        }
      }


      /** TMP */
      if (!this.user.color) {
        this.user.color = '#3e65f8';
        this.user.text_color = this.getContrast(this.user.color);
      }

      await this.setupAvatar(this.user);

      /*
      if(this.user.avatar_url) {

        if(!this.user.avatar_url.includes(this._config.getApiUrl())) {
          this.user.avatar_url = this._config.getApiUrl() + this.user.avatar_url;
        }
  
      }*/
    }

    if (!isNaN(this.width)) {
      this.customWidth = this.width + 'px';
    }

  }

  getContrast(hexcolor: string): string {

    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }

    // Convert to RGB value
    let r = parseInt(hexcolor.substr(0, 2), 16);
    let g = parseInt(hexcolor.substr(2, 2), 16);
    let b = parseInt(hexcolor.substr(4, 2), 16);

    // Get YIQ ratio
    let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    // Check contrast
    return (yiq >= 128) ? 'black' : 'white';

  };


  showUserDialog(user: any): void {

    this.viewUserInfoDialogRef = this._dialog.open(
      UserInfoDialogComponent,
      {
        width: '350px',
        data: {
          user: user
        },
        disableClose: false
      }
    );

  }

  // setup user avatar
  // check image if exist, if not add default image
  async setupAvatar(user: any): Promise<boolean> {
    // we check if avatar exists via GET request
    // if not we add default avatar
    if (user.avatar_url) {

      try {
        // we check if avatar_url is string
        if (typeof user.avatar_url != 'string') {
          throw new Error('Avatar url is not string');
        }

        if (!this.user.avatar_url.includes(this._config.getApiUrl())) {
          this.user.avatar_url = this._config.getApiUrl() + this.user.avatar_url;
        }

      } catch (error) {
        // if error we add default avatar
        this.user.avatar_url = false;
      }

      if (!this.user.avatar_url) {
        return false;
      }

      try {
        // we check if images exists
        await this._http.get(this.user.avatar_url, {
          responseType: 'blob'
        }).toPromise();
      } catch (error) {
        // if error we add default avatar
        this.user.avatar_url = false;
      }

      /*
      try {

        const image = await this._http.get(user.avatar_url, {
          responseType: 'blob'
        }).toPromise();

        // create url from blob
        const url = URL.createObjectURL(image);
        this.user.avatar_url = this._sanitizer.bypassSecurityTrustUrl(url);

        return true;

      } catch (error) {

        // if error we add default avatar
        this.user.avatar_url = false;

        return false;
      }*/

    } else {
      return false;
    }
  }


}
