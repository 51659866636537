import { Component, OnInit } from '@angular/core';
import { Level } from 'src/app/interface/level';
import { LevelService } from 'src/app/services/level.service';

@Component({
  selector: 'app-start-collaborating',
  templateUrl: './start-collaborating.component.html',
  styleUrls: ['./start-collaborating.component.scss']
})
export class StartCollaboratingComponent implements OnInit {

  loading: boolean = true;
  platformsToRecommend: Level[];

  constructor(
    private _levelService: LevelService,
  ) { }

  ngOnInit(): void {

    this._levelService.recommendPlatforms().subscribe((platforms: Level[]) => {

      this.platformsToRecommend = [...platforms];

      this.loading = false;

    }, (err) => {

      this.loading = false;

    })

  }

}
