import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfigService } from 'src/app/services/config.service';
import { LayoutService } from 'src/app/services/layout.service';
import { UploadImageDialogComponent } from '../upload-image-dialog/upload-image-dialog.component';

import { Sizes } from '../../sizes';

@Component({
  selector: 'app-change-image-button',
  templateUrl: './change-image-button.component.html',
  styleUrls: ['./change-image-button.component.scss']
})
export class ChangeImageButtonComponent implements OnInit {

  @Input() levelInfo: any;
  @Input() type: string;    // background || avatar

  @Output() change = new EventEmitter<any>();

  uploadImageDialogRef: MatDialogRef<UploadImageDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _config: ConfigService,
    private _layoutService: LayoutService
  ) { }

  ngOnInit(): void {
  }

  changeImage(): void {
    this.uploadImageDialogRef = this._dialog.open(
      UploadImageDialogComponent,
      {
        width: (this._layoutService.getSize() < Sizes.xl) ? '80vw' : '50vw',
        data: {
          levelInfo: this.levelInfo,
          type: this.type
        },
        disableClose: true
      }
    );

    this.uploadImageDialogRef.afterClosed().subscribe(result => {
      if (result) {
        
        if(!result.url.includes(this._config.getApiUrl())) {
          result.url = this._config.getApiUrl() + result.url;
        }

        this.change.emit(result);
      }
    });
  }

}
