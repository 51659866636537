<div class="d-flex align-items-center mb-2">
    <h2 class="mb-0" mat-dialog-title>
        Email body
    </h2>
    <div class="ms-auto">
        <button mat-button color="secondary" [mat-dialog-close]="false">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>


<mat-dialog-content class="pb-2">
    
    <div class="my-2">
        <div class="mat-caption text-muted">HTML</div>
        <ng-container *ngIf="data.html_body">
            <textarea class="w-100" disabled>{{ data.html_body }}</textarea>
        </ng-container>
        <ng-container *ngIf="!data.html_body">
            <div class="border p-2">
                /
            </div>
        </ng-container>
    </div>

    <div class="my-2">
        <div class="mat-caption text-muted">Plain text</div>
        <ng-container *ngIf="data.plain_text_body">
            <textarea class="w-100" disabled>{{ data.plain_text_body }}</textarea>
        </ng-container>
        <ng-container *ngIf="!data.plain_text_body">
            <div class="border p-2">
                /
            </div>
        </ng-container>
    </div>

</mat-dialog-content>

<mat-dialog-actions>    

    <button mat-raised-button [mat-dialog-close]="false">Close</button>

</mat-dialog-actions>