<div class="d-flex align-items-center mb-4">

    <app-user-avatar width="50" fontSize="16px" [info]="data.user"></app-user-avatar>

    <div class="ms-2">

        <h2 class="mat-h2 mb-0">{{ data.user.full_name }}</h2>
        <div class="text-muted mat-caption">{{ data.user.education }}</div>

        <!--
        <div class="d-flex align-items-center">
         
            <div style="background: green; width: 15px; height: 15px; border-radius: 50%;"></div>
            <div class="ml-2">Online</div>
        </div>
        -->
        
      
    </div>

</div>

  
<ng-container *ngIf="!sendMessage">

    <!--<button mat-raised-button color="primary" (click)="showMessageForm()">Send message</button>-->

    <button mat-raised-button color="secondary" class="ms-2" (click)="viewProfile(data.user._id)">View profile</button>

</ng-container>

<ng-container *ngIf="sendMessage">

    <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate>

        
        <mat-form-field class="w-100">
            <mat-label>Your message</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="message"
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>

        <button mat-raised-button color="primary" type="submit">Send</button>

    </form>

    

</ng-container>



