import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LevelService } from 'src/app/services/level.service';

@Component({
  selector: 'app-change-role',
  templateUrl: './change-role.component.html',
  styleUrls: ['./change-role.component.scss']
})
export class ChangeRoleComponent implements OnInit {

  levelType: string;

  loading: boolean = true;
  mainForm: UntypedFormGroup;
  submitted = false;

  constructor(
    private _fb: UntypedFormBuilder, 
    private _snackBar: MatSnackBar,
    private _levelService: LevelService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ChangeRoleComponent>,
  ) { }

  ngOnInit(): void {

    this._levelService.getLevel(this.data.level_id).subscribe((result: any) => {

      this.levelType = (result.data.type)

      this.loading = false;
      
      if (result.success) {

        this.createForm();

      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }


    }, err => {

      this.loading = false;

      if (err.status != 200) {
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    })

   
  }

  get f() { return this.mainForm.controls; }

  createForm() {
    this.mainForm = this._fb.group({      
      member_id: [this.data.member_id, Validators.required],
      role: [this.data.role, Validators.required]
    });
  }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this._levelService.changeRole(
        this.mainForm.value.member_id,
        this.mainForm.value.role
      ).subscribe((data: any) => {

        if(data.success) {

          this._dialogRef.close({
            member_id: this.mainForm.value.member_id,
            role: this.mainForm.value.role
          });

        }
        else {
          this._snackBar.open('Error: ' + data.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => { 

        if(err.status != 200) {        

          this._snackBar.open('Error: ' + err.status, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });

        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

}
