<ng-container *ngIf="visible">

    <mat-toolbar class="d-flex align-items-center bg-white" [ngClass]="{ 'navbar-sidebar-close' : (opened && startScroll > 100) , 'navbar-sidebar-open' : (!opened && startScroll > 100)}"><!--[ngClass]="{ 'navbar-sidebar-open' : (!opened && (startScroll > 100)) }">-->

        <ng-container *ngIf="loggedIn">
            <button (click)="openMainMenuDrawer()" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                <ng-container *ngIf="openedMainMenu; else closeIcon">
                    <mat-icon>menu</mat-icon>
                </ng-container>
                <ng-template #closeIcon>
                    <mat-icon>menu_open</mat-icon>
                </ng-template>
            </button>


            <div class="ms-3" *ngIf="loggedIn">
                <app-search-bar></app-search-bar>
            </div>

        </ng-container>

        <ng-container *ngIf="!loggedIn">
            <a routerLink="/">
                <img src="assets/main-logo.png" />
            </a>
        </ng-container>

        <div class="ms-auto d-flex align-items-center">      

            <div class="d-flex align-items-center">

                <ng-container *ngIf="loggedIn">

                    <button mat-button color="primary" routerLink="/create-platform" class="me-2 d-none d-md-block">
                        <mat-icon>add</mat-icon>
                        Create Platform
                    </button>

                    <button mat-icon-button [matMenuTriggerFor]="notificationmenu" class="me-2 d-none d-md-block" (click)="showNotifications()" matBadge="{{ (user?.notifications?.notifications) ? user?.notifications?.notifications : '' }}" matBadgeColor="accent">
                        <mat-icon>notifications_none</mat-icon>
                    </button>

                    <mat-menu #notificationmenu="matMenu" xPosition="before" class="my-2 notification-dialog">
                        <div style="width: 270px;">

                            <ng-container *ngIf="!notificationLoading">
                                <mat-list role="list">

                                    <ng-container *ngIf="notifications.length > 0">

                                        <ng-container *ngFor="let n of notifications">
                                            <mat-list-item role="listitem" class="pointer py-3" (click)="$event.stopPropagation(); markAsViewedNotification(n)" style="height: auto;"><!--- min-height: 80px;">-->
                                                <ng-container *ngIf="n.activity_type == 'add-member'">
                                                    <div class="d-flex align-items-center">
                                                        <app-user-avatar width="35" fontSize="12px" [info]="n.sender_id">
                                                        </app-user-avatar>
                                                        <div class="ms-3">                                                        
                                                            <div style="font-size: .8rem">
                                                                <span class="fw-bold">{{ n.sender_id?.full_name }}</span> {{'added you as the member of' | translate}}
                                                                <span class="fw-bold" [ngClass]="{ 'color-interest' : (n.level_type == 'interest'), 'color-wg' : (n.level_type == 'wg') }">{{
                                                                    n.options?.level_name | truncate : 50 }}</span>.

                                                                <div class="mt-1 text-muted" style="font-size: .9em;">{{ n.date_created | myDate }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="n.activity_type == 'level-admin'">
                                                    <div class="d-flex align-items-center">
                                                        <app-user-avatar width="35" fontSize="12px" [info]="n.sender_id">
                                                        </app-user-avatar>
                                                        <div class="ms-3">                                                        
                                                            <div style="font-size: .8rem">
                                                                <span class="fw-bold">{{ n.sender_id?.full_name }}</span> {{'added you as the coordinator of the' | translate}}
                                                                <span class="fw-bold" [ngClass]="{ 'color-interest' : (n.level_type == 'interest'), 'color-wg' : (n.level_type == 'wg') }">{{
                                                                    n.options?.level_name | truncate : 50 }}</span>.

                                                                <div class="mt-1 text-muted" style="font-size: .9em;">{{ n.date_created | myDate }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="n.activity_type == 'request-membership'">

                                                    <ng-container *ngIf="n.accepted">
                                                        <div class="d-flex align-items-center mt-3">
                                                            <div class="text-success me-3">
                                                                <mat-icon>done</mat-icon>
                                                            </div>
                                                            Request succesfully accepted.
                                                        </div>
                                                    </ng-container>

                                                    <ng-container *ngIf="n.rejected">
                                                        <div class="d-flex align-items-center mt-3">
                                                            <div class="text-success me-3">
                                                                <mat-icon>clear</mat-icon>
                                                            </div>
                                                            Request succesfully rejected.
                                                        </div>
                                                    </ng-container>

                                                    <ng-container *ngIf="!n.accepted && !n.rejected">

                                                        <div class="my-2">
                                                            <div class="d-flex align-items-center" [routerLink]="n.link">
                                                                <app-user-avatar width="35" fontSize="12px" [info]="n.sender_id">
                                                                </app-user-avatar>
                                                                <div class="ms-3">                                                                
                                                                    <div style="font-size: .8rem">
                                                                        <span class="fw-bold">{{ n.sender_id?.full_name }}</span> {{'requested membership for' | translate}}
                                                                        <span class="fw-bold" [ngClass]="{ 'color-interest' : (n.level_type == 'interest'), 'color-wg' : (n.level_type == 'wg'), 'color-link' : (n.level_type == 'cplatform') }">{{
                                                                            n.options?.level_name | truncate : 50 }}</span>.

                                                                        <div class="mt-2 text-italic text-muted" *ngIf="n.options?.message">
                                                                            {{ n.options?.message | mystriphtml | truncate:50 }}
                                                                        </div>

                                                                        <div class="mt-1 text-muted" style="font-size: .9em;">{{ n.date_created | myDate }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="mt-3">
                                                                <button mat-stroked-button (click)="requestRespond(n._id, true)">Accept</button>
                                                                <button mat-button (click)="requestRespond(n._id, false)">Reject</button>
                                                                <button mat-button color="primary" (click)="showReqInfo(n)">
                                                                    <mat-icon>info</mat-icon> Info
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </ng-container>


                                                </ng-container>
                                                <ng-container *ngIf="n.activity_type == 'membership-request-granted'">
                                                    <div class="d-flex align-items-center cursor" [routerLink]="n.link">
                                                        <app-user-avatar width="35" fontSize="12px" [info]="n.sender_id">
                                                        </app-user-avatar>
                                                        <div class="ms-3">                                                       
                                                            <div style="font-size: .8rem">
                                                                <span class="fw-bold">{{ n.sender_id?.full_name }}</span> {{'granted your membership' | translate}}
                                                                <span class="fw-bold" [ngClass]="{ 'color-interest' : (n.level_type == 'interest'), 'color-wg' : (n.level_type == 'wg'), 'color-link' : (n.level_type == 'cplatform') }">{{
                                                                    n.options?.level_name | truncate : 50 }}</span>.

                                                                <div class="mt-1 text-muted" style="font-size: .9em;">{{ n.date_created | myDate }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </mat-list-item>
                                            <mat-divider></mat-divider>
                                        </ng-container>

                                    </ng-container>
                                    <ng-container *ngIf="notifications.length == 0">

                                        <mat-list-item role="listitem" class="d-flex flex-row">
                                        
                                            <div class="material-icons me-2 text-success" style="font-size: 2rem;">
                                                done
                                            </div> 
                                            {{'No new notifications.' | translate}}
                                        
                                        </mat-list-item>
                                        <mat-divider></mat-divider>

                                    </ng-container>

                                </mat-list>

                                <div class="mt-auto px-2 pt-2 text-center">
                                    <div class="mat-caption pointer color-link view-notifications d-flex justify-content-center align-items-center" routerLink="/notifications">
                                        <mat-icon>visibility</mat-icon>

                                        <div class="ms-1">
                                            {{'View all notifications' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="notificationLoading">
                                <div class="flex-grow-1 d-flex justify-content-center  my-4">
                                    <mat-spinner></mat-spinner>
                                </div>
                            </ng-container>



                        </div>
                    </mat-menu>
                </ng-container>

                <button mat-icon-button color="primary" aria-label="Example icon button with a menu icon" [matMenuTriggerFor]="accountmenu">                
                    <ng-container *ngIf="loggedIn">
                        <div style="margin-top: -2px">
                            <app-user-avatar width="30" fontSize="12px" [info]="user"></app-user-avatar>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!loggedIn">
                        <mat-icon>login</mat-icon> 
                    </ng-container>
                </button>
                <mat-menu #accountmenu="matMenu" class="header--account-menu py-2 my-2">

                    <ng-container *ngIf="!loggedIn">
                        <button mat-menu-item routerLink="/login">
                            <mat-icon class="account-menu-icon me-2"  style="background: #a65eaf;">login</mat-icon>
                            {{'Login' | translate}}
                        </button>
                        <button mat-menu-item routerLink="/register">
                            <mat-icon class="account-menu-icon me-2" style="background: #288ebf;">face</mat-icon>
                            {{ 'Create account' | translate }}
                        </button>

                    </ng-container>

                    <ng-container *ngIf="loggedIn">

                        <section class="header--account-menu--profile" style="height: 60px;">

                            <div class="d-flex align-items-center" routerLink="/profile">

                                <div class="header--account-menu--profile--avatar">
                                    <app-user-avatar width="35" fontSize="12px" [info]="user"></app-user-avatar>
                                </div>

                                <div class="mt-1" *ngIf="user" style="margin-left: 5px">
                                    <span style="color: #fff!important">{{ user.full_name }}</span>
                                    <div class="mat-caption" i18n="Edit profile|Account menu">
                                        {{'View profile' | translate}}
                                    </div>
                                </div>

                            </div>

                        </section>

                        <button mat-menu-item routerLink="/account-settings">
                            <mat-icon class="account-menu-icon me-2">settings</mat-icon>
                            <!--<ng-container i18n="Account settings|Account menu">Account settings</ng-container>-->
                            {{ 'Account settings' | translate }}
                        </button>
                        <button mat-menu-item routerLink="/logout">
                            <mat-icon class="account-menu-icon me-2">power_settings_new</mat-icon>
                            <!--<ng-container i18n="Logout|Account menu">Logout</ng-container>-->
                            {{'Logout' | translate}}
                        </button>

                        <!-- 
                        <div class="m-2 mx-4">
                            <mat-slide-toggle>Dark mode</mat-slide-toggle>
                        </div>-->

                        <div class="mat-caption text-muted mt-2 me-4 text-right">
                            {{'Version' | translate}}: v{{ version }}
                        </div>

                    </ng-container>

                </mat-menu>

                
                <ng-container *ngIf="loggedIn && !openedCommunityDrawer">
                    <div class="ms-1">
                        <button mat-icon-button (click)="openCommunityDrawer()">
                            <ng-container *ngIf="openedCommunityDrawer; else closeCommunityIcon">
                                <mat-icon>menu</mat-icon>
                            </ng-container>
                            <ng-template #closeCommunityIcon>
                                <mat-icon>menu_open</mat-icon>
                            </ng-template>
                        </button>       
                    </div>
                </ng-container>


            </div>
        </div>


    </mat-toolbar>

    <!--
    <div class="d-none d-md-block" style="height: 80px;"></div>
    <div class="d-block d-md-none" style="height: 80px;"></div>
    -->

</ng-container>