<ng-container *ngIf="loading">

    <div class="d-flex h-100 align-items-center justify-content-center">
        <mat-spinner></mat-spinner>
    </div>

</ng-container>

<ng-container *ngIf="!loading">

    <ng-container *ngIf="notFound">

        <div class="h-100 py-8 py-md-0 d-flex flex-column flex-md-row align-items-center justify-content-center bg-dark text-white">

            <!--
            <div class="text-center">

                <div class="display-5">{{ 'Page not found' | translate }}</div>

                <div class="text-danger">
                    <mat-icon>clear</mat-icon>
                </div>


            </div>-->

            <div class="text-center text-md-start">
                <div class="display-4 fw-boldest">
                    404
                </div>
                <div class="text-gray-400 fw-bold">{{ 'Page not found' | translate }}...</div>
            </div>

            <img src="assets/404.jpg" class="img-fluid" />

        </div>

    </ng-container>

</ng-container>