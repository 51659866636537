import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ChangeAvatarDialogComponent } from 'src/app/account-settings/change-avatar-dialog/change-avatar-dialog.component';
import { ChangeBackgroundDialogComponent } from 'src/app/account-settings/change-background-dialog/change-background-dialog.component';
import { LayoutService } from 'src/app/services/layout.service';
import { MembershipService } from 'src/app/services/membership.service';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { UsersService } from 'src/app/services/users.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

import { Sizes } from '../../sizes';
import { SocialDialogComponent } from './social-dialog/social-dialog.component';
import { skip } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  private subscription$: Subscription;

  loading: boolean = true;
  user: any;
  userFound: boolean;
  userInfo: any;

  memberships: number = 0;
  platforms: any = [];
  interests: number = 0;
  wgs: number = 0;

  personalProfile: boolean = false;
  id: any = null;
  edit: boolean = false;

  changeAvatarDialogRef: MatDialogRef<ChangeAvatarDialogComponent>;
  changeBackgroundDialogRef: MatDialogRef<ChangeBackgroundDialogComponent>;
  socialDialogRef: MatDialogRef<SocialDialogComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private _userinfoService: UserinfoService,
    private _membershipService: MembershipService,
    private _snackBar: MatSnackBar,
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _layoutService: LayoutService,
    private _usersService: UsersService,
    private _router: Router,
    private _titleService: Title
  ) {

    this.subscription$ = this._route.url.pipe(
      skip(1) // Skip the first emission during initialization
    ).subscribe((data) => {

      console.log('URL changed');

      // we check if URL changed the ID
      const id = this._route.snapshot.paramMap.get('id');

      if (id != this.id) {
        this.id = id;
        this.ngOnInit();
      }

    });

  }

  async ngOnInit(): Promise<void> {

    this.personalProfile = false;

    this._titleService.setTitle('Profile | ExArca');

    this.id = this._route.snapshot.paramMap.get('id');

    if (!this.id) {
      //we check if we have the user info
      this.userInfo = this._userinfoService.getLocalInfo();

      this.id = this.userInfo?._id;

      if (this.userInfo) {
        this.personalProfile = true;
      }

    }
    // we get the user info

    if (!this.id) {
      this.userFound = false;
      this.loading = false;
      return;
    }

    try {
      const result: any = await this._usersService.getUser(this.id).toPromise();
      this.userFound = true;

      if (!result.success) {
        throw new Error('User not found');
      }

      this.user = result.data;

      try {
        const result: any = await this._membershipService.getUserMemberships(this.id).toPromise();

        if (!result.success) {
          throw new Error('Membership not found');
        }

        this.memberships = result.data.count;
        this.platforms = result.data.platforms;
        this.interests = result.data.interests;
        this.wgs = result.data.wgs;

      } catch (err) {

        console.log('error collecting membership', err.message);

      }

    } catch (err) {

      this.userFound = false;

    }

    this.loading = false;

  }

  editProfile() {
    this.edit = !this.edit;
    //this.user['edit_name'] = false;
    this.user['edit_introduction'] = false;
    this.user['edit_education'] = false;
  }

  viewPublicProfile() {

    this._router.navigate(['profile/' + this.user._id]);

  }

  changeBackground() {

    this.changeBackgroundDialogRef = this._dialog.open(
      ChangeBackgroundDialogComponent,
      {
        width: (this._layoutService.getSize() < Sizes.xl) ? '80vw' : '50vw',
        data: {
          user: this.user
        },
        disableClose: false
      }
    );

    this.changeAvatarDialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });

  }

  changeAvatar() {
    this.changeAvatarDialogRef = this._dialog.open(
      ChangeAvatarDialogComponent,
      {
        width: (this._layoutService.getSize() < Sizes.xl) ? '80vw' : '50vw',
        data: {
          user: this.user
        },
        disableClose: false
      }
    );

    this.changeAvatarDialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  /** update field */
  updatedField(property: string, value: string, field?: string) {

    this._usersService.updateUserField(property, value).subscribe((result: any) => {

      if (result.success) {

        this.user[property] = value;

        if (field) {
          this.user[field] = false;
        }

      }

    }, err => {

      if (err.status != 200) {

        this.loading = false;

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    });


  }

  socialDialog() {
    this.socialDialogRef = this._dialog.open(
      SocialDialogComponent,
      {
        width: (this._layoutService.getSize() < Sizes.xl) ? '80vw' : '30vw',
        data: this.user,
        disableClose: false
      }
    );

    this.socialDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.user['social'] = result;
      }
    });
  }

  redirectSocial(link: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        data: {
          title: 'Continue?',
          text: 'You will be redirected to the another website:<br /><i>' + link + '</i>',
          leftButton: 'Cancel',
          rightButton: 'Visit website'
        }
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        window.open((link.includes('http') ? link : 'http://' + link), "_blank");
      }
    });

  }

  ngOnDestroy() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

}
