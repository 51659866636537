import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from 'src/app/services/users.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-admin-join-users',
  templateUrl: './admin-join-users.component.html',
  styleUrls: ['./admin-join-users.component.scss']
})
export class AdminJoinUsersComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  users: any[] = [];

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<AdminJoinUsersComponent>,
    private _userService: UsersService
  ) { }

  ngOnInit(): void {
    this.users = this.data.users;

    this.createForm();
  }

  createForm() {
    this.mainForm = this._fb.group({      
      userId1: ['', Validators.required],
      userId2: ['', Validators.required]
    });
  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {


      if(this.mainForm.value.userId1 == this.mainForm.value.userId2) {

        this._snackBar.open('Can\'t join same account', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

        return false;
      }


      this.confirmDialogRef = this._dialog.open(
        ConfirmDialogComponent,
        {
          width: '350px',
          data: {
            title: 'Are you sure?',
            text: 'That you want to join this users?',
            leftButton: 'Cancel',
            rightButton: 'Yes'
          },
          disableClose: true
        }
      );
  
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          
          this._userService.adminJoinUsers(     
            this.mainForm.value.userId1,
            this.mainForm.value.userId2
          ).subscribe((result: any) => {
    
            if (result.success) {
              this._dialogRef.close(this.mainForm.value.userId2);
            }
            else {
              this._snackBar.open('Error: ' + result.message, '', {
                duration: 2000,
                panelClass: ['error-snackbar']
              });
            }
            
          }, err => {
    
            if (err.status != 200) {
              // snackbar
              this._snackBar.open('Error', '', {
                duration: 2000,
                panelClass: ['error-snackbar']
              });
            }
    
          });

        }
      });

    }
    else {
      this._snackBar.open('Please select users', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }
  }
}
