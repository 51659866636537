import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ProposalsService } from 'src/app/services/proposals.service';
import { Sizes } from 'src/app/sizes';

@Component({
  selector: 'app-show-proposals',
  templateUrl: './show-proposals.component.html',
  styleUrls: ['./show-proposals.component.scss']
})
export class ShowProposalsComponent implements OnInit, OnDestroy {

  _url: string;
  subscription: Subscription;

  loading: boolean = true;
  displayedColumns: string[] = ['title', 'author', 'date_created', 'action'];
  proposalData: MatTableDataSource<any>;
  resultsLength = 0;

  isWg: boolean = false;

  @Input() id: string;      // level id
  @Input() role: number;    // user role
  @Input() set isWorkGroup(isWorkGroup: boolean) {
    this.isWg = isWorkGroup;

    if (isWorkGroup) {
      // add visible columns to the displayed columns in front
      this.displayedColumns.unshift('visible');
    }

  }
  @Input() set url(url: string) {
    this._url = url;
  };    // user role

  constructor(
    private _proposalService: ProposalsService,
    private _snackBar: MatSnackBar,
    private _config: ConfigService,
    private _layoutService: LayoutService
  ) {

    this.subscription = this._layoutService.currentSize$.subscribe((size) => {

      if (size < Sizes.md) {
        this.displayedColumns = ['title', 'action'];
      }
      else if (size == Sizes.md) {
        this.displayedColumns = ['title', 'author', 'action'];
      }
      else {
        this.displayedColumns = ['title', 'author', 'date_created', 'action'];
      }

    });


  }

  ngOnInit(): void {

    this.getProposals().then((proposals: any) => {

      if (!this.proposalData) {
        this.proposalData = new MatTableDataSource(proposals);
      }
      else {
        this.proposalData.data = [];
        this.proposalData.data = [...proposals];
      }

      this.resultsLength = proposals.length


    });

  }

  getProposals() {

    return new Promise((resolve, reject) => {

      if (this.isWg) {
        this._proposalService.getProposals(this.id).subscribe((result: any) => {

          this.loading = false;

          if (result.success) {
            resolve(result.data);
          }
          else {

            resolve([]);

          }

        }, err => {

          if (err.status != 200) {

            this.loading = false;

            resolve([]);


          }

        });

      }
      else {

        // we get only public proposals from all wgs
        this._proposalService.getPublicProposals(this.id).subscribe((result: any) => {

          this.loading = false;

          if (result.success) {
            resolve(result.data);
          }
          else {

            resolve([]);

          }

        }, err => {

          if (err.status != 200) {

            this.loading = false;

            resolve([]);
          }

        });

      }

    });

  }

  changeVisibility(proposalId: string, visible: boolean) {

    this._proposalService.changeVisibility(this.id, proposalId, !visible).subscribe((result: any) => {

      if (result.success) {

        this._snackBar.open('Visibility changed', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });

        this.getProposals().then((proposals: any) => {

          if (!this.proposalData) {
            this.proposalData = new MatTableDataSource(proposals);
          }
          else {
            this.proposalData.data = [];
            this.proposalData.data = [...proposals];
          }

          this.resultsLength = proposals.length

        });

      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {
        this._snackBar.open('Error: ' + err.statusText, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    });

  }

  generateProsposalPDF(proposalId: string) {

    let newWindow = window.open();

    this._proposalService.generatePDF(this.id, proposalId).subscribe((result: any) => {

      if (result.success) {

        //window.open(this._config.getApiUrl() + "/download/doc/" + result.data, "_blank");
        newWindow.location.href = this._config.getApiUrl() + "/download/doc/" + result.data;

      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }


    }, err => {

      if (err.status != 200) {
        this._snackBar.open('Error: ' + err.statusText, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    });

  }

  ngOnDestroy() {

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

}
