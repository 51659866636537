import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MilestonesService } from 'src/app/services/milestones.service';

@Component({
  selector: 'app-milestones-dialog',
  templateUrl: './milestones-dialog.component.html',
  styleUrls: ['./milestones-dialog.component.scss']
})
export class MilestonesDialogComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  text: string = '';

  minDate = new Date();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<MilestonesDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _milestonesService: MilestonesService
  ) { }

  ngOnInit(): void {
    this.createForm(this.data.current);

    this.mainForm.patchValue({
      level_id: this.data.level_id
    });
  }

  createForm(data?: any) {

    this.mainForm = this._fb.group({
      name: [(data && data.name) ? data.name : '', [Validators.required]],
      due: [(data && data.due) ? new Date(parseInt(data.due)) : '', Validators.required],
      description: [(data && data.description) ? data.description : ''], 
      level_id: ['', Validators.required]
    });

    this.text = (data && data.description) ? data.description : '';
  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    let date = new Date(this.mainForm.value.due);

    if (this.mainForm.status != "INVALID") {


      let action = (this.data.current) ?  
        this._milestonesService.editMilestone(
          this.data.current._id,
          this.mainForm.value.level_id,
          this.mainForm.value.name,
          date.getTime(),
          this.mainForm.value.description,
          
        ) :
        this._milestonesService.addMilestone(
          this.mainForm.value.level_id,
          this.mainForm.value.name,
          date.getTime(),
          this.mainForm.value.description
        );

        action.subscribe((result: any) => {
  
        if (result.success) {
          this._dialogRef.close(result.data);
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });
    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }
  }

  onCkEditorValue(text: string) {
    this.mainForm.patchValue({
      description: text
    });
  }

}
