import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { MembershipService } from 'src/app/services/membership.service';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { ChangeRoleComponent } from './change-role/change-role.component';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class MembersComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  members: any = [];

  @Input() id: string; // level id
  @Input() role: number; // user role
  @Input() type: string; // level type
  @Input() isProject: string; // level subtype

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  changeRoleDialogRef: MatDialogRef<ChangeRoleComponent>;
  newMemberSubscription: Subscription;

  constructor(
    private _dialog: MatDialog,
    private _membershipService: MembershipService,
    private _snackBar: MatSnackBar,
    private _userinfoService: UserinfoService
  ) {}

  ngOnInit(): void {
    this.getMembers().then((members: any) => {
      this.loading = false;
      this.members = members;
    });

    this.newMemberSubscription =
      this._membershipService.newMemberEmiter$.subscribe(async (val: any[]) => {
        if (val && val.length > 0) {
          this.members = await this.getMembers();
        }
      });
  }

  getMembers() {
    return new Promise((resolve, reject) => {
      let promise = this._userinfoService.getLoggedIn()
        ? this._membershipService.getMembers(this.id)
        : this._membershipService.getPlatformMembers(this.id);

      promise.subscribe(
        (result: any) => {
          if (result.success) {
            resolve(result.data);
          } else {
            resolve([]);

            /*
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });*/
          }
        },
        (err) => {
          if (err.status != 200) {
            resolve([]);

            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar'],
            });
          }
        }
      );
    });
  }

  deleteMember(id: string) {
    this.confirmDialogRef = this._dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        title: 'Are you sure?',
        text: 'That you want to delete this member?', // This member will also be deleted in lower levels (if any)',
        leftButton: 'Cancel',
        rightButton: 'Yes',
      },
      disableClose: true,
    });

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._membershipService.deleteMember(this.id, id).subscribe(
          (result: any) => {
            if (result.success) {
              let membersLength = this.members.length;

              for (let i = 0; i < membersLength; i++) {
                if (this.members[i]._id == id) {
                  this.members.splice(i, 1);
                  i--;
                  membersLength--;
                }
              }
            } else {
              this._snackBar.open('Error: ' + result.message, '', {
                duration: 2000,
                panelClass: ['error-snackbar'],
              });
            }
          },
          (err) => {
            if (err.status != 200) {
              // snackbar
              this._snackBar.open('Error', '', {
                duration: 2000,
                panelClass: ['error-snackbar'],
              });
            }
          }
        );
      }
    });
  }

  changeRole(id: string, role: number) {
    this.changeRoleDialogRef = this._dialog.open(ChangeRoleComponent, {
      width: '350px',
      data: {
        member_id: id,
        level_id: this.id,
        role: role,
      },
      disableClose: true,
    });

    this.changeRoleDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        for (let m of this.members) {
          if (m._id == result.member_id) {
            m.role = result.role;
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.newMemberSubscription.unsubscribe();
  }
}
