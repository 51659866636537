<ng-container *ngIf="!loading">


    <mat-toolbar>
        <h1 class="mat-h1">{{'Community' | translate}}</h1>
    </mat-toolbar>

    <div class="my-2">

        <ng-container *ngIf="members.length == 0">
            <div class="mx-2">
                {{'There are no members.' | translate}}
            </div>
        </ng-container>

        <ng-container *ngIf="members.length > 0">



            <mat-card class="mat-elevation-z0 px-0 px-md-2 px-lg-4">

                <mat-list role="list">

                    <mat-list-item *ngFor="let member of members; let last = last;">

                        <app-user-avatar matListAvatar width="40" fontSize="12px"
                            [info]="member.user_id"></app-user-avatar>
                        <div mat-line class="fw-boldest text-gray-800 pointer">
                            {{ member.user_id.full_name }}
                        </div>
                        <div matLine class="text-gray-500 fw-bold">

                            <ng-container *ngIf="member.role == 0 || member.role == 1">
                                <ng-container *ngIf="member.multiple">
                                    <ng-container *ngIf="member.coordinator; else other">
                                        {{ 'Interest' | translate }}
                                        <span class="text-lowercase">{{'Co-ordinator' | translate}}</span>
                                    </ng-container>
                                    <ng-template #other>
                                        {{'Platform owner' | translate }}
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngIf="!member.multiple">

                                    <ng-container *ngIf="type == 'cplatform'">
                                        {{ 'Platform' | translate }} -
                                    </ng-container>
                                    <ng-container *ngIf="type == 'interest'">

                                        <ng-container *ngIf="isProject; else showInterest">
                                            {{ 'Project' | translate }}
                                        </ng-container>
                                        <ng-template #showInterest>
                                            {{ 'Interest' | translate }}
                                        </ng-template>

                                    </ng-container>
                                    <ng-container *ngIf="type == 'wg'">

                                        <ng-container *ngIf="isProject; else showWorkgroup">
                                            {{ 'WP' | translate }} -
                                        </ng-container>
                                        <ng-template #showWorkgroup>
                                            {{ 'WG' | translate }} -
                                        </ng-template>

                                    </ng-container>

                                    <span class="text-lowercase">{{'Co-ordinator' | translate}}</span>
                                </ng-container>

                            </ng-container>
                            <ng-container *ngIf="member.role == 2 || member.role == 3">{{'Member' | translate}}
                            </ng-container>

                        </div>
                        <ng-container *ngIf="role == 0 || role == 1">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">

                                <ng-container *ngIf="member.role != 0">
                                    <button mat-menu-item (click)="changeRole(member._id, member.role)">
                                        <mat-icon>create</mat-icon>
                                        <span>{{'Change role' | translate}}</span>
                                    </button>
                                </ng-container>

                                <button mat-menu-item (click)="deleteMember(member._id)">
                                    <mat-icon>delete</mat-icon>
                                    <span>{{'Delete' | translate}}</span>
                                </button>

                            </mat-menu>
                        </ng-container>

                        <mat-divider *ngIf="!last"></mat-divider>
                    </mat-list-item>
                </mat-list>

            </mat-card>

            <!--
            <mat-card>
                <ng-container *ngFor="let member of members">

                    <div class="d-flex align-items-center w-100">

                        <mat-card-header class="my-2">
                            <div mat-card-avatar>
                                <app-user-avatar width="40" fontSize="12px" [info]="member.user_id"></app-user-avatar>
                            </div>

                            <mat-card-title>{{ member.user_id.full_name }}</mat-card-title>

                            <mat-card-subtitle>
                                <ng-container *ngIf="member.role == 0 || member.role == 1">
                                    <ng-container *ngIf="member.multiple">{{'Platform owner' | translate }}</ng-container>
                                    <ng-container *ngIf="!member.multiple">

                                        <ng-container *ngIf="type == 'cplatform'">
                                            {{ 'Platform' | translate }} -
                                        </ng-container>
                                        <ng-container *ngIf="type == 'interest'">
                                            {{ 'Interest' | translate }}
                                        </ng-container>
                                        <ng-container *ngIf="type == 'wg'">
                                            {{ 'Work Group' | translate }} -
                                        </ng-container>

                                        <span class="text-lowercase">{{'Co-ordinator' | translate}}</span>
                                    </ng-container>

                                </ng-container>
                                <ng-container *ngIf="member.role == 2 || member.role == 3">{{'Member' | translate}}</ng-container>
                            </mat-card-subtitle>
                        </mat-card-header>


                        <ng-container *ngIf="role == 0 || role == 1">

                            <button mat-button [matMenuTriggerFor]="menu" class="ms-auto">
                                <span class="material-icons">
                                    more_vert
                                </span>
                            </button>
                            <mat-menu #menu="matMenu">

                                <ng-container *ngIf="member.role != 0">
                                    <button mat-menu-item (click)="changeRole(member._id, member.role)">
                                        <mat-icon>create</mat-icon>
                                        <span>{{'Change role' | translate}}</span>
                                    </button>
                                </ng-container>

                                <button mat-menu-item (click)="deleteMember(member._id)">
                                    <mat-icon>delete</mat-icon>
                                    <span>{{'Delete' | translate}}</span>
                                </button>

                            </mat-menu>

                        </ng-container>

                    </div>


                </ng-container>
            </mat-card>-->

        </ng-container>
    </div>

</ng-container>