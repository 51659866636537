import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  addTask(levelId: string, name: string, due: number, description: string, users: any[]) {

    return this._http.post(this._config.getApiUrl() + '/api/tasks', {    
      level_id: levelId,
      name: name,
      due: due,
      description: description,
      users: users
    }, this._httpOptionsService.getHeader())

  }

  editTask(taskId: string, levelId: string, name: string, due: number, description: string, users: any[]) {

    return this._http.put(this._config.getApiUrl() + '/api/tasks/' + taskId, {    
      level_id: levelId,
      name: name,
      due: due,
      description: description,
      users: users
    }, this._httpOptionsService.getHeader())

  }

  deleteTask(levelId: string, taskId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/tasks/' + levelId + '/' + taskId, this._httpOptionsService.getHeader())
  }

  getTasks(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/tasks/' + levelId, this._httpOptionsService.getHeader())
  }

  changeStatus(levelId: string, taskId: string, userStatus: number) {
    return this._http.put(this._config.getApiUrl() + '/api/tasks/status/' + taskId, {    
      level_id: levelId,
      user_status: userStatus
    }, this._httpOptionsService.getHeader())
  }

  getUserTasks() {
    return this._http.get(this._config.getApiUrl() + '/api/tasks/user', this._httpOptionsService.getHeader())
  }
}
