<ng-container *ngIf="!loading">

    <mat-toolbar class="d-flex align-items-center my-4" *ngIf="role == 0 || role == 1">

        <h1 class="mat-h1 mb-0">{{'Tasks' | translate}}</h1>

        <button mat-raised-button (click)="addTask()" class="ms-auto">
            <mat-icon>add</mat-icon>
        </button>

    </mat-toolbar>

    <div class="my-4">

        <ng-container *ngIf="tasks.length == 0">
            <div class="mx-2">
                {{'No tasks' | translate}}
            </div>
        </ng-container>

        <ng-container *ngIf="tasks.length > 0">


            <ng-container *ngFor="let t of tasks">

                <mat-accordion>
                    <mat-expansion-panel  class="my-3">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="align-items-center fw-boldest">
                                {{ t.name | truncate : 60}}
                            </mat-panel-title>
                            <mat-panel-description>

                                <div class="ms-auto d-flex align-items-center">
                                    <div class="mat-caption" style="width: 150px;">
                                        {{ t.due | date: "longDate" }}
                                    </div>

                                    <div style="width: 150px;" class="mat-caption d-none d-md-block">
                                        {{'Progress' | translate}} - {{ t.status }} %
                                        <mat-progress-bar mode="determinate" [value]="t.status" class="mb-0"></mat-progress-bar>
                                    </div>
                                </div>

                            </mat-panel-description>
                        </mat-expansion-panel-header>


                        <div class="d-flex align-items-center mb-4">

                            <div class="flex-grow-1">
                                <div class="d-flex flex-wrap align-items-center">

                                    <div class="me-2">
                                        <app-user-avatar width="20" fontSize="10px" [info]="t.user_id">
                                        </app-user-avatar>
                                    </div>

                                   
                                    <div class="d-flex flex-wrap">
                                        <div class="me-2">
                                            {{ t.user_id.full_name | truncate : 45 }}
                                        </div>
                                        <div class="mat-caption text-gray-600" matTooltip="{{ t.date_created | date: 'medium' }}">
                                            {{ t.date_created | myDate }}
                                        </div>
                                    </div>                                         
                                    
                                </div>
                            </div>

                            <!-- mobile-menu -->
                            <button mat-icon-button [matMenuTriggerFor]="menu" class="ms-auto d-md-none">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">         
                                <button class="d-md-none" mat-menu-item (click)="edit(t)">
                                    <mat-icon>create</mat-icon>
                                    <span>{{'Edit' | translate}}</span>
                                </button>   
                                <button class="d-md-none" mat-menu-item (click)="delete(t._id)">
                                    <mat-icon>delete</mat-icon>
                                    <span>{{'Delete' | translate}}</span>
                                </button>  
                            </mat-menu>
                            

                            <!-- desktop-menu -->
                            <div class="ms-auto d-none d-md-block">
                                <div class="d-flex align-items-center" *ngIf="role == 0 || role == 1">
                                    <div class="ms-auto">
                                        <button mat-button (click)="edit(t)" class="small-btn">
                                            <mat-icon>create</mat-icon>
                                        </button>

                                        <button mat-button (click)="delete(t._id)" class="small-btn">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <table class="w-100 task-view">                           
                            <tr>
                                <td class="align-top fw-bold" style="width: 150px;">
                                    {{'Due' | translate}}:
                                </td>
                                <td>
                                    {{ t.due | date: "longDate" }}
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <ng-container *ngIf="t.users.length > 0">
                                <tr>
                                    <td class="align-top fw-bold">
                                        {{'Responsible' | translate}}:
                                    </td>
                                    <td>
                                        <div *ngFor="let user of t.users">
                                            {{ user?.user_id?.full_name }}                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="t.can_change_status">
                                <tr>
                                    <td class="align-top fw-bold">
                                        {{'Task status' | translate}}:
                                    </td>
                                    <td>
                                        <mat-select style="width:  200px;" [(ngModel)]="t.userStatus" (selectionChange)="changeStatus(t)">
                                            <mat-select-trigger>
                                                <div class="d-flex align-items-center">
                                                    <ng-container *ngIf="t.userStatus == 0">
                                                        <mat-icon class="text-danger">clear</mat-icon> <div class="ms-3">{{'Not started' | translate}}</div>
                                                    </ng-container>
                                                    <ng-container *ngIf="t.userStatus == 50">
                                                        <mat-icon class="text-warning">build</mat-icon> <div class="ms-3">{{'In progress' | translate}}</div>
                                                    </ng-container>
                                                    <ng-container *ngIf="t.userStatus == 100">
                                                        <mat-icon class="text-success">done</mat-icon> <div class="ms-3">{{'Finished' | translate}}</div>
                                                    </ng-container>
                                                </div>
                                            </mat-select-trigger>
                                            <mat-option value="0">
                                                <mat-icon class="text-danger">clear</mat-icon>
                                                {{'Not started' | translate}}
                                            </mat-option>
                                            <mat-option value="50">
                                                <mat-icon class="text-warning">build</mat-icon>
                                                {{'In progress' | translate}}
                                            </mat-option>
                                            <mat-option value="100">
                                                <mat-icon class="text-success">done</mat-icon>
                                                {{'Finished' | translate}}
                                            </mat-option>
                                        </mat-select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td class="align-top fw-bold">
                                    {{'Task description' | translate}}:
                                </td>
                                <td>
                                    <ng-container *ngIf="t.description; else noDescription">
                                        <div class="ck-content" [innerHTML]="t.description | sanitizeHtml"></div>
                                    </ng-container>
                                    <ng-template #noDescription>
                                        /
                                    </ng-template>
                                </td>
                            </tr>
                        </table>

                       

                    </mat-expansion-panel>
                </mat-accordion>

            </ng-container>



        </ng-container>
    </div>

</ng-container>