import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/services/layout.service';
import { CreatePlatformDialogComponent } from './create-platform-dialog/create-platform-dialog.component';
import { SuccessCreatingPlatformComponent } from './success-creating-platform/success-creating-platform.component';

import { Sizes } from '../../sizes';

@Component({
  selector: 'app-create-platform-user',
  templateUrl: './create-platform-user.component.html',
  styleUrls: ['./create-platform-user.component.scss']
})
export class CreatePlatformUserComponent implements OnInit {

  createPlatformDialogRef: MatDialogRef<CreatePlatformDialogComponent>;
  successPlatformDialogRef: MatDialogRef<SuccessCreatingPlatformComponent>;

  @Input() credits: string;
  creditsMapping: any = {
    '=0': 'credit',
    '=1': 'credit',
    '=2': 'credit_2',
    'other': 'credits'
  }


  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _layoutService: LayoutService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    
  }

  newPlatform() {
    this.createPlatformDialogRef = this._dialog.open(
      CreatePlatformDialogComponent,
      {
        width: (this._layoutService.getSize() < Sizes.xxl) ? '90vw' : '50vw',
        disableClose: false
      }
    );

    this.createPlatformDialogRef.afterClosed().subscribe(result => {
      if (result) {     
        
        this.successPlatformDialogRef = this._dialog.open(
          SuccessCreatingPlatformComponent,
          {            
            disableClose: false
          }
        );        

        this._router.navigate(['p/' + result]);        

      }
    });
  }

}
