import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class WikiService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  addPage(levelId: string, title: string, content: string, attachments: any[]) {

    return this._http.post(this._config.getApiUrl() + '/api/wiki', {    
      level_id: levelId,
      title: title,
      content: content,
      attachments: attachments,
    }, this._httpOptionsService.getHeader())

  }
  
  editPage(pageId: string, levelId: string, title: string, content: string, attachments: any[]) {

    return this._http.put(this._config.getApiUrl() + '/api/wiki/' + pageId, {    
      level_id: levelId,
      title: title,
      content: content,
      attachments: attachments,
    }, this._httpOptionsService.getHeader())

  }

  deletePage(levelId: string, pageId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/wiki/' + levelId + '/' + pageId, this._httpOptionsService.getHeader())
  }

  removePage(levelId: string, pageId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/wiki/remove/' + levelId + '/' + pageId, this._httpOptionsService.getHeader())
  }

  getPages(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/wiki/' + levelId, this._httpOptionsService.getHeader())
  }

  getAvailableWikis(levelId: string, targetLevelId: string, root?: boolean) {
    return this._http.get(this._config.getApiUrl() + '/api/wiki/available/' + levelId + '/' + targetLevelId + '/' + root, this._httpOptionsService.getHeader())
  }

  importWikis(levelId: string, wikis: any[]) {
    return this._http.post(this._config.getApiUrl() + '/api/wiki/import', {    
      level_id: levelId,
      wikis: wikis
    }, this._httpOptionsService.getHeader())
  }
}
