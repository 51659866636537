<ng-container *ngIf="!loading">


    <mat-toolbar class="d-flex align-items-center my-4">

        <ng-container *ngIf="!_blogId; else showBack">
            <h1 class="mat-h1 mb-0">Blog</h1>
        </ng-container>
        <ng-template #showBack>            
            <button mat-button (click)="backButton()">
                <mat-icon>chevron_left</mat-icon>
                {{'Back' | translate}}
            </button>
        </ng-template>

        <ng-container *ngIf="role == 0 || role == 1">

            <ng-container *ngIf="create; else showResults">

                <button mat-raised-button (click)="cancel()" class="ms-auto">
                    <div class="d-flex align-items-center">
                        <span class="material-icons">
                            close
                        </span> Cancel
                    </div>
                </button>

            </ng-container>
            <ng-template #showResults>

                <ng-container *ngIf="!_blogId || !article; else showEdit">

                    <!-- mobile-menu -->
                    <button mat-raised-button (click)="addArticle()" class="ms-auto d-md-none">
                        <mat-icon>
                            add
                        </mat-icon>
                    </button>

                    <!-- desktop-menu -->
                    <button mat-raised-button (click)="addArticle()" class="ms-auto d-none d-md-block">
                        
                        <div class="d-flex align-items-center">
                            <mat-icon>
                                add
                            </mat-icon>
                            &nbsp;
                            
                            <div class="d-none d-md-block ms-1">Add article</div>
                        </div>
                       
                    </button>

                </ng-container>
                <ng-template #showEdit>

                    <div class="ms-auto">

                        <!-- mobile-menu -->
                        <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" class="ms-auto d-md-none">
                            <span class="material-icons">
                                more_vert
                            </span>
                        </button>
                        <mat-menu #menu="matMenu">
                            
                            <button mat-menu-item (click)="editArticle(article)">
                                <mat-icon>create</mat-icon>
                                <span>{{'Edit article' | translate}}</span>
                            </button>

                            <button mat-menu-item (click)="deleteArticle(article)">
                                <mat-icon>delete</mat-icon>
                                <span>{{'Delete article' | translate}}</span>
                            </button>

                        </mat-menu>

                        <!-- desktop-menu -->
                        <button mat-raised-button (click)="editArticle(article)" class="ms-auto d-none d-md-inline">
                                    
                            <div class="d-flex align-items-center">
                                <mat-icon>
                                    create
                                </mat-icon>
                                
                                <div class="d-none d-md-block ms-1">{{'Edit article' | translate}}</div>
                            </div>
                            
                        </button>

                       
                        <button mat-raised-button (click)="deleteArticle(_blogId)" color="warn" class="ms-3 d-none d-md-inline">
                                
                            <div class="d-flex align-items-center">
                                <mat-icon>
                                    delete
                                </mat-icon>
                                
                                <div class="d-none d-md-block ms-1">{{'Delete article' | translate}}</div>
                            </div>
                            
                        </button>

                    </div>

                </ng-template>


            </ng-template>

        </ng-container>
    </mat-toolbar>

    <div class="my-4">

        <!--
        <button mat-button (click)="backButton()" *ngIf="_blogId">
            <mat-icon>chevron_left</mat-icon>
            {{'Back' | translate}}
        </button>-->

        <mat-card class="mat-elevation-z0 ">

            <ng-container *ngIf="create; else showResults">

                <div class="mx-2">

                    <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>
                                Name
                            </mat-label>
                            <input matInput formControlName="name">
                        </mat-form-field>

                        <div class="editor-size">
                            <app-my-editor [levelid]="id" [data]="text" (output)="onCkEditorValue($event)">
                            </app-my-editor>
                        </div>

                        <div class="mt-3">
                            <button mat-raised-button color="primary" class="me-2" type="submit">
                                <ng-container *ngIf="!data?.current; else updateButton">
                                    <span class="material-icons">
                                        save
                                    </span> Save
                                </ng-container>

                                <ng-template #updateButton>
                                    Update
                                </ng-template>
                            </button>
                            <button mat-raised-button (click)="cancel()">Cancel</button>
                        </div>

                    </form>

                </div>

            </ng-container>

            <ng-template #showResults>

                <ng-container *ngIf="_blogId; else showSpecific">

                    <div>

                        

                        <ng-container *ngIf="article">

                            <h1>{{ article.name }}</h1>

                            <!--
                            <div class="d-flex mat-caption mb-4">
                                <div>{{ article.user_id.full_name }}</div>
                                <div class="ms-4">{{ article.date_created | myDate }}</div>
                            </div>-->

                            <div class="d-flex align-items-center flex-wrap mt-2 mb-4">
                                <div class="me-2">
                                    <app-user-avatar width="25" fontSize="12px" [info]="article.user_id">
                                    </app-user-avatar>
                                </div>

                                
                                <div class="me-4 fw-bold">
                                    {{ article.user_id?.full_name | truncate : 45 }}
                                </div>
                                <div class="text-muted " matTooltip="{{ article.date_created | date: 'medium' }}">
                                    {{ article.date_created | myDate }}
                                </div>

                               
                            </div>
                            
                            <div class="ck-content" [innerHTML]="article.description | sanitizeHtml"></div>

                            <div class="my-4">

                                <app-comments [comments]="discussions" [user]="user" (commentRemoved)="removeComment($event)"></app-comments>
                         
                                <ng-container *ngIf="user">
                                    <div class="mt-5 mt-lg-6">
                                        <app-add-comment [hideAttachment]="true" [user]="user" [saved]="saved"
                                        [levelId]="id" (save)="onCommentSave($event)"></app-add-comment>
                                    </div>
                                </ng-container>
                            </div>

                        </ng-container>
                        <ng-container *ngIf="!article">
                            Article not found.
                        </ng-container>

                    </div>

                </ng-container>

                <ng-template #showSpecific>

                    <ng-container *ngIf="resultsLength == 0">
                        No articles.
                    </ng-container>

                    <div class="blog-news pt-0">
                        <ng-container *ngIf="resultsLength > 0">

                            <ul class="list-unstyled blog-articles">
                                
                                    <li class="mb-6 my-xl-8 mx-xl-8"  *ngFor="let a of articles">
                                        

                                        <div class="d-flex align-items-center my-3 mb-4">

                                            <div class="me-2">
                                                <app-user-avatar width="40" fontSize="12px" [info]="a.user_id">
                                                </app-user-avatar>
                                            </div>
    
                                            <div class="d-flex flex-column">
                                            
                                                <div class="fw-boldest text-gray-800">
                                                    {{ a.user_id.full_name | truncate : 45 }}
                                                </div>
                                                <div class="text-gray-500 fw-bold" matTooltip="{{ a.date_created | date: 'medium' }}">
                                                    {{ a.date_created | myDate }}
                                                </div>

                                            </div>
                                        </div>


                                        <h2 routerLink="{{ url }}/{{ a._id }}" class="pointer">{{ a.name | truncate: 80 }}</h2>

                                        <div class="mb-3">
                                            {{ a.description | mystriphtml | truncate:350 }}
                                        </div>


                                        <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                                            <button mat-button routerLink="{{ url }}/{{ a._id }}" color="primary">{{'View' | translate}}</button>


                                            <div class="ms-4 text-muted mat-caption">
                                                Comments ({{ a.number_of_comments }})
                                            </div>

                                        </div>

                                   
                                    </li> 

                                </ul>


                        </ng-container>
                    </div>

                </ng-template>

            </ng-template>

        </mat-card>

    </div>



</ng-container>