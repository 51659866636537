import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from '../services/layout.service';
import { UnsubscribeService } from '../services/unsubscribe.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  loading: boolean = true;
  requestNotFound: boolean = false;

  id: any;
  email: any;

  constructor(
    private _route: ActivatedRoute,
    private _unsubscribeService: UnsubscribeService,
    private _layoutService: LayoutService
  ) { }

  ngOnInit(): void {

    if(this._route.snapshot.data.hideHeader) {

      // Set timeout because NG0100 error
      setTimeout(()=> {
        this._layoutService.hideToolbar();
      }, 0)
      
    }  

    this.id = this._route.snapshot.paramMap.get('id');
    this.email = this._route.snapshot.paramMap.get('email');


    this._unsubscribeService.unsubscribe(this.id, this.email).subscribe((result: any) => {

      if(!result.success) {
        this.requestNotFound = true;
      }

      this.loading = false;
      
    }, err => {
      
      if (err.status != 200) {
        this.requestNotFound = true;
        this.loading = false;
      }

    });

  }

}
