import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from "rxjs";
import { LevelService } from 'src/app/services/level.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-platform-level',
  templateUrl: './platform-level.component.html',
  styleUrls: ['./platform-level.component.scss']
})
export class PlatformLevelComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  id: any = null;
  page: any = null;
  subpage: any = null;

  loading: boolean = true;
  levelInfo: any;

  constructor(
    private _permissionsService: NgxPermissionsService,
    private _route: ActivatedRoute,
    private _levelService: LevelService,
    private _router: Router,
    private _userinfoService: UserinfoService,
    private _titleService: Title
  ) {


    this.subscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {

        let newLevel = false;

        if (this.id && this.id != this._route.snapshot.paramMap.get('id')) {
          newLevel = true;
        }

        this.id = this._route.snapshot.paramMap.get('id');

        /**
         * this._route.firstChild <-- so that we get the paramters from child routes
         */
        if (this._route.firstChild) {
          this.page = this._route.firstChild.snapshot.paramMap.get('page');
          this.subpage = this._route.firstChild.snapshot.paramMap.get('subpage');
        }
        else {
          this.page = null;
          this.subpage = null;
        }

        if (newLevel) {

          this.loading = true;
          this.loadData();

        }

      }
    });
  }

  ngOnInit(): void {

    this.id = this._route.snapshot.paramMap.get('id');

    if (this._route.firstChild) {
      this.page = this._route.firstChild.snapshot.paramMap.get('page');
      this.subpage = this._route.firstChild.snapshot.paramMap.get('subpage');
    }
    else {
      this.page = null;
      this.subpage = null;
    }


    this.loadData();

  }

  loadData() {

    this._permissionsService.removePermission('PLATFORM_ADMIN');
    this._permissionsService.removePermission('FOLLOWER');

    let promise = this._userinfoService.getLoggedIn() ?
      this._levelService.getLevel(this.id) : this._levelService.getLevelPublic(this.id)

    promise.subscribe((result: any) => {

      if (result.success) {

        this.levelInfo = result.data;

        this._titleService.setTitle(result.data.name);

        if (this.levelInfo.role == 0 || this.levelInfo.role == 1) {
          this._permissionsService.addPermission('PLATFORM_ADMIN');
        }
        else if (this.levelInfo.role == 3) {
          this._permissionsService.addPermission('FOLLOWER');
        }

        if (!this.levelInfo.introduction) {
          this.levelInfo.introduction = '<i>Write introduction</i>'
        }

      }

      this.loading = false;

    }, err => {

      this.loading = false;

      /*
      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }*/
    });

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
