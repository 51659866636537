<ng-container *ngIf="!loading">


    <mat-toolbar class="d-flex flex-wrap align-items-center my-4">

        <h1 class="mat-h1 mb-0">{{'Project Proposals' | translate}}</h1>

        <ng-container *ngIf="isWg">
            <div class="ms-auto">
                <button mat-raised-button routerLink="{{ _url }}//add-proposal">
                    <mat-icon>add</mat-icon>
                    <div class="d-none d-md-inline">
                        {{'Add proposal' | translate}}
                    </div>
                </button>
            </div>
        </ng-container>


    </mat-toolbar>

    <ng-container *ngIf="resultsLength == 0">

        {{'No project proposals' | translate}}.

    </ng-container>

    <div [hidden]="resultsLength == 0">


        <table mat-table matSort [dataSource]="proposalData" class="mat-elevation-z2 w-100 mt-3">

            <ng-container matColumnDef="visible">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="visible"> Visible </th>
                <td mat-cell *matCellDef="let element" style="width: 100px;">
                    <ng-container *ngIf="element.visible_to_public; else notVisible">
                        <span class="material-icons" style="color:green">
                            check
                        </span>
                    </ng-container>
                    <ng-template #notVisible>
                        <span class="material-icons">
                            block
                        </span>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="title"> {{'Title' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="pe-1 py-1 py-md-0" style="cursor: pointer;"
                    title="{{element.title}}" routerLink="{{ _url }}/{{ element._id }}">
                    <span style="font-weight: bold;">{{ element.nickname }}</span> - {{ element.title | truncate : 60
                    }}
                </td>
            </ng-container>

            <ng-container matColumnDef="date_created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> {{'Created' | translate}} </th>
                <td mat-cell *matCellDef="let element" matTooltip="{{ element.date_created | date: 'medium' }}"
                    style="width: 130px;">{{
                    element.date_created | myDate }}</td>
            </ng-container>


            <ng-container matColumnDef="author">
                <th mat-header-cell *matHeaderCellDef> {{'Author' | translate}} </th>
                <td mat-cell *matCellDef="let element" style="width: 70px;">
                    <app-user-avatar [info]="element.user_id"></app-user-avatar>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> {{'Action' | translate}}</th>
                <td mat-cell *matCellDef="let element" style="width: 40px;">

                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item routerLink="{{ _url }}/{{ element._id }}">
                            <mat-icon>visibility</mat-icon>
                            <span>{{'View' | translate}}</span>
                        </button>
                        <ng-container *ngIf="isWg">
                            <button mat-menu-item (click)="changeVisibility(element._id, element.visible_to_public)">
                                <ng-container *ngIf="!element.visible_to_public; else notVisibleButton">
                                    <mat-icon>check</mat-icon>
                                    <span>Make visible</span>
                                </ng-container>
                                <ng-template #notVisibleButton>
                                    <mat-icon>block</mat-icon>
                                    <span>Hide from public</span>
                                </ng-template>
                            </button>
                            <button mat-menu-item (click)="generateProsposalPDF(element._id)">
                                <mat-icon>get_app</mat-icon>
                                <span>{{'Export as PDF' | translate}}</span>
                            </button>
                        </ng-container>
                    </mat-menu>

                    <!--
                    <button mat-stroked-button class="small-btn me-2" color="primary" matTooltip="{{'View' | translate}}" routerLink="{{ _url }}/{{ element._id }}">
                        <mat-icon>visibility</mat-icon>
                    </button>

                    <button mat-stroked-button class="small-btn" matTooltip="{{'Export as PDF' | translate}}" (click)="generateProsposalPDF(element._id)">
                        <mat-icon>get_app</mat-icon>
                    </button>    -->
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>

</ng-container>