import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthentificationService } from '../services/authentification.service';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss']
})
export class ValidateComponent implements OnInit {

  requestMembership: string;
  newMemberId: string;

  loading: boolean = true;
  showError: boolean = false;
  showErroMessage: String = '';

  constructor(
    private _route: ActivatedRoute,
    private _authenticationService: AuthentificationService,
    private _router: Router,
    private _layoutService: LayoutService,
    private _cookieService: CookieService
  ) { }

  ngOnInit(): void {

    this.requestMembership = this._route.snapshot.queryParams['requestMembership'];
    this.newMemberId = this._route.snapshot.queryParams['newMember'];

    if(this._route.snapshot.data.hideHeader) {

      // Set timeout because NG0100 error
      setTimeout(()=> {
        this._layoutService.hideToolbar();
      }, 0)
      
    }  


    this._authenticationService.validateEmail(this._route.snapshot.paramMap.get('id')).subscribe((result: any) => {  
      
      if(result.success) { 
        
        if(this.newMemberId) {
          let expiredDate = new Date();
          expiredDate.setMinutes(expiredDate.getMinutes() + 10);  // 10 minutes
          this._cookieService.set('newMemberId', this.newMemberId, expiredDate, '/', '', true, 'Strict');
        }

        if(this.requestMembership) {
          this._router.navigate(['register/' + result.data], { queryParams: { requestMembership: this.requestMembership }});
        } 
        else {
          this._router.navigate(['register/' + result.data]);
        }

      }
      else {
        this.loading = false;
        this.showError = true;
        this.showErroMessage = result.message;

      }

      

    }, err => {  

      if(err.status != 200) {
        this.loading = false;

        this.showError = true;
        this.showErroMessage = 'Request not found';
      }


    });

  }

}
