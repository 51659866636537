<div class="d-flex align-items-center mb-2">
  <h2 mat-dialog-title class="d-flex align-items-center mb-0">    
    {{'Browse users' | translate}}
  </h2>

  <div class="ms-auto">
    <button mat-button color="secondary" [mat-dialog-close]="false">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content>

  <ng-container *ngIf="loading; else showResult">

    Loading ...

  </ng-container>

  <ng-template #showResult>

    <cdk-virtual-scroll-viewport [itemSize]="48" style="height: 400px">

      <mat-list role="list">
        <mat-list-item *cdkVirtualFor="let user of users">

          <mat-checkbox [(ngModel)]="user.selected" (change)="selectionCount(user.selected)">

            <div class="d-flex align-items-center ms-2">

              <app-user-avatar width="25" fontSize="10px" [info]="user"></app-user-avatar>
              <div class="ms-2">
                {{ user.full_name }}
              </div>

            </div>

          </mat-checkbox>

        </mat-list-item>
      </mat-list>

    </cdk-virtual-scroll-viewport>

    <ng-container *ngIf="users.length == 0">
      {{'No users' | translate}}.
    </ng-container>

  </ng-template>



</mat-dialog-content>

<mat-dialog-actions>
  <ng-container *ngIf="selectionIndex > 0">
    <button mat-raised-button color="primary" class="mr-2" (click)="save()">{{'Select' | translate}}
      ({{ selectionIndex }})</button>
  </ng-container>
  <ng-container *ngIf="selectionIndex == 0">
    <button mat-raised-button color="primary" class="mr-2" disabled>{{'Select' | translate}}</button>
  </ng-container>

  <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>

</mat-dialog-actions>
