import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  addPage(levelId: string, title: string, content: string) {

    return this._http.post(this._config.getApiUrl() + '/api/pages', {    
      level_id: levelId,
      title: title,
      content: content
    }, this._httpOptionsService.getHeader())

  }

  editPage(pageId: string, levelId: string, title: string, content: string) {

    return this._http.put(this._config.getApiUrl() + '/api/pages/' + pageId, {    
      level_id: levelId,
      title: title,
      content: content
    }, this._httpOptionsService.getHeader())

  }

  deletePage(pageId: string, levelId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/pages/' + levelId + '/' + pageId, this._httpOptionsService.getHeader())
  }

  getPages(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/pages/' + levelId, this._httpOptionsService.getHeader())
  }
}
