import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { BrowseUsersDialogComponent } from 'src/app/component/browse-users-dialog/browse-users-dialog.component';
import { ChatService } from 'src/app/services/chat.service';
import { SocketService } from 'src/app/services/socket.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-new-message-dialog',
  templateUrl: './new-message-dialog.component.html',
  styleUrls: ['./new-message-dialog.component.scss']
})
export class NewMessageDialogComponent implements OnInit {

  myControl = new UntypedFormControl();

  loading: boolean = true;

  mainForm: UntypedFormGroup;
  submitted: boolean = false;

  options: any[] = [];
  filteredOptions: Observable<any[]>;


  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emailCtrl = new UntypedFormControl();
  userCtrl = new UntypedFormControl();
  filteredUsers: Observable<string[]>;
  users: any[] = [];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  browseUserDialogRef: MatDialogRef<BrowseUsersDialogComponent>;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<NewMessageDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _usersService: UsersService,
    private _ngZone: NgZone,
    private _chatService: ChatService,
    private _socketService: SocketService,
    private _dialog: MatDialog
  ) { 

    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(''),
      switchMap(value => {
        if (value !== '') {
          return this._filter(value);
        } else {
          return of(null);
        }
      })
    );

  }

  ngOnInit(): void {
    /*this.mainForm = this._fb.group({
      user_id: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });*/

    this.mainForm = this._fb.group({
      message: ['', [Validators.required]]
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value !== '') {
          // lookup from github

          return this._filter(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
  }

  /*
  private _filter(value: string): Observable<any[]> {

    return this._usersService.searchConnectedUsers(value).pipe(
      // map the item property of the github results as our return object
      map((data:any) => data.data),
      // catch errors
      catchError(_ => {
        return of(null);
      })
    );

  }*/

  get f() { return this.mainForm.controls; }
  
  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") { 
      
      this._chatService.sendChat(this.users, this.mainForm.value.message).subscribe((result: any) => {
  
        if (result.success) {

          this._socketService.emit('send-message', {
            chat_id: result.data.chat_id,
            user_id: result.data.user_id,
            users: this.users, 
            message: this.mainForm.value.message,
            date_created: result.data.date_created
          })

          this._dialogRef.close(result.data);

          this._snackBar.open('Message is sent', '', {
            duration: 2000
          });
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });
    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }
  }

  /*
  selected(event: MatAutocompleteSelectedEvent): void {
    this.mainForm.patchValue({
      'user_id': event.option.value._id
    })
  }*/


  displayFn(user: any): string {
    return user && user.full_name ? user.full_name : '';
  }

  /*
  add(event: MatChipInputEvent): void {

    let emailRegex = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;

    if((event.value.match(emailRegex))) {

      this.emailCtrl.setErrors(null);
      
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {

        if(!this.userExist(value.trim())) { 
          this.users.push({ full_name: value.trim(), isEmail: true });
        }
        else {
          this._snackBar.open('User is already added', '', {
            horizontalPosition: 'left',
            duration: 2000
          });
        }
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.userCtrl.setValue(null);
    }
    else {      
      if(event.value != '') { 
        // if email is incorrect
        this.emailCtrl.setErrors({'incorrect': true});
        this.emailCtrl.markAsTouched();
      }
    }

    
  }*/

  remove(user: any): void {
    const index = this.users.indexOf(user);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
   

    //this.users.push(event.option.viewValue);
    if(!this.userExist(event.option.value)) { 
      this.users.push(event.option.value);
    }
    else {
      this._snackBar.open('User is already added', '', {
        horizontalPosition: 'left',
        duration: 2000
      });
    }
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);
    this.emailCtrl.setErrors(null);
  }

  private _filter(value: any): Observable<any[]> {

    // we remove error from input on text change
    this.emailCtrl.setErrors(null);

    let filterValue = ' ';
    if(value && !value._id) {
      filterValue = (value) ? value.toLowerCase() : value;
    }  
    else {
      return of(null);
    } 

    return this._usersService.searchConnectedUsers(filterValue).pipe(
      // map the item property of the github results as our return object
      map((data:any) => data.data),
      // catch errors
      catchError(_ => {
        return of(null);
      })
    );
    
  }

  userExist(input: any): boolean {

    for(let user of this.users) {

      if(input._id) {
        if(user._id == input._id) {
          return true;
        }
      }
      else {
        if(user.full_name == input) {
          return true;
        }
      }

    }

    return false;    

  }

  browseUsers() {

    // we remove the autocomplete
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);

    this.browseUserDialogRef = this._dialog.open(
      BrowseUsersDialogComponent,
      {
        width: '350px',
        data: {         
        },
        disableClose: false
      }
    );

    this.browseUserDialogRef.afterClosed().subscribe(users => {
      if (users) {
        
        for(let user of users) {

          if(!this.userExist(user)) { 
            this.users.push(user);
          }

        }

      }
    });
  }

}
