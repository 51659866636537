<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

    <div class="d-flex align-items-center mb-2">

        <h2 mat-dialog-title class="d-flex align-items-center mb-0">
            <ng-container *ngIf="!edit">        
                <span class="material-icons">
                    add
                </span> 
                {{'Add discussion' | translate}}
            </ng-container>
            <ng-container *ngIf="edit">        
                <span class="material-icons">
                    create
                </span> 
                {{'Edit discussion' | translate}}
            </ng-container>
        </h2>

        <div class="ms-auto">
            <button mat-button color="secondary" [mat-dialog-close]="false">
                <mat-icon>clear</mat-icon>
            </button>
        </div>

    </div>

    <mat-dialog-content>

        <mat-form-field appearance="fill" class="w-100 mb-4" *ngIf="!data.conversation">
            <mat-label>
                {{'Topic name' | translate}}
            </mat-label>
            <input matInput formControlName="topic_name" maxlength="150">
            <mat-hint align="end">{{f.topic_name.value.length}} / 150</mat-hint>
        </mat-form-field>

        <app-my-editor [levelid]="data.level_id" [data]="message" (output)="onCkEditorValue($event)"></app-my-editor>

        <ng-container *ngIf="attachments.length > 0">
            <mat-list role="list">
             <ng-container *ngFor="let a of attachments">
                <mat-list-item role="listitem">

                    <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                    <div mat-line class="d-flex align-items-center">                            
                        <div style="max-width: 250px;" class="text-truncate">
                            {{a.original_name}}
                        </div>
                    
                        <div class="ms-auto">
                            <mat-icon class="pointer" (click)="removeAttachment(a._id)">delete</mat-icon>
                        </div>
                    </div>

                </mat-list-item>
             </ng-container>      
            </mat-list>         
        </ng-container>

        <div style="height: 20px;"></div>

        <app-upload-file (data)="uploadedAttachment($event)" [levelId]="data.level_id" attachment="true"></app-upload-file>


    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button color="primary" class="mr-2" type="submit">
            <ng-container *ngIf="!edit">
                <span class="material-icons">
                    add
                </span> {{'Add' | translate}}
            </ng-container>            
            <ng-container *ngIf="edit">
                {{'Update' | translate}}
            </ng-container>
        </button>
        <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
    </mat-dialog-actions>


</form>