<ng-container *ngIf="!loading">
    <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="d-flex align-items-center mb-2">
            <h2 mat-dialog-title class="mb-0">{{ 'Change role' | translate }}</h2>

            <div class="ms-auto">
                <button mat-button color="secondary" [mat-dialog-close]="false">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>

        </div>
        <mat-dialog-content>

            <ng-container *ngIf="levelType == 'cplatform'; else lowerLevel">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Select role</mat-label>
                    <mat-select formControlName="role">
                        <mat-option [value]="1">Co-ordinator</mat-option>
                        <mat-option [value]="3">Member</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-template #lowerLevel>
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Select role</mat-label>
                    <mat-select formControlName="role">
                        <mat-option [value]="1">Co-ordinator</mat-option>
                        <mat-option [value]="2">Member</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>



        </mat-dialog-content>
        <mat-dialog-actions>

            <button mat-raised-button color="primary" class="ms-2">{{ "Save" | translate }}</button>
            <button mat-raised-button [mat-dialog-close]="true">{{ "Cancel" | translate }}</button>

        </mat-dialog-actions>
    </form>
</ng-container>