import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TasksService } from 'src/app/services/tasks.service';
import { MembershipService } from 'src/app/services/membership.service';

@Component({
  selector: 'app-tasks-dialog',
  templateUrl: './tasks-dialog.component.html',
  styleUrls: ['./tasks-dialog.component.scss']
})
export class TasksDialogComponent implements OnInit {

  loading: boolean = true;

  text: string = '';

  mainForm: UntypedFormGroup;
  submitted: boolean = false;

  availableUsers : any[] = [];
  taskUsers : any[] = [];

  minDate = new Date();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<TasksDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _taskService: TasksService,
    private _membershipService: MembershipService
  ) { }

  ngOnInit(): void {

    //console.log(this.data)

    this.getAvailableUsers().then((users: any) => {
      this.loading = false;
      this.availableUsers = users;

      /** We check existing users */

      if(this.data.current && this.data.current.users.length > 0) {

        let existingUsers = this.data.current.users;

        for(let existing of existingUsers) {

          for(let available of this.availableUsers) {

            if(existing.user_id._id == available.user_id._id) {

              available.user_id.checked = true;
              this.taskUsers.push(available.user_id._id)

            }

          }

        }

      }

      //console.log(this.availableUsers)

      this.createForm(this.data.current);

      this.mainForm.patchValue({
        level_id: this.data.level_id
      });
    });

    
  }

  getAvailableUsers() {

    return new Promise((resolve, reject) => {

      this._membershipService.getMembers(this.data.level_id).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });

  }

  createForm(data?: any) {

    this.mainForm = this._fb.group({
      name: [(data && data.name) ? data.name : '', [Validators.required]],
      due: [(data && data.due) ? new Date(parseInt(data.due)) : '', Validators.required],
      description: [(data && data.description) ? data.description : ''],      
      level_id: ['', Validators.required]
    });

    this.text = (data && data.description) ? data.description : '';
  }

  selected(userId: string) {

    let index = this.taskUsers.indexOf(userId);

    if(index == -1) {
      this.taskUsers.push(userId)
    }
    else {
      this.taskUsers.splice(index, 1)
    }

  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    //console.warn(this.taskUsers)

    this.submitted = true;

    let date = new Date(this.mainForm.value.due);

    if (this.mainForm.status != "INVALID") {

      let action = (this.data.current) ?  
      this._taskService.editTask(
        this.data.current._id,
        this.mainForm.value.level_id,
        this.mainForm.value.name,
        date.getTime(),
        this.mainForm.value.description,
        this.taskUsers        
      ) :
      this._taskService.addTask(
        this.mainForm.value.level_id,
        this.mainForm.value.name,
        date.getTime(),
        this.mainForm.value.description,
        this.taskUsers
      );

      action.subscribe((result: any) => {

      if (result.success) {
        this._dialogRef.close(result.data);
      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
      
    }, err => {

      if (err.status != 200) {
        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    });


    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

  onCkEditorValue(text: string) {
    this.mainForm.patchValue({
      description: text
    });
  }

}
