<div class="search-bar">

    <div class="d-flex align-items-center position-relative">

        <input type="text" placeholder="Search ..." [ngModel]="searchString" (ngModelChange)="changed($event)" [ngClass]="{ 'search-width-full' : resultsLength > 0 }">

        <!--
        <div class="text-gray-500" style="position: absolute; left: 0px; margin-top: 6px; height: auto;">
            <mat-icon style="font-size: 1.4rem;">search</mat-icon>
        </div>-->


        <div style="width: 100%; position: absolute; background: #fafafa; min-height: 40px; max-height: 200px; top: 29px; overflow-y: auto; z-index: 9999!important;" class="border mat-elevation-z2" *ngIf="resultsLength > 0">
            <mat-selection-list role="list" [multiple]="false">
                <mat-list-option role="listitem" *ngFor="let r of results.levels" (click)="showLevel(r.link)">
                    <div class="d-flex align-items-center">
                        <div class="me-2">
                            <div *ngIf="r.type == 'cplatform'" style="width: 6px; height: 6px; border-radius: 50%;" class="platform-bg"></div>
                            <div *ngIf="r.type == 'interest'" style="width: 6px; height: 6px; border-radius: 50%;" class="interest-bg"></div>
                            <div *ngIf="r.type == 'wg'" style="width: 6px; height: 6px; border-radius: 50%;" class="wg-bg"></div>
                        </div>
                        {{ r.name | truncate:80 }}
                    </div>
                </mat-list-option>
                <mat-divider *ngIf="results.levels.length > 0 && results.users.length > 0"></mat-divider>
                <mat-list-option role="listitem" *ngFor="let r of results.users" (click)="showUser(r._id)">
                    <div class="d-flex align-items-center">

                        <app-user-avatar width="25" fontSize="12px" [info]="r">
                        </app-user-avatar>

                        <div class="ms-2">
                            {{ r.full_name | truncate:80 }}
                        </div>

                    </div>
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>


    <!--
    <div class="d-flex align-items-center position-relative">

        <input type="text" placeholder="Search" [ngModel]="searchString" (ngModelChange)="changed($event)" [ngClass]="{ 'search-width-full' : resultsLength > 0 }">

        <div style="position: absolute; right: 6px; margin-top: 6px; background: #fafafa; height: 29px;">
            <mat-icon>search</mat-icon>
        </div>


        <div style="width: 100%; position: absolute; background: #fafafa; min-height: 40px; max-height: 200px; top: 49px; overflow-y: auto; z-index: 9999!important;" class="border mat-elevation-z2" *ngIf="resultsLength > 0">
            <mat-selection-list role="list" [multiple]="false">
                <mat-list-option role="listitem" *ngFor="let r of results.levels" (click)="showLevel(r.link)">
                    <div class="d-flex align-items-center">
                        <div class="me-2">
                            <div *ngIf="r.type == 'cplatform'" style="width: 6px; height: 6px; border-radius: 50%;" class="platform-bg"></div>
                            <div *ngIf="r.type == 'interest'" style="width: 6px; height: 6px; border-radius: 50%;" class="interest-bg"></div>
                            <div *ngIf="r.type == 'wg'" style="width: 6px; height: 6px; border-radius: 50%;" class="wg-bg"></div>
                        </div>
                        {{ r.name | truncate:80 }}
                    </div>
                </mat-list-option>
                <mat-divider *ngIf="results.levels.length > 0 && results.users.length > 0"></mat-divider>
                <mat-list-option role="listitem" *ngFor="let r of results.users" (click)="showUser(r._id)">
                    <div class="d-flex align-items-center">

                        <app-user-avatar width="25" fontSize="12px" [info]="r">
                        </app-user-avatar>

                        <div class="ms-2">
                            {{ r.full_name | truncate:80 }}
                        </div>

                    </div>
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>
    -->

</div>