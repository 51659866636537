<div class="h-100">
    <div class="w-100 h-100">
        <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">
            <div class="container h-100">
                <div class="row h-100">

                    <div class="col-12 flex-column d-flex justify-content-center align-items-center">



                        <div class="my-4 d-flex justify-content-center">
                            <img src="assets/main-logo.png" />
                        </div>


                        <div>

                            
                            <mat-card>

                                <div class="text-center text-md-start">
                                    <h1 class="mat-h1">{{ 'Login' | translate }}</h1>
                                </div>

                                <mat-card-content>


                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>
                                            {{ 'E-mail' | translate }}
                                        </mat-label>
                                        <input matInput formControlName="email">
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>
                                            {{ 'Password' | translate }}
                                        </mat-label>
                                        <input matInput formControlName="password" type="password">
                                    </mat-form-field>

                                    

                                    <mat-dialog-actions>
                                        <div class="d-flex align-items-center">
                                            <button mat-raised-button color="primary" class="mr-2" type="submit">{{
                                                'Login' | translate }}</button>

                                            <div class="ms-2">
                                                <button type="button" mat-button routerLink="/forgot">{{ 'Forgot
                                                    password?' | translate }}</button>
                                            </div>
                                        </div>
                                    </mat-dialog-actions>


                                </mat-card-content>

                            </mat-card>

                            <div *ngIf="showError" class="alert alert-danger text-center" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                                {{ showErroMessage | translate }}
                            </div>


                            <div class="my-4 text-center">
                                or
                            </div>

                            <mat-card>

                                <div class="d-flex flex-column text-center flex-lg-row">


                                    <div class="mb-2 mb-lg-0">
                                    
                                        <cool-google-button color="dark" (click)="signInWithGoogle()">
                                            Login with Google
                                        </cool-google-button>

                                
                                    </div>

                                    <cool-facebook-button color="dark" class="ms-lg-auto" (click)="signInWithFB()">
                                        Login with Facebook
                                    </cool-facebook-button>
                              
                                </div>
                               

                            </mat-card>


                            <div class="my-4 text-center">                                
                                <button mat-button color="primary" type="button" routerLink="/register">Create new Account</button>
                            </div>

                            


                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>