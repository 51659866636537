<div class="container" *ngIf="!loading">
    <div class="row">
        <div class="col my-4">
            <h1>KMFest 2024 - participants</h1>
        </div>

        <ng-container *ngIf="unableToLoad; else showTable">
            <div>
                <h3>Unable to load data</h3>
            </div>
        </ng-container>

        <ng-template #showTable>

            <div>
                <button mat-raised-button type="button" class="mb-3" (click)="openAsCSV()">Open as CSV</button>
            </div>

            <table mat-table matSort [dataSource]="usersData" class="mat-elevation-z2 w-100 mt-3">

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="email"> Email </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.email}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="first_name"> First name </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.first_name || 'N/A'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="last_name"> Last name </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.last_name || 'N/A'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="organization">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="organization"> Organization </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.organization || 'N/A'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="eventSelection">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="eventSelection"> Workshop </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.both_events; else onlyOne">
                            <div class="d-flex" style="gap: 2px">
                                <div class="circle green">1</div>
                                <div class="circle green">2</div>
                            </div>
                        </ng-container>
                        <ng-template #onlyOne>
                            <ng-container *ngIf="element.first_event || element.second_event; else notAvailable">
                                <ng-container *ngIf="element.first_event">
                                    <div class="d-flex" style="gap: 2px">
                                        <div class="circle green">1</div>
                                        <div class="circle gray">2</div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="element.second_event">
                                    <div class="d-flex" style="gap: 2px">
                                        <div class="circle gray">1</div>
                                        <div class="circle green">2</div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-template #notAvailable>
                                N/A
                            </ng-template>
                        </ng-template>

                    </td>
                </ng-container>

                <ng-container matColumnDef="date_created">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Created </th>
                    <td mat-cell *matCellDef="let element">{{ element.date_created | date: "medium" }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </ng-template>
    </div>
</div>