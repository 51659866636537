<ng-container *ngIf="!loading">


    <mat-toolbar class="d-flex flex-wrap align-items-center my-4">

        <h1 class="mat-h1 mb-0">
            <ng-container *ngIf="isProject; else notProject">
                {{'WP - Work Packages' | translate}}
            </ng-container>
            <ng-template #notProject>
                {{'WG - Work Groups' | translate}}                
            </ng-template>
        </h1>

    </mat-toolbar>

    <div class="my-2">

        <ng-container *ngIf="interests.length == 0; else showChildren">
            <div class="mx-2">
                <ng-container *ngIf="!isProject">{{'No interests' | translate}}.</ng-container>
                <ng-container *ngIf="isProject">{{'No projects' | translate}}.</ng-container>
            </div>
        </ng-container>

        <ng-template #showChildren>


            <div class="wg-list">
                
                <div [routerLink]="interest.link" class="wg-item" *ngFor="let interest of interests;">

                    <div class="wg-bg me-4" style="width: 10px; height: 40px;"></div>

                    {{interest.name | truncate : 120 }}

                </div>

            </div>


            <!--
            <mat-card class="mat-elevation-z0 px-0 px-md-2 px-lg-4">

                <mat-list role="list">

                    <mat-list-item *ngFor="let interest of interests; let last = last;">

                        <div [routerLink]="interest.link" class="d-flex align-items-center pointer">

                            <div class="wg-bg me-2" style="width: 20px; height: 40px;"></div>

                            {{interest.name | truncate : 120 }}

                        </div>


                    </mat-list-item>

                </mat-list>

            </mat-card>-->

        </ng-template>

    </div>

</ng-container>