<div class="h-100 create-plaform-page">

    <div class="d-flex flex-column align-items-center justify-content-center h-100">

        <ng-container *ngIf="!successPage; else showSuccessPage">

            <div class="display-4">Create Platform</div>
            <h1 class="text-muted">Create your own Virtual Open Community</h1>

            <div class="mat-elevation-z1 p-4 bg-white">

                <div class="lead">Send us an inquiry and we will send you the details</div>
                
                <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate>

                    <div class="my-4">
                        <label>Comment (optional)</label>
                        <textarea class="w-100 form-control" formControlName="comment"></textarea>
                    </div>

                    <button mat-raised-button color="primary" type="submit">Send inquiry</button>

                    <div *ngIf="showError" class="alert alert-danger text-center mt-2" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                        {{ showErroMessage | translate }}
                    </div>

                </form>


            </div>

        </ng-container>
        <ng-template #showSuccessPage>


            <div class="text-success">
                <mat-icon style="font-size: 4rem; width: auto;">check</mat-icon>
            </div>

            <div class="lead">

                Thank you! You have successfully sent an inquiry. 

                <div class="mt-3 text-center">

                    <button mat-raised-button routerLink="/">Return back</button>

                </div>

            </div>
        </ng-template>



    </div>

</div>
