/**
 * Resolve platform SLUG or show 404 not found page
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LevelService } from '../services/level.service';

@Component({
  selector: 'app-resolver',
  templateUrl: './resolver.component.html',
  styleUrls: ['./resolver.component.scss']
})
export class ResolverComponent implements OnInit {

  loading: boolean = true;
  notFound: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _levelService: LevelService
  ) { }

  ngOnInit(): void {
    let url = this._router.url.split('/');

    if(!url[1]) {
      this._router.navigate(['home']);
    }

    this._levelService.resolvePlatform(url[1]).subscribe((result: any) => {

      this.loading = false;

      if(result.success) {

        this._router.navigate([result.data]);

      } else {

         this.notFound = true;        

      }

    }, err => {

      if (err.status != 200) {

        this.notFound = true;
        this.loading = false;

      }

    });
  }

}
