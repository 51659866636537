import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { InstallService } from '../services/install.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss']
})
export class InstallComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted = false;

  constructor(
    private _fb: UntypedFormBuilder, 
    private _installService: InstallService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _route: ActivatedRoute,
    private _layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    if(this._route.snapshot.data.hideHeader) {

      // Set timeout because NG0100 error
      setTimeout(()=> {
        this._layoutService.hideToolbar();
      }, 0)
      
    }  

    this.createForm();
  }

  createForm() {
    this.mainForm = this._fb.group({     
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]] 
    });
  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this._installService.install(       
        this.mainForm.value.email,
        this.mainForm.value.firstName,
        this.mainForm.value.lastName,
        this.mainForm.value.password
      ).subscribe((result: any) => {  

        if(result.success) {

          this._snackBar.open('Your site was added successfully', '', {
            duration: 2000,
          });

          this._router.navigate(['login']);
        }

      }, err => {

        if(err.status != 200) {
          this._snackBar.open('Please enter all values', '', {
            duration: 2000,
          });
        }

      });

    }

  }

}
