<ng-container *ngIf="loading">
    <div class="d-flex h-100 align-items-center justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>

<ng-container *ngIf="!loading">

    <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

        <div class="d-flex align-items-center mb-2">
            <h2 mat-dialog-title class="d-flex align-items-center mb-0">
                <ng-container *ngIf="!data.edit">
                    <mat-icon>add</mat-icon>
                    {{'Add task' | translate}}
                </ng-container>
                <ng-container *ngIf="data.edit">
                    <mat-icon>create</mat-icon>
                    {{'Edit task' | translate}}
                </ng-container>
            </h2>

            <div class="ms-auto">
                <button mat-button color="secondary" [mat-dialog-close]="false">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>
        </div>

        <mat-dialog-content>

            <mat-form-field appearance="fill" class="w-100">
                <mat-label>
                    {{'Task name' | translate}}
                </mat-label>
                <input matInput formControlName="name">
            </mat-form-field>

            
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'Choose a due date' | translate}}</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="due" [min]="minDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <ng-container *ngIf="availableUsers.length > 0">
                <div class="my-2 mb-4">
                    <div class="mat-caption">{{'Assign members' | translate}}</div>
                    <ng-container *ngFor="let user of availableUsers">
                        <div class="my-2  ml-2">
                            <mat-checkbox [(ngModel)]="user.user_id.checked" [ngModelOptions]="{standalone: true}" (change)="selected(user.user_id._id)">{{ user.user_id.full_name }}</mat-checkbox>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <div class="editor-size">
                <div class="text-gray-600 mat-caption text-capitalize"><i>{{'optional' | translate}}</i></div>
                <app-my-editor [levelid]="data.level_id" [data]="text" (output)="onCkEditorValue($event)"></app-my-editor>                            
            </div>

        </mat-dialog-content>

        <mat-dialog-actions>
            <button mat-raised-button color="primary" class="mr-2" type="submit">
                <ng-container *ngIf="!data.edit">
                    <span class="material-icons">
                        add
                    </span> {{'Add' | translate}}
                </ng-container>

                <ng-container *ngIf="data.edit">
                    {{'Update' | translate}}
                </ng-container>
            </button>
            <button mat-raised-button [mat-dialog-close]="false">{{'Cancel' | translate}}</button>
        </mat-dialog-actions>


    </form>

</ng-container>