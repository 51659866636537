<ng-container *ngIf="loading; else loaded">

    <div class="d-flex h-100 align-items-center justify-content-center">
        <mat-spinner></mat-spinner>
    </div>

</ng-container>

<ng-template #loaded>

    <div *ngIf="resultsLength == 0">

        No emails sent ...

    </div>

    <div [hidden]="resultsLength == 0">

        <table mat-table matSort [dataSource]="emailData" class="mat-elevation-z2 w-100 mt-3">

            <ng-container matColumnDef="sent">

                <th mat-header-cell *matHeaderCellDef mat-sort-header="sent"> Sent </th>
                <td mat-cell *matCellDef="let element">

                    <mat-icon *ngIf="element.sent">done</mat-icon>

                    <mat-icon *ngIf="!element.sent" class="text-danger">close</mat-icon>

                </td>

            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="title"> Title </th>
                <td mat-cell *matCellDef="let element" >
                  
                    {{element.title}}        
                    
                </td>
            </ng-container>

            <ng-container matColumnDef="date_created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="date_created"> Sent </th>
                <td mat-cell *matCellDef="let element" style="width: 180px; font-size: .8rem">{{ element.date_created | date: "medium" }}</td>
            </ng-container>

            <ng-container matColumnDef="content">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="content"> Content </th>
                <td mat-cell *matCellDef="let element" style="font-size: .8rem;">
                   
                    <div class="mx-2 mt-2">
                        <textarea class="w-100" disabled>{{element.content}}</textarea>                        
                    </div>
                    
                </td>
            </ng-container>

            <ng-container matColumnDef="html">

                <th mat-header-cell *matHeaderCellDef> HTML </th>

                <td mat-cell *matCellDef="let element" style="font-size: .8rem;">
                
                    <button mat-button color="primary" (click)="showInfoDialog(element)">
                        <mat-icon>open_in_new</mat-icon>
                    </button>

                </td>

            </ng-container>

            <ng-container matColumnDef="error">
                <th mat-header-cell *matHeaderCellDef> Error </th>
                <td mat-cell *matCellDef="let element" style="font-size: .8rem;" matTooltip="{{ element.error }}">
                    <ng-container *ngIf="element.error">
                        {{element.error | truncate}}
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="user_id">
                <th mat-header-cell *matHeaderCellDef> User </th>
                <td mat-cell *matCellDef="let element">                   
                    {{element.user_id?.email}}                   
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>
</ng-template>