import { format } from 'date-fns';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-event-countdown',
  templateUrl: './event-countdown.component.html',
  styleUrls: ['./event-countdown.component.scss']
})
export class EventCountdownComponent implements OnInit, OnDestroy {

  countdown: string;
  isDeadlineReached: boolean = false;

  intervalRef: any;
  formatedDate: string;

  today = new Date();
  @Input() deadline: string;
  @Output() deadlineReached = new EventEmitter();

  constructor() {

    /*
    setTimeout(() => {
      this.isDeadlineReached = true;
      this.deadlineReached.emit();
      if (this.intervalRef) clearInterval(this.intervalRef);
    }, 4000);*/

  }

  ngOnInit(): void {
    this.formatedDate = format(new Date(this.deadline), 'dd.MM.yyyy HH:mm (EEEE)');
    this.startCountdown();

    this.intervalRef = setInterval(() => {
      this.startCountdown();
    }, 1000);


  }

  startCountdown() {
    const now = new Date();
    const distance = new Date(this.deadline).getTime() - now.getTime();

    if (distance < 0) {
      this.isDeadlineReached = true;
      this.deadlineReached.emit();

      if (this.intervalRef) clearInterval(this.intervalRef);

      return;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    this.countdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }

  ngOnDestroy(): void {
    if (this.intervalRef) clearInterval(this.intervalRef);
  }

}
