import { Injectable, PLATFORM_ID, Optional, Inject, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import config from '../config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config : any = {
    development: config.development,
    api_url: config.api_url
  }

  constructor() { }

  getConfig() {
    return this.config;
  }

  isDevelopment() {
    return this.config.development; 
  }

  getApiUrl() {
    return this.config.api_url;
  }
  
}
