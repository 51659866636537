import * as CKEDITOR from '../../lib/ckeditor5/build/ckeditor';

import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfigService } from 'src/app/services/config.service';
//import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-inline-editor',
  templateUrl: './inline-editor.component.html',
  styleUrls: ['./inline-editor.component.scss']
})
export class InlineEditorComponent implements OnInit {

  config: any = {}; 
  text: string = '';
  _toolbar = [ 'heading', '|', 'alignment', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'todoList', 'fontColor', 'highlight', '|', 'insertTable', 'htmlEmbed', 'mediaEmbed', 'imageUpload', '|', 'undo', 'redo'];

  @Input() set data(data: string) {
    this.text = data;
  }

  @Input() showEditor: boolean;

  @Output() output = new EventEmitter<any>();

  @ViewChild('editorContainer') editorContainer: ElementRef;

  constructor(
    private _config: ConfigService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {

    this.config = {
      toolbar: this._toolbar, 
      image: {
          toolbar: [
              'imageTextAlternative',
              'imageStyle:alignLeft', 
              'imageStyle:full', 
              'imageStyle:alignRight',
              'linkImage'
          ],
          styles: [
            // This option is equal to a situation where no style is applied.
            'full',
  
            // This represents an image aligned to the left.
            'alignLeft',
  
            // This represents an image aligned to the right.
            'alignRight'
        ]
      },
      table: {
          contentToolbar: [
              'tableColumn',
              'tableRow',
              'mergeTableCells',
              'tableCellProperties',
              'tableProperties'
          ]
      },
      simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: this._config.getApiUrl() + '/api/file/user',
      
        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: false,
  
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: { 'Authorization': this.cookieService.get('jwtEx') },
      }
    }

  }

  ngAfterViewInit() {

    CKEDITOR.InlineEditor
      .create( this.editorContainer.nativeElement, this.config )
      .then( editor => {

          editor.model.document.on( 'change:data', () => {
            
            //_this.output.emit(editor.getData());  
            this.text = editor.getData();

          });
      } )
      .catch( error => {
          console.error( error );
      } );
  }

  
  save(text: string) {
    this.output.emit(text);  
  }

  /*
  public onChange( { editor }: ChangeEvent ) {

    if(editor) {

      this.text = editor.getData();
     
    }
  }*/
}
