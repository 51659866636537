<div class="my-3 single-activity position-relative" *ngIf="data">
    <ng-container *ngIf="data.platform_id">
        <ng-container *ngIf="level == 'platform'">

        </ng-container>
        <ng-container *ngIf="level == 'interest'">
            <div class="level-indicator">
                <mat-card class="platform-level pointer" routerLink="{{ data.platform_id.link }}">
                    <div class="d-flex align-items-center" style="margin-top: -35px;">
                        <div class="level-square platform-bg"></div>
                        <div class="mat-caption" matTooltip="{{ data.platform_id.name }}">{{ data.platform_id.name | truncate : 60 }}</div>
                    </div>
                </mat-card>
            </div>
        </ng-container>
        <ng-container *ngIf="level == 'wg'">
            <div class="level-indicator">
                <mat-card class="platform-level pointer" routerLink="{{ data.platform_id.link }}">
                    <div class="d-flex align-items-center" style="margin-top: -35px;">
                        <div class="level-square platform-bg"></div>
                        <div class="mat-caption" matTooltip="{{ data.platform_id.name }}">{{ data.platform_id.name | truncate : 60 }}</div>
                    </div>
                </mat-card>
                <mat-card class="interest-level pointer" routerLink="{{ data.parent_id.link }}">
                    <div class="d-flex align-items-center" style="margin-top: -35px;">
                        <div class="level-square interest-bg"></div>
                        <div class="mat-caption" matTooltip="{{ data.parent_id.name }}">{{ data.parent_id.name | truncate : 60 }}</div>
                    </div>
                </mat-card>
            </div>
        </ng-container>
    </ng-container>


    <ng-container *ngIf="data.level_id">
        <mat-card class="activity-dialog" [ngClass]="{ 'interest-activity': level == 'interest', 'wg-activity': level == 'wg' }">

            <div class="d-flex align-items-center pointer" style="margin-top: -35px;" routerLink="{{ data.level_id.link }}">
                <div class="level-square" [ngClass]="{ 'platform-bg': level == 'cplatform', 'interest-bg': level == 'interest', 'wg-bg': level == 'wg' }"></div>
                <div class="mat-caption" matTooltip="{{ data.level_id.name }}">{{ data.level_id.name | truncate : 60 }}</div>
            </div>

            <mat-card-header class="mt-4 author-card">
                <div mat-card-avatar>
                    <app-user-avatar [info]="data.user_id" width="40"></app-user-avatar>
                </div>
                <mat-card-title>{{ data.user_id.full_name }}</mat-card-title>
                <mat-card-subtitle>
                    <div matTooltip="{{ data.date_created | date: 'medium' }}">{{ data.date_created | myDate }}</div>
                </mat-card-subtitle>
                <!-- 10 minutes ago -->
            </mat-card-header>

            <mat-card-content class="d-flex">

                <app-activity-icon [type]="type"></app-activity-icon>

                <ng-container *ngIf="(type == 'newTopic') && data.options">

                    <div class="my-2" style="position: relative;">
                        <div class="mat-caption text-uppercase">{{ 'New topic' | translate }}</div>

                        <h2 class="mat-h2">{{ data.options.topic_name }}</h2>

                        <!--<div class="activity-text-glow"></div>-->
                        <div class="activity-text">{{ data.options.conversation_text | mystriphtml | truncate:160 }}</div>

                    </div>

                </ng-container>

                <ng-container *ngIf="(type == 'newComment') && data.options">

                    <div class="my-2" style="position: relative;">
                        <div class="mat-caption text-uppercase">{{'New comment' | translate}}</div>

                        <h2 class="mat-h2">{{ data.options.topic_name }}</h2>

                        <!--<div class="activity-text-glow"></div>-->
                        <div class="activity-text">{{ data.options.conversation_text | mystriphtml | truncate:160 }}</div>

                    </div>

                </ng-container>

                <ng-container *ngIf="(type == 'newEvent') && data.options">

                    <div class="my-2" style="position: relative;">
                        <div class="mat-caption text-uppercase">{{'New event' | translate}}</div>

                        <h2 class="mat-h2">{{ data.options.event_name }}</h2>

                        <div class="d-flex">
                            <div class="mat-caption">{{ data.options.event_start_date | date: "longDate" }} -</div>
                            <div class="mat-caption ml-2">{{ data.options.event_end_date | date: "longDate" }}</div>
                        </div>
                    </div>

                </ng-container>

                <ng-container *ngIf="(type == 'newFile') && data.options">

                    <div class="my-2" style="position: relative;">
                        <div class="mat-caption text-uppercase">{{'New file' | translate}}</div>

                        <h2 class="mat-h2">{{ data.options.file_name }}</h2>

                    </div>

                </ng-container>


                <ng-container *ngIf="(type == 'newMilestone') && data.options">

                    <div class="my-2" style="position: relative;">
                        <div class="mat-caption text-uppercase">{{'New milestone' | translate}}</div>

                        <h2 class="mat-h2">{{ data.options.milestone_name }}</h2>

                        due to <i>{{ data.options.milestone_due | date: "longDate" }}</i>

                    </div>

                </ng-container>

                <ng-container *ngIf="(type == 'newTask') && data.options">

                    <div class="my-2" style="position: relative;">
                        <div class="mat-caption text-uppercase">{{'New task' | translate}}</div>

                        <h2 class="mat-h2">{{ data.options.task_name }}</h2>

                        due to <i>{{ data.options.task_due | date: "longDate" }}</i>

                    </div>

                </ng-container>

                <ng-container *ngIf="(type == 'newNews') && data.options">

                    <div class="my-2" style="position: relative;">
                        <div class="mat-caption text-uppercase">{{'New news' | translate}}</div>

                        <h2 class="mat-h2">{{ data.options.news_name }}</h2>

                        <div class="activity-text">{{ data.options.news_description | mystriphtml | truncate:160 }}</div>

                    </div>

                </ng-container>

            </mat-card-content>

            <mat-card-actions>
                <button mat-button color="primary" routerLink="{{ data.link }}" class="text-uppercase">{{ 'View' | translate }}</button>
            </mat-card-actions>
        </mat-card>
    </ng-container>
</div>