import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class InstallService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService
  ) { 
  }

  shouldInstall() {
    return this._http.get(this._config.getApiUrl() + '/install/status');
  }

  install(    
    email: string,
    first_name: string,
    last_name: string,
    password: string
  ) {

    return this._http.post(this._config.getApiUrl() + '/install/start', {          
      email: email,
      first_name: first_name,
      last_name: last_name,
      password: password
    });

  }
}
