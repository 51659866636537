import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MilestonesService } from 'src/app/services/milestones.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { MilestonesDialogComponent } from './milestones-dialog/milestones-dialog.component';

@Component({
  selector: 'app-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['./milestones.component.scss']
})
export class MilestonesComponent implements OnInit {

  loading: boolean = true;

  milestones: any = [];
  
  @Input() id: string;      // level id
  @Input() role: number;    // user role

  addMilestonesDialogRef: MatDialogRef<MilestonesDialogComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _milestonesService: MilestonesService
  ) { }

  ngOnInit(): void {
    this.getMilestones().then((milestones: any) => {
      this.loading = false;
      this.milestones = milestones;
    });
  }

  getMilestones() {

    return new Promise((resolve, reject) => {

      this._milestonesService.getMilestones(this.id).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });

  }

  addMilestone() {

    this.addMilestonesDialogRef = this._dialog.open(
      MilestonesDialogComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id
        },
        disableClose: true
      }
    );

    this.addMilestonesDialogRef.afterClosed().subscribe(result => {
      if (result) {        

        this.milestones.push(result);        

        this.milestones.sort((a, b) => a.due - b.due);

      }
    });

  }

  edit(milestone: any) {
    this.addMilestonesDialogRef = this._dialog.open(
      MilestonesDialogComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id,
          current: milestone,
          edit: true
        },
        disableClose: true
      }
    );

    this.addMilestonesDialogRef.afterClosed().subscribe(result => {
      if (result) {     
        
        for(let m of this.milestones) {

          if(m._id == result._id) {

            m.name = result.name;
            m.due = result.due;
            m.description = result.description;

          }

        }

      }
    });
  }

  delete(id: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this milestone?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._milestonesService.deleteMilestone(id, this.id).subscribe((result: any) => {

          if (result.success) {
            
            let index = 0;

            for(let i = 0; i < this.milestones.length; i++) {
              if(this.milestones[i]._id == id) {
                index = i;
              }
            } 

            this.milestones.splice(index, 1);
            
          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {  
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        }); 


      }

    });

  }

  showMore(milestone: any, hideMore: boolean) {

    milestone['show_more'] = hideMore;

  }

}
