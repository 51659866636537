import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/services/layout.service';
import { WbsDialogComponent } from './wbs-dialog/wbs-dialog.component';

@Component({
  selector: 'app-wbs',
  templateUrl: './wbs.component.html',
  styleUrls: ['./wbs.component.scss']
})
export class WbsComponent implements OnInit, OnDestroy {

  @Input() id: string;
  @Input() currentId: string;
  @Input() project: string;

  chatSidebarStatus: boolean = true;
  chatSidebarListenerSub: Subscription; // we add this to adjust the fixed position of the wbs

  wbsDialogDialogRef: MatDialogRef<WbsDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _layoutService: LayoutService,
  ) { 

    // we listen for the changes of the chatbar sidebar
    this.chatSidebarListenerSub = this._layoutService.showChatSidebar$.subscribe((chatSidebarStatus: boolean) => {      
      this.chatSidebarStatus = chatSidebarStatus;
    });

  }

  ngOnInit(): void {
  }

  wbsDialog() {
    this.wbsDialogDialogRef = this._dialog.open(
      WbsDialogComponent,
      {
        width: '800px',
        height: '730px',
        data: {
          level_id: this.id,
          current_id: this.currentId,
          project: this.project
        },
        disableClose: false
      }
    );

    this.wbsDialogDialogRef.afterClosed().subscribe(result => {
      if (result) {        

        // @todo

      }
    });
  }

  ngOnDestroy() {
    this.chatSidebarListenerSub.unsubscribe();
  }

}
