import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TopicService } from 'src/app/services/topic.service';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-discussion-dialog',
  templateUrl: './discussion-dialog.component.html',
  styleUrls: ['./discussion-dialog.component.scss']
})
export class DiscussionDialogComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  edit: boolean = false;
  actionType: string = '';
  message: string = '';

  attachments: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DiscussionDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _topicService: TopicService,
    private _fileService: FileService
  ) { }

  ngOnInit(): void {

    this.edit = this.data.edit;

    this.createForm();

    this.mainForm.patchValue({
      level_id: this.data.level_id
    });

  }

  createForm() {

    if(this.data && this.data.conversation) {
      this.actionType = 'edit_conversation';

      this.message = this.data.conversation.message;

      /** This is for comment */
      this.mainForm = this._fb.group({
        message: [this.data.conversation.message, Validators.required],
        level_id: ['', Validators.required]
      });

      // we add existing attachments
      if(this.data.conversation.attachments) {
        this.attachments = this.data.conversation.attachments;
      }
    }
    else {
      if(this.data && this.data.topic) {
        this.actionType = 'edit_topic';

        this.message = this.data.topic.message;

        /** This is for topic */
        this.mainForm = this._fb.group({
          topic_name: [this.data.topic.topic_id.topic_name, [Validators.required]],
          message: [this.data.topic.message, Validators.required],
          level_id: ['', Validators.required]
        });  
        
        // we add existing attachments
        if(this.data.topic.attachments) {
          this.attachments = this.data.topic.attachments;
        }
      }
      else {
        this.actionType = 'new';

        /** New comment */
        this.mainForm = this._fb.group({
          topic_name: ['', [Validators.required]],
          message: ['', Validators.required],
          level_id: ['', Validators.required]
        });     
      }      
    }    
  }

  uploadedAttachment(attachment: any): void {
    this.attachments.push({
      _id: attachment._id,
      original_name: attachment.original_name
    })
  }

  removeAttachment(fileId: string): void {

    this._fileService.deleteFile(this.data.level_id, fileId).subscribe((result: any) => {        
  
      if (result.success) {

        let index = -1;

        for(let i = 0; i < this.attachments.length; i++) {

          if(this.attachments[i]._id == fileId) {
            index = i;
          }

        }

        if(index != -1) {
          this.attachments.splice(index, 1);
        }     
       
      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });

   

  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      let action = this._topicService.addTopic(
        this.mainForm.value.level_id,
        this.mainForm.value.topic_name,
        this.mainForm.value.message,
        this.attachments
      );

      if(this.actionType == 'edit_conversation') {
        action = this._topicService.editComment(
          this.mainForm.value.level_id,
          this.data.conversation._id,
          this.mainForm.value.message,
          this.attachments
        );
      }
      else if(this.actionType == 'edit_topic') {
        action = this._topicService.editTopic(
          this.mainForm.value.level_id,          
          this.data.topic.topic_id._id,
          this.data.topic._id,
          this.mainForm.value.topic_name,
          this.mainForm.value.message,
          this.attachments
        );
      }

      action.subscribe((result: any) => {
  
        if (result.success) {
          this._dialogRef.close(result.data);
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

  onCkEditorValue(text: string) {
    this.mainForm.patchValue({
      message: text
    });
  }

}
