import { Pipe, PipeTransform } from '@angular/core';
import { format, formatDistance, formatRelative, subDays, differenceInDays  } from 'date-fns';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'myDate'
})
export class MyDatePipe implements PipeTransform {

  constructor(private _datePipe: DatePipe) {}

  transform(value: string, ...args: string[]): String {

    let generatedString = '/';   

    let d = new Date(value);

    let currentDate = null;


    /**
     * First we check the format of the input string
     */

    if (Object.prototype.toString.call(d) === "[object Date]") {
      if (!(isNaN(d.getTime()))) {  
        /** Date is valid */
        currentDate = d; 
      }
    } 

    if(Number.isInteger(parseInt(value))) {
      /** Date is valid */
      currentDate = new Date(parseInt(value)); 
    }  

    /** If date was found */
    if(currentDate) {
      
      /** We check for the date difference */

      if(differenceInDays(currentDate, new Date()) < -7) {

        /** If more then 7 days we show date  */
        return this._datePipe.transform(currentDate, 'mediumDate'); //format(currentDate, 'dd/MM/yyyy')

      }
      else {
         
        /** We show relative date */
        return formatDistance(currentDate, new Date(), { addSuffix: true });

      }


    }

    return generatedString;
  }

}
