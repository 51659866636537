import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ServerReply } from '../component/chat-box/server-reply';
import { UserinfoService } from './userinfo.service';

interface User {
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  initials: string;
  avatar: {
    url: string
  },
  online: boolean;
  lang: string;
  role: number;
  validated: boolean;
  user_settings: any[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {

  constructor(
    private _router: Router,     
    private _cookieService: CookieService,
    private permissionsService: NgxPermissionsService,
    private _userinfoService: UserinfoService,
    private _translate: TranslateService    
  ) { }


  resolve() {

    if(this._cookieService.get('jwtEx')) {

      // get user avatar, username, settings
      this._userinfoService.getUserInfo().subscribe((data: ServerReply) => {

        if(data && data.success) {         

          let user:User = <User>data.data;

          if(user.lang) {
            this._translate.use(user.lang);
          }

          this._userinfoService.setLocalInfo(user);

          if (user.role == 0) {
            this.permissionsService.addPermission('ADMIN');
          }
          else if (user.role == 1) {
            this.permissionsService.addPermission('SUPPORT');
          }
          else if (user.role == 2) {
            this.permissionsService.addPermission('CUSTOMER');
          }
          
          this._userinfoService.setLoggedIn(true);
          return true;      

        }
        else {
          //this.logout();

          return false;
        }

      }, err => {         
        if(err.status != 200) {  
          
          //this.logout();

          return false;
        }
      })

    }
    else {
      this.permissionsService.flushPermissions();

      this._userinfoService.setLoggedIn(false);
      return false;
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {  

    return new Promise(resolve => {   

      if(this._cookieService.get('jwtEx')) {        
        resolve(true);      
      }
      else {       
        this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        resolve(false);
      }

    });
   
  }

  logout(redirect?: boolean) {  

    this._userinfoService.setLoggedIn(false);
    
    this.permissionsService.flushPermissions();
    this._cookieService.deleteAll('/', '');
    this._cookieService.deleteAll('/');
  

    if(redirect != false) {
      this._router.navigate(['login']);
    }   

  }
  
}
