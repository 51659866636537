<div class="container-fluid h-100">
    <div class="row h-100">
        <div class="col">

            <ng-container *ngIf="loading">

                <div class="text-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

            </ng-container>
            <ng-container *ngIf="!loading">


                <div class="h-100">

                    <mat-toolbar class="mb-4 flex-wrap" style="height: auto; min-height: 64px;">

                        <div class="d-flex align-items-center justify-content-center justify-content-md-start col-12 col-md-6">

                            <button mat-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" matTooltip="Previous">
                                <mat-icon>chevron_left</mat-icon>
                            </button>

                            <div class="text-center mx-3 d-flex align-items-center">
                                <button mat-button mwlCalendarToday [view]="view" [(viewDate)]="viewDate" matTooltip="Today">
                                    <mat-icon>center_focus_strong</mat-icon>
                                </button>

                                <h1 class="mb-0">
                                    {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
                                </h1>
                            </div>

                            <button mat-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" matTooltip="Next"><mat-icon>chevron_right</mat-icon></button>

                        </div>

                        <button mat-button color="primary" class="mx-auto me-md-0 ms-md-auto" routerLink="./upcoming">All upcoming events</button>

                    </mat-toolbar>


                    <mwl-calendar-month-view 
                        [viewDate]="viewDate" 
                        [events]="events2" 
                        [refresh]="refresh"
                        [activeDayIsOpen]="activeDayIsOpen"
                        (eventClicked)="handleEvent($event.event)"
                    ></mwl-calendar-month-view>


                </div>


            </ng-container>

        </div>
    </div>
</div>