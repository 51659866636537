<div *ngIf="!id">
        
</div>
<div class="d-flex flex-column h-100 position-relative" *ngIf="id">


    <div class="conversation-space flex-fill pb-md-4">

        <div class="d-flex align-items-end h-100">

            <div class="conversation w-100" style="display:flex; flex-direction:column-reverse;">

               

                <ng-container *ngIf="messages.length > 0; else noMessage">
                    <div class="d-flex flex-column w-100" *ngFor="let m of messages">

                        <div class="mat-caption ms-2 d-flex align-items-center" *ngIf="m.him">


                            <app-user-avatar width="30" fontSize="12px" [info]="m.user_id"></app-user-avatar>

                            <div class="ms-2">
                                {{ m.user_id.full_name }}
                            </div>
                        </div>

                        <div class="my-2 bubble me ms-auto" [ngClass]="{ 'me ms-auto': m.me, 'him': m.him }">                            
                           

                            {{ m.text }}

                            <div class="mat-caption mt-2" *ngIf="m.him">
                                <span class="text-muted">{{m.date_created | myDate}}</span>
                            </div>

                            <div class="mat-caption mt-2" *ngIf="m.me">
                                <span style="color: #8c9df3">{{m.date_created | myDate}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-template #noMessage>
                    <div class="ms-auto">

                        <div class="d-flex align-items-center me-md-6">

                            <div class="me-2 fw-boldest mat-h3" style="color: #d8d8d8">No messages. Start writing ...</div>

                            <img src="/assets/windy-arrow-messages.png">

                        </div>

                    </div>
                </ng-template>
            </div>
        </div>

    </div>

    <form [formGroup]="mainForm" (ngSubmit)="onSubmit()">
        <div class="mt-auto d-flex flex-wrap align-items-center">
                                    
            <mat-form-field class="flex-fill mt-3 me-2 me-md-5" appearance="fill">
                <mat-label>Your message</mat-label>
                <input matInput formControlName="message" />
            </mat-form-field>

            <div>
                <button mat-raised-button color="primary" type="submit">
                    <mat-icon>send</mat-icon>
                </button>
            </div>
        </div>
    </form>

</div>