import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { UserinfoService } from '../services/userinfo.service';
import { UsersService } from '../services/users.service';
import { ChangeAvatarDialogComponent } from './change-avatar-dialog/change-avatar-dialog.component';

import { Sizes } from '../sizes';
import { Title } from '@angular/platform-browser';
import { ConfirmDialogComponent } from '../utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  loading: boolean = true;
  user: any;

  deleteDate: number;
  mainForm: UntypedFormGroup;
  submitted: boolean = false;

  changeAvatarDialogRef: MatDialogRef<ChangeAvatarDialogComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;


  constructor(
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _userService: UsersService,
    private _userinfoService: UserinfoService,
    private _dialog: MatDialog,
    private _layoutService: LayoutService,
    private _titleService: Title
  ) { }

  ngOnInit(): void {

    this._titleService.setTitle('Account Settings | ExArca');

    this.subscription = this._userinfoService.loggedIn$.subscribe((value) => {
      if (value) {
        this.user = this._userinfoService.getLocalInfo();

        if(this.user && this.user.request_to_delete_date) {
          
          let date = new Date(parseInt(this.user.request_to_delete_date));
          date.setDate(date.getDate() + 2);

          this.deleteDate = date.getTime();   
          

        }

        this.loading = false;

        this.createForm(this.user)
      }
    });

  }

  createForm(user: any) {
    this.mainForm = this._fb.group({
      email: [user.email, [Validators.required, Validators.email]],
      firstName: [user.first_name, Validators.required],
      lastName: [user.last_name, Validators.required],
      password: ['']
    });
  }

  changeAvatar() {
    this.changeAvatarDialogRef = this._dialog.open(
      ChangeAvatarDialogComponent,
      {
        width: (this._layoutService.getSize() < Sizes.xl) ? '80vw' : '50vw',
        data: {
          user: this.user
        },
        disableClose: false
      }
    );

    this.changeAvatarDialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this._userService.editUser(
        this.mainForm.value.email,
        this.mainForm.value.firstName,
        this.mainForm.value.lastName,
        this.mainForm.value.password,
      ).subscribe((result: any) => {

        if (result.success) {

          this._userinfoService.setLocalInfo(result.data);

        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }
  }

  changeEmailNotification(status: boolean) {

    this._userService.emailReceivingSettings(status)
      .subscribe((result: any) => {
        if (!result.success) {
          this.user.stop_email = !status;

          this._snackBar.open('Error ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          this.user.stop_email = !status;

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });
  }

  deleteAccount() {
    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure that you want to delete this account?',
          text: 'If you agree, your account will be deleted after 48 hours from request. This action is irreversible!',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._userService.requestToDeleteAccount()
          .subscribe((result: any) => {
            if (result.success) {

              this.user.request_to_delete = !this.user.request_to_delete;

            }
            else {

              this._snackBar.open('Error ' + result.message, '', {
                duration: 2000,
                panelClass: ['error-snackbar']
              });

            }

          }, err => {

            if (err.status != 200) {

              this._snackBar.open('Error', '', {
                duration: 2000,
                panelClass: ['error-snackbar']
              });
            }

          });

      }
    });
  }

  recoverAccount() {

    this._userService.requestToDeleteAccount()
      .subscribe((result: any) => {
        if (result.success) {

          this.user.request_to_delete = !this.user.request_to_delete;

        }
        else {

          this._snackBar.open('Error ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });

        }

      }, err => {

        if (err.status != 200) {

          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
