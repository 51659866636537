import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigService } from 'src/app/services/config.service';
import { HttpOptionsService } from 'src/app/services/http-options.service';

@Component({
  selector: 'app-kmfest-results',
  templateUrl: './kmfest-results.component.html',
  styleUrls: ['./kmfest-results.component.scss']
})
export class KmfestResultsComponent implements OnInit {

  loading: boolean = true;
  unableToLoad: boolean = false;

  displayedColumns: string[] = ['email', 'first_name', 'last_name', 'eventSelection', 'organization', 'date_created'];
  usersData: MatTableDataSource<any>;
  resultsLength = 0;
  users: any[] = [];

  constructor(
    private _config: ConfigService,
    private _http: HttpClient,
    private _httpOptionsService: HttpOptionsService,
    private _snackBar: MatSnackBar,
  ) { }

  async ngOnInit(): Promise<void> {

    try {
      const result: any = await this._http.get(this._config.getApiUrl() + '/api/kmfest/subscribers', this._httpOptionsService.getHeader()).toPromise();

      if (result.success) {
        this.users = result.data;
        this.usersData = new MatTableDataSource(this.users);
        this.resultsLength = this.users.length;
      }

    } catch (error) {
      this.unableToLoad = true;

      this._snackBar.open('Error ' + error.message, '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    } finally {
      this.loading = false;
    }

  }

  async openAsCSV() {

    try {
      const result: any = await this._http.get(this._config.getApiUrl() + '/api/kmfest/subscribers/csv', this._httpOptionsService.getHeader()).toPromise();

      if (result.success) {
        // receive csv as result.data and then save as CSV in browser
        const blob = new Blob([result.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'kmfest-subscribers.csv';
        link.click();

        window.URL.revokeObjectURL(url);
        link.remove();

      }

    } catch (error) {

      this._snackBar.open('Error ' + error.message, '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }
  }

}
