import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChatService } from 'src/app/services/chat.service';
import { SocketService } from 'src/app/services/socket.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

import { ChatInfo } from 'src/app/component/chat-box/chat-info';
import { ServerReply } from 'src/app/component/chat-box/server-reply';

@Component({
  selector: 'app-conversation-view',
  templateUrl: './conversation-view.component.html',
  styleUrls: ['./conversation-view.component.scss']
})
export class ConversationViewComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;

  id: string;
  messages: any[] = [];
  userData: any[] =  [];

  @Input() set chatId(id: string) {
      this.id = id;

      if(this.id) {
        this.getMessages(this.id);
      }
  }

  @Input() set user(user: any[]) {
    if(user) {
      this.userData = [...user];   
    }
  }

  @Output() deletedChat = new EventEmitter<any>(); 

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _chatService: ChatService,
    private _dialog: MatDialog,
    private _socketService: SocketService
  ) { }

  ngOnInit(): void {
    this.mainForm = this._fb.group({
      message: ['', Validators.required]
    });


    this._socketService.on('incoming-message', (data) => {

      if(this.id == data.chat_id) {
        this.messages.unshift({
          user_id: data.user,
          date_created: data.date_created,
          text: data.message,
          him: true
        })
      }


    });
  }

  getMessages(chatId: string) {
    this._chatService.getChatMessages(chatId).subscribe((result: ServerReply|undefined) => {

      if(result.success) {

        this.messages = result.data.messages;
        
      }
      else {    

        this._snackBar.open(result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }

    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }
    });
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.value.message.length > 0) { 


      this._chatService.sendChat(this.userData, this.mainForm.value.message).subscribe((result: any) => {
  
        if (result.success) {

          this.submitted = false;

          this._socketService.emit('send-message', {
            chat_id: result.data.chat_id,
            user_id: result.data.user_id,
            users: this.userData, 
            message: this.mainForm.value.message,
            date_created: result.data.date_created
          })
          
          this.messages.unshift({
            text: this.mainForm.value.message,
            date_created: result.data.date_created,
            me: true
          })

          this.mainForm.patchValue({
            message: ''
          })

        }
        else {
          this._snackBar.open('Error' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your message...', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

  deleteChat() {
    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this chat?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {


        this._chatService.deleteChat(this.id).subscribe((result: any) => {

          if(result.success) {
    
            this.deletedChat.emit(this.id);
            
          }
          else {    
    
            this._snackBar.open(result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
    
          }
    
        }, err => {
    
          if (err.status != 200) {
    
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
    
          }
        });

      }
    });
  }

}
