import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProposalsService } from 'src/app/services/proposals.service';

@Component({
  selector: 'app-project-proposal',
  templateUrl: './project-proposal.component.html',
  styleUrls: ['./project-proposal.component.scss']
})
export class ProjectProposalComponent implements OnInit {

  url: string;
  addProposal: boolean;
  currentPage: string;
  proposalId: string;
  isWorkGroup: boolean

  @Input() id: string;      // level id
  @Input() role: number;    // user role
  @Input() set type(type: 'interest' | 'wg') {
    this.isWorkGroup = type === 'wg';
  }
  @Input() set page(pageId: string) {
    this.url = this._router.url;

    /**
     * We setup the routes
     * to show the right page
     */
    let checkID = new RegExp("^[0-9a-fA-F]{24}$");
    this.currentPage = (checkID.test(pageId)) ? 'show-proposal' : pageId;
    this.proposalId = pageId;

  }

  proposals: any[] = [];

  constructor(
    private _router: Router
  ) { }

  ngOnInit(): void {


  }



}
