<div class="w-100 h-100">

    <div class="container h-100">
        <div class="row h-100">

            <div class="col-12 flex-column d-flex justify-content-center align-items-center">

                <div class="my-4 d-flex justify-content-center">
                    <a routerLink="/">
                        <img src="assets/main-logo.png" />
                    </a>
                </div>

                <div>

                    <ng-container *ngIf="!id; else showId">

                        <ng-container *ngIf="!done">
                            <mat-card>
                                
                                <div class="text-center text-md-start">
                                    <h1 class="mat-h1">{{ 'Create new Account' | translate }}</h1>
                                </div>

                                <mat-card-content class="text-center text-lg-start">


                                    <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">

                                        <div>

                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>
                                                    {{ 'Your e-mail' | translate }}
                                                </mat-label>
                                                <input matInput type="text" formControlName="email">
                                            </mat-form-field>

                                            
                                        </div>

                                        <button mat-raised-button type="submit">
                                            <mat-icon>email</mat-icon>
                                            Register with Email
                                        </button>

                                    </form>

                                </mat-card-content>

                            </mat-card>

                            <div *ngIf="showError" class="alert alert-danger text-center" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                                {{ showErroMessage | translate }}
                            </div>



                            <div class="my-4 text-center">
                                or
                            </div>

                            <mat-card>

                                <div class="d-flex flex-column text-center flex-lg-row">


                                    <div class="mb-2 mb-lg-0 me-lg-4">
                                    
                                        <cool-google-button color="dark" (click)="signInWithGoogle()">
                                            Register with Google
                                        </cool-google-button>

                                    </div>
                                      
                                    <cool-facebook-button color="dark" class="ms-lg-auto" (click)="signInWithFB()">
                                        Register with Facebook
                                    </cool-facebook-button>
                                    

                                </div>

                            </mat-card>
                        </ng-container>

                        <mat-card *ngIf="done">

                            <mat-card-content>

                                <div class="d-flex align-items-center">


                                    <div>
                                        <h2 class="mat-h2 mb-2">{{'Please validate your email' | translate}}</h2>

                                        {{'Validation link was sent to your e-mail.' | translate}}
                                    </div>

                                    <div class="ms-auto">
                                        <mat-icon>email</mat-icon>
                                    </div>

                                </div>


                            </mat-card-content>

                        </mat-card>



                    </ng-container>
                    <ng-template #showId>

                        <ng-container *ngIf="loading">
                            <div class="d-flex align-items-center justify-content-center">
                                <mat-spinner></mat-spinner>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!loading">


                            <ng-container *ngIf="requestNotFound; else reqNotFound">

                                <mat-card>

                                    <h1 class="mat-h1">{{ 'Registration request failed' | translate }}</h1>

                                    <div *ngIf="showError" class="alert alert-danger">
                                        {{ showErroMessage | translate }}
                                    </div>
                                </mat-card>

                            </ng-container>
                            
                            <ng-template #reqNotFound>

                                <form [formGroup]="mainForm" (ngSubmit)="createAccount()" novalidate class="h-100">

                                    <mat-card>
    
                                        <h1 class="mat-h1">{{ 'Your account information' | translate }}</h1>
    
                                        <mat-card-content>
    
                                            <mat-form-field appearance="outline" class="w-100" style="opacity: .6;">
                                                <mat-label>
                                                    {{ 'E-mail' | translate }}
                                                </mat-label>
                                                <input matInput formControlName="email" [readonly]="true">
                                            </mat-form-field>
    
                                            <div class="row">
    
                                                <div class="col">
    
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>{{'First name' | translate}}</mat-label>
                                                        <input matInput formControlName="firstName">
                                                    </mat-form-field>
    
                                                </div>
    
                                                <div class="col">
    
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>{{'Last name' | translate}}</mat-label>
                                                        <input matInput formControlName="lastName">
                                                    </mat-form-field>
    
                                                </div>
    
                                            </div>
    
                                            <mat-form-field appearance="outline" class="w-100" [hidden]="socialLogin">
                                                <mat-label>{{'Password' | translate}}</mat-label>
                                                <input matInput formControlName="password">
                                                <mat-hint align="start">{{'At least 8 characters long' | translate}}</mat-hint>
                                            </mat-form-field>
    
                                            <div class="my-3 mat-caption">
                                                By using our services you agree with our
    
                                                <button mat-button color="primary" routerLink="/privacy-policy">Privacy policy</button>
                                            </div>
    
    
                                            <div *ngIf="showError" class="alert alert-danger">
                                                {{ showErroMessage | translate }}
                                            </div>
    
    
                                            <button mat-raised-button color="primary">Finish &amp; Create Account</button>
    
                                        </mat-card-content>
    
                                    </mat-card>
    
                                </form>

                            </ng-template>                            

                        </ng-container>

                    </ng-template>

                </div>

            </div>

        </div>
    </div>

</div>