import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalsService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService,
    private _translate: TranslateService
  ) { }

  saveProposal(
    level_id: string,
    title: string,
    nickname: string,
    trl: string,
    project_type: string,
    owner: string,
    proposing_organization: string,
    subject_business_secret: boolean,
    areas_of_required_competencies: string,
    short_description: string,
    draft_project_scope: string,
    draft_project_duration: string,
    draft_cost: string,
    expected_sources: string,
    expected_benefits: string,
    kpi: string,
    assumptions_and_risks: string,
    main_project_risks: string,
    draft: boolean,
    name_contact: string,
    email?: string,
    mobile?: string,
    others?: string
  ) {
    return this._http.post(this._config.getApiUrl() + '/api/proposals', {
      level_id: level_id,
      title: title,
      nickname: nickname,
      trl: trl,
      project_type: project_type,
      owner: owner,
      proposing_organization: proposing_organization,
      subject_business_secret: subject_business_secret,
      areas_of_required_competencies: areas_of_required_competencies,
      short_description: short_description,
      draft_project_scope: draft_project_scope,
      draft_project_duration: draft_project_duration,
      draft_cost: draft_cost,
      expected_sources: expected_sources,
      expected_benefits: expected_benefits,
      kpi: kpi,
      assumptions_and_risks: assumptions_and_risks,
      main_project_risks: main_project_risks,
      name_contact: name_contact,
      draft: draft,
      email: email,
      mobile: mobile,
      others: others
    }, this._httpOptionsService.getHeader())
  }

  updateProposal(
    id: string,
    level_id: string,
    title: string,
    nickname: string,
    trl: string,
    project_type: string,
    owner: string,
    proposing_organization: string,
    subject_business_secret: boolean,
    areas_of_required_competencies: string,
    short_description: string,
    draft_project_scope: string,
    draft_project_duration: string,
    draft_cost: string,
    expected_sources: string,
    expected_benefits: string,
    kpi: string,
    assumptions_and_risks: string,
    main_project_risks: string,
    draft: boolean,
    name_contact: string,
    email?: string,
    mobile?: string,
    others?: string
  ) {
    return this._http.put(this._config.getApiUrl() + '/api/proposals/' + level_id + '/' + id, {
      level_id: level_id,
      title: title,
      nickname: nickname,
      trl: trl,
      project_type: project_type,
      owner: owner,
      proposing_organization: proposing_organization,
      subject_business_secret: subject_business_secret,
      areas_of_required_competencies: areas_of_required_competencies,
      short_description: short_description,
      draft_project_scope: draft_project_scope,
      draft_project_duration: draft_project_duration,
      draft_cost: draft_cost,
      expected_sources: expected_sources,
      expected_benefits: expected_benefits,
      kpi: kpi,
      assumptions_and_risks: assumptions_and_risks,
      main_project_risks: main_project_risks,
      name_contact: name_contact,
      draft: draft,
      email: email,
      mobile: mobile,
      others: others
    }, this._httpOptionsService.getHeader())
  }

  getProposals(level_id: string) {
    return this._http.get(this._config.getApiUrl() + '/api/proposals/' + level_id, this._httpOptionsService.getHeader())
  }

  getPublicProposals(level_id: string) {
    return this._http.get(this._config.getApiUrl() + '/api/proposals/public/' + level_id, this._httpOptionsService.getHeader())
  }

  getSpecificProposal(level_id: string, proposal_id: string) {
    return this._http.get(this._config.getApiUrl() + '/api/proposals/' + level_id + '/' + proposal_id, this._httpOptionsService.getHeader())
  }

  changeVisibility(level_id: string, proposal_id: string, visible: boolean) {
    return this._http.put(this._config.getApiUrl() + '/api/proposals/' + level_id + '/' + proposal_id + '/visibility', { visible: visible }, this._httpOptionsService.getHeader())
  }

  generatePDF(level_id: string, proposal_id: string) {
    return this._http.get(this._config.getApiUrl() + '/api/proposals/export/' + level_id + '/' + proposal_id + '/' + this._translate.currentLang, this._httpOptionsService.getHeader())
  }

  deleteProposal(level_id: string, proposal_id: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/proposals/' + level_id + '/' + proposal_id, this._httpOptionsService.getHeader())
  }
}
