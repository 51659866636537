import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TasksService } from 'src/app/services/tasks.service';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { TasksDialogComponent } from './tasks-dialog/tasks-dialog.component';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  loading: boolean = true;

  user: any;
  tasks: any = [];
  
  @Input() id: string;      // level id
  @Input() role: number;    // user role

  addTaskDialogRef: MatDialogRef<TasksDialogComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _tasksService: TasksService,
    private _userinfoService: UserinfoService
  ) { }

  ngOnInit(): void {

    this.user = this._userinfoService.getLocalInfo();

    this.getTasks().then((tasks: any) => {
      this.loading = false;
      this.tasks = tasks;
    });

  }

  getTasks() {

    return new Promise((resolve, reject) => {

      this._tasksService.getTasks(this.id).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });

  }

  addTask() {
    this.addTaskDialogRef = this._dialog.open(
      TasksDialogComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id
        },
        disableClose: true
      }
    );

    this.addTaskDialogRef.afterClosed().subscribe(result => {
      if (result) {        

        this.tasks.push(result);        

      }
    });
  }

  edit(task: any) {
    this.addTaskDialogRef = this._dialog.open(
      TasksDialogComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id,
          current: task,
          edit: true
        },
        disableClose: true
      }
    );

    this.addTaskDialogRef.afterClosed().subscribe(result => {
      if (result) {     
        
        for(let m of this.tasks) {

          if(m._id == result._id) {

            m.name = result.name;
            m.due = result.due;
            m.description = result.description;
            m.users = result.users;
            m.status = result.status;
            m.can_change_status = result.can_change_status;

          }

        }

      }
    });
  }

  delete(id: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this task?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._tasksService.deleteTask(this.id, id).subscribe((result: any) => {

          if (result.success) {
            
            let index = 0;

            for(let i = 0; i < this.tasks.length; i++) {
              if(this.tasks[i]._id == id) {
                index = i;
              }
            } 

            this.tasks.splice(index, 1);
            
          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {  
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        }); 


      }

    });

  }

  changeStatus(task: any) {
    this._tasksService.changeStatus(this.id, task._id, task.userStatus).subscribe((result: any) => {

      if (result.success) {        
        
        
        task.status = result.data.status;


      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {  
        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    }); 
  }

}
