<form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="h-100">


    <div class="border h-100 d-flex flex-column">
        <mat-toolbar style="height: auto;" class="py-2 flex-wrap">

            <button mat-raised-button (click)="exitForm()" type="button">
                <mat-icon>chevron_left</mat-icon>
                {{'Back' | translate}}
            </button>


            <div class="ms-auto">
                <ng-container *ngIf="proposal">
                    <button mat-raised-button color="secondary" (click)="exitForm()" type="button">
                        <mat-icon>close</mat-icon>
                        {{'Cancel' | translate}}
                    </button>
                </ng-container>
                <button mat-raised-button color="primary" class="ms-3" type="submit">{{'Save' | translate}}</button>
            </div>
        </mat-toolbar>

        <div class="form-window" style="overflow-y: auto;">

            <div #proposalForm class="mx-4">

                <div class="mt-4 mb-5">

                    <h2 class="mb-4">{{'Partnering Project Charter Proposal' | translate}}</h2>


                    <h3 class="mb-2 text-primary">1. {{'Project Title' | translate}}</h3>

                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                                <mat-label>
                                    {{'Title' | translate}}
                                </mat-label>
                                <input matInput formControlName="title" type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100 mt-2" style="margin-bottom: -1.25em">
                                <mat-label>
                                    {{'Nickname' | translate}}
                                </mat-label>
                                <input matInput formControlName="nickname" type="nickname">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100 mt-2" style="margin-bottom: -1.25em">
                                <mat-label>
                                    TRL - Technology readiness level
                                </mat-label>
                                <mat-select formControlName="trl" [compareWith]="compareWith" multiple>
                                    <mat-option *ngFor="let opt of trl" [value]="opt.key">{{opt.value}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>


                </div>
                <div class="my-5">
                    <h3 class="mb-2 text-primary">2. {{'Project Type' | translate}}</h3>

                    <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                        <mat-label>
                            {{'Select type' | translate}}
                        </mat-label>

                        <mat-select formControlName="project_type" [compareWith]="compareWith" multiple>
                            <mat-option *ngFor="let opt of projectTypes" [value]="opt.key">{{opt.value |
                                translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>

                <div class="my-5">
                    <h3 class="mb-2 text-primary">3. {{'Owner(s) of the Project Idea' | translate}}</h3>

                    <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                        <mat-label>
                            {{'Name, Surname/ Organization' | translate}}
                        </mat-label>
                        <input matInput formControlName="owner" type="text">
                    </mat-form-field>

                </div>

                <div class="my-5">
                    <h3 class="mb-2 text-primary">4. {{'Proposing Organization' | translate}}</h3>

                    <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                        <mat-label>
                            {{'Organization Name' | translate}}
                        </mat-label>
                        <input matInput formControlName="proposing_organization" type="text">
                    </mat-form-field>

                </div>

                <div class="my-5">
                    <h3 class="mb-2 text-primary">5. {{'Subject of Business Secret' | translate}}</h3>


                    <div class="mt-3 mb-2">
                        <mat-radio-group color="primary" formControlName="subject_business_secret"
                            style="margin-bottom: -1.25em">
                            <mat-radio-button [value]="true" class="me-4 ms-2">{{'Yes' | translate}}</mat-radio-button>
                            <mat-radio-button [value]="false">{{'No' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>


                </div>

                <div class="my-5">
                    <h3 class="mb-2 text-primary">6. {{'Areas of Required Competencies / Expertise of Potential Project
                        Partners' | translate}}</h3>

                    <!--<mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                        <mat-label>
                            {{'Areas of Required Competencies / Expertise of Potential Project Partners' | translate}}
                        </mat-label>
                        <textarea matInput formControlName="areas_of_required_competencies"></textarea>
                    </mat-form-field>-->

                    <!--<ckeditor [config]="config" [editor]="Editor" formControlName="areas_of_required_competencies"></ckeditor>-->
                    <app-my-editor
                        [toolbar]="['bold', 'alignment', 'italic', 'bulletedList', 'numberedList', 'link', 'imageUpload']"
                        [levelid]="id" [data]="f.areas_of_required_competencies.value"
                        (output)="onCkEditorValue('areas_of_required_competencies', $event)"></app-my-editor>

                </div>

                <div class="my-5">
                    <h3 class="mb-2 text-primary">7. {{'Short Description of the Proposed Project Business Case' |
                        translate}}</h3>

                    <!--
                    <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                        <mat-label>
                            {{'Short Description' | translate}}
                        </mat-label>
                        <textarea matInput formControlName="short_description"></textarea>
                    </mat-form-field>-->

                    <!--<ckeditor [config]="config" [editor]="Editor" formControlName="short_description"></ckeditor>-->
                    <app-my-editor
                        [toolbar]="['bold', 'alignment', 'italic', 'bulletedList', 'numberedList', 'link', 'imageUpload']"
                        [levelid]="id" [data]="f.short_description.value"
                        (output)="onCkEditorValue('short_description', $event)"></app-my-editor>

                </div>

                <div class="my-5">
                    <h3 class="mb-2 text-primary">8. {{'Draft Project Scope & Expected Deliverables' | translate}}</h3>

                    <!--
                    <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                        <mat-label>
                            {{'Draft Project Scope & Expected Deliverables' | translate}}
                        </mat-label>
                        <textarea matInput formControlName="draft_project_scope"></textarea>
                    </mat-form-field>-->

                    <!--<ckeditor [config]="config" [editor]="Editor" formControlName="draft_project_scope"></ckeditor>-->
                    <app-my-editor
                        [toolbar]="['bold', 'alignment', 'italic', 'bulletedList', 'numberedList', 'link', 'imageUpload']"
                        [levelid]="id" [data]="f.draft_project_scope.value"
                        (output)="onCkEditorValue('draft_project_scope', $event)"></app-my-editor>


                </div>

                <div class="my-5">
                    <h3 class="mb-2 text-primary">9. {{'Conceptual Estimations: Time & Costs & Funding' | translate}}
                    </h3>

                    <div class="row">

                        <div class="col">

                            <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                                <mat-label>
                                    {{'Project Duration' | translate}}
                                </mat-label>
                                <input matInput formControlName="draft_project_duration" type="text">
                            </mat-form-field>

                        </div>
                        <div class="col">

                            <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                                <mat-label>
                                    {{'Draft Cost Estimation (EUR)' | translate}}
                                </mat-label>
                                <input matInput formControlName="draft_cost" type="text">
                            </mat-form-field>

                        </div>
                        <div class="col">

                            <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                                <mat-label>
                                    {{'Expected Funding Sources' | translate}}
                                </mat-label>
                                <input matInput formControlName="expected_sources" type="text">
                            </mat-form-field>

                        </div>

                    </div>

                </div>

                <div class="my-5">
                    <h3 class="mb-2 text-primary">10. {{'Expected Benefits & KPI - Key Perfomance Indicators' |
                        translate}} </h3>


                    <div class="row">

                        <div class="col">

                            <mat-label>
                                {{'Expected Project Benefits' | translate}}
                            </mat-label>

                            <!--<ckeditor [config]="config" [editor]="Editor" formControlName="expected_benefits"></ckeditor>-->
                            <app-my-editor
                                [toolbar]="['bold', 'alignment', 'italic', 'bulletedList', 'numberedList', 'link', 'imageUpload']"
                                [levelid]="id" [data]="f.expected_benefits.value"
                                (output)="onCkEditorValue('expected_benefits', $event)"></app-my-editor>

                        </div>

                        <div class="col">

                            <!--
                            <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                                <mat-label>
                                    {{'KPI - Key Perfomance Indicators' | translate}}
                                </mat-label>
                                <textarea matInput formControlName="kpi"></textarea>
                            </mat-form-field>-->

                            <mat-label>
                                {{'KPI - Key Perfomance Indicators' | translate}}
                            </mat-label>
                            <!--<ckeditor [config]="config" [editor]="Editor" formControlName="kpi"></ckeditor>-->
                            <app-my-editor
                                [toolbar]="['bold', 'alignment', 'italic', 'bulletedList', 'numberedList', 'link', 'imageUpload']"
                                [levelid]="id" [data]="f.kpi.value"
                                (output)="onCkEditorValue('kpi', $event)"></app-my-editor>

                        </div>

                    </div>

                </div>

                <div class="my-5">
                    <h3 class="mb-2 text-primary">11. {{'Main Project Assumptions' | translate}}</h3>


                    <!--<ckeditor [config]="config" [editor]="Editor" formControlName="assumptions_and_risks"></ckeditor>-->
                    <app-my-editor
                        [toolbar]="['bold', 'alignment', 'italic', 'bulletedList', 'numberedList', 'link', 'imageUpload']"
                        [levelid]="id" [data]="f.assumptions_and_risks.value"
                        (output)="onCkEditorValue('assumptions_and_risks', $event)"></app-my-editor>

                </div>

                <div class="my-5">
                    <h3 class="mb-2 text-primary">12. {{'Main Project Risks / Mitigation Plan' | translate}}</h3>

                    <!--
                    <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                        <mat-label>
                            {{'Main Project Risks / Mitigation Plan' | translate}}
                        </mat-label>
                        <textarea matInput formControlName="main_project_risks"></textarea>
                    </mat-form-field>-->

                    <!--<ckeditor [config]="config" [editor]="Editor" formControlName="main_project_risks"></ckeditor>-->
                    <app-my-editor
                        [toolbar]="['bold', 'alignment', 'italic', 'bulletedList', 'numberedList', 'link', 'imageUpload']"
                        [levelid]="id" [data]="f.main_project_risks.value"
                        (output)="onCkEditorValue('main_project_risks', $event)"></app-my-editor>

                </div>

                <div class="my-5">
                    <h3 class="mb-2 text-primary">13. {{'Contact Person' | translate}}</h3>

                    <div class="row">

                        <div class="col">

                            <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                                <mat-label>
                                    a. {{'Name, Surname /Organization' | translate}}
                                </mat-label>
                                <input matInput formControlName="name_contact" type="text">
                            </mat-form-field>

                        </div>

                        <div class="col">

                            <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                                <mat-label>
                                    b. {{'E-mail' | translate}} <span>({{'optional' | translate}})</span>
                                </mat-label>
                                <input matInput formControlName="email" type="email" email>
                            </mat-form-field>

                        </div>

                    </div>

                    <div class="row mt-2">

                        <div class="col">

                            <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                                <mat-label>
                                    c. {{'Mobile' | translate}} <span>({{'optional' | translate}})</span>
                                </mat-label>
                                <input matInput formControlName="mobile" type="text">
                            </mat-form-field>

                        </div>

                        <div class="col">

                            <mat-form-field appearance="outline" class="w-100" style="margin-bottom: -1.25em">
                                <mat-label>
                                    d. {{'Others' | translate}} <span>({{'optional' | translate}})</span>
                                </mat-label>
                                <input matInput formControlName="others" type="text">
                            </mat-form-field>

                        </div>

                    </div>

                </div>

            </div>
        </div>

    </div>

</form>