import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { ProposalsService } from 'src/app/services/proposals.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-specific-proposal',
  templateUrl: './specific-proposal.component.html',
  styleUrls: ['./specific-proposal.component.scss']
})
export class SpecificProposalComponent implements OnInit {

  loading: boolean = true;
  editing: boolean = false;

  proposal: any;
  error: boolean;
  errorMessage: string;

  isWg: boolean = false;

  @Input() id: string;      // proposalId
  @Input() levelId: string;      // level id
  @Input() set isWorkGroup(isWorkGroup: boolean) {
    this.isWg = isWorkGroup;
  }


  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private _proposalService: ProposalsService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _dialog: MatDialog,
    private _config: ConfigService
  ) { }

  ngOnInit(): void {

    this.getSpecificProposal();

  }


  generateProsposalPDF() {

    let newWindow = window.open();

    this._proposalService.generatePDF(this.levelId, this.id).subscribe((result: any) => {

      if (result.success) {

        //window.open(this._config.getApiUrl() + "/download/doc/" + result.data, "_blank");
        newWindow.location.href = this._config.getApiUrl() + "/download/doc/" + result.data;

      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }


    }, err => {

      if (err.status != 200) {
        this._snackBar.open('Error: ' + err.statusText, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    });

  }

  getSpecificProposal() {

    return new Promise((resolve, reject) => {

      this._proposalService.getSpecificProposal(this.levelId, this.id).subscribe((result: any) => {

        this.loading = false;

        if (result.success) {
          this.proposal = result.data;

          /**
           * Project type resolver
           */
          if (this.proposal.project_type.length > 0) {
            for (let p of this.proposal.project_type) {
              this.proposal[p] = true;
            }
          }

        }
        else {
          this.error = true;
          this.errorMessage = result.message;
        }

        return resolve(true);

      }, err => {

        if (err.status != 200) {

          this.loading = false;
          this.error = true;
          this.errorMessage = err.status + ' : ' + err.statusText;

          return resolve(false);

        }

      });

    });

  }


  backButton() {

    let currentUrl = this._router.url;
    let tmpArray = currentUrl.split('/');
    tmpArray.pop();

    this._router.navigate([tmpArray.join('/')]);

  }

  editProposal(status: boolean) {
    this.editing = status;
  }

  deleteProposal() {
    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure that you want to delete this proposal?',
          text: 'If you agree, your proposal will be deleted!',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._proposalService.deleteProposal(this.levelId, this.id).subscribe((result: any) => {

          if (result.success) {

            this.backButton();

          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }


        }, err => {

          if (err.status != 200) {
            this._snackBar.open('Error: ' + err.statusText, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }

        });

      }
    });
  }

  closeForm(status: boolean) {

    this.getSpecificProposal().then(() => {

      this.editing = false;

    });

  }
}
