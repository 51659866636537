<div class="d-flex align-items-center mb-2">

    <h2 mat-dialog-title class="mb-0">
        {{'WBS structure' | translate}}
    </h2>

    <div class="ms-auto">
        <button mat-button color="secondary" [mat-dialog-close]="false">
            <mat-icon>clear</mat-icon>
        </button>
    </div>

</div>

<div class="position-relative" style="height: 600px;">
    <ng-container *ngIf="loading">
        <div class="d-flex h-100 align-items-center justify-content-center">
            <mat-spinner></mat-spinner>
        </div>
    </ng-container>

    <button mat-mini-fab color="secondary" style="position: absolute; bottom: 10px; right: 10px;" (click)="zoomOut()">
        <mat-icon>remove</mat-icon>
    </button>
    <button mat-mini-fab color="secondary" style="position: absolute; bottom: 55px; right: 10px;" (click)="zoomIn()">
        <mat-icon>add</mat-icon>
    </button>
    <div #chartContainer id="chartContainer" class="w-100"></div>
</div>

