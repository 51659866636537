import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from 'src/app/services/config.service';
import { SystemService } from 'src/app/services/system.service';

@Component({
  selector: 'app-admin-database',
  templateUrl: './admin-database.component.html',
  styleUrls: ['./admin-database.component.scss']
})
export class AdminDatabaseComponent implements OnInit {

  loading: boolean = true;
  errorMessage: string = '';
  createBackupErrorMessage: string = '';
  backupScriptOutput: string = '';
  backups: any[] = [];

  constructor(
    private _systemService: SystemService,
    private _snackBar: MatSnackBar,
    private _config: ConfigService
  ) { }

  ngOnInit(): void {

    this._systemService.adminGetBackups().subscribe((result: any) => {

      this.loading = false;

      if(result.success) {        
        this.backups = [...result.data]
      }
      else {
        this.errorMessage = result.message;
      }

    }, err => {

      this.loading = false;

      if (err.status != 200) {

        this.errorMessage = `API endpoint: ${ err.status } - ${ err.statusText }`;

      }

    });

  }

  createBackup() {

    this.createBackupErrorMessage = '';

    this._systemService.adminCreateNewBackup().subscribe((result: any) => {

      if(result.success) {
        this.backupScriptOutput = result.data;

        this._systemService.adminGetBackups().subscribe((result: any) => {
          this.backups = [...result.data]
        });

      }
      else {
        this.createBackupErrorMessage = result.message;
      }

    }, err => {

      if(err.status != 200) {
        
        this.createBackupErrorMessage = `API endpoint: ${ err.status } - ${ err.statusText }`;

      }

    });

  }

  downloadBackupFile(backupName: string) {

    this._systemService.adminDownloadBackup(backupName).subscribe((result: any) => {

      if(result && result.success) {
        window.open(this._config.getApiUrl() + "/download/backup/" + result.data, "_blank");
      }
      else {
        this._snackBar.open(result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });      
      }

    }, err => {
      if (err.status != 200) {
        this._snackBar.open(`${ err.status } - ${ err.statusText }`, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });

  }

}
