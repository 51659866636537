import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditLevelComponent } from './edit-level/edit-level.component';
import { AddLevelMemberComponent } from './add-level-member/add-level-member.component';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { LevelService } from 'src/app/services/level.service';
import { ChangeCoordinatorComponent } from './change-coordinator/change-coordinator.component';


@Component({
  selector: 'app-level-admin-menu',
  templateUrl: './level-admin-menu.component.html',
  styleUrls: ['./level-admin-menu.component.scss']
})
export class LevelAdminMenuComponent implements OnInit {

  @Input() mode: string;     
  @Input() wg: string;    
  @Input() id: string;

  @Output() levelDelete = new EventEmitter<any>(); 

  editLevelDialogRef: MatDialogRef<EditLevelComponent>;
  addLevelMemberDialogRef: MatDialogRef<AddLevelMemberComponent>;
  changeCoordinatorDialogRef: MatDialogRef<ChangeCoordinatorComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _levelService: LevelService,
  ) { }

  ngOnInit(): void {

    //console.log(this.mode)
  }

  editLevel(): void {

    this.editLevelDialogRef = this._dialog.open(
      EditLevelComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id
        },
        disableClose: true
      }
    );

    this.editLevelDialogRef.afterClosed().subscribe(result => {
      if (result) {        

        
        // tmp
        window.location.reload();

      }
    });

  }

  addMember() {

    this.addLevelMemberDialogRef = this._dialog.open(
      AddLevelMemberComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id
        },
        disableClose: true
      }
    );

    /*
    this.addLevelMemberDialogRef.afterClosed().subscribe(result => {
      if (result) {        

        // @todo
        // window.location.reload();

        // we need to notify member component to update the users list

      }
    });*/

  }

  deleteLevel() {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this level?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._levelService.deleteLevel(this.id).subscribe((result: any) => {

          if (result.success) {
            this._snackBar.open('Level deleted successfully', '', {
              duration: 2000
            });

            this.levelDelete.emit(this.id)
          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }

        }, err => {

          if (err.status != 200) {

            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });

          }
        });
      }
    })

  }

  changeCoordinator() {
    
    this.changeCoordinatorDialogRef = this._dialog.open(
      ChangeCoordinatorComponent,
      {
       minWidth: '450px',
        data: {
          level_id: this.id
        },
        disableClose: true
      }
    );

    this.changeCoordinatorDialogRef.afterClosed().subscribe(result => {
      if (result) {   

        // @todo
        window.location.reload();

      }
    });

  }

}
