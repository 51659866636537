export default [
    {
        name: 'Home',
        link: '/',
        icon: 'home'
    }, {
        name: 'News',
        link: '/news',
        icon: 'description'
    }, {
        name: 'Events',
        link: '/events',
        icon: 'event'
    }, {
        name: 'Wiki',
        link: '/wiki',
        icon: 'assignment'
    }, {
        name: 'Community',
        link: '/community',
        icon: 'supervisor_account'
    }, {
        name: 'Collab. interests',
        link: '/i',
        icon: 'layers'
    }, {
        name: 'Projects',
        link: '/p',
        icon: 'filter_alt'
    }, {
        name: 'Blog',
        link: '/blog',
        icon: 'rss_feed'
    }
]