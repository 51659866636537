import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  viewFile(levelId: string, fileId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/file/view/' + levelId + '/' + fileId, this._httpOptionsService.getHeader()) 
  }

  getFiles(levelId: string, folderId?: string) {
    return (!folderId) ? 
        this._http.get(this._config.getApiUrl() + '/api/file/' + levelId, this._httpOptionsService.getHeader()) 
        :
        this._http.get(this._config.getApiUrl() + '/api/file/' + levelId + '/' + folderId, this._httpOptionsService.getHeader());
  }

  deleteFile(levelId: string, fileId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/file/' + levelId + '/' + fileId, this._httpOptionsService.getHeader())
  }

  deleteAvatar() {
    return this._http.delete(this._config.getApiUrl() + '/api/file/avatar/', this._httpOptionsService.getHeader())
  }

  deleteBackground() {
    return this._http.delete(this._config.getApiUrl() + '/api/file/background/', this._httpOptionsService.getHeader())
  }

  saveCroppedImage(levelId: string, type: string, image: any) {
    return this._http.post(this._config.getApiUrl() + '/api/file/crop', {
      level_id: levelId,
      type: type,
      image: image
    }, this._httpOptionsService.getHeader())
  }

  saveCroppedImageUser(userId: string, image: any) {
    return this._http.post(this._config.getApiUrl() + '/api/file/crop/user', {
      user_id: userId,
      image: image
    }, this._httpOptionsService.getHeader())
  }

  saveCroppedBackgroundUser(userId: string, image: any) {
    return this._http.post(this._config.getApiUrl() + '/api/file/crop/background', {
      user_id: userId,
      image: image
    }, this._httpOptionsService.getHeader())
  }

  deletePublicImage(type: string, levelId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/file/public/' + type + '/' + levelId, this._httpOptionsService.getHeader())
  }

  addFolder(levelId: string, name: string, folderId: string) {
    return this._http.post(this._config.getApiUrl() + '/api/file/folder', {
      level_id: levelId,
      name: name,
      folder_id: folderId
    }, this._httpOptionsService.getHeader())
  }

  renameFolder(levelId: string, name: string, folderId: string) {
    return this._http.put(this._config.getApiUrl() + '/api/file/folder', {
      level_id: levelId,
      name: name,
      folder_id: folderId
    }, this._httpOptionsService.getHeader())
  }

  downloadFile(levelId: string, fileId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/file/prepare/' + levelId + '/' + fileId, this._httpOptionsService.getHeader())
  }

  moveUp(fileId: string, folderId: string) {
    return this._http.put(this._config.getApiUrl() + '/api/file/move/up/' + fileId + ((folderId) ? ('/' + folderId) : ''), {}, this._httpOptionsService.getHeader())
  }

  moveDown(fileId: string, folderId: string) {
    return this._http.put(this._config.getApiUrl() + '/api/file/move/down/' + fileId + ((folderId) ? ('/' + folderId) : ''), {}, this._httpOptionsService.getHeader())
  }

}
