<div class="container">
    <div class="row">
        <div class="col my-4 ">

            <ng-container *ngIf="page == 'add-user'">
                <app-add-user></app-add-user>
            </ng-container>
            <ng-container *ngIf="page == 'view-user'">
                <app-admin-show-users></app-admin-show-users>
            </ng-container>
            <ng-container *ngIf="page == 'emails'">
                <app-admin-view-emails></app-admin-view-emails>
            </ng-container>
            <ng-container *ngIf="page == 'logs'">
                <app-admin-view-logs></app-admin-view-logs>
            </ng-container>
            <ng-container *ngIf="page == 'status'">
                <app-admin-system-status></app-admin-system-status>
            </ng-container>
            <ng-container *ngIf="page == 'database'">
                <app-admin-database></app-admin-database>
            </ng-container>

        </div>
    </div>
</div>