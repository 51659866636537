import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LevelService } from 'src/app/services/level.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-workspace-grid-view',
  templateUrl: './workspace-grid-view.component.html',
  styleUrls: ['./workspace-grid-view.component.scss']
})
export class WorkspaceGridViewComponent implements OnInit {

  _levels: any = [];

  @Input() set levels(levels: any) { this._levels = levels }

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _levelService: LevelService,
    private _snackBar: MatSnackBar
  ) { }
 
  ngOnInit(): void {
  }

  deleteLevel(levelId: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this platform? This action is irreversible!',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._levelService.deleteLevel(levelId).subscribe((result: any) => {

          if (result.success) {
            let length = this.levels.length;
            for(let i = 0; i < length; i++) {
              if(this.levels[i]['_id'] == levelId) {
                this.levels.splice(i, 1);                
                i--;
                length--;                
              }
            }
          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }

        }, err => {

          if (err.status != 200) {

            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });

          }
        });
      }
    });

  }

}
