import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ChatService } from '../services/chat.service';
import { SocketService } from '../services/socket.service';
import { ConfirmDialogComponent } from '../utils/confirm-dialog/confirm-dialog.component';
import { NewMessageDialogComponent } from './new-message-dialog/new-message-dialog.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy { 

  private routerSubscription: Subscription;

  isHandset$: Observable<boolean> = this._breakpointObserver.observe('(max-width: 1000px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  loading: boolean = true;

  chats: any[] = [];
  chatId: string;
  user: any;

  newMessageDialogRef: MatDialogRef<NewMessageDialogComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  @ViewChild('chatDrawer') public drawer: MatDrawer;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _chatService: ChatService,
    private _socketService: SocketService,
    private _titleService: Title,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this._titleService.setTitle('Messages | ExArca');

    this._chatService.chatFocusStatus(true);
    //this._chatService.messagesNumber$.next(0);

    this._chatService.getUserChats().subscribe((result: any) => {
      
      if(result.success) {

        this.chats = result.data;
        
        if(this.chats.length > 0) {


          let paramChatId = (this._route.firstChild) ? this._route.firstChild.snapshot.paramMap.get('chatId') : null;

          if(paramChatId) {

            this.chats.map((chat, index) => {

              if(chat._id == paramChatId) {

                this.chatId = this.chats[index]._id;
                this.user = this.chats[index].other_user;
                this.chats[index].selected = true;

              }

            });

          }

          /*
          else {
            this.chatId = this.chats[0]._id;
            this.user = this.chats[0].other_user;
            this.chats[0].selected = true;
          }*/
          
        }

        this.loading = false;
        
      }
      else {    

        this.loading = false;

        this._snackBar.open(result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });

      }

    }, err => {

      if (err.status != 200) {

        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snafckbar']
        });

      }
    });

    this._socketService.on('incoming-message', (data) => {

      if(data.user_id) {

        if(this.chats.length > 0) {
          for (let i = 0; i < this.chats.length; i++) {
          
            if(this.chats[i].other_user._id == data.user_id) {
              
              this.chats[i].last_text = data.message;

              this.chats[i].newMessages = true;

            }
            
          }
        }
        else {
          window.location.reload();
        }

      }

    });

    this.routerSubscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {

        if(this.chats.length > 0) {

          this.chats.map((chat, index) => {
            chat.selected = false;
          });

          let paramChatId = this._route.firstChild.snapshot.paramMap.get('chatId');

          if(paramChatId) {

            let found = false;

            this.chats.map((chat, index) => {

              if(chat._id == paramChatId) {

                if(this.chats[index].unread) {

                  // zmanjšam ocifro
                  //this._chatService.messagesNumber$.next(-1);
                  this._chatService.messagesNumber$.next(0);

                  this.chats[index].unread = false;
                }


                this.chatId = this.chats[index]._id;
                this.user = this.chats[index].other_user;
                this.chats[index].selected = true;
                


                found = true;
              }

            });

            if(!found) {
              this.chatId = this.chats[0]._id;
              this.user = this.chats[0].other_user;
              this.chats[0].selected = true;
            }

          }
          else {
            this.chatId = this.chats[0]._id;
            this.user = this.chats[0].other_user;
            this.chats[0].selected = true;
          }

        }
        else {
          this._snackBar.open('Chat not found', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
          this._router.navigate(['/messages/']);
        }

      }
    });

  }

  newMessage(): void {

    this.newMessageDialogRef = this._dialog.open(
      NewMessageDialogComponent,
      {
       minWidth: '450px',
        data: {                 
        },
        disableClose: false
      }
    );

    this.newMessageDialogRef.afterClosed().subscribe(result => {
      if (result) {

        window.location.reload()
      }
    });

  }

  selectChat(index: number) {

    const isSmallScreen = this._breakpointObserver.isMatched(
      "(max-width: 1000px)"
    );
    if (isSmallScreen) {
      this.drawer.toggle();
    }
    
    this._router.navigate(['/messages/' + this.chats[index]._id])

  }

  deleteChat(id: string) {
    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this chat?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {


        this._chatService.deleteChat(id).subscribe((result: any) => {

          if(result.success) {
    
            this.onDeleteChat(id);
            
          }
          else {    
    
            this._snackBar.open(result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
    
          }
    
        }, err => {
    
          if (err.status != 200) {
    
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
    
          }
        });

      }
    });
  }

  onDeleteChat(id: string) {

    let deleteIndex = -1;
    
    for (let index = 0; index < this.chats.length; index++) {
      
        if(this.chats[index]._id == id) {
          deleteIndex = index;
        }

    }

    if(deleteIndex != -1) {
      this.chats.splice(deleteIndex, 1)
    }

    if(this.chats.length > 0) {
      this.chatId = this.chats[0]._id;
      this.user = this.chats[0].other_user;
      this.chats[0].selected = true;
    }

  }

  toggleDrawer() {
    this.drawer.toggle();
  }

  ngOnDestroy() {
    this._chatService.chatFocusStatus(false);

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}
