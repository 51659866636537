<div class="container my-2" *ngIf="!loading">

    <div class="h-40px-xl"></div>

    <div class="row">
        <div class="col-12 col-lg-12">

            <h1 class="mat-h1 heading-stripe mb-5">{{'Account settings' | translate}}</h1>


            <mat-tab-group mat-align-tabs="start">
                <mat-tab>

                    <ng-template mat-tab-label>
                        <mat-icon class="me-2">person</mat-icon>
                        Profile
                    </ng-template>

                    <div class="col-12 col-xl-8 my-5">

                        <form [formGroup]="mainForm" (ngSubmit)="onSubmit()" novalidate class="mt-4">


                            <div class="position-relative" style="width: 120px;">
                                <app-user-avatar width="120" fontSize="30px" [info]="user"></app-user-avatar>

                                <div class="admin-edit-button-above-image">
                                    <button type="button" mat-mini-fab color="accent" class="ms-auto" (click)="changeAvatar()">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                </div>
                            </div>


                            <div class="row my-4">



                                <div class="col-12 col-xl-6">

                                    <div>

                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'E-mail' | translate}}</mat-label>
                                            <input matInput formControlName="email">
                                        </mat-form-field>

                                    </div>


                                    <div>

                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'First name' | translate}}</mat-label>
                                            <input matInput formControlName="firstName">
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'Last name' | translate}}</mat-label>
                                            <input matInput formControlName="lastName">
                                        </mat-form-field>

                                    </div>

                                    <div>

                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'Password' | translate}}</mat-label>
                                            <input matInput formControlName="password">
                                            <mat-hint align="start">{{'At least 8 characters long' | translate}}</mat-hint>
                                        </mat-form-field>

                                    </div>


                                </div>

                                <div class="col-12 col-md-6 d-flex justify-content-center align-items-center">
                                    
                                </div>

                            </div>


                            <button mat-raised-button color="primary" type="submit">
                            {{'Update' | translate}}
                        </button>

                        </form>

                    </div>
                </mat-tab>
                <mat-tab>

                    <ng-template mat-tab-label>
                        <mat-icon class="me-2">settings</mat-icon>
                        Settings
                    </ng-template>

                    <div class="my-2">

                        <div class="my-5">

                            <div class="d-flex">

                                <div style="width: 100px;" class="d-flex justify-content-center">
                                    <mat-slide-toggle color="primary" class="text-muted mat-caption" [(ngModel)]="!user.stop_email" (change)="changeEmailNotification(user.stop_email)"></mat-slide-toggle>
                                </div>

                                <div>
                                    <h3 class="mb-0">Enable/disable receiving notification emails</h3>
                                    <div class="mat-caption text-muted">Enable or disable receiving non-cruicial emails in your inbox.</div>
                                </div>
                               
                            </div>
                        </div>


                        <div class="my-5">

                            <div class="d-flex">


                                <ng-container *ngIf="!user.request_to_delete; else requestMade">

                                    <div style="width: 100px;" class="d-flex justify-content-center">
                                        <button mat-mini-fab color="warn" (click)="deleteAccount()">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>

                                    <div>
                                        <h3 class="mb-0">Delete your account <span style="font-size: .9em" class="text-danger">(irreversible action)</span></h3>
                                        <div class="mat-caption text-muted">Your account will be deleted in 48 hours. <i>During that period you can cancel deletion</i>.</div>
                                    </div>

                                </ng-container>

                                <ng-template #requestMade>

                                    <div style="width: 100px;" class="d-flex justify-content-center">
                                        <button mat-mini-fab color="warn" (click)="recoverAccount()">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </div>

                                    <div>
                                        <h3 class="mb-0">Your account will be deleted at <span class="fw-bold">{{ deleteDate | date }}</span></h3>
                                        <button mat-button color="warn" (click)="recoverAccount()">
                                            <mat-icon>close</mat-icon>
                                            Cancel request
                                        </button>
                                    </div>
                                    
                                </ng-template>
    
                               

                            </div>

                           
                        </div>

                    </div>
                </mat-tab>
            </mat-tab-group>

        </div>

    </div>

</div>