import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  adminGetUsers() {
    return this._http.get(this._config.getApiUrl() + '/api/user', this._httpOptionsService.getHeader())
  }

  adminAddUser(
    email: string,
    first_name: string,
    last_name: string,
    password: string,
    role: string
  ) {

    return this._http.post(this._config.getApiUrl() + '/api/user', {          
      email: email,
      first_name: first_name,
      last_name: last_name,
      password: password,
      role: role,
    }, this._httpOptionsService.getHeader());

  }

  adminEditUser(
    userId: string,
    email: string,
    first_name: string,
    last_name: string,
    password: string,
    role: string,
    stopEmail: boolean
  ) {

    return this._http.put(this._config.getApiUrl() + '/api/user/' + userId, {          
      email: email,
      first_name: first_name,
      last_name: last_name,
      password: password,
      role: role,
      stop_email: stopEmail
    }, this._httpOptionsService.getHeader());

  }

  adminToggleExperiment(userId: string, experiment_test: boolean) {
    return this._http.put(this._config.getApiUrl() + '/api/user/experiment/' + userId, {
      experiment_test: experiment_test
    }, this._httpOptionsService.getHeader())
  }

  adminChangeActivatedStatus(userId: string, status: boolean) {
    return this._http.put(this._config.getApiUrl() + '/api/user/active/' + userId, {
      status: status
    }, this._httpOptionsService.getHeader())
  }

  adminChangedValidatedStatus(userId: string, status: boolean) {
    return this._http.put(this._config.getApiUrl() + '/api/user/email/validate/' + userId, {
      status: status
    }, this._httpOptionsService.getHeader())
  }

  adminSendValidationEmail(userId: string) {
    return this._http.put(this._config.getApiUrl() + '/api/user/send/validation/email/' + userId, {}, this._httpOptionsService.getHeader())
  }

  adminDeleteUser(userId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/user/' + userId, this._httpOptionsService.getHeader())
  }

  adminChangeCredit(userId: string, action: string) {
    return this._http.post(this._config.getApiUrl() + '/api/user/credit', {          
      user_id: userId,
      action: action
    }, this._httpOptionsService.getHeader());
  }

  adminJoinUsers(userId1: string, userId2: string) {
    return this._http.post(this._config.getApiUrl() + '/api/user/join', {          
      user_id1: userId1,
      user_id2: userId2,
    }, this._httpOptionsService.getHeader());
  }

  editUser(
    email: string,
    first_name: string,
    last_name: string,
    password: string
  ) {

    return this._http.put(this._config.getApiUrl() + '/api/user/', {          
      email: email,
      first_name: first_name,
      last_name: last_name,
      password: password
    }, this._httpOptionsService.getHeader());

  }

  updateUserField(field: string, value: string) {

    return this._http.put(this._config.getApiUrl() + '/api/user/field', {          
      field: field,
      value: value
    }, this._httpOptionsService.getHeader());

  }

  updateSocial(facebook: string, twitter: string, linkedin: string) {

    return this._http.put(this._config.getApiUrl() + '/api/user/social', {          
      facebook: facebook,
      twitter: twitter,
      linkedin: linkedin
    }, this._httpOptionsService.getHeader());

  }

  emailReceivingSettings(status: boolean) {
    return this._http.put(this._config.getApiUrl() + '/api/user/email/receive', {          
      status: status
    }, this._httpOptionsService.getHeader());
  }

  requestToDeleteAccount() {
    return this._http.put(this._config.getApiUrl() + '/api/user/request/delete', {          
      status: status
    }, this._httpOptionsService.getHeader());
  }

  searchConnectedUsers(search: string) {
    return this._http.get(this._config.getApiUrl() + '/api/user/search/' + search, this._httpOptionsService.getHeader())
  }

  getConnectedUsers(activatedUser?: boolean) {
    return this._http.get(this._config.getApiUrl() + '/api/user/connected/' + activatedUser, this._httpOptionsService.getHeader())
  }

  getAdminLevelsAtTheSameLevel(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/user/same/level/' + levelId, this._httpOptionsService.getHeader())
  }

  /**
   * Get user info for profile
   * @param userId 
   * @returns 
   */
  getUser(userId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/user/spec/' + userId , this._httpOptionsService.getHeader())
  }
  
  // User settings (open chatboxes, sidebar, theme)
  saveUserSettings(setting: string, value: string) {
    return this._http.put(this._config.getApiUrl() + '/api/user/update/settings/' + setting, {          
      value: value
    }, this._httpOptionsService.getHeader());
  }

}
