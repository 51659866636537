<div class="row">
    <div class="col-12 col-lg-6 col-xxl-4 my-4 px-xxl-3" *ngFor="let l of _levels">

        <mat-card class="position-relative workspace-grid d-flex flex-column" style="height: 100%;">

            <div class="flex-fill">
                <ng-container *ngIf="l.owner">
                    <div class="d-none d-md-block">
                        <div class="admin-edit-button-above-image">
                            <button mat-mini-fab color="primary" class="ms-auto" routerLink="/p/{{ l._id }}" matTooltip="You are the owner">
                                <mat-icon>grade</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>

                <div class="pointer" routerLink="/p/{{ l._id }}">
                    <app-level-background [data]="l.background_image_url" rootClass="level-background mb-3">
                    </app-level-background>
                </div>


                <div class="d-flex flex-column p-3" routerLink="/p/{{ l._id }}">

                    <div class="d-flex justify-content-center w-100">
                        <div id="platform-avatar" class="position-relative">

                            <ng-container *ngIf="l.owner">
                                <div class="d-block d-md-none">
                                    <div class="admin-edit-button-above-image">
                                        <button mat-mini-fab color="primary" class="ms-auto" routerLink="/p/{{ l._id }}" matTooltip="You are the owner">
                                            <mat-icon>grade</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>

                            <app-level-avatar [data]="l.avatar_url"></app-level-avatar>
                        </div>
                    </div>

                    <div class="flex-fill">
                        <div class="mb-3 platform-title">{{ l.name | truncate : 80 }}</div>
                        <mat-card-subtitle class="mb-0 platform-subtitle">{{ l.description | mystriphtml | truncate:150
                            }}</mat-card-subtitle>
                    </div>

                </div>

            </div>
            <mat-card-actions class="mt-3">
                <button mat-button color="primary" routerLink="/p/{{ l._id }}" class="text-uppercase">{{ 'Visit' |
                    translate }}</button>
                <ng-container *ngxPermissionsOnly="['ADMIN']">
                    <button mat-button color="warn" (click)="deleteLevel(l._id)" class="text-uppercase">{{ 'Delete' |
                        translate }}</button>
                </ng-container>
            </mat-card-actions>
        </mat-card>

    </div>
</div>