<div class="d-flex align-items-center mb-2">
    <h2 mat-dialog-title class="mb-0">{{ data.title }}</h2>

    <div class="ms-auto">
        <button mat-button color="secondary" [mat-dialog-close]="false">
            <mat-icon>clear</mat-icon>
        </button>
    </div>

</div>
<mat-dialog-content class="mb-2">
    
    

    <div [innerHTML]="data.description | sanitizeHtml"></div>

</mat-dialog-content>
<mat-dialog-actions>
        
    <button mat-raised-button color="primary" class="me-2"  [mat-dialog-close]="true">View</button>
    <button mat-raised-button [mat-dialog-close]="false">Close</button>

</mat-dialog-actions>