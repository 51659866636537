import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpOptionsService } from './http-options.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _httpOptionsService: HttpOptionsService
  ) { }

  addNews(
    levelId: string, 
    name: string, 
    description: string, 
    ) {

    return this._http.post(this._config.getApiUrl() + '/api/news', {    
      level_id: levelId,
      name: name,
      description: description
    }, this._httpOptionsService.getHeader())

  }

  editNews(newsId: string, levelId: string, name: string, description: string) {

    return this._http.put(this._config.getApiUrl() + '/api/news/' + newsId, {    
      level_id: levelId,
      name: name,
      description: description
    }, this._httpOptionsService.getHeader())

  }

  deleteNews(newsId: string, levelId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/news/' + levelId + '/' + newsId, this._httpOptionsService.getHeader())
  }

  removeNews(newsId: string, levelId: string) {
    return this._http.delete(this._config.getApiUrl() + '/api/news/remove/' + levelId + '/' + newsId, this._httpOptionsService.getHeader())
  }

  getNews(levelId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/news/' + levelId, this._httpOptionsService.getHeader())
  }

  getRelatedNews(levelId: string, newsId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/news/related/' + levelId + '/' + newsId, this._httpOptionsService.getHeader())
  }

  getSpecificNews(levelId: string, newsId: string) {
    return this._http.get(this._config.getApiUrl() + '/api/news/' + levelId + '/' + newsId, this._httpOptionsService.getHeader())
  }

  getAvailableNews(levelId: string, targetLevelId: string, root?: boolean) {
    return this._http.get(this._config.getApiUrl() + '/api/news/available/' + levelId + '/' + targetLevelId + '/' + root, this._httpOptionsService.getHeader())
  }

  importNews(levelId: string, news: any[]) {
    return this._http.post(this._config.getApiUrl() + '/api/news/import', {    
      level_id: levelId,
      news: news
    }, this._httpOptionsService.getHeader())
  }
}
