import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-level-background',
  templateUrl: './level-background.component.html',
  styleUrls: ['./level-background.component.scss']
})
export class LevelBackgroundComponent implements OnInit {

  image: any;

  @Input() set data(data: any) {
    this.image = data;    

    if(this.image && !this.image.includes(this._config.getApiUrl())) {
      this.image = this._config.getApiUrl() + this.image;
    }
  }
  @Input() rootClass: string;

  constructor(
    private _config: ConfigService,
  ) { }

  ngOnInit(): void {

    if(this.image) {     
      
      if(!this.image.includes(this._config.getApiUrl())) {
        this.image = this._config.getApiUrl() + this.image;
      }
    }
  }

}
