<ng-container *ngIf="loading">

    <div class="text-center">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

</ng-container>
<ng-contaienr *ngIf="!loading">

    <div *ngIf="chats.length == 0" class="container h-100">

        <div class="row h-100">

            <div class="col pb-md-4">
                <div class="p-2 d-flex align-items-center justify-content-center h-100">

                    <div>
                        <img src="./assets/empty.png">

                        <h2 class="mat-h2">You don't have any messages</h2>

                        <div class="mt-3 text-center">
                            <button mat-raised-button color="primary" (click)="newMessage()">
                                <mat-icon>message</mat-icon> Send a Message
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>

    <div class="container-fluid h-100 d-flex flex-column" *ngIf="chats.length > 0">

        <mat-toolbar class="mb-4" style="height: auto; min-height: 64px;">

            <div class="d-flex w-100 align-items-center">

                <h1 class="mb-0">
                    Messages
                </h1>


                <div class="ms-auto">
                    <button mat-raised-button color="secondary" class="small-btn" (click)="newMessage()">
                        <mat-icon>message</mat-icon>
                        <span class="d-none d-md-inline ms-1">New Message</span>
                    </button>
                </div>

            </div>

            

        </mat-toolbar>

        <div class=" p-2 flex-fill mb-4" id="inbox">


            <mat-sidenav-container class="h-100">

                <mat-sidenav #chatDrawer position="start" class="chat-messages" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="((isHandset$ | async) === false)">

                    <ul class="chat-list" style="overflow-y: auto;">
                        <li *ngFor="let c of chats; let i = index" [ngClass]="{ 'selected': c.selected }"
                            class="pointer" (click)="selectChat(i)">
                            <div class="d-flex align-items-center">

                                <div style="width: 25px;">
                                    <div *ngIf="c.unread"
                                        style="background: blue; width: 8px; height: 8px; border-radius: 50%;">
                                    </div>
                                </div>
                                <div class="flex-fill">
                                    <div class="d-flex align-items-center w-100 pe-2">

                                        <ng-container *ngIf="c.other_user">

                                            <ng-container *ngFor="let u of c.other_user">
                                                <app-user-avatar width="30" fontSize="12px" [info]="u"
                                                    disableLink="true"></app-user-avatar>

                                                <div class="ms-2" *ngIf="c.other_user.length == 1">
                                                    <h5 class="mb-0">{{ u.full_name }}</h5>
                                                </div>
                                            </ng-container>

                                        </ng-container>


                                        <div class="ms-auto">
                                            <button mat-icon-button class="ms-auto" [matMenuTriggerFor]="menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">                                
                                                <button mat-menu-item (click)="deleteChat(c._id)">
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Delete</span>
                                                </button>
                                            </mat-menu>
                                        </div>

                                    </div>

                                    <div class="last-text" *ngIf="c?.last_text">{{ c.last_text | truncate : 150 }}</div>
                                </div>

                            </div>
                        </li>
                    </ul>

                </mat-sidenav>

                <mat-sidenav-content class="h-100 d-flex flex-column">

                    <ng-container *ngIf="(isHandset$ | async)">

                        <mat-toolbar class="d-flex align-items-center">
                            <button mat-raised-button class="small-btn me-2" (click)="toggleDrawer()">
                                <mat-icon>menu_open</mat-icon>
                            </button>

                            <ng-container *ngIf="user && user.length > 0">

                                <div class="ms-auto">

                                    <ng-container *ngFor="let u of user">
                                        
                                        <app-user-avatar width="30" fontSize="12px" [info]="u"></app-user-avatar>

                                    </ng-container>
                                </div>

                            </ng-container>
                        </mat-toolbar>

                        
                    </ng-container>

                    <div class="p-4 h-100">                    

                        <ng-container *ngIf="!chatId; else showMessages">
                            <div class="d-flex flex-wrap ms-4 ms-md-0">
                                <div>
                                    <img src="/assets/windy-arrow-messages.png" style="transform: rotate(-180deg);">
                                </div>
                                <div class="fw-boldest mat-h3" style="color: #d8d8d8">Select conversation ...</div>
                            </div>
                        </ng-container>
                        <ng-template #showMessages>
                            <app-conversation-view class="h-100" [chatId]="chatId" [user]="user" (deletedChat)="onDeleteChat($event)">
                            </app-conversation-view>
                        </ng-template>

                    </div>

                </mat-sidenav-content>

            </mat-sidenav-container>


            <!--
            <div class="row h-100">

                <div class="col-12 col-lg-3">

                    <div class="mat-elevation-z1">

                        <ul class="chat-list" style="overflow-y: auto;">
                            <li *ngFor="let c of chats; let i = index" [ngClass]="{ 'selected': c.selected, 'd-none': c.hide }"
                                class="pointer" (click)="selectChat(i)">
                                <div class="d-flex">

                                    <div style="width: 15px;">
                                        <div *ngIf="c.newMessages"
                                            style="margin-top: 13px; background: blue; width: 5px; height: 5px; border-radius: 50%;">
                                        </div>
                                    </div>
                                    <div class="flex-fill">
                                        <div class="d-flex align-items-center w-100 pe-2">

                                            <ng-container *ngIf="c.other_user">

                                                <ng-container *ngFor="let u of c.other_user">
                                                    <app-user-avatar width="30" fontSize="12px" [info]="u"
                                                        disableLink="true"></app-user-avatar>

                                                    <div class="ms-2" *ngIf="c.other_user.length == 1">
                                                        <h5 class="mb-0">{{ u.full_name }}</h5>
                                                    </div>
                                                </ng-container>

                                            </ng-container>


                                            <div class="ms-auto">
                                                <button mat-icon-button class="ms-auto" [matMenuTriggerFor]="menu">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">                                
                                                    <button mat-menu-item (click)="deleteChat(c._id)">
                                                        <mat-icon>delete</mat-icon>
                                                        <span>Delete</span>
                                                    </button>
                                                </mat-menu>
                                            </div>

                                        </div>

                                        <div class="last-text" *ngIf="c?.last_text">{{ c.last_text | truncate : 150 }}</div>
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>

                </div>

                <div class="col-12 col-lg-9 flex-fill">

                    <app-conversation-view [chatId]="chatId" [user]="user" (deletedChat)="onDeleteChat($event)">
                    </app-conversation-view>

                </div>

            </div>-->

        </div>

    </div>
</ng-contaienr>