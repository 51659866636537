import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  saved: number;      // for editor helper

  user: any;
  article: any;
  articles: any = [];
  resultsLength = 0;
  edit: boolean = false;
  _blogId: string = '';

  loading: boolean = true;
  create: boolean = false;
  text: string = '';

  url: string;

  discussions: any = [];

  @Input() id: string;      // level id
  @Input() role: number;    // user role
  

  @Input() set blogId(id: string) {    
    this._blogId = id;
    
    this.url = this._router.url;
    this.loading = true;
    this.showBlog();

  }

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _blogService: BlogService,
    private _fb: UntypedFormBuilder,
    private _router: Router,
    private _userinfoService: UserinfoService
  ) { }

  ngOnInit(): void {
    this.user = this._userinfoService.getLocalInfo();    
  }

  showBlog() {

    if(!this._blogId) {
      this.getBlogs().then((data: any) => {
        this.loading = false;


        this.articles = [...data];
        this.resultsLength = data.length;

      });
    }
    else {

      this.getSpecificBlog().then((data: any) => {
        this.loading = false;

        this.article = data;

        this.discussions = [...data.discussions];

      });

    }

  }

  createForm(data?: any) {    

    this._blogId = '';

    if(data) {
      this.edit = true;
      this._blogId = data._id;
    }
    else {
      this.edit = false;
    }

    this.mainForm = this._fb.group({
      level_id: [this.id, Validators.required],
      name: [(data && data.name) ? data.name : '', [Validators.required]],
      description: [(data && data.description) ? data.description : '', Validators.required]     
    });

    this.text = (data && data.description) ? data.description : '';

  }


  getBlogs() {
    return new Promise((resolve, reject) => {

      this._blogService.getBlogs(this.id).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve([]);

          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });
  }
  
  addArticle() {
    this.createForm();
    this.create = true;
  }

  cancel() {
    this.create = false;
  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      let action = (!this.edit) ? 
      this._blogService.addBlog(
        this.mainForm.value.level_id,
        this.mainForm.value.name,
        this.mainForm.value.description
      ) : 
      this._blogService.editBlog(
        this._blogId,
        this.mainForm.value.level_id,
        this.mainForm.value.name,
        this.mainForm.value.description
      )
      ;

      action.subscribe((result: any) => {
  
        if (result.success) {

          this.create = false;          
          
          if(this.article && this.edit) {

              this.article.name = this.mainForm.value.name;
              this.article.description = this.mainForm.value.description;

          }
          else {
            this.articles.unshift(result.data);
            this.resultsLength++;
          }

          

        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

  onCkEditorValue(text: string) {
    this.mainForm.patchValue({
      description: text
    });
  }

  editArticle(article: any) {

    this.createForm(article);
    this.create = true;
    
  }

  deleteArticle(_blogId: string) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          title: 'Are you sure?',
          text: 'That you want to delete this article?',
          leftButton: 'Cancel',
          rightButton: 'Yes'
        },
        disableClose: true
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {

        this._blogService.deleteBlog(_blogId, this.id).subscribe((result: any) => {

          if (result.success) {

            this._router.navigateByUrl(this._router.url.substr(0, this._router.url.lastIndexOf('/'))); // go to parent URL

          }
          else {
            this._snackBar.open('Error: ' + result.message, '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
  
        }, err => {
  
          if (err.status != 200) {  
            // snackbar
            this._snackBar.open('Error', '', {
              duration: 2000,
              panelClass: ['error-snackbar']
            });
          }
        }); 

      }

    });

  }

  getSpecificBlog() {
    return new Promise((resolve, reject) => {

      this._blogService.getSpecificBlog(this.id, this._blogId).subscribe((result: any) => {

        if (result.success) {
          resolve(result.data);
        }
        else {

          resolve(false);

          /*
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });*/
        }

      }, err => {

        if (err.status != 200) {

          resolve([]);

          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
      });


    });
  }

  onCommentSave(commentInfo: any) {

    this._blogService.addComment(this.id, this._blogId, commentInfo.message).subscribe((result: any) => {

      if (result.success) {

        this.saved = Date.now();         // var for saved article - to empty comment
        
        this.article.number_of_comments++;

        const comment = result.data;
        comment.isOwner = true;

        // trigger event to update comments
        // while pushing to array, we need to update comments
        this.discussions = [...this.discussions, result.data];
      }
      else {
        this._snackBar.open('Error: ' + result.message, '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }

    }, err => {

      if (err.status != 200) {
        // snackbar
        this._snackBar.open('Error', '', {
          duration: 2000,
          panelClass: ['error-snackbar']
        });
      }
    });
  
  }

  backButton() {

    this.create = false;
    this.discussions = [];
    this.article = null;

    let currentUrl = this._router.url;
    let tmpArray = currentUrl.split('/');
    tmpArray.pop();
        
    this._router.navigate([tmpArray.join('/')]);

  }
  

  // we delete comment
  async removeComment(commentId: string) {

    try {
      await this._blogService.deleteComment(this._blogId, commentId).toPromise();

      // we remove it from array
      this.discussions = this.discussions.filter((item: any) => {
        return item._id != commentId;
      });

    } catch(err) {
      this._snackBar.open('Error: ' + err.message, '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }
}
