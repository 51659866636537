import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-events-dialog',
  templateUrl: './events-dialog.component.html',
  styleUrls: ['./events-dialog.component.scss']
})
export class EventsDialogComponent implements OnInit {

  mainForm: UntypedFormGroup;
  submitted: boolean = false;
  isEditMode: boolean = false;

  text: string = '';

  hours: any[] = []
  minutes: any[] = []

  startHour: number = 9;
  startMinutes: number = 0; 
  endHour: number = 10;
  endMinutes: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<EventsDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private _eventsService: EventsService
  ) { 

    for(let i = 0; i < 24; i++) {      
      this.hours.push( i );
    }

    for(let i = 0; i < 60; i++) {
      this.minutes.push( i );
    }

  }

  ngOnInit(): void {
    this.createForm(this.data.current);

    if(this.data.current) {
      this.isEditMode = true;
    }

    this.mainForm.patchValue({
      level_id: this.data.level_id
    });
  }

  createForm(data?: any) {

    if(data) {
      let startDate = new Date(data.start_date);

      data['start_hour'] = startDate.getHours();
      data['start_minutes'] = startDate.getMinutes();

      let endDate = new Date(data.end_date);
      data['end_hour'] = endDate.getHours();
      data['end_minutes'] = endDate.getMinutes();

    }


    this.mainForm = this._fb.group({
      level_id: ['', Validators.required],
      title: [(data && data.title) ? data.title : '', [Validators.required]],
      description: [(data && data.description) ? data.description : ''],
      start_date: [(data && data.start_date) ? data.start_date : '', Validators.required],
      start_hour: (data && data.start_hour) ? data.start_hour : 0,
      start_minutes: (data && data.start_minutes) ? data.start_minutes : 0,
      end_date: [(data && data.end_date) ? data.end_date : '', Validators.required],
      end_hour: (data && data.end_hour) ? data.end_hour : 0,
      end_minutes: (data && data.end_minutes) ? data.end_minutes : 0,
      all_day: [(!data || (data && data.all_day == false)) ? false : true, Validators.required]      
    });

    this.text = (data && data.description) ? data.description : '';
  }

  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      let startDate = new Date(this.mainForm.value.start_date);
      startDate.setHours(this.mainForm.value.start_hour);
      startDate.setMinutes(this.mainForm.value.start_minutes);

      let endDate = new Date(this.mainForm.value.end_date);
      endDate.setHours(this.mainForm.value.end_hour);
      endDate.setMinutes(this.mainForm.value.end_minutes);


      let action = (this.data.current) ?  
        this._eventsService.editEvents(
          this.data.current._id,
          this.mainForm.value.level_id,
          this.mainForm.value.title,
          this.mainForm.value.description,
          startDate,
          //this.mainForm.value.start_hour,
          //this.mainForm.value.start_minutes,
          endDate,
          //this.mainForm.value.end_hour,
          //this.mainForm.value.end_minutes,
          this.mainForm.value.all_day
        ) :
        this._eventsService.addEvent(
          this.mainForm.value.level_id,
          this.mainForm.value.title,
          this.mainForm.value.description,
          startDate,
          //this.mainForm.value.start_hour,
          //this.mainForm.value.start_minutes,
          endDate,
          //this.mainForm.value.end_hour,
          //this.mainForm.value.end_minutes,
          this.mainForm.value.all_day
        );

      action.subscribe((result: any) => {
  
        if (result.success) {
          this._dialogRef.close(result.data);
        }
        else {
          this._snackBar.open('Error: ' + result.message, '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }
        
      }, err => {

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error', '', {
            duration: 2000,
            panelClass: ['error-snackbar']
          });
        }

      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
        panelClass: ['error-snackbar']
      });
    }

  }

  allDayClick(checked: boolean) {
   if(checked) {
    this.mainForm.patchValue({
      start_hour: 0,
      start_minutes: 0,
      end_hour: 0,
      end_minutes: 0
    });
   }
   else {
    this.mainForm.patchValue({
      start_hour: this.startHour,
      start_minutes: this.startMinutes,
      end_hour: this.endHour,
      end_minutes: this.endMinutes
    });
   }
  }

  onCkEditorValue(text: string) {
    this.mainForm.patchValue({
      description: text
    });
  }

}
