<div class="p-4 mat-elevation-z1 bg-white mb-5">

    <div class="d-lg-flex align-items-center text-lg-start" style="text-align: center;">

       
        <img src="/assets/default_avatar.jpg" />
        <div>

            <h2 class="mat-h2">{{'Create your Platform' | translate}}</h2>

            <div class="mat-caption mb-2">
                {{'Create your own Platform and start your virtual collaboration' | translate}}
            </div>

            <button mat-raised-button color="primary" (click)="newPlatform()">
                <mat-icon>add</mat-icon>
                {{'Create platform' | translate}}
            </button>

        </div>
        <div class="ms-auto d-flex justify-content-center">
            <mat-card class="mat-elevation-z0" style="margin-top: 5px;">
                <mat-card-header matTooltip="1 credit is 1 platform" class="w-100 text-center">                                      
                  <mat-card-title>{{credits}}</mat-card-title>
                  <mat-card-subtitle>{{ credits | i18nPlural: creditsMapping | translate :{count: credits} }}</mat-card-subtitle>
                </mat-card-header>
                <!--<mat-card-actions>
                  <button mat-button>LIKE</button>                                      
                </mat-card-actions>-->
              </mat-card>
        </div>

    </div>

</div>