<button mat-mini-fab color="accent" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <!--<button mat-menu-item>
        <mat-icon>reply</mat-icon>
        <span>Invite member</span>
    </button>          --->
    <ng-container *ngIf="mode != 'platform'">
        <button mat-menu-item (click)="addMember()">
            <mat-icon>add</mat-icon>
            <span>{{'Add members' | translate}}</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="editLevel()">
            <mat-icon>create</mat-icon>
            <span>{{'Edit' | translate}} 
                <ng-container *ngIf="!wg">
                    <ng-container *ngIf="mode != 'project'; else project1">{{'interest' | translate}}</ng-container>
                    <ng-template #project1>{{'project' | translate}}</ng-template>
                </ng-container>
                <ng-container *ngIf="wg">
                    <ng-container *ngIf="mode != 'project'; else project2">{{'WG' | translate}}</ng-container>
                    <ng-template #project2>{{'WP' | translate}}</ng-template>
                </ng-container>
            </span>
        </button>
        <button mat-menu-item (click)="changeCoordinator()">
            <mat-icon>face</mat-icon>
            <span>{{'Change coordinator' | translate}}</span>
        </button>
        <button mat-menu-item (click)="deleteLevel()">
            <mat-icon>delete</mat-icon>
            <span>{{'Delete' | translate}} 
                <ng-container *ngIf="!wg">
                    <ng-container *ngIf="mode != 'project'; else project3">{{'interest' | translate}}</ng-container>
                    <ng-template #project3>{{'project' | translate}}</ng-template>
                </ng-container>
                <ng-container *ngIf="wg">
                    <ng-container *ngIf="mode != 'project'; else project4">{{'WG' | translate}}</ng-container>
                    <ng-template #project4>{{'WP' | translate}}</ng-template>
                </ng-container>
            </span>
        </button>
    </ng-container>
    <ng-container *ngIf="mode == 'platform'">
        <!--<mat-divider></mat-divider>-->
        <button mat-menu-item (click)="editLevel()">
            <mat-icon>create</mat-icon>
            <span>{{'Edit platform' | translate}}</span>
        </button>
        <button mat-menu-item (click)="changeCoordinator()">
            <mat-icon>face</mat-icon>
            <span>{{'Change coordinator' | translate}}</span>
        </button>
    </ng-container>
</mat-menu>